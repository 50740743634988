import { makeStyles } from 'tss-react/mui';
export var ResidentTableStyle = makeStyles()(function (theme) { return ({
    avatarStyle: {
        margin: '0 10px 0 0',
        width: '64px',
        height: '64px',
        border: "solid 1px ".concat(theme.palette.customColor.borderGrey)
    },
    tableCellStyle: {
        '&.MuiTableCell-root': {
            verticalAlign: 'middle',
            padding: '6px 0',
        },
    },
}); });
