import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from '@mui/material';
import AppVersion from '../AppVersion/AppVersion';
import { footerStyle } from './Footer.style';
import { useAppSelector } from 'hooks/reduxHooks';
import { appRoutesEndpoints } from 'routes/appRoutesEndpoints';
var Footer = function () {
    var classes = footerStyle().classes;
    var pageRoute = useAppSelector(function (state) { var _a; return (_a = state.router.location) === null || _a === void 0 ? void 0 : _a.pathname; });
    return (_jsx(Box, { component: 'footer', className: classes.container, children: pageRoute === appRoutesEndpoints.homePage && _jsx(AppVersion, {}) }));
};
export default Footer;
