import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { VictoryPie, VictoryAnimation, VictoryLabel } from 'victory';
import './index.css';
import { useCallback } from 'react';
import { roundOff } from 'globals/global.functions';
var CircularProgress = function (_a) {
    var value = _a.value, goal = _a.goal, _b = _a.innerText, innerText = _b === void 0 ? '' : _b, _c = _a.tooltip, tooltip = _c === void 0 ? false : _c, _d = _a.fontSize, fontSize = _d === void 0 ? 120 : _d;
    var showValue = useCallback(function () {
        return goal && value ? "".concat(roundOff(value, 0), "/").concat(goal) : innerText || value;
    }, [value, innerText, goal]);
    return (_jsx("div", { "data-testid": 'circular-progress', children: _jsxs("svg", { viewBox: '0 0 400 400', width: '100%', height: '100%', children: [tooltip && (_jsx("title", { "data-testid": 'circular-progress-tooltip', children: value })), _jsx(VictoryPie, { standalone: false, width: 400, height: 400, data: [
                        { x: 1, y: value },
                        { x: 2, y: goal ? goal - value : 100 - value },
                    ], colorScale: ['#19B3A6', '#EEEEEE'], innerRadius: 120, labels: function () { return null; } }), _jsx(VictoryAnimation, { duration: 1000, data: [
                        { x: 1, y: value },
                        { x: 2, y: goal ? goal - value : 100 - value },
                    ], children: function () {
                        return (_jsx(VictoryLabel, { textAnchor: 'middle', verticalAnchor: 'middle', x: 200, y: 200, text: showValue(), style: { fontSize: fontSize } }));
                    } })] }) }));
};
export { CircularProgress };
