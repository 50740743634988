import { makeStyles } from 'tss-react/mui';
export var footerStyle = makeStyles()(function (theme) { return ({
    container: {
        borderTop: "1px solid ".concat(theme.palette.common.border),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        clear: 'both',
        position: 'relative',
        height: theme.footerHeight,
        marginTop: theme.footerHeight,
        padding: "0 5%",
    },
}); });
