import React from 'react';
var useIntersectionObserver = function (triggerOnTarget) {
    var _a = React.useState(false), inView = _a[0], setInView = _a[1];
    var observerRef = React.useRef(null);
    var intersectionCallback = React.useCallback(function (entries) {
        if (entries[0].isIntersecting) {
            if (!inView) {
                triggerOnTarget();
                setInView(true);
            }
        }
        else {
            if (inView) {
                setInView(false);
            }
        }
    }, [inView, setInView, triggerOnTarget]);
    React.useEffect(function () {
        var observer = new IntersectionObserver(intersectionCallback, {
            threshold: 1,
        });
        if (observerRef.current) {
            observer.observe(observerRef.current);
        }
        return function () {
            if (observerRef.current) {
                observer.unobserve(observerRef.current);
            }
        };
    }, [observerRef, intersectionCallback]);
    return { observerRef: observerRef };
};
export default useIntersectionObserver;
