import LOSGraphModel from '../model/losGraphModel';
import { DATE_FORMAT_YEAR } from 'globals/global.constants';
import moment from 'moment';
import 'moment-timezone';
var LOSGraphParser = /** @class */ (function () {
    function LOSGraphParser() {
        this.losGraphData = {
            overall: {
                data: [],
                score: 0,
            },
            blue: {
                data: [],
                score: 0,
            },
            green: {
                data: [],
                score: 0,
            },
            gold: {
                data: [],
                score: 0,
            },
        };
        this.getDataArray = function (data) {
            var dataArray = Object.entries(data).map(function (entry) {
                var dateString = entry[0], los = entry[1];
                return {
                    timestamp: moment.tz(dateString, 'UTC').format(DATE_FORMAT_YEAR),
                    los: los,
                };
            });
            return dataArray;
        };
    }
    /**
     * @description function to parse the response data
     * @function parse
     * @param {ILOSGraphDataResponse[]} data api response data
     * @returns {ILOSGraphParse} parsed data
     */
    LOSGraphParser.prototype.parse = function (data, currentMonthString) {
        var _a, _b, _c, _d;
        this.losGraphData = new LOSGraphModel({
            data: this.getDataArray((data === null || data === void 0 ? void 0 : data.overall) || {}),
            score: ((_a = data === null || data === void 0 ? void 0 : data.overall) === null || _a === void 0 ? void 0 : _a[currentMonthString]) || 0,
        }, {
            data: this.getDataArray((data === null || data === void 0 ? void 0 : data.blue) || {}),
            score: ((_b = data === null || data === void 0 ? void 0 : data.blue) === null || _b === void 0 ? void 0 : _b[currentMonthString]) || 0,
        }, {
            data: this.getDataArray((data === null || data === void 0 ? void 0 : data.green) || {}),
            score: ((_c = data === null || data === void 0 ? void 0 : data.green) === null || _c === void 0 ? void 0 : _c[currentMonthString]) || 0,
        }, {
            data: this.getDataArray((data === null || data === void 0 ? void 0 : data.gold) || {}),
            score: ((_d = data === null || data === void 0 ? void 0 : data.gold) === null || _d === void 0 ? void 0 : _d[currentMonthString]) || 0,
        });
        return this.losGraphData;
    };
    return LOSGraphParser;
}());
export default LOSGraphParser;
