var openState = {
    data: [],
    lastEvaluatedKey: '',
    loading: false,
    totalRows: 0,
    currentPage: 1,
};
var completedState = {
    data: [],
    lastEvaluatedKey: '',
    loading: false,
    totalRows: 0,
    currentPage: 1,
};
export var initialCIRangeMilestonesStates = {
    open: openState,
    completed: completedState,
};
