var _a;
import { AssessmentName, AssessmentStatuses } from 'globals/enums';
export var initialAssessmentStatus = (_a = {},
    _a[AssessmentName.HOLISTIC] = {
        status: AssessmentStatuses.COMPLETE,
        datetime: null,
    },
    _a[AssessmentName.KATZ_INDEPENDENCE] = {
        status: AssessmentStatuses.COMPLETE,
        datetime: null,
    },
    _a[AssessmentName.LAWTON_BRODY] = {
        status: AssessmentStatuses.COMPLETE,
        datetime: null,
    },
    _a[AssessmentName.MEDICAL_CONDITION] = {
        status: AssessmentStatuses.COMPLETE,
        datetime: null,
    },
    _a[AssessmentName.CAREGIVER_STRAIN] = {
        status: AssessmentStatuses.COMPLETE,
        datetime: null,
    },
    _a[AssessmentName.WELLNESS_SURVEY] = {
        status: AssessmentStatuses.COMPLETE,
        datetime: null,
    },
    _a[AssessmentName.MEDICATION_LIST] = {
        status: AssessmentStatuses.COMPLETE,
        datetime: null,
    },
    _a[AssessmentName.UCLA_LONELINESS] = {
        status: AssessmentStatuses.COMPLETE,
        datetime: null,
    },
    _a[AssessmentName.MEMBER_QUESTIONNAIRE_1] = {
        status: AssessmentStatuses.COMPLETE,
        datetime: null,
    },
    _a[AssessmentName.MEMBER_QUESTIONNAIRE_2] = {
        status: AssessmentStatuses.COMPLETE,
        datetime: null,
    },
    _a[AssessmentName.MEMBER_QUESTIONNAIRE_3] = {
        status: AssessmentStatuses.COMPLETE,
        datetime: null,
    },
    _a);
