import { makeStyles } from 'tss-react/mui';
export var goalStyle = makeStyles()(function (theme) { return ({
    button: {
        maxWidth: '100px',
        minWidth: '80px',
        height: '40px',
        padding: '8px 17.9px 8px 18px',
        borderRadius: '19px',
        boxShadow: '0 10px 15px 0 rgba(0, 126, 154, 0.15)',
        color: '#fff',
        fontSize: '18px',
        fontWeight: 600,
    },
    input: {
        margin: '0 9px',
        maxWidth: '80px',
        height: '42px',
        borderRadius: '10px',
        backgroundColor: '#f1f7ed',
    },
}); });
