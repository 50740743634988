var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { InputText, InputMasked, Label } from '../../../common/Input';
import clsx from 'clsx';
import { Masked } from '../Masked.component';
import { fieldsStyle } from '../Fields.style';
import { Box, Typography } from '@mui/material';
var SelectInputText = React.forwardRef(function (_a, ref) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var label = _a.label, multiline = _a.multiline, defaultValue = _a.defaultValue, inline = _a.inline, center = _a.center, spacing = _a.spacing, masked = _a.masked, errorText = _a.errorText, helperText = _a.helperText, readOnly = _a.readOnly, errorField = _a.errorField, validated = _a.validated, fontSize = _a.fontSize, secondary = _a.secondary, extraComponent = _a.extraComponent, bottomText = _a.bottomText, unitValue = _a.unitValue, props = _a.props;
    if (ref === void 0) { ref = null; }
    var classes = fieldsStyle().classes;
    return (_jsxs(_Fragment, { children: [_jsxs(Box
            //border={errorField ? 1 : 0}
            //borderColor={errorField ? 'red' : 'transparent'}
            , { 
                //border={errorField ? 1 : 0}
                //borderColor={errorField ? 'red' : 'transparent'}
                className: clsx((_b = {}, _b[classes.inlineFormFieldDesc] = inline, _b), (_c = {}, _c[classes.alignCenter] = center, _c)), marginRight: spacing ? 2 : 0, children: [masked ? (_jsx(InputMasked, __assign({ id: label, fullWidth: true, variant: 'outlined', multiline: multiline, defaultValue: defaultValue, placeholder: helperText, InputProps: {
                            readOnly: readOnly,
                        }, className: clsx((_d = {}, _d[classes.errorMasked] = errorField, _d), (_e = {}, _e[classes.validMasked] = validated === false, _e)), inputProps: { inputRef: ref }, inputComponent: Masked }, props))) : (_jsx(InputText, __assign({ id: label, fullWidth: true, variant: 'outlined', multiline: multiline, defaultValue: defaultValue, placeholder: helperText, InputProps: {
                            readOnly: readOnly,
                        }, inputProps: __assign({}, props === null || props === void 0 ? void 0 : props.inputProps), className: clsx((_f = {}, _f[classes.errorField] = errorField, _f), (_g = {}, _g[classes.validInput] = validated === false, _g), (_h = {}, _h[classes.fontLarge] = fontSize === 'large', _h), (_j = {}, _j[classes.removeSpinArrow] = props.type === 'number', _j), (_k = {}, _k[classes.secondary] = secondary === true, _k)), inputRef: ref }, props))), extraComponent && _jsx(Box, { paddingLeft: 1, children: extraComponent }), bottomText && (_jsx(Label, { className: classes.multilineHelperText, children: bottomText })), unitValue && (_jsx(Typography, { variant: 'subtitle1', children: unitValue }))] }), errorText && (_jsx(Typography, { variant: 'subtitle1', className: classes.errorText, children: errorText }))] }));
});
export default SelectInputText;
