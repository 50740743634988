import { removeLocalStorage } from 'globals/global.functions';
export var UPDATE_IS_RENDER_LOCATION = 'UPDATE_IS_RENDER_LOCATION';
/**
 * @function createPusherLocationData action creator to create create location data
 * @param data
 * @returns null if location data exist else void
 */
export var createPusherLocationData = function (data) { return function (dispatch, getState) {
    var currentPagePath = getState().router.location.pathname;
    var currentLocationData = createLocationData(data.seniorId);
    removeLocalStorage("location-".concat(currentLocationData));
    if (currentPagePath.includes('/senior-location') &&
        currentPagePath.includes(data.seniorId)) {
        dispatch(updateIsRenderLocation(false));
        setTimeout(function () {
            dispatch(updateIsRenderLocation(true));
        }, 500);
    }
}; };
var updateIsRenderLocation = function (value) { return function (dispatch) {
    dispatch({ type: UPDATE_IS_RENDER_LOCATION, payload: value });
}; };
/**
 * @function createLocationData
 * @description method to create data for location event
 * @param {string} seniorId senior ID
 * @returns seniorId
 */
var createLocationData = function (seniorId) {
    return seniorId;
};
