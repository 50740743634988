import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Box, ClickAwayListener, List, ListItem } from '@mui/material';
import { moreOptionsStyle } from './MoreOptions.style';
import SharedStorage from 'store/SharedStorage';
import { LOGOUT_MESSAGE } from 'globals/global.constants';
import { onClickLogout } from 'pages/WCPages/Login/Login.action';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import ErrorIcon from 'common/Icons/ErrorIcon';
var MoreOptions = function (_a) {
    var open = _a.open, setOpen = _a.setOpen;
    var classes = moreOptionsStyle().classes;
    var dispatch = useAppDispatch();
    var navigate = useNavigate();
    var isPasswordResetWarning = useAppSelector(function (state) { var _a; return (_a = state.auth.passwordCreatedData) === null || _a === void 0 ? void 0 : _a.isPasswordResetWarning; });
    var handleClick = function () {
        setOpen(function (prev) { return !prev; });
    };
    var handleClickAway = function () {
        setOpen(false);
    };
    var handleLogout = function () {
        dispatch(onClickLogout(LOGOUT_MESSAGE));
        SharedStorage.setNavigationEnable(false); //TODO need to check this logic
    };
    return (_jsx(Box, { component: 'div', children: _jsx(ClickAwayListener, { onClickAway: handleClickAway, children: _jsxs(Box, { component: 'div', className: classes.root, children: [_jsx(Box, { className: classes.iconContainer, onClick: handleClick, children: open ? _jsx(ExpandLessIcon, {}) : _jsx(ExpandMoreIcon, {}) }), open && (_jsxs(List, { className: classes.dropdown, component: 'nav', "aria-label": 'secondary mailbox folders', children: [_jsxs(ListItem, { button: true, onClick: function () { return navigate("/change-password"); }, children: [isPasswordResetWarning && (_jsx(Box, { display: 'flex', width: '30px', children: _jsx(ErrorIcon, { className: classes.errorIcon }) })), _jsx(Typography, { variant: 'subtitle1', children: "Change password" })] }), _jsxs(ListItem, { button: true, onClick: handleLogout, children: [isPasswordResetWarning && (_jsx(Box, { display: 'flex', width: '30px' })), _jsx(Typography, { variant: 'subtitle1', children: "Logout" })] })] }))] }) }) }));
};
export default MoreOptions;
