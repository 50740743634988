import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Box, Grid } from '@mui/material';
import { detailStyles, metaBoxStyles } from './MetaBox.style';
import ShowHyphen from 'common/ShowHyphen/ShowHyphen';
var MetaBox = function (props) {
    var _a = props.data, data = _a === void 0 ? [] : _a, _b = props.columns, columns = _b === void 0 ? 12 : _b;
    var classes = metaBoxStyles().classes;
    return (_jsx(Box, { className: classes.container, "data-testid": 'print-meta-box', children: _jsx(Grid, { container: true, columnSpacing: '30px', columns: columns, children: data === null || data === void 0 ? void 0 : data.map(function (item, i) { return (_jsx(Details, { firstLabel: (item === null || item === void 0 ? void 0 : item.firstLabel) || '', firstValue: (item === null || item === void 0 ? void 0 : item.firstValue) || '', secondLabel: (item === null || item === void 0 ? void 0 : item.secondLabel) || '', secondValue: (item === null || item === void 0 ? void 0 : item.secondValue) || '', columns: (item === null || item === void 0 ? void 0 : item.columns) || false }, item.firstLabel || item.secondLabel || i)); }) }) }));
};
var Details = React.memo(function (_a) {
    var firstLabel = _a.firstLabel, secondLabel = _a.secondLabel, firstValue = _a.firstValue, secondValue = _a.secondValue, columns = _a.columns;
    var classes = detailStyles().classes;
    return (_jsx(Grid, { xl: columns, item: true, children: _jsxs(Box, { gap: '36px', display: 'flex', alignItems: 'stretch', children: [_jsxs(Box, { children: [_jsx("div", { className: classes.firstLabel, style: { marginBottom: '36px' }, children: firstLabel }), _jsx("div", { className: classes.secondLabel, children: secondLabel })] }), _jsxs(Box, { children: [_jsx("div", { className: classes.detailText, style: { marginBottom: '36px' }, children: firstLabel && _jsx(ShowHyphen, { children: firstValue }) }), _jsx("div", { className: classes.detailText, children: secondLabel && _jsx(ShowHyphen, { children: secondValue }) })] })] }) }));
});
export default MetaBox;
