export var SET_SLEEP_START_END_TIME = 'SET_SLEEP_START_END_TIME';
export var SET_SELECTED_DATE = 'SET_SELECTED_DATE';
export var RESET_DATES_WELLNESS_DASHBOARD = 'RESET_DATES_WELLNESS_DASHBOARD';
export var resetWellnessDashboard = function () { return function (dispatch) {
    dispatch({ type: RESET_DATES_WELLNESS_DASHBOARD });
}; };
export var setSelectedDate = function (date, type) { return function (dispatch) {
    dispatch({ type: SET_SELECTED_DATE, payload: { date: date, type: type } });
}; };
export var setSleepHeartRateTime = function (startTime, endTime) { return function (dispatch) {
    dispatch({ type: SET_SLEEP_START_END_TIME, payload: { startTime: startTime, endTime: endTime } });
}; };
