var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { getReportService } from 'services/reportsService/reportsService';
import { showError } from '../common.action';
export var GET_REPORTS = 'GET_REPORTS';
export var GET_REPORTS_SUCCESS = 'GET_REPORTS_SUCCESS';
export var GET_REPORTS_FAIL = 'GET_REPORTS_FAIL';
export var SET_REPORT_DOWNLOAD_LINKS = 'SET_REPORT_DOWNLOAD_LINKS';
export var UPDATE_REPORTS_PAGE_NUMBER = 'UPDATE_REPORTS_PAGE_NUMBER';
export var RESET_REPORTS = 'RESET_REPORTS';
export var TRIGGER_REPORTS_REFRESH = 'TRIGGER_REPORTS_REFRESH';
export var RESET_REPORTS_TRIGGER = 'RESET_REPORTS_TRIGGER';
/**
 * @function getReports
 * @description action creator to start fetching reports data
 * @param {IGetReportsParams} params
 */
export var getReports = function (params) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch({ type: GET_REPORTS });
                return [4 /*yield*/, getReportService(params)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
        }
    });
}); }; };
/**
 * @function getReportsSuccess
 * @description action creator to store reports table data
 * @param tableData
 */
export var getReportsSuccess = function (tableData) { return function (dispatch) {
    var data = tableData.data, lastEvaluatedKey = tableData.lastEvaluatedKey;
    dispatch({
        type: GET_REPORTS_SUCCESS,
        payload: {
            data: data,
            lastEvaluatedKey: lastEvaluatedKey,
        },
    });
}; };
/**
 * @function getReportsFail
 * @description action creator to fetch error on api get fail
 * @param error
 */
export var getReportsFail = function (error) { return function (dispatch) {
    dispatch(showError(error));
    dispatch({
        type: GET_REPORTS_FAIL,
    });
}; };
/**
 * @function updateReportsPageNumber
 * @description action creator to update the page number of reports table
 * @param {string | number} value
 */
export var updateReportsPageNumber = function (value) { return function (dispatch) {
    dispatch({ type: UPDATE_REPORTS_PAGE_NUMBER, payload: value });
}; };
/**
 * @function resetReports
 * @description action creator to reset reports table
 */
export var resetReports = function () { return function (dispatch) {
    dispatch({
        type: RESET_REPORTS,
    });
}; };
/**
 * @function triggerReportsRefresh
 * @description action creator to trigger Reports Refresh
 */
export var triggerReportsRefresh = function () { return function (dispatch) {
    dispatch({
        type: TRIGGER_REPORTS_REFRESH,
    });
}; };
/**
 * @function resetReportsTrigger
 * @description action creator to reset reports table refresh trigger
 */
export var resetReportsTrigger = function () { return function (dispatch) {
    dispatch({
        type: RESET_REPORTS_TRIGGER,
    });
}; };
/**
 * @description action to set fetched report download link
 * @function setReportDownloadLink
 * @param {IDownloadReportParams} payload
 */
export var setReportDownloadLink = function (payload) { return function (dispatch) {
    dispatch({
        type: SET_REPORT_DOWNLOAD_LINKS,
        payload: payload,
    });
}; };
