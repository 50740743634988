var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { PAGINATION_TYPE } from 'globals/enums';
import clsx from 'clsx';
import { Box, Pagination } from '@mui/material';
import get from 'lodash.get';
import { getPaginationOffsetData } from 'store/commonReducer/common.action';
import { useRowStyles } from './PaginationContainerStyle';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
var PaginationOffsetContainer = React.memo(function (_a) {
    var _b;
    var loadingPath = _a.loadingPath, paginationType = _a.paginationType, className = _a.className, rowsPerPage = _a.rowsPerPage, _c = _a.pagePath, pagePath = _c === void 0 ? '' : _c, _d = _a.offsetPath, offsetPath = _d === void 0 ? '' : _d, _e = _a.limit, limit = _e === void 0 ? 10 : _e, _f = _a.tableData, tableData = _f === void 0 ? [] : _f, getData = _a.getData, onSuccess = _a.onSuccess, onError = _a.onError, componentProps = _a.componentProps, WrappedComponent = _a.WrappedComponent, onPageChange = _a.onPageChange, _g = _a.isScrollOnTop, isScrollOnTop = _g === void 0 ? false : _g, _h = _a.withBorder, withBorder = _h === void 0 ? false : _h, _j = _a.containerClass, containerClass = _j === void 0 ? '' : _j;
    var classes = useRowStyles().classes;
    var dispatch = useAppDispatch();
    var offset = useAppSelector(function (state) { return get(state, offsetPath); });
    var page = useAppSelector(function (state) { return get(state, pagePath); });
    var isDataLoading = useAppSelector(function (state) {
        return get(state, loadingPath);
    });
    var rows = React.useMemo(function () {
        var endRange = page * rowsPerPage;
        return tableData.slice(endRange - rowsPerPage, endRange);
    }, [tableData, page, rowsPerPage]);
    /**
     * @description get total number of pages
     */
    var totalPages = React.useMemo(function () {
        return Math.ceil(tableData.length / rowsPerPage);
    }, [tableData, rowsPerPage]);
    var fetchMoreRecords = React.useCallback(function (currentPage) {
        //Run only when user is on last page and need to fetch more data from DB
        if (currentPage === totalPages && offset >= 0) {
            dispatch(getPaginationOffsetData(getData, rowsPerPage, currentPage, offset, limit, onSuccess, onError, tableData));
        }
    }, [
        offset,
        totalPages,
        tableData,
        dispatch,
        onSuccess,
        onError,
        getData,
        limit,
        rowsPerPage,
    ]);
    var handleChange = function (event, value) {
        dispatch(onPageChange(value));
        fetchMoreRecords(value);
    };
    var modifiedComponentProps = React.useCallback(function () {
        return __assign(__assign({}, componentProps), { currentPage: page, isDataLoading: isDataLoading, data: __spreadArray([], rows, true) });
    }, [page, isDataLoading, rows, componentProps]);
    //if there is no data on page then move to previous page
    React.useEffect(function () {
        if (page !== 1 && rows.length === 0) {
            dispatch(onPageChange(page - 1));
        }
    }, [page, rows, dispatch, onPageChange]);
    React.useEffect(function () {
        if (isScrollOnTop) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [page, isScrollOnTop]);
    var renderPagination = function () {
        var _a;
        if (paginationType === PAGINATION_TYPE.SECONDARY) {
            return (_jsx(Pagination, { className: clsx((_a = {},
                    _a[classes.root] = true,
                    _a[classes.secondary] = !withBorder,
                    _a[classes.secondaryWithBorder] = withBorder,
                    _a)), count: totalPages, page: page, onChange: handleChange, showFirstButton: true, showLastButton: true }));
        }
        return (_jsx(Pagination, { className: className, count: totalPages, page: page, onChange: handleChange, showFirstButton: true, showLastButton: true }));
    };
    return (_jsxs(Box, { "data-testid": 'PaginationContainer', display: 'flex', height: '100%', flexDirection: 'column', justifyContent: 'space-between', className: clsx(containerClass, (_b = {}, _b[classes.border] = withBorder, _b)), children: [_jsx(WrappedComponent, __assign({}, modifiedComponentProps())), rows.length !== 0 && renderPagination()] }));
});
export default PaginationOffsetContainer;
