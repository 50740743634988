var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { GET_DEVICES_DATA, GET_DEVICES_DATA_SUCCESSFUL, GET_DEVICES_DATA_FAIL, GET_WATCH_DETAILS, RESET_WATCH_DETAILS, } from './Devices.action';
var INITIAL_STATE = {
    data: [],
    deviceDataLoader: true,
    watchPhoneNumber: '',
    watchModelNumber: '',
};
export var devicesReducer = function (state, action) {
    if (state === void 0) { state = INITIAL_STATE; }
    switch (action.type) {
        case GET_DEVICES_DATA:
            return __assign(__assign({}, state), { deviceDataLoader: true });
        case GET_DEVICES_DATA_SUCCESSFUL:
            return __assign(__assign({}, state), { data: action.payload.data, deviceDataLoader: false });
        case GET_DEVICES_DATA_FAIL:
            return __assign(__assign({}, state), { deviceDataLoader: false });
        case GET_WATCH_DETAILS:
            return __assign(__assign({}, state), { watchPhoneNumber: action.payload.watchPhoneNumber, watchModelNumber: action.payload.watchModelNumber });
        case RESET_WATCH_DETAILS:
            return __assign(__assign({}, state), { watchPhoneNumber: '', watchModelNumber: '' });
        default:
            return state;
    }
};
