import { jsx as _jsx } from "react/jsx-runtime";
import { TableContainer } from '@mui/material';
import Table from 'common/Table/Table.component';
import { PRIMARY_COLOR } from 'globals/enums';
import { DATE_FORMAT } from 'globals/global.constants';
import { getTimestamp } from 'globals/global.functions';
import moment from 'moment';
var HEADER_DATA = [
    {
        columnId: 'date',
        label: 'Date',
        style: {
            paddingLeft: '40px',
        },
    },
    {
        columnId: 'time',
        label: 'Time',
    },
    {
        columnId: 'type',
        label: 'Type',
        style: {
            paddingRight: '40px',
        },
    },
];
var ROW_DATA = {
    values: [
        {
            dataId: 'time',
            dataKey: 'date',
            format: function (value) {
                return moment(getTimestamp(value)).format(DATE_FORMAT);
            },
            style: {
                paddingLeft: '40px',
            },
        },
        {
            dataId: 'time',
            dataKey: 'time',
            format: function (value) { return moment(getTimestamp(value)).format('hh:mm'); },
        },
        {
            dataId: 'type',
            style: {
                paddingRight: '40px',
            },
        },
    ],
};
var SOSTable = function (_a) {
    var _b = _a.data, data = _b === void 0 ? [] : _b, _c = _a.loading, loading = _c === void 0 ? false : _c, _d = _a.width, width = _d === void 0 ? '370px' : _d, _e = _a.height, height = _e === void 0 ? '475px' : _e;
    return (_jsx(TableContainer, { sx: { height: height, width: width }, "data-testid": 'SOSTableContainer', children: _jsx(Table, { rowId: 'careInsightId', rowData: ROW_DATA, headerData: HEADER_DATA, primaryColor: PRIMARY_COLOR.GREEN, data: data, isFilterLoading: loading }) }));
};
export default SOSTable;
