var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';
import { Label, InputText } from 'common/Input';
import { commonStyle } from '../FormField.style';
var InputTextBox = function (_a) {
    var _b, _c, _d;
    var _e = _a.label, label = _e === void 0 ? '' : _e, _f = _a.required, required = _f === void 0 ? false : _f, _g = _a.helperText, helperText = _g === void 0 ? '' : _g, _h = _a.inline, inline = _h === void 0 ? false : _h, _j = _a.unitValue, unitValue = _j === void 0 ? '' : _j, _k = _a.spacing, spacing = _k === void 0 ? false : _k, _l = _a.readOnly, readOnly = _l === void 0 ? false : _l, _m = _a.register, register = _m === void 0 ? null : _m, _o = _a.multiline, multiline = _o === void 0 ? false : _o, extraComponent = _a.extraComponent, _p = _a.center, center = _p === void 0 ? false : _p, _q = _a.errorField, errorField = _q === void 0 ? undefined : _q, _r = _a.errorText, errorText = _r === void 0 ? '' : _r, _s = _a.input, input = _s === void 0 ? 'label' : _s, _t = _a.defaultValue, defaultValue = _t === void 0 ? '' : _t, props = __rest(_a, ["label", "required", "helperText", "inline", "unitValue", "spacing", "readOnly", "register", "multiline", "extraComponent", "center", "errorField", "errorText", "input", "defaultValue"]);
    var classes = commonStyle().classes;
    return (_jsxs(_Fragment, { children: [_jsxs(Box
            //border={errorField ? 1 : 0}
            //borderColor={errorField ? 'red' : 'transparent'}
            , { 
                //border={errorField ? 1 : 0}
                //borderColor={errorField ? 'red' : 'transparent'}
                className: clsx((_b = {}, _b[classes.inlineFormFieldDesc] = inline, _b), (_c = {}, _c[classes.alignCenter] = center, _c)), marginRight: spacing ? 2 : 0, "data-testid": 'inputTextBox-component', children: [_jsx(InputText, __assign({ fullWidth: true, variant: 'outlined', multiline: multiline, defaultValue: defaultValue, placeholder: !multiline ? helperText : '', InputProps: {
                            readOnly: readOnly,
                        }, className: clsx((_d = {}, _d[classes.errorField] = errorField, _d)), inputProps: register, inputRef: register === null || register === void 0 ? void 0 : register.ref }, props)), extraComponent && _jsx(Box, { paddingLeft: 1, children: extraComponent }), multiline && (_jsx(Label, { className: classes.multilineHelperText, children: helperText })), unitValue && _jsx(Box, { paddingLeft: 0.5, children: unitValue })] }), errorText && _jsx(Box, { className: classes.errorText, children: errorText })] }));
};
export { InputTextBox };
