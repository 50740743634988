var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Box } from '@mui/material';
import InputRadioWrapper from './component/InputRadioWrapper';
var InputFields = function (props) {
    return (_jsx(Box, { width: '100%', children: _jsx(Box, { children: _jsx(RenderComponent, __assign({}, props)) }) }));
};
var RenderComponent = function (props) {
    if (props.radio) {
        return _jsx(InputRadioWrapper, __assign({}, props));
    }
    return _jsx(_Fragment, {});
};
export { InputFields };
