var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable max-len */
import { makeStyles } from 'tss-react/mui';
import { fontSizes, TemplateTypography } from 'common/Print/Print.utility';
export var medicalConditionTemplateStyles = makeStyles()(function (theme, _a) {
    var indexWidth = _a.indexWidth;
    return ({
        container: {},
        listItem: {
            display: 'flex',
            width: '100%',
            maxWidth: '100%',
            marginBottom: '30px',
            paddingBottom: '30px',
            borderBottom: '1px solid rgba(0, 0, 0, 0.5)',
            '&:last-of-type': {
                marginBottom: 0,
            },
        },
        listIndex: __assign(__assign({}, TemplateTypography.regular), { fontSize: fontSizes.subHeading2, fontWeight: 600, textAlign: 'right', marginRight: '10px', width: "".concat(indexWidth || 0, "em"), color: '#0186a5' }),
        '@media print': {
            listItem: {
                pageBreakInside: 'avoid',
                breakInside: 'avoid',
            },
        },
    });
});
