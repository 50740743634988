import PusherApp from 'pusher/pusherApp';
var ChannelUtils = /** @class */ (function () {
    function ChannelUtils() {
    }
    /**
     * @function subscribeChannel
     * @description metod to subscribe the channel
     * @param {string} channelName channel name
     * @returns {PusherTypes.Channel}
     */
    ChannelUtils.prototype.subscribeChannel = function (channelName) {
        var eventModuleObj = new PusherApp();
        return eventModuleObj.subscribeChannel(channelName);
    };
    /**
     * @function channelEventListener
     * @param channel channel to bind the event listener
     * @param event event name
     * @param dataParser method to parse the data which recieved from pusher
     * @param listenerMethod method to execute once get data from pusher
     */
    ChannelUtils.prototype.channelEventListener = function (channel, event, dataParser, listenerMethod) {
        channel.bind(event, function (data) {
            try {
                data = dataParser(data);
                listenerMethod(data);
            }
            catch (error) {
                // eslint-disable-next-line no-console
                console.log('Data is missing in the API', error);
            }
        });
    };
    return ChannelUtils;
}());
export default ChannelUtils;
