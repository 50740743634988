var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { GET_MEDICATION_LIST_SUCCESS, UPDATE_MEDICATION_LIST_PAGE_NUMBER, GET_MEDICINE_SEARCH_RESULT, SEARCH_RESULT_SUCCESS, SEARCH_RESULT_FAIL, RESET_SEARCH, MEDICATION_TOGGLE_IS_COMPLETED, SET_MEDICATION_DIALOG_DATA, EMPTY_MEDICATION_DIALOG_DATA, RESET_MEDICATION_DATA, } from './MedicationList.action';
export var INITIAL_STATE = {
    data: [],
    lastEvaluatedKey: '',
    loading: false,
    totalRows: 0,
    currentPage: 1,
    searchResult: [],
    searchLoading: false,
    errorMessage: '',
    isCompleted: true,
    medicationDialogData: [],
};
export var medicationListReducer = function (state, action) {
    if (state === void 0) { state = INITIAL_STATE; }
    switch (action.type) {
        case GET_MEDICATION_LIST_SUCCESS: {
            return __assign(__assign({}, state), { data: __spreadArray([], action.payload.data, true), lastEvaluatedKey: action.payload.lastEvaluatedKey, loading: false });
        }
        case UPDATE_MEDICATION_LIST_PAGE_NUMBER: {
            return __assign(__assign({}, state), { currentPage: action.payload });
        }
        case GET_MEDICINE_SEARCH_RESULT: {
            return __assign(__assign({}, state), { searchLoading: true });
        }
        case SEARCH_RESULT_SUCCESS: {
            return __assign(__assign({}, state), { searchResult: action.payload, searchLoading: false, errorMessage: INITIAL_STATE.errorMessage });
        }
        case SEARCH_RESULT_FAIL: {
            return __assign(__assign({}, state), { searchLoading: false, errorMessage: action.payload });
        }
        case RESET_SEARCH:
            return __assign(__assign({}, state), { searchResult: INITIAL_STATE.searchResult, errorMessage: INITIAL_STATE.errorMessage });
        case MEDICATION_TOGGLE_IS_COMPLETED: {
            return __assign(__assign({}, state), { isCompleted: action.payload.isCompleted });
        }
        case SET_MEDICATION_DIALOG_DATA: {
            return __assign(__assign({}, state), { medicationDialogData: action.payload });
        }
        case EMPTY_MEDICATION_DIALOG_DATA: {
            return __assign(__assign({}, state), { medicationDialogData: INITIAL_STATE.medicationDialogData });
        }
        case RESET_MEDICATION_DATA: {
            return {
                data: [],
                lastEvaluatedKey: '',
                loading: false,
                totalRows: 0,
                currentPage: 1,
                searchResult: [],
                searchLoading: false,
                errorMessage: '',
                isCompleted: true,
                medicationDialogData: [],
            };
        }
        default:
            return state;
    }
};
