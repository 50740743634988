var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { push } from 'redux-first-history';
import { DATE_FORMAT, DIALOG_TYPES, REGEX, LocationLocateMeError, } from 'globals/global.constants';
import { getCurrentResidentID, getCurrentSenior, getLocalStorage, getTimestamp, setLocalStorage, } from 'globals/global.functions';
import moment from 'moment';
import { getImage } from 'pages/WCPages/AddUser/ProfileInfo/ProfileInfo.action';
import { getSeniorLocationHistoryService, getSeniorLocationPostAPIService, getSeniorLocationService, } from 'services/locationService/seniorLocationService';
import { convertEndDateInUTCTz, convertStartDateInUTCTz, convertUTCSecondsToUnixNanoSeconds, } from 'globals/date.functions';
import { INITIAL_STATE } from './common.reducer';
import { resetPasswordService } from 'services/userService/userService';
import { hideApplicationLoader, showApplicationLoader, } from 'common/ApplicationLoader';
// eslint-disable-next-line max-len
import { validateEmailAddressService } from 'services/addUserService/validateEmailAddressAndMobileService';
import { getBasicInfoService, getCareGiverService, getDentistInfoService, getDoctorInfoService, getMinimalInfoService, getPharmacyInfoService, } from 'services/commonService/common.service';
import { getDevicesInfo } from 'pages/WCPages/AddUser/DeviceInstallation/Devices.action';
import { GetSeniorData, Roles } from 'globals/enums';
import { APPLICATION_CODE } from 'globals/applicationCodes';
import { showToast } from 'common/Toast';
export * from './seniorList/seniorList.action';
export * from './careAgentList/careAgentList.action';
export * from './cWMemberRecords/cWMemberRecords.action';
export var GET_PAGINATION_DATA = 'GET_PAGINATION_DATA';
export var GET_PAGINATION_DATA_SUCCESSFUL = 'GET_PAGINATION_DATA_SUCCESSFUL';
export var GET_PAGINATION_DATA_FAILED = 'GET_PAGINATION_DATA_FAILED';
export var EMPTY_PAGINATION_DATA = 'EMPTY_PAGINATION_DATA';
export var OPEN_DIALOG = 'OPEN_DIALOG';
export var OPEN_OVERLAY_DIALOG = 'OPEN_OVERLAY_DIALOG';
export var CLOSE_DIALOG = 'CLOSE_DIALOG';
export var CLOSE_OVERLAY_DIALOG = 'CLOSE_OVERLAY_DIALOG';
export var SET_SENIOR_DETAIL = 'SET_SENIOR_DETAIL';
export var SET_CAREGIVER_INFO = 'SET_CAREGIVER_INFO';
export var SET_PROVIDER_INFO = 'SET_PROVIDER_INFO';
export var SET_SENIOR_IMAGE = 'SET_SENIOR_IMAGE';
export var EMPTY_SENIOR_DETAIL = 'EMPTY_SENIOR_DETAIL';
export var EMPTY_CAREGIVER_INFO = 'EMPTY_CAREGIVER_INFO';
export var EMPTY_PROVIDER_INFO = 'EMPTY_PROVIDER_INFO';
export var EMPTY_SENIOR_IMAGE = 'EMPTY_SENIOR_IMAGE';
export var SET_SENIOR_LOCATION = 'SET_SENIOR_LOCATION';
export var SET_SENIOR_LOCATE_ME_ERROR = 'SET_SENIOR_LOCATE_ME_ERROR';
export var EMPTY_LOCATE_ME_POST_API_RESPONSE = 'EMPTY_LOCATE_ME_POST_API_RESPONSE';
export var EMPTY_SENIOR_LOCATION = 'EMPTY_SENIOR_LOCATION';
export var GET_MONTH_ENABLE_DATES = 'GET_MONTH_ENABLE_DATES';
export var EMPTY_LOCATION_DATES = 'EMPTY_LOCATION_DATES';
export var SET_CARD_LOCATION = 'SET_CARD_LOCATION';
export var EMPTY_CARD_LOCATION = 'EMPTY_CARD_LOCATION';
export var SET_STATIC_MESSAGES = 'SET_STATIC_MESSAGES';
export var SET_MEDICAL_CONDITION = 'SET_MEDICAL_CONDITION';
export var RESET_MEDICAL_CONDITION = 'RESET_MEDICAL_CONDITION';
/**
 * @description action creator to fetch the table data for pagination
 * @param {function} apiFn call back to get the table data
 * @param {numebr} rowsPerPage Display total number of rows in table
 * @param {string} cacheKey key to store data in localstorage
 * @param {number} currentPage current table page
 * @returns void
 */
export var getPaginationData = function (apiFn, rowsPerPage, cacheKey, currentPage) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var cacheData, totalPages, res, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 4, , 5]);
                    cacheData = getLocalStorage(cacheKey) || {
                        data: [],
                        lastEvaluatedKey: {},
                    };
                    totalPages = Math.ceil(cacheData.data.length / rowsPerPage);
                    if (!((cacheData.lastEvaluatedKey && currentPage === totalPages) ||
                        !cacheData.data.length)) return [3 /*break*/, 2];
                    dispatch({ type: GET_PAGINATION_DATA });
                    return [4 /*yield*/, apiFn(cacheData.lastEvaluatedKey)];
                case 1:
                    res = _a.sent();
                    cacheData.lastEvaluatedKey = res.lastEvaluatedKey;
                    cacheData.data = __spreadArray(__spreadArray([], cacheData.data, true), res.data, true);
                    cacheData.data = cacheData.data.sort(function (a, b) {
                        return moment(b.call_time).valueOf() - moment(a.call_time).valueOf();
                    });
                    setLocalStorage(cacheKey, cacheData);
                    dispatch({ type: GET_PAGINATION_DATA_SUCCESSFUL, payload: cacheData });
                    return [3 /*break*/, 3];
                case 2:
                    dispatch({ type: GET_PAGINATION_DATA_SUCCESSFUL, payload: cacheData });
                    _a.label = 3;
                case 3: return [3 /*break*/, 5];
                case 4:
                    error_1 = _a.sent();
                    dispatch({ type: GET_PAGINATION_DATA_FAILED });
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
};
/**
 * @description action creator to fetch the table data for pagination
 * @param {Function} apiFn call back to get the table data
 * @param {number} rowsPerPage Display total number of rows in table
 * @param {string} cacheKey key to store data in localstorage
 * @param {number} currentPage current table page
 * @returns void
 */
export var getPaginationDataIsolated = function (apiFn, rowsPerPage, cacheKey, currentPage, onSuccess, onError, tableData, lastEvaluatedKey, searchQuery, onSearchSuccess) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (cacheKey) {
                dispatch(getPaginationDataWithCache(apiFn, rowsPerPage, cacheKey, currentPage, onSuccess, onError));
            }
            else {
                dispatch(getPaginationDataWithoutCache(apiFn, rowsPerPage, currentPage, onSuccess, onError, tableData, lastEvaluatedKey, searchQuery, onSearchSuccess));
            }
            return [2 /*return*/];
        });
    }); };
};
/**
 * @description action creator to fetch the table data for pagination
 * @param {Function} apiFn call back to get the table data
 * @param {number} currentPage current table page
 * @param {number} offset offset to fetch Data
 * @param {number} limit limit of length of data in single fetch
 * @param onSuccess
 * @param onError
 * @param tableData
 * @returns void
 */
export var getPaginationOffsetData = function (apiFn, rowsPerPage, currentPage, offset, limit, onSuccess, onError, tableData, searchQuery, onSearchSuccess) {
    if (offset === void 0) { offset = 0; }
    if (limit === void 0) { limit = 10; }
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var data, totalPages, res, len, error_2;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 3, , 4]);
                    data = {
                        data: tableData.length > 0 ? __spreadArray([], tableData, true) : [],
                    };
                    totalPages = Math.ceil(data.data.length / rowsPerPage);
                    if (!((offset >= 0 && currentPage === totalPages) || !data.data.length)) return [3 /*break*/, 2];
                    return [4 /*yield*/, dispatch(apiFn(data.offset, limit, searchQuery))];
                case 1:
                    res = _b.sent();
                    data.data = __spreadArray(__spreadArray([], data.data, true), res.data, true);
                    len = ((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.length) || 0;
                    if (len < limit) {
                        data.offset = -1; // -1 means stop further fetching of data
                    }
                    else {
                        data.offset = offset + limit;
                    }
                    // onSuccess is feeded 3 params newData, newOffset, apiFn response
                    if (searchQuery) {
                        dispatch(onSearchSuccess(data.data, data.offset));
                    }
                    else {
                        dispatch(onSuccess(data.data, data.offset, res.response));
                    }
                    _b.label = 2;
                case 2: return [3 /*break*/, 4];
                case 3:
                    error_2 = _b.sent();
                    console.log(error_2);
                    dispatch(onError(error_2));
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
};
var getPaginationDataWithoutCache = function (apiFn, rowsPerPage, currentPage, onSuccess, onError, tableData, lastEvaluatedKey, searchQuery, onSearchSuccess) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var data, totalPages, res, error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    data = {
                        data: tableData.length > 0 ? __spreadArray([], tableData, true) : [],
                        lastEvaluatedKey: lastEvaluatedKey || '',
                    };
                    totalPages = Math.ceil(data.data.length / rowsPerPage);
                    if (!((data.lastEvaluatedKey && currentPage === totalPages) ||
                        !data.data.length)) return [3 /*break*/, 2];
                    return [4 /*yield*/, dispatch(apiFn(searchQuery, data.lastEvaluatedKey))];
                case 1:
                    res = _a.sent();
                    data.lastEvaluatedKey = res.lastEvaluatedKey;
                    data.data = __spreadArray(__spreadArray([], data.data, true), res.data, true);
                    if (searchQuery) {
                        dispatch(onSearchSuccess(data));
                    }
                    else {
                        dispatch(onSuccess(data));
                    }
                    _a.label = 2;
                case 2: return [3 /*break*/, 4];
                case 3:
                    error_3 = _a.sent();
                    console.log(error_3);
                    dispatch(onError(error_3));
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
};
var getPaginationDataWithCache = function (apiFn, rowsPerPage, cacheKey, currentPage, onSuccess, onError) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var cacheData, totalPages, res, error_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 4, , 5]);
                    cacheData = getLocalStorage(cacheKey) || {
                        data: [],
                        lastEvaluatedKey: '',
                    };
                    totalPages = Math.ceil((cacheData.data.length || 1) / rowsPerPage);
                    if (!((cacheData.lastEvaluatedKey && currentPage === totalPages) ||
                        !cacheData.data.length)) return [3 /*break*/, 2];
                    return [4 /*yield*/, dispatch(apiFn(cacheData.lastEvaluatedKey))];
                case 1:
                    res = _a.sent();
                    cacheData.lastEvaluatedKey = res.lastEvaluatedKey;
                    cacheData.data = __spreadArray(__spreadArray([], cacheData.data, true), res.data, true);
                    setLocalStorage(cacheKey, cacheData);
                    dispatch(onSuccess(cacheData));
                    return [3 /*break*/, 3];
                case 2:
                    dispatch(onSuccess(cacheData));
                    _a.label = 3;
                case 3: return [3 /*break*/, 5];
                case 4:
                    error_4 = _a.sent();
                    dispatch(onError(error_4));
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
};
/**
 * @description action creator to clear out exisitng table data of particular key
 * @param {*} cacheKey local storage key to remove.
 * @returns void
 */
export var emptyPaginationData = function (cacheKey) { return function (dispatch, getState) {
    var _a, _b, _c;
    var currentSeniorId = (_c = (_b = (_a = getState().common) === null || _a === void 0 ? void 0 : _a.seniorDetail) === null || _b === void 0 ? void 0 : _b.minimalInfo) === null || _c === void 0 ? void 0 : _c.user_id;
    localStorage.removeItem("".concat(cacheKey, "-").concat(currentSeniorId));
    dispatch({ type: EMPTY_PAGINATION_DATA });
}; };
/**
 * @description action to open the dialog(pop-up)
 * @param {string} message message which needs to display
 * @param {DIALOG_TYPES} dialogType type of dialog to open
 * @returns void
 */
export var openDialog = function (_a) {
    var firstMessage = _a.firstMessage, boldMessage = _a.boldMessage, secondMessage = _a.secondMessage, onSuccessButton = _a.onSuccessButton, cancelButtonText = _a.cancelButtonText, successButtonText = _a.successButtonText, type = _a.type, isFailButton = _a.isFailButton, data = _a.data, dialogTitle = _a.dialogTitle, showAlertIcon = _a.showAlertIcon, id = _a.id;
    return function (dispatch) {
        dispatch({
            type: OPEN_DIALOG,
            payload: {
                firstMessage: firstMessage,
                boldMessage: boldMessage,
                secondMessage: secondMessage,
                type: type,
                onSuccessButton: onSuccessButton,
                successButtonText: successButtonText,
                cancelButtonText: cancelButtonText,
                isFailButton: isFailButton,
                data: data,
                dialogTitle: dialogTitle,
                showAlertIcon: showAlertIcon,
                id: id,
            },
        });
    };
};
export var closeDialog = function () { return function (dispatch) {
    dispatch({ type: CLOSE_DIALOG });
}; };
export var openOverlayDialog = function (_a) {
    var firstMessage = _a.firstMessage, secondMessage = _a.secondMessage, data = _a.data, type = _a.type, isAutoSave = _a.isAutoSave, isLogout = _a.isLogout;
    return function (dispatch) {
        if (isAutoSave) {
            dispatch(showToast('Auto Saved Successfully!', 'success'));
        }
        else {
            dispatch({
                type: OPEN_OVERLAY_DIALOG,
                payload: {
                    firstMessage: firstMessage,
                    secondMessage: secondMessage,
                    data: data,
                    type: type,
                    isLogout: isLogout,
                },
            });
        }
    };
};
export var closeOverlayDialog = function () { return function (dispatch) {
    dispatch({ type: CLOSE_OVERLAY_DIALOG });
}; };
export var setSeniorDetail = function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var userRole;
        return __generator(this, function (_a) {
            userRole = getState().auth.userRole;
            if (args.length === 0) {
                dispatch(fetchSeniorDetail());
                dispatch(getDevicesInfo());
                // do not call getCareGiver & getProviderDetail for Warehouse employee and BDM
                if (!userRole.includes(Roles.WarehouseEmployee) &&
                    !userRole.includes(Roles.BDM)) {
                    dispatch(getCareGiver());
                    dispatch(getProviderDetail());
                }
                return [2 /*return*/];
            }
            if (args.includes(GetSeniorData.SENIOR)) {
                dispatch(fetchSeniorDetail());
            }
            if (args.includes(GetSeniorData.CAREGIVER)) {
                dispatch(getCareGiver());
            }
            if (args.includes(GetSeniorData.PROVIDER)) {
                dispatch(getProviderDetail());
            }
            if (args.includes(GetSeniorData.DEVICES)) {
                dispatch(getDevicesInfo());
            }
            return [2 /*return*/];
        });
    }); };
};
export var fetchSeniorDetail = function (param) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, accountID, seniorID, params, minimalInfo, basicInfo, error_5;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = getCurrentSenior(), accountID = _a.accountID, seniorID = _a.seniorID;
                dispatch(showApplicationLoader());
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 5]);
                params = {
                    senior_id: param ? param.seniorID : seniorID,
                    account_id: param ? param.accountID : accountID,
                };
                return [4 /*yield*/, getMinimalInfoService(params)];
            case 2:
                minimalInfo = _b.sent();
                return [4 /*yield*/, getBasicInfoService(params)];
            case 3:
                basicInfo = _b.sent();
                dispatch({
                    type: SET_SENIOR_DETAIL,
                    payload: {
                        seniorDetail: {
                            minimalInfo: minimalInfo,
                            basicInfo: basicInfo,
                        },
                    },
                });
                dispatch(hideApplicationLoader());
                return [2 /*return*/, {
                        minimalInfo: minimalInfo,
                        basicInfo: basicInfo,
                    }];
            case 4:
                error_5 = _b.sent();
                dispatch(hideApplicationLoader());
                dispatch(showError(error_5));
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}); }; };
export var getCareGiver = function () { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, accountID, seniorID, params, careGiverInfo, error_6;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = getCurrentSenior(), accountID = _a.accountID, seniorID = _a.seniorID;
                dispatch(showApplicationLoader());
                _b.label = 1;
            case 1:
                _b.trys.push([1, 3, , 4]);
                params = {
                    senior_id: seniorID,
                    account_id: accountID,
                };
                return [4 /*yield*/, getCareGiverService(params)];
            case 2:
                careGiverInfo = _b.sent();
                dispatch({
                    type: SET_CAREGIVER_INFO,
                    payload: {
                        careGiverInfo: careGiverInfo,
                    },
                });
                dispatch(hideApplicationLoader());
                return [2 /*return*/, careGiverInfo];
            case 3:
                error_6 = _b.sent();
                dispatch(hideApplicationLoader());
                dispatch(showError(error_6));
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
export var getProviderDetail = function () { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, accountID, seniorID, params, doctorInfo, dentistInfo, pharmacyInfo, error_7;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = getCurrentSenior(), accountID = _a.accountID, seniorID = _a.seniorID;
                dispatch(showApplicationLoader());
                _b.label = 1;
            case 1:
                _b.trys.push([1, 5, , 6]);
                params = {
                    senior_id: seniorID,
                    account_id: accountID,
                };
                return [4 /*yield*/, getDoctorInfoService(params)];
            case 2:
                doctorInfo = _b.sent();
                return [4 /*yield*/, getDentistInfoService(params)];
            case 3:
                dentistInfo = _b.sent();
                return [4 /*yield*/, getPharmacyInfoService({
                        senior_id: params.senior_id,
                    })];
            case 4:
                pharmacyInfo = _b.sent();
                dispatch({
                    type: SET_PROVIDER_INFO,
                    payload: {
                        providerInfo: {
                            doctor: doctorInfo,
                            dentist: dentistInfo,
                            pharmacy: pharmacyInfo,
                        },
                    },
                });
                dispatch(hideApplicationLoader());
                return [3 /*break*/, 6];
            case 5:
                error_7 = _b.sent();
                dispatch(hideApplicationLoader());
                dispatch(showError(error_7));
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}); }; };
export var setSeniorImage = function () { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var seniorInfo, image, profileImage;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                seniorInfo = __assign({}, getCurrentSenior());
                return [4 /*yield*/, dispatch(getImage({
                        senior_id: seniorInfo.seniorID,
                        account_id: seniorInfo.accountID,
                    }))];
            case 1:
                image = _b.sent();
                profileImage = (_a = image.images) === null || _a === void 0 ? void 0 : _a.find(function (data) { return (data === null || data === void 0 ? void 0 : data.image_id) === image.profile.id; });
                dispatch({
                    type: SET_SENIOR_IMAGE,
                    payload: {
                        profilePic: {
                            format: profileImage === null || profileImage === void 0 ? void 0 : profileImage.format,
                            image: (profileImage === null || profileImage === void 0 ? void 0 : profileImage.image_body) || '',
                        },
                    },
                });
                return [2 /*return*/];
        }
    });
}); }; };
export var emptySeniorDetail = function () { return function (dispatch) {
    dispatch({
        type: EMPTY_SENIOR_DETAIL,
    });
    dispatch({
        type: EMPTY_CAREGIVER_INFO,
    });
    dispatch({
        type: EMPTY_PROVIDER_INFO,
    });
}; };
export var emptySeniorImage = function () { return function (dispatch) {
    dispatch({
        type: EMPTY_SENIOR_IMAGE,
    });
}; };
/**
 * @function getSeniorFullName
 * @description action creator to get full name of senior
 * @returns {string} senior full name
 */
export var getSeniorFullName = function () { return function (dispatch, getState) {
    var _a = getState().common.seniorDetail.minimalInfo.name, first_name = _a.first_name, last_name = _a.last_name, middle_name = _a.middle_name;
    return "".concat(first_name ? first_name : '', " ").concat(middle_name ? middle_name : '', " ").concat(last_name ? last_name : '');
}; };
/**
 * @function getSeniorLocation
 * @description action creator to get senior location
 * @returns void
 */
export var getSeniorLocation = function (_a) {
    var _b = _a.isHistory, isHistory = _b === void 0 ? false : _b, date = _a.date, startTime = _a.startTime, endTime = _a.endTime, _c = _a.isResident, isResident = _c === void 0 ? false : _c, _d = _a.isCard, isCard = _d === void 0 ? false : _d;
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var value, _a, accountID, seniorID, timezone, homeCoordinates, radius, start_timestamp, end_timestamp, params, seniorLocationData, error_8;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    value = getState().common.seniorDetail.basicInfo.radius.value;
                    if (!value) {
                        return [2 /*return*/];
                    }
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    _a = isResident
                        ? getCurrentResidentID()
                        : getCurrentSenior(), accountID = _a.accountID, seniorID = _a.seniorID, timezone = _a.timezone;
                    homeCoordinates = getState().common.seniorDetail.minimalInfo.address.coordinates;
                    radius = getState().common.seniorDetail.basicInfo.radius;
                    start_timestamp = convertUTCSecondsToUnixNanoSeconds(convertStartDateInUTCTz(moment(date).format(DATE_FORMAT), timezone, startTime));
                    end_timestamp = convertUTCSecondsToUnixNanoSeconds(convertEndDateInUTCTz(moment(date).format(DATE_FORMAT), timezone, endTime));
                    params = {
                        start_timestamp: start_timestamp, //1626750309000000000
                        end_timestamp: end_timestamp, //1628313586000000000
                        senior_id: seniorID || '',
                        account_id: accountID || '',
                        history: isHistory,
                        home_lat: homeCoordinates.latitude,
                        home_lng: homeCoordinates.longitude,
                        home_radius: radius.value,
                        home_radius_unit: radius.radius_measurement,
                        timezone: timezone || '',
                    };
                    dispatch({ type: isCard ? EMPTY_CARD_LOCATION : EMPTY_SENIOR_LOCATION });
                    return [4 /*yield*/, getSeniorLocationService(params)];
                case 2:
                    seniorLocationData = _b.sent();
                    if (!homeCoordinates.latitude) {
                        seniorLocationData = __assign({}, INITIAL_STATE.seniorLocation.locationData);
                    }
                    dispatch({
                        type: isCard ? SET_CARD_LOCATION : SET_SENIOR_LOCATION,
                        payload: {
                            seniorLocation: {
                                locationData: __assign({}, seniorLocationData),
                            },
                        },
                    });
                    return [3 /*break*/, 4];
                case 3:
                    error_8 = _b.sent();
                    console.error({ error: error_8 });
                    return [2 /*return*/, error_8];
                case 4: return [2 /*return*/];
            }
        });
    }); };
};
export var postLocationAPICall = function () { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var seniorID, params, seniorLocateMeError, error_9, errorCode;
    var _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                seniorID = getCurrentSenior().seniorID;
                _c.label = 1;
            case 1:
                _c.trys.push([1, 3, , 4]);
                params = {
                    senior_id: seniorID,
                };
                dispatch(emptyPostApiResponse());
                dispatch(showApplicationLoader());
                return [4 /*yield*/, getSeniorLocationPostAPIService(params)];
            case 2:
                seniorLocateMeError = _c.sent();
                dispatch(hideApplicationLoader());
                dispatch(setSeniorLocateMeError(seniorLocateMeError));
                return [3 /*break*/, 4];
            case 3:
                error_9 = _c.sent();
                dispatch(hideApplicationLoader());
                errorCode = (_b = (_a = error_9 === null || error_9 === void 0 ? void 0 : error_9.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.application_code;
                if (errorCode === APPLICATION_CODE.deviceNotReachableCode) {
                    dispatch(setSeniorLocateMeError(LocationLocateMeError.deviceNotReachable));
                }
                else {
                    dispatch(setSeniorLocateMeError(LocationLocateMeError.locationNotFetched));
                }
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
export var setSeniorLocateMeError = function (errorMessage) { return function (dispatch) {
    dispatch({
        type: SET_SENIOR_LOCATE_ME_ERROR,
        payload: {
            seniorLocation: {
                locateMeResponse: errorMessage,
            },
        },
    });
}; };
export var emptyPostApiResponse = function () { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        dispatch({ type: EMPTY_LOCATE_ME_POST_API_RESPONSE });
        return [2 /*return*/];
    });
}); }; };
export var resetSeniorLocation = function (isCard) {
    if (isCard === void 0) { isCard = false; }
    return function (dispatch) {
        dispatch({ type: isCard ? EMPTY_CARD_LOCATION : EMPTY_SENIOR_LOCATION });
    };
};
export var resetLocationDates = function () { return function (dispatch) {
    dispatch({ type: EMPTY_LOCATION_DATES });
}; };
/**
 * @function getSeniorCalenderDates
 * @description action creator to get all dates which has location data for a particular month
 * @returns void
 */
export var getSeniorCalenderDates = function (month) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, accountID, seniorID, timezone, start_timestamp, end_timestamp, monthKey, params, seniorLocationDates;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = getCurrentSenior(), accountID = _a.accountID, seniorID = _a.seniorID, timezone = _a.timezone;
                start_timestamp = convertUTCSecondsToUnixNanoSeconds(convertStartDateInUTCTz(moment(month).startOf('month').format(DATE_FORMAT), timezone));
                end_timestamp = convertUTCSecondsToUnixNanoSeconds(convertEndDateInUTCTz(moment(month).endOf('month').format(DATE_FORMAT), timezone));
                monthKey = moment(getTimestamp(start_timestamp))
                    .tz(timezone || moment.tz.guess())
                    .format('MM-YYYY');
                params = {
                    senior_id: seniorID,
                    account_id: accountID,
                    start_timestamp: start_timestamp,
                    end_timestamp: end_timestamp,
                    timezone: timezone,
                };
                return [4 /*yield*/, getSeniorLocationHistoryService(params)];
            case 1:
                seniorLocationDates = _c.sent();
                return [4 /*yield*/, dispatch({
                        type: GET_MONTH_ENABLE_DATES,
                        payload: {
                            calenderDates: (_b = {}, _b["".concat(monthKey)] = __assign({}, seniorLocationDates), _b),
                        },
                    })];
            case 2:
                _c.sent();
                return [2 /*return*/, seniorLocationDates.calenderDates];
        }
    });
}); }; };
export var resetPassword = function (otp, email, password) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var params, error_10;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                params = {
                    reset_code: otp,
                    email: email,
                    password: password,
                };
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                dispatch(showApplicationLoader());
                return [4 /*yield*/, resetPasswordService(params)];
            case 2:
                _a.sent();
                dispatch(hideApplicationLoader());
                dispatch(openOverlayDialog({
                    type: DIALOG_TYPES.SUCCESS,
                    firstMessage: "You have successfully changed your password.",
                    secondMessage: "Please login again",
                }));
                dispatch(push('/login'));
                return [3 /*break*/, 4];
            case 3:
                error_10 = _a.sent();
                dispatch(hideApplicationLoader());
                dispatch(showError(error_10));
                return [2 /*return*/, error_10];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
export var showError = function (error) { return function (dispatch) {
    var _a, _b, _c, _d;
    dispatch(openOverlayDialog({
        type: DIALOG_TYPES.ERROR,
        data: {
            errorCode: (_b = (_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.application_code,
            errorMessage: (_d = (_c = error === null || error === void 0 ? void 0 : error.response) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.message,
        },
    }));
}; };
export var isValidEmail = function (email) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var param, emailExist, response, error_11;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                param = {
                    email: email,
                };
                _a.label = 1;
            case 1:
                _a.trys.push([1, 4, , 5]);
                dispatch(showApplicationLoader());
                emailExist = false;
                if (!RegExp(REGEX.EMAIL).test(email)) return [3 /*break*/, 3];
                return [4 /*yield*/, validateEmailAddressService(param)];
            case 2:
                response = _a.sent();
                emailExist = response.data.email_exists;
                _a.label = 3;
            case 3:
                dispatch(hideApplicationLoader());
                return [2 /*return*/, emailExist];
            case 4:
                error_11 = _a.sent();
                dispatch(hideApplicationLoader());
                dispatch(showError(error_11));
                return [2 /*return*/, true];
            case 5: return [2 /*return*/];
        }
    });
}); }; };
