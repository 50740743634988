var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { GET_CONFIG_DATA } from './config.action';
import { configInitialState, } from './config.action.types';
var configReducer = function (state, action) {
    if (state === void 0) { state = configInitialState; }
    if (action.type === GET_CONFIG_DATA) {
        return __assign(__assign({}, state), { messages: {
                resourcesInfo: action.payload.resourcesInfo,
                uploadFileInfo: action.payload.uploadFileInfo,
                uploadUrlInfo: action.payload.uploadUrlInfo,
            }, locationResponseTime: action.payload.locationResponseTime, autoSaveTimeOut: action.payload.autoSaveTimeOut, passwordExpiryWarningDays: action.payload.passwordExpiryWarningDays, passwordExpiryDays: action.payload.passwordExpiryDays });
    }
    else {
        return state;
    }
};
export default configReducer;
