import { makeStyles } from 'tss-react/mui';
export var goalPercentageStyle = makeStyles()(function (theme) { return ({
    cardContainer: {
        width: '395px',
        minHeight: '518px',
        position: 'relative'
    },
    headingContainer: {
        padding: '12px 20px',
    },
    cicularGraphContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    graphInnerValues: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    goalPercentageText: {
        fontSize: '36px',
        fontWeight: 'bold',
        lineHeight: 1.3,
    },
    row: {
        padding: '9px 20px',
        display: 'flex',
        alignItems: 'center',
    },
    bottomOverallGoal: {
        height: '50px',
        padding: '0px 20px',
        bottom: '0px',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: '0px 0px 15px 15px',
        backgroundColor: theme.palette.background.green,
    },
}); });
