import FacilityModel from '../model/facilityModel';
import { FACILITY_TYPES, Y_N } from 'globals/enums';
var FacilityParser = /** @class */ (function () {
    function FacilityParser() {
        this.facilityData = [];
    }
    /**
     * @description function to parse the response data
     * @function parse
     * @param {IFacilityDataResponse[]} data api response array data
     * @returns {IFacilityParse[]} parsed data
     */
    FacilityParser.prototype.parse = function (data) {
        this.facilityData = data.map(function (facility) {
            var facilityTypeResponse = (facility === null || facility === void 0 ? void 0 : facility.facility_type) || {};
            var parsedFacilityType = [];
            if (facilityTypeResponse.assisted_living_yn_c === Y_N.Y)
                parsedFacilityType.push(FACILITY_TYPES.ASSISTED_LIVING);
            if (facilityTypeResponse.independent_living_yn_c === Y_N.Y)
                parsedFacilityType.push(FACILITY_TYPES.INDEPENDENT_LIVING);
            if (facilityTypeResponse.memory_care_yn_c === Y_N.Y)
                parsedFacilityType.push(FACILITY_TYPES.MEMORY_CARE);
            return new FacilityModel(facility.id, facility.name, parsedFacilityType, facility.corporate_id, facility.corporate_name, facility.date_modified, facility.address_street || '', facility.address_city || '', facility.address_state || '', facility.address_postalcode || '', facility.total_residents || 0, facility.phone_office);
        });
        return this.facilityData;
    };
    return FacilityParser;
}());
export default FacilityParser;
