import { corpFacilityUserRole } from 'globals/global.constants';
import CorpFacilityUserModel from '../model/corporateListModel';
var CorpFacilityUsersParser = /** @class */ (function () {
    function CorpFacilityUsersParser() {
        this.agentList = [];
    }
    /**
     * @description function to parse the response data
     * @function parse
     * @param {ICorpFacilityUserAPIData} data api response array data
     * @returns {ICorpFacilityUserAPIData[]} parsed data
     */
    CorpFacilityUsersParser.prototype.parse = function (data) {
        this.agentList = data.map(function (userData) {
            var _a;
            return new CorpFacilityUserModel(userData.id, {
                firstName: userData.first_name,
                lastName: userData.last_name,
            }, {
                street: userData === null || userData === void 0 ? void 0 : userData.address_state,
                city: userData === null || userData === void 0 ? void 0 : userData.address_city,
                state: userData === null || userData === void 0 ? void 0 : userData.address_state,
                postalCode: userData === null || userData === void 0 ? void 0 : userData.address_postalcode,
            }, userData.account_name, userData.account_id, userData.corporate_name, userData.corporate_id, (_a = corpFacilityUserRole[userData.role]) === null || _a === void 0 ? void 0 : _a.label, userData.email, userData.phone, userData.facility_type);
        });
        return this.agentList;
    };
    return CorpFacilityUsersParser;
}());
export default CorpFacilityUsersParser;
