var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Box, TextField, useTheme } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'date-fns';
import moment from 'moment-timezone';
import { makeStyles } from 'tss-react/mui';
import { OpenPickerIcon } from 'common/InputFields/component/Input.component';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { setSelectedDate } from '../WellnessDashboard.actions';
import { GraphView } from 'globals/enums';
var monthPickerStyle = makeStyles()(function () { return ({
    root: {
        '& .MuiIconButton-root': {
            padding: '0 8px 0 0',
        },
    },
}); });
export default function MonthPicker(_a) {
    var setCurrentMonth = _a.setCurrentMonth;
    var theme = useTheme();
    var classes = monthPickerStyle().classes;
    var selectedDate = useAppSelector(function (state) { return state.wellnessDashboard.selectedDate.month; });
    var dispatch = useAppDispatch();
    var handleDateChange = function (date) {
        setCurrentMonth(date);
    };
    var navigatePrevMonth = function () {
        var prevMonth = moment(selectedDate).subtract(1, GraphView.MONTH);
        handleDateChange(prevMonth);
        dispatch(setSelectedDate(prevMonth, GraphView.MONTH));
    };
    var navigateNextMonth = function () {
        var nextMonth = moment(selectedDate).add(1, GraphView.MONTH);
        var selectedMonth = moment(selectedDate)
            .clone()
            .startOf(GraphView.MONTH)
            .format('x');
        var currentMonth = moment().clone().startOf(GraphView.MONTH).format('x');
        if (selectedMonth == currentMonth) {
            return false;
        }
        handleDateChange(nextMonth);
        dispatch(setSelectedDate(nextMonth, GraphView.MONTH));
    };
    return (_jsxs(Box, { display: 'flex', alignItems: 'center', "data-testid": 'month-picker', children: [_jsx(NavigateBeforeIcon, { style: {
                    cursor: 'pointer',
                    color: theme.palette.customColor.primaryGreen,
                }, onClick: navigatePrevMonth }), _jsx(LocalizationProvider, { dateAdapter: AdapterDateFns, children: _jsx(DesktopDatePicker, { renderInput: function (params) { return (_jsx(TextField, __assign({}, params, { inputProps: __assign(__assign({}, params.inputProps), { readOnly: true }), className: classes.root, variant: 'standard', sx: {
                            svg: {
                                '& .cls-1': {
                                    fill: theme.palette.customColor.primaryGreen,
                                },
                            },
                        } }))); }, 
                    // autoOk
                    components: {
                        OpenPickerIcon: OpenPickerIcon,
                    }, 
                    // disableToolbar
                    disableFuture: true, InputProps: {
                        disableUnderline: true,
                        style: {
                            backgroundColor: 'white',
                            paddingLeft: 8,
                            paddingRight: 8,
                            borderRadius: 5,
                            width: 160,
                            border: "1px solid ".concat(theme.palette.customColor.lighterBlack),
                        },
                    }, 
                    // format='MMMM yyyy'
                    views: ['year', GraphView.MONTH], 
                    // variant='inline'
                    value: selectedDate, onChange: function (value) {
                        dispatch(setSelectedDate(value, GraphView.MONTH));
                    }, onAccept: handleDateChange }) }), _jsx(NavigateNextIcon, { style: {
                    cursor: 'pointer',
                    color: theme.palette.customColor.primaryGreen,
                }, onClick: navigateNextMonth })] }));
}
