import { Fragment as _Fragment, jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useAppSelector } from 'hooks/reduxHooks';
var RenderName = function (_a) {
    var name = _a.name;
    return (_jsxs(_Fragment, { children: [(name === null || name === void 0 ? void 0 : name.firstName) && (_jsxs(_Fragment, { children: [name.firstName, "\u00A0"] })), (name === null || name === void 0 ? void 0 : name.middleName) && (_jsxs(_Fragment, { children: [name.middleName, "\u00A0"] })), (name === null || name === void 0 ? void 0 : name.lastName) && (_jsxs(_Fragment, { children: [name.lastName, "\u00A0"] }))] }));
};
var UserName = function (_a) {
    var firstName = _a.firstName, middleName = _a.middleName, lastName = _a.lastName, name = _a.name;
    var userName = useAppSelector(function (state) { return state.auth.userName; });
    return (_jsxs(_Fragment, { children: [name && _jsx(RenderName, { name: name }), firstName && (_jsxs(_Fragment, { children: [userName.first_name, "\u00A0"] })), middleName && (_jsxs(_Fragment, { children: [userName.middle_name, "\u00A0"] })), lastName && (_jsxs(_Fragment, { children: [userName.last_name, "\u00A0"] }))] }));
};
export default UserName;
