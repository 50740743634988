'use strict';
var _a;
var KEYS = Object.freeze({
    NAVIGATION_ENABLE: 'navigationEnable',
});
var SharedStorage = /** @class */ (function () {
    function SharedStorage() {
    }
    SharedStorage.save = function (key, data) {
        this.store[key] = data;
    };
    Object.defineProperty(SharedStorage, "navigationEnable", {
        get: function () {
            return this.store[this.keys.NAVIGATION_ENABLE];
        },
        enumerable: false,
        configurable: true
    });
    SharedStorage.setNavigationEnable = function (data) {
        this.save(this.keys.NAVIGATION_ENABLE, data);
    };
    Object.defineProperty(SharedStorage, "userData", {
        get: function () {
            return this.store[this.keys.USER_DATA];
        },
        enumerable: false,
        configurable: true
    });
    SharedStorage.keys = KEYS;
    SharedStorage.store = (_a = {},
        _a[KEYS.NAVIGATION_ENABLE] = false,
        _a);
    return SharedStorage;
}());
export default SharedStorage;
