import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from '@mui/material';
import { changePasswordStyle } from './ChangePassword.style';
import UpdatePassword from 'common/UpdatePassword/UpdatePassword';
import WelcomeWrapper from 'common/WelcomeWrapper/WelcomeWrapper.component';
var ChangePassword = function () {
    var classes = changePasswordStyle().classes;
    return (_jsx(WelcomeWrapper, { children: _jsx(Box, { className: classes.mainContainer, children: _jsx(Box, { boxShadow: 1, className: classes.choosePassword, children: _jsx(UpdatePassword, { showCancelBtn: true, heading: 'Change password', showOldPasswordField: true, placeholderText: 'Enter New Password' }) }) }) }));
};
export default ChangePassword;
