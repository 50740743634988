export var GET_ASSESSMENT = 'GET_ASSESSMENT';
export var UPDATE_ASSESSMENT_PAGE_NUMBER = 'UPDATE_ASSESSMENT_PAGE_NUMBER';
export var RESET_ASSESSMENT = 'RESET_ASSESSMENT';
export var GET_ASSESSMENT_HISTORY = 'GET_ASSESSMENT_HISTORY';
export var GET_ASSESSMENT_HISTORY_SUCCESS = 'GET_ASSESSMENT_HISTORY_SUCCESS';
export var GET_ASSESSMENT_HISTORY_FAIL = 'GET_ASSESSMENT_HISTORY_FAIL';
export var UPDATE_ASSESSMENT_HISTORY_PAGE_NUMBER = 'UPDATE_ASSESSMENT_HISTORY_PAGE_NUMBER';
export var TOGGLE_VIEW_STATE = 'TOGGLE_VIEW_STATE';
export var TOGGLE_IS_COMPLETED = 'TOGGLE_IS_COMPLETED';
export var GET_LOADING_STATUS = ' GET_LOADING_STATUS';
export var GET_SEARCH_RESULT = ' GET_SEARCH_RESULT';
export var SEARCH_RESULT_SUCCESS = 'SEARCH_RESULT_SUCCESS';
export var SEARCH_RESULT_FAIL = 'SEARCH_RESULT_FAIL';
export var RESET_SEARCH = 'RESET_SEARCH';
/**
 * @function updateAssessmentPageNumber
 * @description action creator to update the page number of history table
 * @param {string | number} value
 */
export var updateAssessmentPageNumber = function (value) { return function (dispatch) {
    dispatch({
        type: UPDATE_ASSESSMENT_PAGE_NUMBER,
        payload: value,
    });
}; };
