export var INITIAL_STATE = {
    thresholds: {
        vitals: {
            active: [],
            inactive: [],
        },
        //@need to store whole object from API
        selectedVital: null,
    },
};
