import { jsx as _jsx } from "react/jsx-runtime";
/** @format */
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { hideToast } from './Toast.action';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
var Toast = function () {
    var dispatch = useAppDispatch();
    var toast = useAppSelector(function (state) { return state.toast; });
    /**
     * @description handle when we close toast
     * @param {any} event
     * @param {any} reason
     * @returns {void}
     */
    var handleClose = function (event, reason) {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(hideToast());
    };
    return (_jsx(Snackbar, { anchorOrigin: { vertical: 'bottom', horizontal: 'center' }, open: toast.open, autoHideDuration: 6000, onClose: handleClose, children: _jsx(Alert, { variant: 'filled', elevation: 6, onClose: handleClose, severity: toast.type, children: toast.message }) }));
};
export { Toast };
