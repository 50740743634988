import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable max-len */
import { PusherConnectionStatus, SecretsKeys } from 'globals/enums';
import React from 'react';
import AlertChannelEvents from './channels/alertChannelEvents';
import PusherApp from './pusherApp';
import SummaryChannelEvents from './channels/summaryChannelEvents';
import AlarmChannelEvents from './channels/alarmChannelEvents';
import { useQuery } from 'utilities/react-query';
import { getSecretsDataService } from 'services/configService/config.service';
import { showError } from 'store/commonReducer/common.action';
import { hideApplicationLoader } from 'common/ApplicationLoader';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
var PusherContext = React.createContext({});
function PusherProvider(_a) {
    var children = _a.children;
    var isAuthenticated = useAppSelector(function (state) { var _a; return (_a = state.auth) === null || _a === void 0 ? void 0 : _a.isAuthenticated; });
    var isPusher = useAppSelector(function (state) { var _a, _b; return (_b = (_a = state.auth) === null || _a === void 0 ? void 0 : _a.roleConfig) === null || _b === void 0 ? void 0 : _b.isPusher; });
    var pusherApp = new PusherApp();
    var alertChannelEvents = new AlertChannelEvents();
    var summaryChannelEvents = new SummaryChannelEvents();
    var alarmChannelEvents = new AlarmChannelEvents();
    var _b = React.useState(false), connectionStatus = _b[0], setConnectionStatus = _b[1];
    var dispatch = useAppDispatch();
    //query to get secrets data
    var _c = useQuery({
        queryKey: ['secretsData'],
        queryFn: function () {
            return getSecretsDataService({
                keys: JSON.stringify([
                    SecretsKeys.PUSHER_CHANNEL_CLUSTER,
                    SecretsKeys.PUSHER_CHANNEL_KEY,
                ]),
            });
        },
        enabled: isAuthenticated,
        onError: function (error) {
            dispatch(hideApplicationLoader());
            dispatch(showError(error));
        },
    }), data = _c.data, isSuccess = _c.isSuccess;
    React.useEffect(function () {
        if (isAuthenticated && isSuccess && data && isPusher) {
            pusherApp.establishConnection(data.pusherKey, data.pusherCluster);
            pusherApp.onPusherStateChange(function (state) {
                setConnectionStatus(state);
            });
            pusherApp.onError(function (error) {
                // eslint-disable-next-line no-console
                console.log('pusher error', error);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, isSuccess, isPusher]);
    var contextValue = React.useMemo(function () { return ({
        pusherApp: pusherApp,
        connectionStatus: connectionStatus,
        alertChannelEvents: alertChannelEvents,
        summaryChannelEvents: summaryChannelEvents,
        alarmChannelEvents: alarmChannelEvents,
    }); }, [
        pusherApp,
        connectionStatus,
        alertChannelEvents,
        summaryChannelEvents,
        alarmChannelEvents,
    ]);
    return (_jsx(PusherContext.Provider, { value: contextValue, children: children }));
}
/**
 * @function userPuser
 * @description custom hook to provide EventModule methods and pusher connection status
 * @returns pusher connectionStatus and EventModule class instance
 */
function usePusher() {
    var context = React.useContext(PusherContext);
    if (!context) {
        // Fail fast if not within a PusherProvider
        throw new Error('usePusher must be used within a PusherProvider');
    }
    var pusherApp = context.pusherApp, connectionStatus = context.connectionStatus, alertChannelEvents = context.alertChannelEvents, summaryChannelEvents = context.summaryChannelEvents, alarmChannelEvents = context.alarmChannelEvents;
    return {
        pusherConnectionStatus: connectionStatus === PusherConnectionStatus.Connected,
        pusherApp: pusherApp,
        alertChannelEvents: alertChannelEvents,
        summaryChannelEvents: summaryChannelEvents,
        alarmChannelEvents: alarmChannelEvents,
    };
}
export { PusherProvider, usePusher };
