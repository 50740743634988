var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { showApplicationLoader, hideApplicationLoader, } from 'common/ApplicationLoader';
import { API } from 'globals/api';
import { emptyStringToNull, getCurrentSenior, getQueryParamTimezone, unmaskPhoneNumber, } from 'globals/global.functions';
import { showToast } from 'common/Toast';
import { seniorPersonaForm } from 'forms';
import get from 'lodash.get';
import { setSeniorDetail, setSeniorImage, } from 'store/commonReducer/common.action';
import { isEmpty } from 'lodash';
// eslint-disable-next-line max-len
import { validateEmailAddressService, validateMobileNumberService, } from '../../../../services/addUserService/validateEmailAddressAndMobileService';
import { push } from 'redux-first-history';
import { GetSeniorData } from 'globals/enums';
export var UNIQUE_EMAIL_ID = 'UNIQUE_EMAIL_ID';
export var UNIQUE_NUMBER = 'UNIQUE_NUMBER';
export var RESET_VALIDATION = 'RESET_VALIDATION';
export var UNIQUE_EMPLOYEE_ID = 'UNIQUE_EMPLOYEE_ID';
export var createAccount = function (data, isProfileCreated) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var res, newSeniorAccount, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    dispatch(showApplicationLoader());
                    return [4 /*yield*/, API({
                            url: 'users/senior/minimal-info',
                            method: 'post',
                            data: data,
                        })];
                case 1:
                    res = _a.sent();
                    newSeniorAccount = res.data[0];
                    dispatch(hideApplicationLoader());
                    if (isProfileCreated) {
                        dispatch(showToast('Account saved successfully!', 'success'));
                        dispatch(setSeniorDetail(GetSeniorData.SENIOR));
                    }
                    else {
                        dispatch(showToast("Account created successfully!", 
                        // "Account created successfully!Login credentials and app links are sent to the senior's email address.",
                        'success'));
                    }
                    dispatch(push("/senior/".concat(newSeniorAccount.senior_id, "/").concat(newSeniorAccount.account_id, "/").concat(getQueryParamTimezone(newSeniorAccount.timezone), "/onboarding-info")));
                    dispatch(setSeniorDetail(GetSeniorData.SENIOR));
                    dispatch(setSeniorImage());
                    return [2 /*return*/, { success: true, data: res.data[0] }];
                case 2:
                    error_1 = _a.sent();
                    if (error_1.response.data.message.includes('Zipcode')) {
                        dispatch(hideApplicationLoader());
                        dispatch(showToast('Zipcode is invalid', 'error'));
                    }
                    else {
                        dispatch(hideApplicationLoader());
                        dispatch(showToast(error_1.response.data.message, 'error'));
                    }
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var generateMember = function () {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var res, memberId, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, API({
                            url: 'cognito/user/generate-memberid',
                            method: 'get',
                        })];
                case 1:
                    res = _a.sent();
                    memberId = res.data.member_id;
                    return [2 /*return*/, memberId];
                case 2:
                    error_2 = _a.sent();
                    console.error('Error generating member ID:', error_2);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var fetchSeniorInfo = function (data) {
    return function (dispatch) {
        dispatch(showApplicationLoader());
        var seniorInfo = getCurrentSenior();
        if (!seniorInfo) {
            dispatch(hideApplicationLoader());
            return Promise.resolve(null);
        }
        var minimalInfoAPI = API({
            // eslint-disable-next-line max-len
            url: "users/senior/minimal-info?senior_id=".concat(seniorInfo.seniorID, "&account_id=").concat(seniorInfo.accountID),
            method: 'get',
            data: data,
        });
        var basicInfoAPI = API({
            // eslint-disable-next-line max-len
            url: "users/senior/basic-info?senior_id=".concat(seniorInfo.seniorID, "&account_id=").concat(seniorInfo.accountID),
            method: 'get',
            data: data,
        });
        var personAPI = API({
            // eslint-disable-next-line max-len
            url: "users/senior/persona?senior_id=".concat(seniorInfo.seniorID, "&account_id=").concat(seniorInfo.accountID),
            method: 'get',
            data: data,
        });
        return Promise.all([minimalInfoAPI, basicInfoAPI, personAPI])
            .then(function (res) {
            var _a, _b, _c, _d;
            dispatch(hideApplicationLoader());
            return {
                minimalInfo: (_a = res[0]) === null || _a === void 0 ? void 0 : _a.data,
                basicInfo: !isEmpty((_b = res[1]) === null || _b === void 0 ? void 0 : _b.data) && ((_c = res[1]) === null || _c === void 0 ? void 0 : _c.data),
                persona: (_d = res[2]) === null || _d === void 0 ? void 0 : _d.data,
            };
        })
            .catch(function () {
            dispatch(hideApplicationLoader());
        });
    };
};
var updatePersona = function (data) {
    Object.entries(data.persona).forEach(function (persona) {
        var index = seniorPersonaForm.findIndex(function (_a) {
            var field_id = _a.field_id;
            return field_id === persona[0];
        });
        seniorPersonaForm[index].field_value.data = persona[1];
    });
    return seniorPersonaForm;
};
export var saveProfileInfo = function (data, param) {
    return function (dispatch) {
        var _a, _b;
        dispatch(showApplicationLoader());
        var accountInfo = getCurrentSenior();
        var updatedPersona = updatePersona(data);
        data.senior_info.home_phone = unmaskPhoneNumber(data.senior_info.home_phone);
        data.senior_info.emergency_phone = unmaskPhoneNumber(data.senior_info.emergency_phone);
        data.senior_info.social_media_links = [
            (_a = data.senior_info.social_media_links) === null || _a === void 0 ? void 0 : _a.replace(/^https?:\/\//i, ''),
            (_b = data.senior_info.social_media_links2) === null || _b === void 0 ? void 0 : _b.replace(/^https?:\/\//i, ''),
        ];
        delete data.senior_info.social_media_links2;
        var updatedSeniorInfo = emptyStringToNull(data.senior_info);
        data.persona = updatedPersona;
        data = __assign({ senior_info: updatedSeniorInfo, persona: updatedPersona }, accountInfo);
        var seniorInfoAPI = API({
            url: 'users/senior/basic-info',
            method: 'put',
            data: __assign({ senior_info: updatedSeniorInfo }, accountInfo),
        });
        var seniorPersonaAPI = API({
            url: 'users/senior/persona',
            method: 'put',
            data: __assign({ persona: updatedPersona }, accountInfo),
        });
        Promise.all([seniorInfoAPI, seniorPersonaAPI])
            .then(function () {
            dispatch(hideApplicationLoader());
            dispatch(showToast('Saved Successfully', 'success'));
            if (!isEmpty(param)) {
                dispatch(setSeniorDetail(GetSeniorData.SENIOR));
            }
            return { success: true };
        })
            .catch(function (err) {
            dispatch(hideApplicationLoader());
            dispatch(showToast(get(err, 'response.data.message'), 'error'));
        });
    };
};
export var uplaodImage = function (data) {
    return function (dispatch) {
        var accountInfo = getCurrentSenior();
        if (!accountInfo) {
            dispatch(hideApplicationLoader());
            return Promise.resolve(null);
        }
        return API({
            url: 'users/senior/image',
            method: 'post',
            data: {
                image: data,
                account_id: accountInfo.account_id,
                senior_id: accountInfo.senior_id,
            },
        })
            .then(function (res) {
            return res.data[0];
        })
            .catch(function () {
            /**/
        });
    };
};
export var removeImage = function (image_id) {
    return function (dispatch) {
        var accountInfo = getCurrentSenior();
        if (!accountInfo) {
            dispatch(hideApplicationLoader());
            return Promise.resolve(null);
        }
        var account_id = accountInfo.account_id, senior_id = accountInfo.senior_id;
        return API({
            url: 'users/senior/image',
            method: 'delete',
            data: {
                image_id: image_id,
                account_id: account_id,
                senior_id: senior_id,
            },
        })
            .then(function () {
            dispatch(setSeniorImage());
            return { success: true };
        })
            .catch(function () {
            /**/
        });
    };
};
export var setProfileImage = function (image_id, param) {
    return function (dispatch) {
        var accountInfo = getCurrentSenior();
        if (!accountInfo || !image_id) {
            dispatch(hideApplicationLoader());
            return Promise.resolve(null);
        }
        var account_id = accountInfo.account_id, senior_id = accountInfo.senior_id;
        return API({
            url: 'users/senior/profile-image',
            method: 'post',
            data: {
                image_id: image_id, // Ensure image_id is correctly passed
                account_id: account_id,
                senior_id: senior_id,
            },
        })
            .then(function () {
            if (!isEmpty(param)) {
                dispatch(setSeniorImage());
            }
            return { success: true };
        })
            .catch(function (error) {
            console.error('Error setting profile image:', error);
            dispatch(showToast('Error setting profile image!', 'error'));
        });
    };
};
export var getImage = function (accountInfo) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var res, images, latestImageId, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!accountInfo) {
                        return [2 /*return*/, Promise.resolve(null)];
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, API({
                            url: "users/senior/image?senior_id=".concat(accountInfo.senior_id, "&account_id=").concat(accountInfo.account_id),
                            method: 'get',
                        })];
                case 2:
                    res = _a.sent();
                    images = res.data.images;
                    latestImageId = getLatestImageId(images);
                    // Check if we have a valid latestImageId
                    if (latestImageId) {
                        // Call the setProfileImage function with the latest image ID
                        dispatch(setProfileImage(latestImageId, null));
                    }
                    return [2 /*return*/, { profile: res.data.profile, images: images }];
                case 3:
                    err_1 = _a.sent();
                    console.error('Error fetching images:', err_1);
                    return [2 /*return*/, err_1];
                case 4: return [2 /*return*/];
            }
        });
    }); };
};
export var getLatestImageId = function (images) {
    if (!images || images.length === 0) {
        return null;
    }
    // Sort the images by date in descending order and pick the first one (latest)
    var latestImage = images.sort(function (a, b) { return new Date(b.date).getTime() - new Date(a.date).getTime(); })[0];
    return latestImage.id;
};
var getImageValue = function (image, sen_id, acc_id) {
    return API({
        // eslint-disable-next-line max-len
        url: "users/senior/download-image?senior_id=".concat(sen_id, "&account_id=").concat(acc_id, "&image_id=").concat(image.id),
        method: 'get',
    })
        .then(function (res) {
        return res.data;
    })
        .catch(function () {
        /**/
    });
};
//calling the email address validation api
export var validateEmailAddress = function (data) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var params, response, error_3;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    dispatch(showApplicationLoader());
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 11, , 12]);
                    params = {
                        email: (_a = data === null || data === void 0 ? void 0 : data.email) === null || _a === void 0 ? void 0 : _a.toLowerCase(),
                        employee_id: data.empId,
                    };
                    return [4 /*yield*/, validateEmailAddressService(params)];
                case 2:
                    response = _b.sent();
                    dispatch(isExistsEmailAddress(false));
                    dispatch(isExistsEmployeeId(false));
                    if (!!response.data.email_exists) return [3 /*break*/, 4];
                    return [4 /*yield*/, dispatch(isExistsEmailAddress(response.data.is_first_login || response.data.email_exists))];
                case 3:
                    _b.sent();
                    return [3 /*break*/, 6];
                case 4: return [4 /*yield*/, dispatch(isExistsEmailAddress(response.data.email_exists, 'Email address already in use'))];
                case 5:
                    _b.sent();
                    _b.label = 6;
                case 6:
                    if (!!response.data.employee_id) return [3 /*break*/, 8];
                    return [4 /*yield*/, dispatch(isExistsEmployeeId(response.data.employee_id))];
                case 7:
                    _b.sent();
                    return [3 /*break*/, 10];
                case 8: return [4 /*yield*/, dispatch(isExistsEmployeeId(response.data.employee_id, 'Employee id already in use'))];
                case 9:
                    _b.sent();
                    _b.label = 10;
                case 10:
                    dispatch(hideApplicationLoader());
                    return [2 /*return*/, response.data];
                case 11:
                    error_3 = _b.sent();
                    dispatch(hideApplicationLoader());
                    dispatch(showToast(error_3.message, 'error'));
                    return [3 /*break*/, 12];
                case 12: return [2 /*return*/];
            }
        });
    }); };
};
export var isExistsEmailAddress = function (data, message) {
    if (message === void 0) { message = ''; }
    return ({
        type: UNIQUE_EMAIL_ID,
        payload: { data: data, message: message },
    });
};
export var isExistsEmployeeId = function (data, message) {
    if (message === void 0) { message = ''; }
    return ({
        type: UNIQUE_EMPLOYEE_ID,
        payload: { data: data, message: message },
    });
};
//calling the mobile number validation api
export var validateMobileNumber = function (data) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var params, accountInfo, response, error_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    dispatch(showApplicationLoader());
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 7, , 8]);
                    params = {
                        mobile_number: unmaskPhoneNumber(data),
                    };
                    accountInfo = getCurrentSenior();
                    if (accountInfo === null || accountInfo === void 0 ? void 0 : accountInfo.senior_id) {
                        params['user_id'] = accountInfo === null || accountInfo === void 0 ? void 0 : accountInfo.senior_id;
                    }
                    return [4 /*yield*/, validateMobileNumberService(params)];
                case 2:
                    response = _a.sent();
                    dispatch(isExistsMobileNumber(false, ''));
                    if (!!response.data.mobile_number_exists) return [3 /*break*/, 4];
                    return [4 /*yield*/, dispatch(isExistsMobileNumber(response.data.mobile_number_exists, ''))];
                case 3:
                    _a.sent();
                    return [3 /*break*/, 6];
                case 4: return [4 /*yield*/, dispatch(isExistsMobileNumber(response.data.mobile_number_exists, 'Mobile number already in use'))];
                case 5:
                    _a.sent();
                    _a.label = 6;
                case 6:
                    dispatch(hideApplicationLoader());
                    return [3 /*break*/, 8];
                case 7:
                    error_4 = _a.sent();
                    dispatch(hideApplicationLoader());
                    dispatch(showToast(error_4.message, 'error'));
                    return [3 /*break*/, 8];
                case 8: return [2 /*return*/];
            }
        });
    }); };
};
var isExistsMobileNumber = function (data, message) { return ({
    type: UNIQUE_NUMBER,
    payload: { data: data, message: message },
}); };
export var resetValidation = function () { return ({
    type: RESET_VALIDATION,
}); };
