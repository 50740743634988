var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { getPaginationDataIsolated, getPaginationOffsetData, } from 'store/commonReducer/common.action';
import { PaginationSearchProvider } from './PaginationSearchContext';
import SearchContainer from './SearchContainer';
import PaginationContainer from './PaginationContainer';
import { PaginationFetchTypes } from 'globals/enums';
import PaginationOffsetContainer from './PaginationOffsetContainer';
import { useAppDispatch } from 'hooks/reduxHooks';
/**
 * @description HOC to provide pagination in tables
 * @param {any} WrappedComponent component
 * @param {Object} componentProps props of wrapped component
 * @param {IWithPaginationProps} withPaginationProps props of HOC
 * @returns {JSX.Element} JSX
 */
var withPaginationTable = function (WrappedComponent, componentProps, withPaginationProps) {
    var WithPaginationSearch = function () {
        var _a;
        var dispatch = useAppDispatch();
        var dependency = (_a = withPaginationProps === null || withPaginationProps === void 0 ? void 0 : withPaginationProps.dependency) !== null && _a !== void 0 ? _a : true;
        React.useEffect(function () {
            if (dependency) {
                if (withPaginationProps.fetchType === PaginationFetchTypes.OFFSET) {
                    var paginationOffsetProps = withPaginationProps.paginationOffsetProps;
                    dispatch(getPaginationOffsetData(paginationOffsetProps === null || paginationOffsetProps === void 0 ? void 0 : paginationOffsetProps.getData, paginationOffsetProps === null || paginationOffsetProps === void 0 ? void 0 : paginationOffsetProps.rowsPerPage, 1, 0, paginationOffsetProps === null || paginationOffsetProps === void 0 ? void 0 : paginationOffsetProps.limit, paginationOffsetProps === null || paginationOffsetProps === void 0 ? void 0 : paginationOffsetProps.onSuccess, paginationOffsetProps === null || paginationOffsetProps === void 0 ? void 0 : paginationOffsetProps.onError, [], ''));
                }
                else {
                    var paginationProps = withPaginationProps.paginationProps;
                    dispatch(getPaginationDataIsolated(paginationProps.getData, paginationProps.rowsPerPage, paginationProps.cacheKey || '', 1, paginationProps.onSuccess, paginationProps.onError, [], ''));
                }
            }
        }, [dispatch, dependency]);
        switch (withPaginationProps.fetchType) {
            case PaginationFetchTypes.LAST_EVALUATED_KEY: {
                var paginationProps = withPaginationProps.paginationProps, searchBarProps = withPaginationProps.searchBarProps;
                var paginationPropsModified = __assign(__assign({}, paginationProps), { componentProps: componentProps, WrappedComponent: WrappedComponent });
                return (_jsxs(PaginationSearchProvider, { children: [searchBarProps && _jsx(SearchContainer, __assign({}, searchBarProps)), _jsx(PaginationContainer, __assign({}, paginationPropsModified))] }));
            }
            case PaginationFetchTypes.OFFSET: {
                var paginationOffsetProps = withPaginationProps.paginationOffsetProps, searchBarProps = withPaginationProps.searchBarProps;
                var paginationOffsetPropsModified = __assign(__assign({}, paginationOffsetProps), { componentProps: componentProps, WrappedComponent: WrappedComponent });
                return (_jsxs(PaginationSearchProvider, { children: [searchBarProps && (_jsx(SearchContainer, __assign({}, searchBarProps, { fetchType: withPaginationProps.fetchType }))), _jsx(PaginationOffsetContainer, __assign({}, paginationOffsetPropsModified))] }));
            }
            default:
                return _jsx(_Fragment, {});
        }
    };
    return WithPaginationSearch;
};
export default withPaginationTable;
