var _a;
/* eslint-disable max-len */
import { appRoutesEndpoints } from '../routes/appRoutesEndpoints';
import { Roles } from '../globals/enums';
export var ROLES_CONFIG = (_a = {},
    _a[Roles.Admin] = {
        // eslint-disable-next-line max-len
        defaultPage: "".concat(appRoutesEndpoints.admin.baseRoute).concat(appRoutesEndpoints.admin.nestedRoutes.careInsightReview.baseRoute),
        defaultHomeRoute: 'dashboard',
        role: Roles.Admin,
        accessLabel: 'Admin',
        isPusher: true,
        careInsightPrivileges: {
            isAlerts: false,
            isApproveAlert: true,
            isApproveSummary: true,
            isAlertActionNotification: false,
            isSummaryActionNotification: false,
            isAlarms: true,
            isCIRangeMilestoneNotification: true,
            isLocationData: true,
            isFacilitySummaryActionNotification: false,
        },
    },
    _a[Roles.CareAgent] = {
        defaultPage: appRoutesEndpoints.homePage,
        role: Roles.CareAgent,
        defaultHomeRoute: 'dashboard',
        accessLabel: 'Agent',
        isPusher: true,
        careInsightPrivileges: {
            isAlerts: true,
            isApproveAlert: false,
            isApproveSummary: false,
            isAlertActionNotification: true,
            isSummaryActionNotification: true,
            isAlarms: true,
            isCIRangeMilestoneNotification: false,
            isLocationData: true,
            isFacilitySummaryActionNotification: false,
        },
    },
    _a[Roles.WarehouseEmployee] = {
        defaultPage: appRoutesEndpoints.homePage,
        defaultHomeRoute: 'onboarding-info',
        accessLabel: 'Warehouse Employee',
        role: Roles.WarehouseEmployee,
        isPusher: false,
        careInsightPrivileges: {
            isAlerts: false,
            isApproveAlert: false,
            isApproveSummary: false,
            isAlertActionNotification: false,
            isSummaryActionNotification: false,
            isAlarms: false,
            isCIRangeMilestoneNotification: false,
            isLocationData: false,
            isFacilitySummaryActionNotification: false,
        },
    },
    _a[Roles.BDM] = {
        defaultPage: "".concat(appRoutesEndpoints.admin.baseRoute).concat(appRoutesEndpoints.admin.nestedRoutes.corporateAndFacilities.baseRoute),
        defaultHomeRoute: 'onboarding-info',
        accessLabel: 'BDM',
        role: Roles.BDM,
        isPusher: false,
        careInsightPrivileges: {
            isAlerts: false,
            isApproveAlert: false,
            isApproveSummary: false,
            isAlertActionNotification: false,
            isSummaryActionNotification: false,
            isAlarms: false,
            isCIRangeMilestoneNotification: false,
            isLocationData: false,
            isFacilitySummaryActionNotification: false,
        },
    },
    _a[Roles.FACILITY_AGENT] = {
        defaultPage: "".concat(appRoutesEndpoints.facilityAgent.baseRoute),
        defaultHomeRoute: 'facility-management',
        accessLabel: 'Community Agent',
        role: Roles.FACILITY_AGENT,
        isPusher: true,
        careInsightPrivileges: {
            isAlerts: false,
            isApproveAlert: false,
            isApproveSummary: false,
            isAlertActionNotification: false,
            isSummaryActionNotification: false,
            isAlarms: false,
            isCIRangeMilestoneNotification: false,
            isLocationData: false,
            isFacilitySummaryActionNotification: true,
        },
    },
    _a);
