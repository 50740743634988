var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { FormControlLabel, RadioGroup, Typography } from '@mui/material';
import { fieldsStyle } from '../InputFields.style';
import { InputRadio } from './Input.component';
var InputRadioWrapper = function (item) {
    var inputProps = item.inputProps, eventProps = item.eventProps, props = __rest(item, ["inputProps", "eventProps"]);
    var classes = fieldsStyle().classes;
    return (_jsx(_Fragment, { children: _jsx(RadioGroup, __assign({}, eventProps, { className: props.className, children: props.radioItems.map(function (data) {
                return (_jsx(FormControlLabel, { control: _jsx(InputRadio, __assign({}, inputProps)), value: data.value, label: props.showRadioLabel ? (_jsx(Typography, { className: classes.labelStyle, children: data.label })) : (''), className: classes.radioComponent }, data.value));
            }) })) }));
};
export default InputRadioWrapper;
