import { getTimestamp } from 'globals/global.functions';
import ActivityGraphModel from '../model/activityGraphModel';
var ActivityGraphParser = /** @class */ (function () {
    function ActivityGraphParser() {
        this.activityGraphData = {
            overall: {
                data: [],
                score: 0,
                target: 0,
            },
            blue: {
                data: [],
                score: 0,
                target: 0,
            },
            green: {
                data: [],
                score: 0,
                target: 0,
            },
            gold: {
                data: [],
                score: 0,
                target: 0,
            },
        };
        this.getDataArray = function (data) {
            var dataArray = Object.entries(data).map(function (entry) {
                var timestamp = entry[0], obj = entry[1];
                return {
                    timestamp: getTimestamp(timestamp) || 0,
                    score: obj.activity_score,
                };
            });
            return dataArray;
        };
    }
    /**
     * @description function to parse the response data
     * @function parse
     * @param {IActivityGraphDataResponse[]} data api response data
     * @returns {IActivityGraphParse} parsed data
     */
    ActivityGraphParser.prototype.parse = function (data) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        this.activityGraphData = new ActivityGraphModel({
            data: this.getDataArray((data === null || data === void 0 ? void 0 : data.overall) || {}),
            score: (data === null || data === void 0 ? void 0 : data.overall_activity_score) || 0,
            target: (data === null || data === void 0 ? void 0 : data.overall_target_score) || 0,
        }, {
            data: this.getDataArray(((_a = data === null || data === void 0 ? void 0 : data.zone) === null || _a === void 0 ? void 0 : _a.blue) || {}),
            score: ((_b = data === null || data === void 0 ? void 0 : data.zone) === null || _b === void 0 ? void 0 : _b.blue_activity_score) || 0,
            target: ((_c = data === null || data === void 0 ? void 0 : data.zone) === null || _c === void 0 ? void 0 : _c.blue_target_score) || 0,
        }, {
            data: this.getDataArray(((_d = data === null || data === void 0 ? void 0 : data.zone) === null || _d === void 0 ? void 0 : _d.green) || {}),
            score: ((_e = data === null || data === void 0 ? void 0 : data.zone) === null || _e === void 0 ? void 0 : _e.green_activity_score) || 0,
            target: ((_f = data === null || data === void 0 ? void 0 : data.zone) === null || _f === void 0 ? void 0 : _f.green_target_score) || 0,
        }, {
            data: this.getDataArray(((_g = data === null || data === void 0 ? void 0 : data.zone) === null || _g === void 0 ? void 0 : _g.gold) || {}),
            score: ((_h = data === null || data === void 0 ? void 0 : data.zone) === null || _h === void 0 ? void 0 : _h.gold_activity_score) || 0,
            target: ((_j = data === null || data === void 0 ? void 0 : data.zone) === null || _j === void 0 ? void 0 : _j.gold_target_score) || 0,
        });
        return this.activityGraphData;
    };
    return ActivityGraphParser;
}());
export default ActivityGraphParser;
