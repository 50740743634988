var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import Box from '@mui/material/Box';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { InputTimePicker } from './Input.component';
import { fieldsStyle } from '../InputFields.style';
var SelectControlledTime = function (_a) {
    var _b;
    var inputProps = _a.inputProps, eventProps = _a.eventProps, props = __rest(_a, ["inputProps", "eventProps"]);
    var classes = fieldsStyle().classes;
    return (_jsxs(LocalizationProvider, { dateAdapter: AdapterDateFns, children: [_jsx(InputTimePicker, __assign({ clearable: true, className: clsx((_b = {}, _b[classes.inputDateError] = props.isError, _b)), variant: 'inline', style: { marginBottom: 4 }, autoOk: true, InputProps: {
                    disableUnderline: true,
                    readOnly: props.readOnly || false,
                }, invalidDateMessage: '', inputProps: __assign(__assign({ placeholder: 'HH:MM' }, inputProps), { 'data-error': props.isError ? 'error' : '' }) }, eventProps)), props.isError && props.errorText && (_jsx(Box, { className: classes.errorTextSelect, children: props.errorText }))] }));
};
export default SelectControlledTime;
