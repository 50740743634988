var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { API } from 'globals/api';
import { ALARM_END_POINTS } from 'globals/apiEndPoints';
import { getTimestamp } from 'globals/global.functions';
/**
 * @function getAllAlarmEventsService
 * @description API service to get existing events with status pending, assigned
 * @param params api payload
 * @returns Promise
 */
export function getAllAlarmEventsService(params) {
    return __awaiter(this, void 0, void 0, function () {
        var response, alarmData, error_1, errorMessage;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, API({
                            url: ALARM_END_POINTS.GET_ALARMS,
                            method: 'get',
                            params: params,
                        })];
                case 1:
                    response = _a.sent();
                    alarmData = response.data;
                    return [2 /*return*/, mapAlarmDataPayload(alarmData)];
                case 2:
                    error_1 = _a.sent();
                    errorMessage = error_1.response.data.message;
                    throw new Error(errorMessage);
                case 3: return [2 /*return*/];
            }
        });
    });
}
/**
 * @function updateAlarmStatusService
 * @description API service to get existing events with status pending, assigned
 * @param params api payload
 * @returns Promise
 */
export function updateAlarmStatusService(params) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, API({
                        url: ALARM_END_POINTS.UPDATE_ALARMS,
                        method: 'put',
                        params: params,
                    })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
/**
 * @function getSOSListService
 * @description API service to get existing SOS with status pending, assigned
 * @param params api payload
 * @returns Promise
 */
export function getSOSListService(params) {
    return __awaiter(this, void 0, void 0, function () {
        var response, error_2, errorMessage;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, API({
                            url: ALARM_END_POINTS.GET_SOS,
                            method: 'get',
                            params: params,
                        })];
                case 1:
                    response = _b.sent();
                    return [2 /*return*/, ((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.data) || []];
                case 2:
                    error_2 = _b.sent();
                    errorMessage = error_2.response.data.message;
                    throw new Error(errorMessage);
                case 3: return [2 /*return*/];
            }
        });
    });
}
/**
 * @function getFallDetectionListService
 * @description API service to get existing Fall Detected with status pending, assigned
 * @param params api payload
 * @returns Promise
 */
export function getFallDetectionListService(params) {
    return __awaiter(this, void 0, void 0, function () {
        var response, error_3, errorMessage;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, API({
                            url: ALARM_END_POINTS.GET_FALL_DETECTION,
                            method: 'get',
                            params: params,
                        })];
                case 1:
                    response = _b.sent();
                    return [2 /*return*/, ((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.data) || []];
                case 2:
                    error_3 = _b.sent();
                    errorMessage = error_3.response.data.message;
                    throw new Error(errorMessage);
                case 3: return [2 /*return*/];
            }
        });
    });
}
var mapAlarmDataPayload = function (payload) {
    return payload.map(function (data) { return createAlarmData(data); });
};
export var createAlarmData = function (data) {
    var _a, _b, _c, _d;
    return {
        accountId: data.account_id,
        seniorId: data.senior_id,
        status: data.status,
        timestamp: getTimestamp(data.timestamp),
        alarmId: data.alarm_id,
        alarmStatus: data.alarm_status,
        lastAlertTime: getTimestamp((_a = data.last_alert) === null || _a === void 0 ? void 0 : _a.time),
        lastCallTime: (_b = data.last_call) === null || _b === void 0 ? void 0 : _b.time,
        lastLocation: {
            lat: (_c = data.last_location) === null || _c === void 0 ? void 0 : _c.lat,
            lng: (_d = data.last_location) === null || _d === void 0 ? void 0 : _d.lng,
        },
    };
};
