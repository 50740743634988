var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { GET_HOMESAFETY_ASSESSMENT_ADMIN, GET_HOMESAFETY_ASSESSMENT_HISTORY_ADMIN_SUCCESS, TOGGLE_HOMESAFETY_ADMIN_VIEW_STATE, UPDATE_ASSESSMENT_HISTORY_ADMIN_PAGE_NUMBER, } from './HomeSafetyAssessmentAdmin.action';
export var INITIAL_STATE = {
    survey: [],
    versionNumber: 1,
    formId: '',
    formStatus: '',
    history: {
        data: [],
        lastEvaluatedKey: '',
        loading: false,
        totalRows: 0,
        currentPage: 1,
    },
    isHistory: false,
};
export var homeSafetyAssessmentAdminReducer = function (state, action) {
    if (state === void 0) { state = INITIAL_STATE; }
    switch (action.type) {
        case GET_HOMESAFETY_ASSESSMENT_ADMIN:
            if (action.payload.emptyData) {
                // Handle the case of empty data
                console.log('Data is empty');
                return state; // Or return a modified state as needed
            }
            return __assign(__assign({}, state), { survey: __spreadArray([], action.payload.survey, true), versionNumber: action.payload.versionNumber, formId: action.payload.formId, formStatus: action.payload.formStatus, publishDateTime: action.payload.publishDateTime });
        case GET_HOMESAFETY_ASSESSMENT_HISTORY_ADMIN_SUCCESS: {
            return __assign(__assign({}, state), { history: __assign(__assign({}, state.history), { data: __spreadArray([], action.payload.data, true), lastEvaluatedKey: action.payload.lastEvaluatedKey, loading: false }) });
        }
        case UPDATE_ASSESSMENT_HISTORY_ADMIN_PAGE_NUMBER: {
            return __assign(__assign({}, state), { history: __assign(__assign({}, state.history), { currentPage: action.payload }) });
        }
        case TOGGLE_HOMESAFETY_ADMIN_VIEW_STATE: {
            return __assign(__assign({}, state), { isHistory: action.payload.isHistory });
        }
        default:
            return state;
    }
};
