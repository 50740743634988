/**
 * @class ActivityGraphModel
 * @description class to maintain the activity graph data
 */
var ActivityGraphModel = /** @class */ (function () {
    function ActivityGraphModel(overall, blue, green, gold) {
        this.overall = overall;
        this.blue = blue;
        this.green = green;
        this.gold = gold;
    }
    return ActivityGraphModel;
}());
export default ActivityGraphModel;
