var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-len */
import { useAppSelector } from 'hooks/reduxHooks';
import { NavLink, useLocation } from 'react-router-dom';
import { Box, Chip, CircularProgress, Typography } from '@mui/material';
import get from 'lodash.get';
import UserName from 'common/UserName';
import { theme } from 'config/theme.config';
import { FACILITY_TYPES, OnboardingTab } from 'globals/enums';
import SeniorShortDetail from './component/SeniorShortDetail/SeniorShortDetail';
import { seniorDetailsStyle } from './SeniorDetail.style';
import AvatarComponent from 'common/Avatar/Avatar.component';
import ZoneChip from 'common/ZoneChip/ZoneChip';
var SeniorDetail = function (_a) {
    var _b = _a.isResident, isResident = _b === void 0 ? false : _b;
    var _c = useAppSelector(function (state) { return state.common; }), seniorDetail = _c.seniorDetail, profilePic = _c.profilePic;
    var basicInfo = seniorDetail.basicInfo, minimalInfo = seniorDetail.minimalInfo, isLoading = seniorDetail.isLoading;
    var pathname = useLocation().pathname;
    var classes = seniorDetailsStyle().classes;
    if (isLoading) {
        return (_jsx(Box, { className: classes.container, display: 'flex', justifyContent: 'center', alignItems: 'center', "data-testid": 'loader', children: _jsx(CircularProgress, { id: 'SeniorDetail' }) }));
    }
    return (_jsx(Box, { className: classes.container, "data-testid": 'seniorDetailComponent', children: _jsxs(Box, { display: 'flex', flexDirection: 'column', children: [_jsxs(Box, { display: 'flex', mb: 2, children: [_jsx(AvatarComponent, { altText: 'senior-profile-image', imageUrl: "data:image/".concat(get(profilePic, 'format'), ";base64, ").concat(get(profilePic, 'image')), child: seniorDetail.nameInitials, className: classes.seniorImage }), _jsxs(Box, { children: [_jsx(Typography, { variant: 'f24bold', children: _jsx(NavLink, { to: isResident
                                            ? ''
                                            : "".concat(pathname.replace('dashboard', 'onboarding-info'), "?tab=").concat(OnboardingTab.PROFILE_INFO), style: {
                                            color: theme.palette.common.black,
                                            wordBreak: 'break-all',
                                        }, children: _jsx(UserName, { name: {
                                                firstName: minimalInfo.name.first_name,
                                                middleName: minimalInfo.name.middle_name,
                                                lastName: minimalInfo.name.last_name,
                                            } }) }) }), _jsxs(Box, { mt: '9px', children: [_jsx(ZoneChip, { zoneType: minimalInfo.zone }), isResident &&
                                            minimalInfo.facility_type ===
                                                FACILITY_TYPES.INDEPENDENT_LIVING && (_jsx(Chip, { label: 'Independent', variant: 'filled', size: 'small', className: classes.chip }))] })] })] }), _jsx(SeniorShortDetail, { isResident: isResident, data: __assign(__assign({}, basicInfo), minimalInfo) })] }) }));
};
export default SeniorDetail;
