var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { makeStyles } from 'tss-react/mui';
import { TemplateTypography } from 'common/Print/Print.utility';
export var headerDataStyles = makeStyles()(function () { return ({
    headerData: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        textAlign: 'right',
        gap: '36px',
    },
    heading: __assign(__assign({}, TemplateTypography.heading), { color: '#0186a5' }),
    subheading: __assign(__assign({}, TemplateTypography.subHeading), { color: '#000' }),
}); });
