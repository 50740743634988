/**
 * @class LOSGraphModel
 * @description class to maintain the activity graph data
 */
var LOSGraphModel = /** @class */ (function () {
    function LOSGraphModel(overall, blue, green, gold) {
        this.overall = overall;
        this.blue = blue;
        this.green = green;
        this.gold = gold;
    }
    return LOSGraphModel;
}());
export default LOSGraphModel;
