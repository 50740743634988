import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import ReactDom from 'react-dom/client';
import { Provider } from 'react-redux';
import { StyledEngineProvider } from '@mui/material/styles';
import { history, store } from './store';
import App from './App';
import { ApplicationLoader } from './common/ApplicationLoader';
import reportWebVitals from './reportWebVitals';
import { PusherProvider } from './pusher/PusherContext';
import PrintFrame from 'common/Print/Print.component';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import { QueryClient, QueryClientProvider } from 'utilities/react-query';
var root = ReactDom.createRoot(document.getElementById('root'));
var queryClient = new QueryClient();
root.render(_jsx(React.StrictMode, { children: _jsx(Provider, { store: store, children: _jsx(QueryClientProvider, { client: queryClient, children: _jsx(Router, { history: history, children: _jsxs(StyledEngineProvider, { injectFirst: true, children: [_jsx(PrintFrame, {}), _jsx(PusherProvider, { children: _jsxs(_Fragment, { children: [_jsx(App, {}), _jsx(ApplicationLoader, {})] }) })] }) }) }) }) }));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(function () { });
