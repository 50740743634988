import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Box, Grid } from '@mui/material';
import { getHourMin } from 'globals/global.functions';
import { sleepStyle } from '../Sleep.style';
import get from 'lodash.get';
import SleepQualityGoals from './SleepQualityGoal';
var SleepQuality = function (_a) {
    var sleepSummary = _a.sleepSummary, goal = _a.goal, _b = _a.noRecordFound, noRecordFound = _b === void 0 ? false : _b, onGoalGetSuccess = _a.onGoalGetSuccess, goalPercentage = _a.goalPercentage;
    var classes = sleepStyle().classes;
    return (_jsx(Box, { "data-testid": 'sleep-quality-graph', children: _jsxs(Box, { display: 'flex', alignItems: 'center', gap: '20px', children: [_jsx(SleepQualityGoals, { noRecordFound: noRecordFound, sleepGoal: goal, percentage: goalPercentage, value: get(sleepSummary, 'sleepScoreAvg', 0), onGoalGetSuccess: onGoalGetSuccess }), !noRecordFound && (_jsxs(Box, { className: classes.sleepDataContainer, width: '43%', height: 'fit-content', style: { alignSelf: 'end', paddingBottom: '25px' }, children: [_jsxs(Grid, { spacing: 2, className: classes.cycleDataContainer, container: true, children: [_jsx(Grid, { item: true, sm: 6, children: _jsxs(Box, { className: classes.cycleData, children: [_jsx(Box, { className: classes.cycleDataValue, children: getHourMin(sleepSummary.totalSleepTime) }), _jsx(Box, { className: classes.cycleDataLabel, children: "Duration" })] }) }), _jsx(Grid, { item: true, sm: 6, children: _jsxs(Box, { className: classes.cycleData, children: [_jsxs(Box, { className: classes.cycleDataValue, children: [sleepSummary.interruptions, " times"] }), _jsx(Box, { className: classes.cycleDataLabel, children: "Interuptions" })] }) })] }), _jsxs(Grid, { spacing: 2, className: classes.cycleDataContainer, container: true, children: [_jsx(Grid, { item: true, sm: 6, children: _jsxs(Box, { className: classes.cycleData, children: [_jsx(Box, { className: classes.cycleDataValue, children: getHourMin(sleepSummary.sleepLatency) }), _jsx(Box, { className: classes.cycleDataLabel, children: "Time to Sleep" })] }) }), _jsx(Grid, { item: true, sm: 6, children: _jsxs(Box, { className: classes.cycleData, children: [_jsx(Box, { className: classes.cycleDataValue, children: getHourMin(sleepSummary.timeToGetUp) }), _jsx(Box, { className: classes.cycleDataLabel, children: "Time to Get Up" })] }) })] })] }))] }) }));
};
export { SleepQuality };
