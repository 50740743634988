var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import clsx from 'clsx';
import { Typography, MenuItem, Box } from '@mui/material';
import { InputSelect } from './Input.component';
import { fieldsStyle } from '../InputFields.style';
var InputSelectWrapper = function (item) {
    var _a, _b, _c, _d;
    var _e;
    var classes = fieldsStyle().classes;
    var inputProps = item.inputProps, eventProps = item.eventProps, _f = item.hideSelect, hideSelect = _f === void 0 ? false : _f, props = __rest(item, ["inputProps", "eventProps", "hideSelect"]);
    return (_jsxs(_Fragment, { children: [_jsxs(InputSelect, __assign({ className: clsx((_a = {}, _a[classes.inputSelectError] = props.isError, _a), (_b = {}, _b[classes.whiteBackground] = props.white, _b), classes.menu), variant: 'outlined', displayEmpty: true, inputProps: __assign(__assign({}, inputProps), { 'data-testid': inputProps === null || inputProps === void 0 ? void 0 : inputProps.dataTestid, classes: {
                        icon: classes.icon,
                    } }), "data-error": props.isError ? 'error' : '' }, eventProps, { children: [!hideSelect && (_jsx(MenuItem, { value: '', disabled: (_e = props.isSelectValueDisable) !== null && _e !== void 0 ? _e : true, classes: { root: classes.selectedMenuColor }, children: _jsx(Box, { style: {
                                color: '#a7a7a7',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                            }, children: item.initialLabel || 'Select' }) })), props.menuItems.map(function (data) { return (_jsx(MenuItem, { value: data.value, children: data.label }, data.value)); })] })), props.errorText && (_jsx(Typography, { variant: 'subtitle1', className: clsx((_c = {}, _c[classes.errorText] = true, _c), (_d = {}, _d[classes.errorCustomStyle] = props.isErrorCustomeStyle, _d)), children: props.errorText }))] }));
};
export default InputSelectWrapper;
