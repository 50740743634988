import { makeStyles } from 'tss-react/mui';
export var seniorDetailsStyle = makeStyles()(function (theme) {
    var _a, _b, _c, _d;
    return ({
        container: {
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            borderRadius: 10,
            padding: 15,
            boxShadow: '0 8px 24px 0 rgba(0, 0, 0, 0.16)',
            border: 'solid 1px #d3d3d3',
        },
        seniorImage: {
            height: 84,
            width: 84,
            marginRight: 14,
        },
        seniorDetail: {
            display: 'flex',
            lineHeight: 1.7,
            padding: '3px',
        },
        seniorDetailHeading: {
            width: '40%',
        },
        seniorDetailValue: {
            width: '60%',
        },
        height: {
            borderLeft: '1px solid',
            borderRight: '1px solid',
        },
        chip: {
            border: "1px solid ".concat((_b = (_a = theme === null || theme === void 0 ? void 0 : theme.palette) === null || _a === void 0 ? void 0 : _a.customColor) === null || _b === void 0 ? void 0 : _b.borderGrey),
            backgroundColor: (_d = (_c = theme === null || theme === void 0 ? void 0 : theme.palette) === null || _c === void 0 ? void 0 : _c.customColor) === null || _d === void 0 ? void 0 : _d.chartGrey,
            fontSize: theme.typography.body1.fontSize,
            marginLeft: '4px',
            padding: '12.5px 2px',
        },
    });
});
