export var SET_PRINT_DATA = 'SET_PRINT_DATA';
export var RESET_PRINT_DATA = 'RESET_PRINT_DATA';
export var setPrintData = function (payload) {
    return {
        type: SET_PRINT_DATA,
        payload: payload
    };
};
export var resetPrintData = function () {
    return {
        type: RESET_PRINT_DATA
    };
};
