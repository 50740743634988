import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Box } from '@mui/material';
import { headerStyles } from './Header.style';
import VimientLogo from 'assets/ClearWellness_Green.png';
var Header = function (_a) {
    var children = _a.children;
    var classes = headerStyles().classes;
    return (_jsxs(Box, { className: classes.container, "data-testid": 'print-header', children: [_jsx(Box, { className: classes.logo, children: _jsx("img", { src: VimientLogo, alt: 'ClearWellness Logo' }) }), _jsx(Box, { children: children })] }));
};
export default Header;
