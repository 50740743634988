var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { GoogleMapAddressComponent } from 'globals/enums';
/**
 * @class AutoCompleteAPI
 * @description class to handle address methods, google map methods and key.
 */
var GoogleMapAddressAPI = /** @class */ (function () {
    function GoogleMapAddressAPI() {
        var _this = this;
        /**
         * @description method to fetch the place from selected address.
         * @param {google.maps.places.Autocomplete} selectedAddress
         * @returns {IInitialStateSelectedAddress} return formated address
         */
        this.getAddress = function (selectedAddress) {
            var place = selectedAddress.getPlace();
            var fullAddress = place.formatted_address || '';
            var addressFields = _this.formatAddress(place);
            return __assign(__assign({}, addressFields), { fullAddress: fullAddress });
        };
        /**
         * @function formatAddress
         * @description method to format the address from address_components
         * @param {google.maps.places.PlaceResult} place google map getPlace() result
         * @returns {IInitialStateSelectedAddress}
         */
        this.formatAddress = function (place) {
            var _a, _b, _c, _d;
            var streetNumber = '';
            var route = '';
            var state = '';
            var city = '';
            var zipCode = '';
            var fullState = '';
            // place.address_components are google.maps.GeocoderAddressComponent objects
            for (var _i = 0, _e = place.address_components; _i < _e.length; _i++) {
                var component = _e[_i];
                // @ts-ignore remove once typings fixed
                var componentType = component.types[0];
                switch (componentType) {
                    case GoogleMapAddressComponent.streetNumber: {
                        streetNumber = component.long_name;
                        break;
                    }
                    case GoogleMapAddressComponent.route: {
                        route = component.long_name;
                        break;
                    }
                    case GoogleMapAddressComponent.locality: {
                        city = component.long_name;
                        break;
                    }
                    case GoogleMapAddressComponent.postalCode: {
                        zipCode = component.long_name;
                        break;
                    }
                    case GoogleMapAddressComponent.state: {
                        state = component.short_name;
                        fullState = component.long_name;
                        break;
                    }
                }
            }
            return {
                streetNumber: streetNumber,
                route: route,
                city: city,
                state: state,
                zipCode: zipCode,
                fullState: fullState,
                fullAddress: '',
                streetAddress: _this.getStreetAddress(streetNumber, route),
                coordinates: {
                    latitude: ((_b = (_a = place.geometry) === null || _a === void 0 ? void 0 : _a.location) === null || _b === void 0 ? void 0 : _b.lat()) || 0,
                    longitude: ((_d = (_c = place.geometry) === null || _c === void 0 ? void 0 : _c.location) === null || _d === void 0 ? void 0 : _d.lng()) || 0,
                },
            };
        };
        /**
         * @description method to concatinate street numbe and route.
         * @param {string} streetNumber
         * @param {string} route
         * @returns {string} address
         */
        this.getStreetAddress = function (streetNumber, route) {
            var address = '';
            address = "".concat(streetNumber, " ").concat(address);
            if (route) {
                address += route;
            }
            return address;
        };
    }
    return GoogleMapAddressAPI;
}());
export default GoogleMapAddressAPI;
