var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { makeStyles } from 'tss-react/mui';
import { TemplateTypography } from 'common/Print/Print.utility';
export var metaBoxStyles = makeStyles()(function () { return ({
    container: {
        padding: '30px',
        borderRadius: '10px',
        boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
        border: 'solid 1px rgba(0, 0, 0, 0.25)',
        backgroundColor: '#fff',
        margin: '0 144px',
        gap: '36px',
    },
}); });
export var detailStyles = makeStyles()(function () { return ({
    firstLabel: __assign(__assign({}, TemplateTypography.regular), { textAlign: 'left', fontWeight: 'bold', color: '#000' }),
    secondLabel: __assign(__assign({}, TemplateTypography.regular), { textAlign: 'left', fontWeight: 'bold', color: '#000' }),
    detailText: __assign(__assign({}, TemplateTypography.regular), { textAlign: 'left', color: '#000', wordBreak: 'break-word' }),
}); });
