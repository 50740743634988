var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Box, TextField, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import moment from 'moment-timezone';
import { OpenPickerIcon } from 'common/InputFields/component/Input.component';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { setSelectedDate } from '../WellnessDashboard.actions';
import { GraphView } from 'globals/enums';
export default function WeekPicker(_a) {
    var setCurrentWeek = _a.setCurrentWeek;
    var theme = useTheme();
    var weekDate = useAppSelector(function (state) { return state.wellnessDashboard.selectedDate.week; });
    var dispatch = useAppDispatch();
    var handleWeekChange = function (val) {
        var date = moment(val);
        var dt = date.startOf(GraphView.WEEK);
        dispatch(setSelectedDate(val, GraphView.WEEK));
        setCurrentWeek(dt);
    };
    var CustomPickersDay = styled(PickersDay, {
        shouldForwardProp: function (prop) {
            return prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay';
        },
    })(function (_a) {
        var theme = _a.theme, dayIsBetween = _a.dayIsBetween, isFirstDay = _a.isFirstDay, isLastDay = _a.isLastDay;
        return (__assign(__assign(__assign({}, (dayIsBetween && {
            borderRadius: 0,
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            '&:hover, &:focus': {
                backgroundColor: theme.palette.primary.dark,
            },
        })), (isFirstDay && {
            borderTopLeftRadius: '50%',
            borderBottomLeftRadius: '50%',
        })), (isLastDay && {
            borderTopRightRadius: '50%',
            borderBottomRightRadius: '50%',
        })));
    });
    var renderWeekPickerDay = function (dt, selectedDates, pickersDayProps) {
        var date = moment(dt).clone();
        if (!weekDate) {
            return _jsx(PickersDay, __assign({}, pickersDayProps));
        }
        var start = moment(weekDate).clone().startOf(GraphView.WEEK);
        var end = moment(weekDate).clone().endOf(GraphView.WEEK);
        var dayIsBetween = date.isBetween(start, end, null, '[]');
        var isFirstDay = date.isSame(start, GraphView.DAY);
        var isLastDay = date.isSame(end, GraphView.DAY);
        return (_jsx(CustomPickersDay, __assign({}, pickersDayProps, { disableMargin: true, dayIsBetween: dayIsBetween, isFirstDay: isFirstDay, isLastDay: isLastDay })));
    };
    var navigatePrevWeek = function () {
        var prevWeek = moment(weekDate).subtract(1, GraphView.WEEK);
        handleWeekChange(prevWeek);
    };
    var navigateNextWeek = function () {
        var nextWeek = moment(weekDate).add(1, GraphView.WEEK);
        var selectedWeek = moment(weekDate).clone().startOf(GraphView.WEEK).format('L');
        var currentWeek = moment().clone().startOf(GraphView.WEEK).format('L');
        if (selectedWeek == currentWeek) {
            return false;
        }
        handleWeekChange(nextWeek);
    };
    return (_jsxs(Box, { display: 'flex', alignItems: 'center', "data-testid": 'week-picker', children: [_jsx(NavigateBeforeIcon, { style: {
                    cursor: 'pointer',
                    color: theme.palette.customColor.primaryGreen,
                }, onClick: navigatePrevWeek }), _jsx(LocalizationProvider, { dateAdapter: AdapterDateFns, children: _jsx(DatePicker, { renderInput: function (params) { return (_jsx(TextField, __assign({}, params, { inputProps: __assign(__assign({}, params.inputProps), { readOnly: true }), variant: 'standard', sx: {
                            svg: {
                                '& .cls-1': {
                                    fill: theme.palette.customColor.primaryGreen,
                                },
                            },
                        } }))); }, components: {
                        OpenPickerIcon: OpenPickerIcon,
                    }, value: weekDate, disableFuture: true, 
                    // autoOk
                    // disableToolbar
                    InputProps: {
                        disableUnderline: true,
                        style: {
                            borderRadius: 5,
                            backgroundColor: 'white',
                            width: 160,
                            paddingLeft: 8,
                            paddingRight: 8,
                            border: "1px solid ".concat(theme.palette.customColor.lighterBlack),
                        },
                    }, onChange: handleWeekChange, inputFormat: "'Week of' MM/dd", renderDay: renderWeekPickerDay }) }), _jsx(NavigateNextIcon, { style: {
                    cursor: 'pointer',
                    color: theme.palette.customColor.primaryGreen,
                }, onClick: navigateNextWeek })] }));
}
