import { applicationLoaderActionTypes } from './ApplicationLoader.types';
export var showApplicationLoader = function (text, loadingApp, isAutoSave) {
    if (text === void 0) { text = ''; }
    if (loadingApp === void 0) { loadingApp = false; }
    if (isAutoSave === void 0) { isAutoSave = false; }
    return function (dispatch) {
        if (!isAutoSave) {
            dispatch({
                type: applicationLoaderActionTypes.SHOW_APPLICATION_LOADER,
                text: text,
                loadingApp: loadingApp,
            });
        }
    };
};
export var hideApplicationLoader = function (isAutoSave) {
    if (isAutoSave === void 0) { isAutoSave = false; }
    return function (dispatch) {
        if (!isAutoSave) {
            dispatch({ type: applicationLoaderActionTypes.HIDE_APPLICATION_LOADER });
        }
    };
};
