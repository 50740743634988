/**
 * @class CorporateManagementModel
 * @description class to maintain the corporateManagementModel model
 */
var CorporateListModel = /** @class */ (function () {
    function CorporateListModel(id, name, phone, totalFacilities, totalResidents) {
        this.id = id;
        this.name = name;
        this.phone = phone;
        this.totalFacilities = totalFacilities;
        this.totalResidents = totalResidents;
    }
    return CorporateListModel;
}());
export default CorporateListModel;
