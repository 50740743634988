var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect, useMemo } from 'react';
import { useAppSelector } from 'hooks/reduxHooks';
import { Box, Grid, CircularProgress } from '@mui/material';
import moment from 'moment-timezone';
import { isEmpty } from 'lodash';
import GraphAPI from 'utilities';
import { getTimestamp } from 'globals/global.functions';
import { DATE_FORMAT_SHORT_YEAR, DATE_FORMAT_SHORT, } from 'globals/global.constants';
import { getFormattedDate } from 'globals/date.functions';
import { GraphView } from 'globals/enums';
import { BodyWeight } from './components/BodyWeight.component';
import { BodyComposition } from './components/BodyComposition.component';
import { BodyHydration } from './components/BodyHydration.component';
import { wellnessDashboardStyle } from '../../WellnessDashboard.style';
var BodyHealthComponent = function () {
    var _a = useAppSelector(function (state) { return state.wellnessDashboard; }), currentState = _a.currentState, startTime = _a.startTime, endTime = _a.endTime, reRender = _a.reRender;
    var classes = wellnessDashboardStyle().classes;
    var _b = useState([]), summary = _b[0], setSummary = _b[1];
    var _c = useState(false), triggerRender = _c[0], setTriggerRender = _c[1];
    var _d = useState(null), lastRecordedDate = _d[0], setLastRecordedDate = _d[1];
    var _e = useState([]), difference = _e[0], setDifference = _e[1];
    var _f = useState([]), graph = _f[0], setGraph = _f[1];
    var _g = useState(''), weightDayStartTime = _g[0], setWeightDayStartTime = _g[1];
    var _h = useState(''), weightDayEndTime = _h[0], setWeightDayEndTime = _h[1];
    var _j = useState({
        loading: true,
        subTitle: '',
        noRecordFound: false,
    }), config = _j[0], setConfig = _j[1];
    var lastAvailableKey = useMemo(function () {
        if (!isEmpty(summary.all_data)) {
            return Object.keys(summary.all_data)[Object.keys(summary.all_data).length - 1];
        }
        return null;
    }, [summary]);
    var graphTickValues = useMemo(function () {
        var tempArray = [];
        var startDate = moment(getTimestamp(currentState === GraphView.DAY ? weightDayStartTime : startTime));
        var endDate = moment(getTimestamp(currentState === GraphView.DAY ? weightDayEndTime : endTime)).add(1, GraphView.DAY);
        while (endDate.diff(startDate, 'days') > 0) {
            tempArray.push(startDate.format(DATE_FORMAT_SHORT));
            startDate.add(1, 'days');
        }
        return tempArray;
    }, [startTime, endTime, weightDayStartTime, weightDayEndTime]);
    var isGraphEmpty = useMemo(function () {
        var checkEmptyGraph = isEmpty(Object.keys(summary.all_data || {}).filter(function (data) {
            return graphTickValues.includes(moment(getTimestamp(data)).format(DATE_FORMAT_SHORT));
        }));
        return checkEmptyGraph;
    }, [summary, graphTickValues]);
    useEffect(function () {
        if (startTime && endTime && (reRender || triggerRender)) {
            setTriggerRender(false);
            var weightGraphStartTime = moment(getTimestamp(startTime))
                .clone()
                .startOf(GraphView.WEEK)
                .format('x');
            var weightGraphEndTime = moment(getTimestamp(startTime))
                .clone()
                .endOf(GraphView.WEEK)
                .format('x');
            setWeightDayStartTime(weightGraphStartTime * 1000000);
            setWeightDayEndTime(weightGraphEndTime * 1000000);
            var getBodyHealthMeasurement = GraphAPI.fetchBodyHealthMeasurement(startTime, endTime);
            var getBodyHealthDifference = GraphAPI.fetchBodyHealthDifference(startTime, endTime);
            var getWeightGraph = GraphAPI.fetchBodyHealthGraph('weight', currentState === GraphView.DAY
                ? weightGraphStartTime * 1000000
                : startTime, currentState === GraphView.DAY ? weightGraphEndTime * 1000000 : endTime);
            var getHydrationGraph = GraphAPI.fetchBodyHealthGraph('hydration', currentState === GraphView.DAY
                ? weightGraphStartTime * 1000000
                : startTime, currentState === GraphView.DAY ? weightGraphEndTime * 1000000 : endTime);
            setConfig(__assign(__assign({}, config), { loading: true }));
            Promise.all([
                getBodyHealthMeasurement,
                getBodyHealthDifference,
                getWeightGraph,
                getHydrationGraph,
            ]).then(function (res) {
                var measurement = res[0], bodyHealthDifference = res[1], weight = res[2], hydration = res[3];
                setConfig(__assign(__assign({}, config), { loading: false }));
                setSummary(__assign({}, measurement));
                setDifference(__assign({}, bodyHealthDifference));
                setGraph({
                    weight: weight,
                    hydration: hydration,
                });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startTime, endTime, reRender, triggerRender]);
    useEffect(function () {
        var lastRecDate = getFormattedDate(lastAvailableKey, DATE_FORMAT_SHORT_YEAR);
        if (getFormattedDate(startTime) !== getFormattedDate(lastAvailableKey) &&
            currentState === GraphView.DAY &&
            !isEmpty(summary)) {
            setLastRecordedDate(lastRecDate);
        }
        else if (isGraphEmpty && !isEmpty(summary)) {
            setLastRecordedDate(lastRecDate);
        }
        else {
            setLastRecordedDate(null);
        }
    }, [summary, lastAvailableKey, isGraphEmpty]);
    return (_jsxs(Grid, { container: true, spacing: 2, "data-testid": 'body-health-container', children: [_jsx(Grid, { item: true, sm: 12, children: _jsx(Box, { className: classes.cardContainer, p: 2, children: config.loading ? (_jsx(Box, { display: 'flex', justifyContent: 'center', alignItems: 'center', children: _jsx(CircularProgress, {}) })) : (_jsx(BodyWeight, { graph: graph, difference: difference, summary: summary, type: currentState, lastRecordedDate: lastRecordedDate, graphTickValues: graphTickValues, reRender: function () { return setTriggerRender(true); } })) }) }), currentState !== GraphView.DAY && (_jsx(Grid, { item: true, sm: 12, children: _jsx(Box, { className: classes.cardContainer, p: 2, children: config.loading ? (_jsx(Box, { display: 'flex', justifyContent: 'center', alignItems: 'center', children: _jsx(CircularProgress, {}) })) : (_jsx(BodyComposition, { difference: difference, summary: summary, type: currentState, lastRecordedDate: lastRecordedDate, lastAvailableKey: lastAvailableKey, graphTickValues: graphTickValues })) }) })), _jsx(Grid, { item: true, sm: 12, children: _jsx(Box, { className: classes.cardContainer, p: 2, children: config.loading ? (_jsx(Box, { display: 'flex', justifyContent: 'center', alignItems: 'center', children: _jsx(CircularProgress, {}) })) : (_jsx(BodyHydration, { difference: difference, summary: summary, type: currentState, lastRecordedDate: lastRecordedDate, graphTickValues: graphTickValues, reRender: function () { return setTriggerRender(true); }, graph: graph })) }) })] }));
};
export { BodyHealthComponent };
