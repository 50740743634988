import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CssBaseline } from '@mui/material';
import { ThemeProvider, responsiveFontSizes } from '@mui/material/styles';
import facilityTheme from './facilityTheme';
var InitTheme = responsiveFontSizes(facilityTheme);
var FacilityThemeProvider = function (_a) {
    var children = _a.children;
    return (_jsxs(ThemeProvider, { theme: InitTheme, children: [_jsx(CssBaseline, {}), children] }));
};
export default FacilityThemeProvider;
