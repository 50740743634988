var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
// eslint-disable-next-line max-len
import { GET_CARE_INSIGHT_REVIEW, RESET_CARE_INSIGHT_REVIEWS, CARE_INSIGHT_REVIEWS_RESET_PAGINATION, CARE_INSIGHT_REVIEWS_END_PAGINATION, } from './CareInsightReview.action';
var careInsightReviewInitialStates = {
    allCareInsightReviews: [],
    isPaginate: true,
};
/**
 * @description admin careInsightReview reducer
 * @returns care insight states
 */
export var careInsightReviewReducer = function (state, action) {
    if (state === void 0) { state = careInsightReviewInitialStates; }
    switch (action.type) {
        case GET_CARE_INSIGHT_REVIEW: {
            return __assign(__assign({}, state), { allCareInsightReviews: __spreadArray(__spreadArray([], state.allCareInsightReviews, true), action.payload.data, true) });
        }
        case RESET_CARE_INSIGHT_REVIEWS: {
            return __assign(__assign({}, state), { allCareInsightReviews: [] });
        }
        case CARE_INSIGHT_REVIEWS_RESET_PAGINATION: {
            return __assign(__assign({}, state), { isPaginate: true });
        }
        case CARE_INSIGHT_REVIEWS_END_PAGINATION: {
            return __assign(__assign({}, state), { isPaginate: false });
        }
        default: {
            return state;
        }
    }
};
