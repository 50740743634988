var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
export var UPDATE_FILTER_DATA = 'UPDATE_FILTER_DATA';
export var UPDATE_FILTER_LOADER = 'UPDATE_FILTER_LOADER';
export var UPDATE_SEARCH_QUERY = 'UPDATE_SEARCH_QUERY';
export var UPDATE_IS_API_SEARCH = 'UPDATE_IS_API_SEARCH';
var initialState = {
    filteredData: null,
    isFilterLoading: false,
    searchQuery: '',
};
var PaginationSearchContext = React.createContext(initialState);
var PaginationSearchDispatchContext = React.createContext(function () { return null; });
function reducer(state, action) {
    switch (action.type) {
        case UPDATE_FILTER_DATA: {
            return __assign(__assign({}, state), { filteredData: action.filteredData
                    ? __spreadArray([], action.filteredData, true) : action.filteredData });
        }
        case UPDATE_FILTER_LOADER: {
            return __assign(__assign({}, state), { isFilterLoading: action.isFilterLoading });
        }
        case UPDATE_SEARCH_QUERY: {
            return __assign(__assign({}, state), { searchQuery: action.searchQuery });
        }
        default:
            throw new Error();
    }
}
var PaginationSearchProvider = function (_a) {
    var children = _a.children;
    var _b = React.useReducer(reducer, initialState), state = _b[0], dispatch = _b[1];
    return (_jsx(PaginationSearchContext.Provider, { value: state, children: _jsx(PaginationSearchDispatchContext.Provider, { value: dispatch, children: children }) }));
};
export { PaginationSearchContext, PaginationSearchDispatchContext, PaginationSearchProvider, };
