/**
 * @class FallGraphModel
 * @description class to maintain the fall graph data
 */
var FallGraphModel = /** @class */ (function () {
    function FallGraphModel(overall, blue, green, gold) {
        this.overall = overall;
        this.blue = blue;
        this.green = green;
        this.gold = gold;
    }
    return FallGraphModel;
}());
export default FallGraphModel;
