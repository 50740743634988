import { makeStyles } from 'tss-react/mui';
var facilityDetailHeader = makeStyles()(function (theme) { return ({
    container: {
        backgroundColor: theme.palette.common.white,
        height: '106px',
        borderRadius: '16px',
        boxShadow: "0 2px 8px 0 ".concat(theme.palette.customColor.boxShadow),
        alignItems: 'center',
        padding: '0 30px',
        margin: '30px 0',
    },
    InnerContainer: {
        height: 'inherit',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-around',
    },
    button: {
        color: theme.palette.common.white,
        padding: '8px 22px',
        fontWeight: '600',
        fontSize: '16px',
        borderRadius: 0,
    },
}); });
export default facilityDetailHeader;
