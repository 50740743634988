var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import moment from 'moment-timezone';
import { isEmpty } from 'lodash';
import { getTimestamp } from 'globals/global.functions';
import { API } from 'globals/api';
import { SLEEP_END_POINTS } from 'globals/apiEndPoints';
export function getSleepDailyService(param) {
    return __awaiter(this, void 0, Promise, function () {
        var response, sleepData, error_1, errorMessage;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, API({
                            url: SLEEP_END_POINTS.GET_SLEEP_DAILY,
                            method: 'get',
                            params: param,
                        })];
                case 1:
                    response = _a.sent();
                    sleepData = __assign(__assign({}, (response.data.data || {})), { target_score: response.data.target_score, goal_percentage: response.data.goal_percentage });
                    return [2 /*return*/, createSleepSummary(sleepData)];
                case 2:
                    error_1 = _a.sent();
                    errorMessage = error_1.response.data.message;
                    throw new Error(errorMessage);
                case 3: return [2 /*return*/];
            }
        });
    });
}
export function getSleepHRService(param) {
    return __awaiter(this, void 0, Promise, function () {
        var response, error_2, errorMessage;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, API({
                            url: SLEEP_END_POINTS.GET_SLEEP_HEART_RATE,
                            method: 'get',
                            params: param,
                        })];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data.data];
                case 2:
                    error_2 = _a.sent();
                    errorMessage = error_2.response.data.message;
                    throw new Error(errorMessage);
                case 3: return [2 /*return*/];
            }
        });
    });
}
export function getSleepPhaseService(param) {
    return __awaiter(this, void 0, Promise, function () {
        var response, error_3, errorMessage;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, API({
                            url: SLEEP_END_POINTS.GET_SLEEP_PHASE,
                            method: 'get',
                            params: param,
                        })];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data.data];
                case 2:
                    error_3 = _a.sent();
                    errorMessage = error_3.response.data.message;
                    throw new Error(errorMessage);
                case 3: return [2 /*return*/];
            }
        });
    });
}
export function getSleepDepthService(param) {
    return __awaiter(this, void 0, Promise, function () {
        var response, error_4, errorMessage;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, API({
                            url: SLEEP_END_POINTS.GET_SLEEP_DEPTH,
                            method: 'get',
                            params: param,
                        })];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data.data[0]];
                case 2:
                    error_4 = _a.sent();
                    errorMessage = error_4.response.data.message;
                    throw new Error(errorMessage);
                case 3: return [2 /*return*/];
            }
        });
    });
}
/**
 * @function createSleepSummary
 * @description method to create the summary data from sleep-daily api response
 * @param {*} data
 * @param {number} selectedDate
 * @returns summary data
 */
var createSleepSummary = function (data) {
    var detailList = [];
    var allSummaryList = [];
    Object.entries(data)
        .filter(function (_a) {
        var key = _a[0];
        return moment(getTimestamp(key)).isValid();
    })
        .forEach(function (_a) {
        var key = _a[0], value = _a[1];
        value.detail.time = parseInt(key);
        detailList.push(value.detail);
        allSummaryList.push(value.summary);
    });
    if (isEmpty(detailList)) {
        return [];
    }
    var dayDetail = detailList[0];
    var sleepSummary = {
        daySummary: {
            sleepScoreAvg: dayDetail.sleep_score,
            totalSleepTime: dayDetail.total_sleep_time,
            interruptions: dayDetail.interruptions,
            sleepLatency: dayDetail.sleep_latency,
            timeToGetUp: dayDetail.time_to_get_up,
            heartRateAvg: data.average_hr,
            summaryCycle: dayDetail.summary_cycle,
        },
        averageSleepScore: data.average_sleep_score,
        averageSleepTime: data.average_total_sleep_time,
        averageSleepTimeInBed: data.average_total_timeinbed,
        summary: detailList,
        summaryList: allSummaryList,
        targetScore: data.target_score,
        goalPercentage: data.goal_percentage,
    };
    return sleepSummary;
};
