/** @format */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { SHOW_TOAST, HIDE_TOAST } from './Toast.types';
var INITIAL_STATE = {
    type: 'success',
    message: '',
    duration: 4000,
    open: false,
};
export var toastReducer = function (state, action) {
    if (state === void 0) { state = INITIAL_STATE; }
    switch (action.type) {
        case SHOW_TOAST:
            return __assign(__assign(__assign({}, state), { open: true }), action.payload);
        case HIDE_TOAST:
            return __assign(__assign(__assign({}, state), { open: false }), action.payload);
        default:
            return state;
    }
};
