import { makeStyles } from 'tss-react/mui';
export var changePasswordStyle = makeStyles()(function (theme) {
    var _a, _b;
    return ({
        mainContainer: {
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
        },
        choosePassword: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            minHeight: 395,
            borderRadius: 16,
            backgroundColor: (_b = (_a = theme === null || theme === void 0 ? void 0 : theme.palette) === null || _a === void 0 ? void 0 : _a.customColor) === null || _b === void 0 ? void 0 : _b.white,
            width: '928px',
        },
    });
});
