var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { CREATE_SOS, CREATE_FALL_DETECTION, CLOSE_ALARM } from './Alarm.action';
var intialState = {
    sos: {},
    fall: {},
};
var alarmReducer = function (state, action) {
    var _a;
    if (state === void 0) { state = intialState; }
    switch (action.type) {
        case CREATE_SOS:
            return __assign(__assign({}, state), { sos: __assign(__assign({}, state.sos), action.payload.alarmData) });
        case CREATE_FALL_DETECTION:
            return __assign(__assign({}, state), { fall: __assign(__assign({}, state.fall), action.payload.alarmData) });
        case CLOSE_ALARM:
            return __assign(__assign({}, state), (_a = {}, _a[action.payload.eventType] = action.payload.updatedAlarm, _a));
        default:
            return state;
    }
};
export default alarmReducer;
