import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, TableContainer, Table, TableRow, TableCell, TableBody, } from '@mui/material';
import { InputFields } from '../InputFields';
import { adlSectionStyles } from './ADLSection.style';
var ADLSection = function (props) {
    var _a = props.title, title = _a === void 0 ? '' : _a, _b = props.options, options = _b === void 0 ? [] : _b;
    var classes = adlSectionStyles().classes;
    var getValue = function (radioData) {
        var selectedOption = radioData === null || radioData === void 0 ? void 0 : radioData.find(function (data) { return data.score === 1; });
        return (selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.label) || '';
    };
    return (_jsxs(Box, { component: 'section', className: classes.section, "data-testid": 'adl-section', children: [_jsx(Box, { className: classes.header, children: _jsx("div", { className: classes.heading, children: title }) }), _jsx(TableContainer, { style: { padding: '10px' }, children: _jsx(Table, { children: _jsx(TableBody, { children: _jsx(TableRow, { children: _jsx(TableCell, { align: 'left', style: { borderBottom: 'none' }, children: _jsx(Box, { children: _jsx(InputFields, { showRadioLabel: true, radio: true, inputProps: {
                                            name: "radio",
                                            style: {
                                                padding: '2px 10px 2px 2px',
                                            },
                                        }, eventProps: {
                                            value: getValue(options),
                                        }, radioItems: options.map(function (option) {
                                            return {
                                                value: option.label,
                                                label: "".concat(option.value, "-").concat(option.label),
                                            };
                                        }), className: classes.radioGroup }) }) }) }) }) }) })] }));
};
export default ADLSection;
