var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { API } from 'globals/api';
import { SENIOR_SERVICE_END_POINTS } from 'globals/apiEndPoints';
/**
 * @function getCareGiverService
 * @description method to get care giver data via api response.
 * @param {*} params
 * @returns caregiver accounts.
 */
export function getCareGiverService(params) {
    return __awaiter(this, void 0, Promise, function () {
        var response, results, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, API({
                            url: SENIOR_SERVICE_END_POINTS.GET_CARE_GIVER,
                            method: 'get',
                            params: params,
                        })];
                case 1:
                    response = _a.sent();
                    results = response.data.caregivers;
                    return [2 /*return*/, mapCareGiverPayload(results)];
                case 2:
                    error_1 = _a.sent();
                    throw new Error(error_1);
                case 3: return [2 /*return*/];
            }
        });
    });
}
/**
 * @function getMinimalInfoService
 * @description method to get minimal info via api response.
 * @param {*} params
 * @returns minimal info.
 */
export function getMinimalInfoService(params) {
    return __awaiter(this, void 0, Promise, function () {
        var response, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, API({
                            url: SENIOR_SERVICE_END_POINTS.GET_MINIMAL_INFO,
                            method: 'get',
                            params: params,
                        })];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
                case 2:
                    error_2 = _a.sent();
                    return [2 /*return*/, error_2];
                case 3: return [2 /*return*/];
            }
        });
    });
}
/**
 * @function getBasicInfoService
 * @description method to get basic info via api response.
 * @param {*} params
 * @returns basic info.
 */
export function getBasicInfoService(params) {
    return __awaiter(this, void 0, Promise, function () {
        var response, error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, API({
                            url: SENIOR_SERVICE_END_POINTS.GET_BASIC_INFO,
                            method: 'get',
                            params: params,
                        })];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
                case 2:
                    error_3 = _a.sent();
                    return [2 /*return*/, error_3];
                case 3: return [2 /*return*/];
            }
        });
    });
}
/**
 * @function getDoctorInfoService
 * @description method to get doctor info via api response.
 * @param {*} params
 * @returns doctor info.
 */
export function getDoctorInfoService(params) {
    return __awaiter(this, void 0, Promise, function () {
        var response, error_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, API({
                            url: SENIOR_SERVICE_END_POINTS.GET_DOCTOR_INFO,
                            method: 'get',
                            params: params,
                        })];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, mapProviderPayload(response.data)];
                case 2:
                    error_4 = _a.sent();
                    return [2 /*return*/, error_4];
                case 3: return [2 /*return*/];
            }
        });
    });
}
/**
 * @function getPharmacyInfoService
 * @description method to get pharmacy info via api response.
 * @param {*} params
 * @returns pharmacy info.
 */
export function getPharmacyInfoService(params) {
    return __awaiter(this, void 0, Promise, function () {
        var response, error_5;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, API({
                            url: SENIOR_SERVICE_END_POINTS.GET_PHARMACY_INFO,
                            method: 'get',
                            params: params,
                        })];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
                case 2:
                    error_5 = _a.sent();
                    return [2 /*return*/, error_5];
                case 3: return [2 /*return*/];
            }
        });
    });
}
/**
 * @function getDentistInfoService
 * @description method to get dentist info via api response.
 * @param {*} params
 * @returns dentist info.
 */
export function getDentistInfoService(params) {
    return __awaiter(this, void 0, Promise, function () {
        var response, error_6;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, API({
                            url: SENIOR_SERVICE_END_POINTS.GET_DENTIST_INFO,
                            method: 'get',
                            params: params,
                        })];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, mapProviderPayload(response.data)];
                case 2:
                    error_6 = _a.sent();
                    return [2 /*return*/, error_6];
                case 3: return [2 /*return*/];
            }
        });
    });
}
var mapCareGiverPayload = function (payload) {
    return payload === null || payload === void 0 ? void 0 : payload.map(function (data) {
        return {
            id: data.caregiver_id,
            name: {
                firstName: data.basic_info.name.first_name,
                middleName: data.basic_info.name.middle_name,
                lastName: data.basic_info.name.last_name,
            },
            caregiverType: data.senior_info.caregiver_type,
            emergencyContact: data.senior_info.emergency_contact,
            mobileNumber: data.basic_info.mobile_number,
            alternateNumber: data.basic_info.alternate_number,
            relationship: data.senior_info.relationship_with_senior,
        };
    });
};
var mapProviderPayload = function (payload) {
    return payload === null || payload === void 0 ? void 0 : payload.map(function (data) {
        return {
            id: data.provider_id,
            name: {
                firstName: data.name.first_name,
                middleName: data.name.middle_name,
                lastName: data.name.last_name,
            },
            contactPhone: data.contact_phone,
            providerName: data.provider_name,
            speciality: data.speciality,
            isPrimary: data.is_primary,
        };
    });
};
