var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { SleepComponent } from './Sleep.component';
import { SleepWeek } from './SleepWeek.component';
import { SleepMonth } from './SleepMonth.component';
import { WellnessWrapper } from '../WellnessWrapper.component';
import { fetchSeniorDetail } from 'store/commonReducer/common.action';
var Sleep = function (props) {
    return (_jsx(WellnessWrapper, { wellnessParam: 'sleep', children: _jsx(RenderComponent, __assign({}, props)) }));
};
var RenderComponent = function () {
    var currentState = useAppSelector(function (state) { return state.wellnessDashboard; }).currentState;
    var dispatch = useAppDispatch();
    useEffect(function () {
        dispatch(fetchSeniorDetail());
    }, []);
    switch (currentState) {
        case 'day':
            return _jsx(SleepComponent, {});
        case 'week':
            return _jsx(SleepWeek, {});
        case 'month':
            return _jsx(SleepMonth, {});
        default:
            return _jsx("div", { children: "Loading..." });
    }
};
export default Sleep;
