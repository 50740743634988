var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { CLOSE_CALL_ENTRY, CREATE_CALL_ENTRY } from './CallEntry.action';
var INITIAL_STATE = {
    callEntryData: [],
    events: {},
};
export var callEntryReducer = function (state, action) {
    if (state === void 0) { state = INITIAL_STATE; }
    switch (action.type) {
        case "GET_CALL_ENTRY":
            return __assign({}, state);
        case "SET_CALL_ENTRY":
            return __assign(__assign({}, state), action.payload);
        case CREATE_CALL_ENTRY:
            return __assign(__assign({}, state), { events: __assign(__assign({}, state.events), action.payload.callEntryAlarm) });
        case CLOSE_CALL_ENTRY:
            return __assign(__assign({}, state), { events: __assign({}, action.payload.updatedCallEntry) });
        default:
            return state;
    }
};
