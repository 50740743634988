import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Box, CircularProgress } from '@mui/material';
import clsx from 'clsx';
export var useStyles = makeStyles()(function (theme) { return ({
    stretch: {
        minHeight: 'fit-content',
        height: '100%',
        '& > div': {
            minHeight: 'fit-content',
            height: '100%',
            '& > div': {
                minHeight: 'fit-content',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                '& > div:nth-of-type(2)': {
                    flexGrow: 1,
                },
            },
        },
    },
    root: {
        border: 0,
        backgroundColor: theme.palette.common.white,
        borderRadius: 17,
        boxShadow: "0 4px 19px ".concat(theme.palette.customColor.boxShadow),
    },
    title: {
        color: theme.palette.customColor.lighterBlack,
        textTransform: 'uppercase',
        fontSize: 18,
    },
    headerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    },
    children: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    subTitle: {
        color: theme.palette.customColor.primaryGreen,
        fontSize: 17,
        fontWeight: 400,
    },
}); });
var SimpleCard = function (_a) {
    var _b, _c;
    var title = _a.title, subTitle = _a.subTitle, children = _a.children, mt = _a.mt, isLoading = _a.isLoading, noRecordFound = _a.noRecordFound, _d = _a.stretch, stretch = _d === void 0 ? false : _d, style = _a.style, childrenClass = _a.childrenClass, cardContentStyle = _a.cardContentStyle;
    var classes = useStyles().classes;
    var cardData = useMemo(function () {
        if (isLoading) {
            return _jsx(CircularProgress, {});
        }
        else if (noRecordFound) {
            return _jsx("div", { children: "No Records Found" });
        }
        else
            return children;
    }, [isLoading, noRecordFound, children]);
    return (_jsx(Box, { mt: mt, "data-testid": 'simpleCard', className: clsx((_b = {},
            _b[classes.stretch] = stretch,
            _b)), children: _jsx(Card, { className: classes.root, style: style, children: _jsxs(CardContent, { style: cardContentStyle, children: [_jsxs(Box, { className: classes.headerContainer, children: [_jsx(Typography, { className: classes.title, color: 'textSecondary', gutterBottom: true, children: title }), _jsx(Typography, { className: classes.subTitle, color: 'textSecondary', gutterBottom: true, children: subTitle })] }), _jsx(Box, { className: clsx(classes.children, (_c = {},
                            _c[childrenClass] = childrenClass,
                            _c)), children: cardData })] }) }) }));
};
export { SimpleCard as Card };
