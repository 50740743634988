var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography, FormControlLabel, RadioGroup } from '@mui/material';
import { InputRadio } from './Input.component';
import { fieldsStyle } from '../InputFields.style';
import clsx from 'clsx';
var InputRadioWrapper = function (item) {
    var _a;
    var classes = fieldsStyle().classes;
    var inputProps = item.inputProps, eventProps = item.eventProps, props = __rest(item, ["inputProps", "eventProps"]);
    return (_jsxs(_Fragment, { children: [_jsx(RadioGroup, __assign({}, eventProps, { className: clsx(props.className, (_a = {},
                    _a[classes.errorTextSelect] = props.isError,
                    _a)), children: props.radioItems.map(function (data) {
                    return (_jsx(FormControlLabel, { control: 
                        //using data-error prop for autoscroll to the first error on the screen
                        _jsx(InputRadio, __assign({}, inputProps, { required: false,
                            'data-error': props.isError ? 'error' : '', dataTestid: "".concat(data.value).concat(inputProps === null || inputProps === void 0 ? void 0 : inputProps.dataTestid) })), disabled: data.disabled, value: data.value, label: props.showRadioLabel ? data.label : '' }, data.value));
                }) })), props.errorText && (_jsx(Typography, { variant: 'subtitle1', className: classes.errorText, children: props.errorText }))] }));
};
export default InputRadioWrapper;
