import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { theme } from 'config/theme.config';
import { sleepPercentage } from './SleepPercentage.style';
import { roundOff } from 'globals/global.functions';
var SleepPercentage = function (_a) {
    var percentage = _a.percentage;
    var classes = sleepPercentage().classes;
    return (_jsxs(Box, { className: classes.container, children: [_jsx(Typography, { variant: 'boldHeading', color: theme.palette.customColor.activityGreen, children: "SLEEP" }), _jsx(Typography, { className: classes.percentage, children: roundOff(percentage, 0) }), _jsx(Typography, { children: "%" })] }));
};
export default SleepPercentage;
