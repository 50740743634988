var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { getCurrentSenior, getQueryParamTimezone, getHourMin, } from 'globals/global.functions';
import MapComponent from 'common/GoogleMap';
import { getSeniorLocation, resetSeniorLocation, } from 'store/commonReducer/common.action';
import { wellnessIndicatorStyle } from './WellnessIndicator.style';
import { LocationStatus } from 'globals/enums';
import ShowHyphen from 'common/ShowHyphen/ShowHyphen';
import { residentSubPages } from 'globals/global.constants';
var LocationDashboardCard = function (_a) {
    var _b = _a.isResident, isResident = _b === void 0 ? false : _b;
    var classes = wellnessIndicatorStyle().classes;
    var navigate = useNavigate();
    var dispatch = useAppDispatch();
    var homeCoordinates = useAppSelector(function (state) { return state.common.seniorDetail.minimalInfo.address.coordinates; });
    var seniorLocation = useAppSelector(function (state) { return state.common.seniorCardLocation.locationData; });
    React.useEffect(function () {
        if (homeCoordinates.latitude) {
            dispatch(getSeniorLocation({
                isResident: isResident,
                isCard: true,
            }));
        }
    }, [dispatch, homeCoordinates, isResident]);
    React.useEffect(function () {
        return function () {
            dispatch(resetSeniorLocation(true));
        };
    }, [dispatch]);
    var redirectToLocationPage = React.useCallback(function () {
        if (isResident) {
            navigate(residentSubPages.location.path, {
                relative: 'route',
            });
        }
        else {
            var seniorInfo = __assign({}, getCurrentSenior());
            navigate("/senior/".concat(seniorInfo.seniorID, "/").concat(seniorInfo.accountID, "/").concat(getQueryParamTimezone(seniorInfo.timezone), "/senior-location"), { replace: true });
        }
    }, [isResident, navigate]);
    var LocationText = React.useMemo(function () {
        switch (seniorLocation.atHome) {
            case LocationStatus.HOME:
                return 'Ḥome';
            case LocationStatus.AWAY:
                return 'Away';
            default:
                return 'No data';
        }
    }, [seniorLocation.atHome]);
    return (_jsxs(Box, { className: classes.locationContainer, "data-testid": 'location-component', children: [_jsxs(Box, { display: 'flex', justifyContent: 'space-between', children: [_jsx(Typography, { className: classes.locationTitle, children: "Location:" }), _jsx(Typography, { className: classes.locationValue, children: LocationText })] }), _jsxs(Box, { display: 'flex', justifyContent: 'space-between', children: [_jsx(Typography, { className: classes.locationTitle, children: "Time away from home:" }), _jsx(Typography, { children: _jsx(ShowHyphen, { children: seniorLocation.timeAwayFromHome &&
                                getHourMin(seniorLocation.timeAwayFromHome, 's', 'short') }) })] }), _jsx(Box, { display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center', children: _jsx(MapComponent, { atHome: seniorLocation.atHome, homeCoordinates: {
                        latitude: homeCoordinates === null || homeCoordinates === void 0 ? void 0 : homeCoordinates.latitude,
                        longitude: homeCoordinates === null || homeCoordinates === void 0 ? void 0 : homeCoordinates.longitude,
                    }, isLoading: homeCoordinates.latitude == '' &&
                        !seniorLocation.currentCoordinates.latitude, currentCoordinates: seniorLocation.currentCoordinates, iconScale: { x: 28, y: 38 }, onClick: redirectToLocationPage, mapOptions: {
                        fullscreenControl: false,
                        keyboardShortcuts: false,
                        gestureHandling: 'none',
                        panControl: false,
                        clickableIcons: false,
                        mapTypeControl: false,
                        zoomControl: false,
                    }, className: classes.mapStyle, showTooltipOnMap: false }) })] }));
};
export { LocationDashboardCard };
