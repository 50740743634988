export var initialStateSelectedAddress = {
    streetNumber: '',
    route: '',
    city: '',
    fullAddress: '',
    fullState: '',
    state: '',
    zipCode: '',
    streetAddress: '',
    coordinates: {
        latitude: 0,
        longitude: 0,
    },
};
