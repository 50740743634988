var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import moment from 'moment';
import { getCurrentSenior } from 'globals/global.functions';
import { convertNanoSecondsToMiliSeconds } from 'globals/date.functions';
import { DATE_FORMAT_SHORT_YEAR } from 'globals/global.constants';
import { showToast } from 'common/Toast';
import { hideApplicationLoader, showApplicationLoader, } from 'common/ApplicationLoader';
import { getCareInsightHistory } from 'pages/WCPages/SeniorCareInsights/SeniorCareInsight.action';
import { CareInsightStatus, ToastMessageType } from 'globals/enums';
export var GET_DASHBOARD_CARE_INSIGHT = 'GET_DASHBOARD_CARE_INSIGHT';
export var GET_DASHBOARD_CARE_INSIGHT_SUCCESS = 'GET_DASHBOARD_CARE_INSIGHT_SUCCESS';
export var GET_DASHBOARD_CARE_INSIGHT_FAIL = 'GET_DASHBOARD_CARE_INSIGHT_FAIL';
/**
 * @description action creator to fetch the care insight on senior dashboard
 * @returns void
 */
export var getDashboardCareInsight = function () { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var seniorInfo, date, days, startDate, endDateTime, endDateTimeStamp, startDateTimestamp, payload, response, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(showApplicationLoader());
                seniorInfo = getCurrentSenior();
                date = new Date();
                days = 30;
                startDate = new Date(date.getTime() - days * 24 * 60 * 60 * 1000);
                endDateTime = new Date();
                endDateTimeStamp = Math.round(endDateTime / 1000) * Math.pow(10, 9);
                startDateTimestamp = Math.round(startDate / 1000) * Math.pow(10, 9);
                payload = {
                    account_id: seniorInfo.accountID,
                    senior_id: seniorInfo.seniorID,
                    start_timestamp: startDateTimestamp,
                    end_timestamp: endDateTimeStamp,
                    desc: true,
                    status: CareInsightStatus.Approved,
                };
                dispatch({
                    type: GET_DASHBOARD_CARE_INSIGHT,
                });
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, dispatch(getCareInsightHistory(payload))];
            case 2:
                response = _a.sent();
                dispatch(hideApplicationLoader());
                dispatch({
                    type: GET_DASHBOARD_CARE_INSIGHT_SUCCESS,
                    payload: { data: response.results },
                });
                return [2 /*return*/, response];
            case 3:
                error_1 = _a.sent();
                dispatch(hideApplicationLoader());
                dispatch({
                    type: GET_DASHBOARD_CARE_INSIGHT_FAIL,
                });
                if (error_1 instanceof Error) {
                    dispatch(showToast(error_1.message, ToastMessageType.Error));
                }
                return [2 /*return*/, null];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
export var CARE_INSIGHT_MESSAGES = Object.freeze({
    GOOD_NEWS: 'Senior is feeling: ',
    ATTENTION: 'Senior is feeling Low-Medium levels of: ',
    ACTION: 'Senior is feeing high levels of: ',
});
export var getWellnessSurveyMessage = function (message) {
    message = JSON.parse(message);
    var goodNewsMessageType = message.every(function (item) { return Object.values(item)[0] === null; });
    var attentionMessageType = message.some(function (item) {
        return Object.values(item)[0] === 'low' || Object.values(item)[0] === 'medium';
    });
    var actionMessageType = message.every(function (item) { return Object.values(item)[0] === 'high'; });
    if (goodNewsMessageType) {
        message =
            CARE_INSIGHT_MESSAGES.GOOD_NEWS +
                message.map(function (item) { return Object.keys(item)[0]; }).join(', ');
    }
    if (attentionMessageType) {
        message =
            CARE_INSIGHT_MESSAGES.ATTENTION +
                message
                    .map(function (item) {
                    return "".concat(Object.keys(item)[0], " - ").concat(Object.values(item)[0].toUpperCase());
                })
                    .join(', ');
    }
    if (actionMessageType) {
        message =
            CARE_INSIGHT_MESSAGES.ACTION +
                message.map(function (item) { return Object.keys(item)[0]; }).join(', ');
    }
    return message;
};
export var mapCareInsightSummary = function (summary) {
    if (summary === void 0) { summary = []; }
    var dateArray = [];
    summary.forEach(function (element) {
        var _a;
        var summaryDate = moment(convertNanoSecondsToMiliSeconds(element.timestamp)).format(DATE_FORMAT_SHORT_YEAR);
        var existData = dateArray.find(function (item) { return (item === null || item === void 0 ? void 0 : item.date) === summaryDate; });
        if (!existData) {
            dateArray.push({
                date: summaryDate,
                event: 'Daily Recap',
                vitalData: [],
                seniorId: element.senior_id,
                timestamp: element.timestamp,
            });
        }
        var index = dateArray.findIndex(function (item) { return (item === null || item === void 0 ? void 0 : item.date) === summaryDate; });
        dateArray[index] = __assign(__assign({}, dateArray[index]), { vitalData: __spreadArray(__spreadArray([], ((_a = dateArray[index]) === null || _a === void 0 ? void 0 : _a.vitalData) || [], true), [__assign({}, element)], false) });
    });
    return dateArray;
};
