var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Controller } from 'react-hook-form';
import Box from '@mui/material/Box';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import clsx from 'clsx';
import { InputDatePicker } from 'common/Input';
import { commonStyle } from '../FormField.style';
import { DATE_FORMAT } from 'globals/global.constants';
var DatePicker = function (_a) {
    var _b = _a.control, control = _b === void 0 ? null : _b, _c = _a.errorField, errorField = _c === void 0 ? undefined : _c, _d = _a.errorText, errorText = _d === void 0 ? '' : _d, _e = _a.defaultValue, defaultValue = _e === void 0 ? '' : _e, _f = _a.disablePast, disablePast = _f === void 0 ? false : _f, _g = _a.maxDate, maxDate = _g === void 0 ? '' : _g, props = __rest(_a, ["control", "errorField", "errorText", "defaultValue", "disablePast", "maxDate"]);
    var classes = commonStyle().classes;
    // Function to handle key down events and prevent typing
    var handleKeyDown = function (event) {
        event.preventDefault();
    };
    return (_jsx(_Fragment, { children: _jsxs(LocalizationProvider, { dateAdapter: AdapterDateFns, children: [_jsx(Controller, { render: function (_a) {
                        var _b;
                        var field = _a.field;
                        return (_jsx(InputDatePicker, __assign({}, field, { clearable: true, 
                            //disableToolbar
                            className: clsx((_b = {}, _b[classes.inputDateError] = errorField, _b)), variant: 'inline', autoOk: true, disablePast: disablePast, inputProps: {
                                placeholder: DATE_FORMAT,
                                'data-testid': 'datePicker-component',
                                onKeyDown: handleKeyDown, // Prevent typing
                            }, InputProps: {
                                disableUnderline: true,
                            }, invalidDateMessage: '', format: 'MM/dd/yyyy', disabled: props.disabled ? true : false, maxDate: maxDate })));
                    }, name: props.name, defaultValue: defaultValue, control: control, rules: props.rules }), errorText && (_jsx(Box, { className: classes.errorTextSelect, children: errorText }))] }) }));
};
export { DatePicker };
