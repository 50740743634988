import { makeStyles } from 'tss-react/mui';
export var useSearchBarStyles = makeStyles()(function (theme) {
    var _a, _b, _c;
    return ({
        root: {
            overflow: 'hidden',
            borderRadius: 8,
            backgroundColor: theme.palette.secondary.main,
            width: '100%',
            height: 36,
            padding: '0px 0px 0px 15px',
        },
        searchList: {
            zIndex: 1,
            maxHeight: '20%',
            overflowY: 'scroll',
            position: 'absolute',
            fontSize: 16,
            padding: '5px 0px 5px 10px',
            borderRadius: 2,
            boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
            backgroundColor: '#fff',
            '&::-webkit-scrollbar': {
                width: 8,
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#00a9cf',
                borderRadius: 4,
            },
        },
        searchListItems: {
            padding: '10px 0',
            borderBottom: '1px solid lightGrey',
            '&:last-child': { borderBottom: 'none' },
        },
        disableIcon: {
            pointerEvents: 'none',
            color: (_a = theme.palette) === null || _a === void 0 ? void 0 : _a.text.disabled,
        },
        crossIcon: { margin: '5px', cursor: 'pointer' },
        searchIcon: {
            margin: '5px',
            color: (_c = (_b = theme === null || theme === void 0 ? void 0 : theme.palette) === null || _b === void 0 ? void 0 : _b.primary) === null || _c === void 0 ? void 0 : _c.main,
        },
    });
});
