import { getTimestamp } from 'globals/global.functions';
import FallGraphModel from '../model/fallGraphModel';
var FallGraphParser = /** @class */ (function () {
    function FallGraphParser() {
        this.fallGraphData = {
            overall: {
                data: [],
                score: 0,
            },
            blue: {
                data: [],
                score: 0,
            },
            green: {
                data: [],
                score: 0,
            },
            gold: {
                data: [],
                score: 0,
            },
        };
        this.getDataArray = function (data) {
            var dataArray = Object.entries(data).map(function (entry) {
                var timestamp = entry[0], obj = entry[1];
                return {
                    timestamp: getTimestamp(timestamp) || 0,
                    score: (obj === null || obj === void 0 ? void 0 : obj.fall_count) || 0,
                };
            });
            return dataArray;
        };
    }
    /**
     * @description function to parse the response data
     * @function parse
     * @param {IFallGraphDataResponse[]} data api response data
     * @returns {IFallGraphParse} parsed data
     */
    FallGraphParser.prototype.parse = function (data) {
        var _a, _b, _c, _d, _e, _f;
        this.fallGraphData = new FallGraphModel({
            data: this.getDataArray((data === null || data === void 0 ? void 0 : data.overall) || {}),
            score: (data === null || data === void 0 ? void 0 : data.overall_fall) || 0,
        }, {
            data: this.getDataArray(((_a = data === null || data === void 0 ? void 0 : data.zone) === null || _a === void 0 ? void 0 : _a.blue) || {}),
            score: ((_b = data === null || data === void 0 ? void 0 : data.zone) === null || _b === void 0 ? void 0 : _b.blue_fall_count) || 0,
        }, {
            data: this.getDataArray(((_c = data === null || data === void 0 ? void 0 : data.zone) === null || _c === void 0 ? void 0 : _c.green) || {}),
            score: ((_d = data === null || data === void 0 ? void 0 : data.zone) === null || _d === void 0 ? void 0 : _d.green_fall_count) || 0,
        }, {
            data: this.getDataArray(((_e = data === null || data === void 0 ? void 0 : data.zone) === null || _e === void 0 ? void 0 : _e.gold) || {}),
            score: ((_f = data === null || data === void 0 ? void 0 : data.zone) === null || _f === void 0 ? void 0 : _f.gold_fall_count) || 0,
        });
        return this.fallGraphData;
    };
    return FallGraphParser;
}());
export default FallGraphParser;
