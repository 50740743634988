/**
 * @class WellbeingGraphModel
 * @description class to maintain the wellbeing graph data
 */
var WellbeingGraphModel = /** @class */ (function () {
    function WellbeingGraphModel(overall, blue, green, gold) {
        this.overall = overall;
        this.blue = blue;
        this.green = green;
        this.gold = gold;
    }
    return WellbeingGraphModel;
}());
export default WellbeingGraphModel;
