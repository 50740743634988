import { makeStyles } from 'tss-react/mui';
export var emotionalSurveyCardStyle = makeStyles()(function (theme) { return ({
    card: {
        minHeight: '527px',
        height: '527px',
    },
    headingContainer: {
        padding: '12px 20px 0 20px',
    },
    cicularGraphContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    graphInnerValues: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    scoreText: {
        fontSize: '36px',
        fontWeight: 'bold',
        lineHeight: 1.3,
    },
}); });
