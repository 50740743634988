import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Box, useTheme } from '@mui/material';
import { VictoryAxis, VictoryArea, VictoryLegend, VictoryChart, VictoryStack, VictoryBar, VictoryLine, VictoryScatter, VictoryGroup, VictoryVoronoiContainer, VictoryLabel, } from 'victory';
import get from 'lodash.get';
import { HeartRespirationTooltip } from 'common/Tooltips';
import { roundToTen } from 'globals/global.functions';
import { heartRateStyle } from './HearRate.style';
var HeartRateActivity = function (_a) {
    var summary = _a.summary, heartRateActivity = _a.heartRateActivity, activityChartData = _a.activityChartData, minMaxData = _a.minMaxData, type = _a.type;
    var theme = useTheme();
    var classes = heartRateStyle().classes;
    var stackChartData = activityChartData;
    var lineChartData = heartRateActivity;
    var areaChartData = minMaxData;
    var legendChartData = [
        {
            name: 'Moderate',
            symbol: { fill: theme.palette.customColor.moderateGreen, type: 'square' },
        },
        {
            name: 'Intense',
            symbol: { fill: theme.palette.customColor.intenseGreen, type: 'square' },
        },
        {
            name: 'Heart Rate',
            symbol: { fill: theme.palette.customColor.error, type: 'minus' },
        },
    ];
    var minValue = type == 'day' ? roundToTen(get(summary, 'data.min') * 0.8, 'min') : 0;
    var maxValue = roundToTen(get(summary, 'data.max'), 'max');
    var getArray = function (min, max) {
        var step = 10;
        var len = Math.floor((max - min) / step) + 1;
        return Array(len)
            .fill(0)
            .map(function (_, idx) { return min + idx * step; });
    };
    return (_jsx(Box, { className: classes.activityContainer, "data-testid": 'heart-rate-activity', children: _jsxs(VictoryChart, { minDomain: { y: minValue }, maxDomain: { y: maxValue }, domainPadding: 50, width: 900, height: 300, containerComponent: _jsx(VictoryVoronoiContainer, { voronoiBlacklist: ['area', 'bar'], activateLabels: true, labels: function (_a) {
                    var datum = _a.datum;
                    return "".concat(datum.x);
                }, labelComponent: _jsx(HeartRespirationTooltip, { data2: areaChartData, data: lineChartData }) }), children: [_jsx(VictoryLegend, { orientation: 'horizontal', gutter: 20, symbolSpacer: 6, x: 320, y: 10, style: {
                        labels: { fontSize: 16, padding: 0, margin: 0 },
                    }, data: legendChartData }), _jsx(VictoryAxis, { orientation: 'bottom', style: {
                        axis: {
                            stroke: theme.palette.customColor.titleBlack,
                        },
                        tickLabels: {
                            fill: theme.palette.customColor.titleBlack,
                            fontSize: 15,
                        },
                    }, tickFormat: function (x) { return x; }, fixLabelOverlap: true }), _jsx(VictoryAxis, { orientation: 'right', dependentAxis: true, tickFormat: getArray(minValue, maxValue), fixLabelOverlap: true, style: {
                        axis: {
                            stroke: theme.palette.customColor.lighterBlack,
                        },
                        tickLabels: {
                            fill: theme.palette.customColor.lighterBlack,
                            fontSize: 15,
                        },
                    }, label: 'bpm', axisLabelComponent: _jsx(VictoryLabel, { angle: 0, dy: -130, style: {
                            fontWeight: 600,
                            fontSize: 15,
                            color: theme.palette.customColor.lighterBlack,
                        } }) }), _jsx(VictoryArea, { name: 'area', interpolation: 'natural', style: {
                        data: { fill: theme.palette.customColor.chartGrey },
                    }, data: areaChartData }), _jsx(VictoryStack, { colorScale: [
                        theme.palette.customColor.moderateGreen,
                        theme.palette.customColor.intenseGreen,
                    ], children: stackChartData.map(function (chartData) {
                        return (_jsx(VictoryBar, { name: 'bar', style: { data: chartData.length > 2 ? {} : { width: 30 } }, data: chartData }, "heartRateActivity-".concat(chartData.length)));
                    }) }), _jsxs(VictoryGroup, { style: {
                        data: {
                            fillOpacity: 0.7,
                            stroke: theme.palette.customColor.intenseGreen,
                            strokeWidth: 2,
                        },
                    }, color: theme.palette.common.white, data: lineChartData, children: [_jsx(VictoryLine, { style: { data: { stroke: theme.palette.customColor.error } } }), _jsx(VictoryScatter, { style: { data: { fill: theme.palette.common.white } }, size: 4 })] })] }) }));
};
export { HeartRateActivity };
