var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { UNIQUE_EMAIL_ID, UNIQUE_EMPLOYEE_ID, UNIQUE_NUMBER, RESET_VALIDATION, } from './ProfileInfo.action';
var INITIAL_STATE = {
    isEmailExists: null,
    isPhoneExists: null,
    errorEmailMessage: '',
    errorNumberMessage: '',
};
export var validateDataReducer = function (state, action) {
    if (state === void 0) { state = INITIAL_STATE; }
    switch (action.type) {
        case UNIQUE_EMAIL_ID:
            return __assign(__assign({}, state), { isEmailExists: action.payload.data, errorEmailMessage: action.payload.message });
        case UNIQUE_EMPLOYEE_ID:
            return __assign(__assign({}, state), { isEmpIdExists: action.payload.data, errorEmpIdMessage: action.payload.message });
        case UNIQUE_NUMBER:
            return __assign(__assign({}, state), { isPhoneExists: action.payload.data, errorNumberMessage: action.payload.message });
        case RESET_VALIDATION:
            return __assign({}, INITIAL_STATE);
        default:
            return state;
    }
};
