var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useRef } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { Box, Grid, Button, Typography, CircularProgress, Tooltip, } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import LocateMe from 'assets/icons/LocateMe.svg';
import { getAddressByLatLng, getHourMin, setLocalStorage, getCurrentSenior, removeLocalStorage, getLocalStorage, } from 'globals/global.functions';
import { DATE_FORMAT, LocationLocateMeError, TIME_FORMAT, } from 'globals/global.constants';
import { postLocationAPICall, setSeniorLocateMeError, } from 'store/commonReducer/common.action';
import { LocateMePostAPIResponseType, LocationStatus } from 'globals/enums';
import ShowHyphen from 'common/ShowHyphen/ShowHyphen';
import { locationInfoStyle } from './LocationInfo.style';
export var LocationInfo = function (_a) {
    var props = __rest(_a, []);
    var atHome = props.atHome, timeAwayFromHome = props.timeAwayFromHome, lastUpdated = props.lastUpdated;
    var seniorInfo = __assign({}, getCurrentSenior());
    var timer = useRef(null);
    var classes = locationInfoStyle().classes;
    var _b = React.useState(''), address = _b[0], setAddress = _b[1];
    var _c = React.useState(false), locateMeDisable = _c[0], setLocateMeDisable = _c[1];
    var _d = React.useState(false), showLoader = _d[0], setShowLoader = _d[1];
    var locateMeResponse = useAppSelector(function (state) { return state.common.seniorLocation; }).locateMeResponse;
    var locationResponseTime = useAppSelector(function (state) { return state.config.locationResponseTime; });
    var dispatch = useAppDispatch();
    var LocationText = React.useMemo(function () {
        switch (atHome) {
            case LocationStatus.HOME:
                return 'Ḥome';
            case LocationStatus.AWAY:
                return 'Away';
            default:
                return 'No data';
        }
    }, [atHome]);
    var updateAddress = function () { return __awaiter(void 0, void 0, void 0, function () {
        var formattedAddress;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setAddress('');
                    if (!props.currentCoordinates.latitude) return [3 /*break*/, 2];
                    return [4 /*yield*/, getAddressByLatLng(props.currentCoordinates.latitude, props.currentCoordinates.longitude)];
                case 1:
                    formattedAddress = _a.sent();
                    setAddress(formattedAddress == '' ? 'No Address founds' : formattedAddress);
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    /**
     * @description function to set the timer for locate me button
     * @function setTimer
     * @param {number} remainingSeconds
     */
    var setTimer = function (remainingSeconds) {
        var timer = setTimeout(function () {
            dispatch(setSeniorLocateMeError(LocationLocateMeError.locationNotFetched));
            setShowLoader(false);
            removeLocalStorage("location-".concat(seniorInfo.seniorID));
        }, remainingSeconds * 1000);
        return timer;
    };
    //handleLocateMe function call when we click on locate me button
    var handleLocateMe = function () {
        dispatch(postLocationAPICall());
    };
    var getRemainingSeconds = function () {
        var currentTime = moment().format();
        var localStorageStartTime = getLocalStorage("location-".concat(seniorInfo.seniorID));
        var completedSeconds = moment(currentTime).diff(localStorageStartTime, 'seconds');
        var remainingSeconds = locationResponseTime - completedSeconds;
        return remainingSeconds;
    };
    React.useEffect(function () {
        updateAddress();
    }, [props.currentCoordinates.latitude, props.currentCoordinates.longitude]);
    // Method to handle disable of locate me button
    var disableLocationHandler = React.useCallback(function (value) {
        //if on past dates then we disable locate me button
        if (moment().tz(props.seniorTimezone).format(DATE_FORMAT) !==
            moment(props.lastUpdated)
                .tz(props.seniorTimezone)
                .format(DATE_FORMAT) &&
            props.lastUpdated !== 0) {
            setLocateMeDisable(true);
        }
        else {
            setLocateMeDisable(value);
        }
    }, [props.lastUpdated, props.seniorTimezone]);
    // when we get response from POST locate-me API
    React.useEffect(function () {
        if (locateMeResponse) {
            if (locateMeResponse === LocateMePostAPIResponseType.Success) {
                setShowLoader(true);
                disableLocationHandler(true);
                //get current time
                var locateMeClickTime = moment().format();
                //update the current time in LS
                setLocalStorage("location-".concat(seniorInfo.seniorID), locateMeClickTime);
                //start timer and assign to timer.current
                timer.current = setTimer(locationResponseTime);
            }
            else {
                setShowLoader(false);
                disableLocationHandler(false);
            }
        }
    }, [locateMeResponse, disableLocationHandler]);
    //when we refresh the page or comes on location screen.
    React.useEffect(function () {
        //check if we have config time
        if (locationResponseTime) {
            var localStorageStartTime = getLocalStorage("location-".concat(seniorInfo.seniorID));
            var remainingSeconds = getRemainingSeconds();
            //check if we have LS time for current senior
            if (localStorageStartTime) {
                setShowLoader(true);
                disableLocationHandler(true);
                timer.current = setTimer(remainingSeconds);
            }
        }
    }, [locationResponseTime]);
    React.useEffect(function () {
        var remainingSeconds = getRemainingSeconds();
        if (props.lastUpdated && isNaN(remainingSeconds)) {
            if (moment().tz(props.seniorTimezone).format(DATE_FORMAT) !==
                moment(props.lastUpdated).tz(props.seniorTimezone).format(DATE_FORMAT)) {
                disableLocationHandler(true);
            }
            else {
                disableLocationHandler(false);
                timer.current =
                    !isNaN(remainingSeconds) && setTimer(locationResponseTime);
            }
        }
    }, [props.lastUpdated]);
    React.useEffect(function () {
        return function () {
            dispatch(setSeniorLocateMeError(''));
            clearTimeout(timer.current);
        };
    }, []);
    return (_jsx(Box, { children: _jsxs(Grid, { container: true, className: classes.locationInfoContainer, children: [_jsx(Grid, { item: true, sm: 3, className: classes.detailContainer, children: _jsx(Details, { firstLabel: 'Location', firstValue: LocationText, secondLabel: 'Address', secondValue: _jsx(ShowHyphen, { children: address }) }) }), _jsx(Grid, { item: true, sm: 3, className: classes.detailContainer, children: _jsx(Details, { firstLabel: 'Latitude', firstValue: _jsx(ShowHyphen, { children: props.currentCoordinates.latitude }), secondLabel: 'Longitude', secondValue: _jsx(ShowHyphen, { children: props.currentCoordinates.longitude }) }) }), _jsx(Grid, { item: true, sm: 4, className: classes.detailContainer, children: _jsx(Details, { firstLabel: 'Time away from home', firstValue: _jsx(ShowHyphen, { children: timeAwayFromHome && getHourMin(timeAwayFromHome, 's', 'short') }), secondLabel: 'Last updated date & time', secondValue: _jsx(ShowHyphen, { children: lastUpdated &&
                                moment(props.lastUpdated)
                                    .tz(props.seniorTimezone)
                                    .format("".concat(DATE_FORMAT, " ").concat(TIME_FORMAT)) }) }) }), _jsxs(Grid, { sm: 2, className: classes.locateMeContainer, children: [_jsxs(Box, { className: classes.locateMe, children: [_jsx(Box, { className: classes.locateMeBox, children: _jsx(Button, { variant: 'contained', color: 'primary', className: classes.locateMeButton, onClick: function () { return handleLocateMe(); }, disabled: locateMeDisable, children: _jsxs(Box, { display: 'flex', alignItems: 'center', children: [_jsx("img", { src: LocateMe, height: 25, width: 22 }), _jsx(Typography, { variant: 'h5', children: "Locate Me" })] }) }) }), locateMeResponse &&
                                    locateMeResponse !== LocateMePostAPIResponseType.Success && (_jsxs(Box, { display: 'flex', style: { color: '#cc0000' }, children: [_jsx(Typography, { variant: 'subtitle1', children: "Failed. Try again" }), _jsx(Tooltip, { title: _jsx(Typography, { variant: 'subtitle1', children: locateMeResponse }), arrow: true, classes: {
                                                arrow: classes.tooltipArrow,
                                                tooltip: classes.tooltip,
                                            }, children: _jsx(InfoOutlinedIcon, { style: { paddingLeft: 4 } }) })] }))] }), _jsx(Box, { className: classes.locateMeLoader, children: locateMeDisable && showLoader && (_jsx(CircularProgress, { size: 30, thickness: 3.6, style: { marginLeft: 10 } })) })] })] }) }));
};
var Details = function (_a) {
    var firstLabel = _a.firstLabel, secondLabel = _a.secondLabel, firstValue = _a.firstValue, secondValue = _a.secondValue;
    var classes = locationInfoStyle().classes;
    return (_jsxs(Box, { display: 'flex', alignItems: 'baseline', children: [_jsxs(Box, { pt: 0.1, pl: 2, className: classes.justifySpace, children: [_jsxs(Typography, { variant: 'h5', className: clsx(classes.label, classes.marginB20), children: [firstLabel, ":"] }), _jsxs(Typography, { variant: 'h5', style: { paddingTop: 2 }, className: classes.label, children: [secondLabel, ":"] })] }), _jsxs(Box, { ml: 5, className: classes.justifySpace, children: [_jsx(Typography, { className: clsx(classes.detailText, classes.marginB20), variant: 'body1', children: firstValue }), _jsx(Typography, { className: classes.detailText, variant: 'body1', children: secondValue })] })] }));
};
