/** @format */
import { SHOW_TOAST, HIDE_TOAST } from './Toast.types';
export var showToast = function (message, type) {
    return function (dispatch) {
        dispatch({
            type: SHOW_TOAST,
            payload: {
                type: type,
                message: message,
            },
        });
    };
};
export var hideToast = function () {
    return function (dispatch) {
        dispatch({
            type: HIDE_TOAST,
        });
    };
};
