import MedicationListModel from '../model/medicationServiceModel';
var MedicationListParser = /** @class */ (function () {
    function MedicationListParser() {
        this.medicationListData = [];
    }
    /**
     * @description function to parse the response data
     * @function parse
     * @param {IMedicationListDataResponse} data api response array data
     * @returns {IMedicationListDataParse[]} parsed data
     */
    MedicationListParser.prototype.parse = function (data) {
        this.medicationListData = data === null || data === void 0 ? void 0 : data.map(function (data) {
            return new MedicationListModel(data.medicine, data.dose_form, {
                doseFrequencyTime: data.dose_frequency.every,
                doseFrequencyUnit: data.dose_frequency.period,
            }, data.when, data.date_prescribed, data.date_discontinued, data.pharmacy_name, data.pharmacy_phone_number, data.notes, data.modification_date, data.medication_id, data.status);
        });
        return this.medicationListData;
    };
    return MedicationListParser;
}());
export default MedicationListParser;
