var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Box } from '@mui/material';
import { Label } from 'common/Input';
import SelectDate from './component/SelectDate';
import SelectControlledDate from './component/SelectControlledDate';
import SelectControlledTime from './component/SelectControlledTime';
import InputTextWrapper from './component/InputTextWrapper';
import { fieldsStyle } from './InputFields.style';
import InputSelectWrapper from './component/InputSelectWrapper';
import InputRadioWrapper from './component/InputRadioWrapper';
import { ProgressBar } from './component/ProgressBar';
import AutoCompleteField from 'common/Fields/Components/AutoCompleteField';
/**
 * @description component to access all the form fields
 * @return {JSX.Element} JSX
 */
var InputFields = function (props) {
    var classes = fieldsStyle().classes;
    return (_jsxs(Box, { width: '100%', children: [props.isLabel && (_jsxs(Label, { htmlFor: props.label, className: classes.errorTextStyle, "data-testid": 'field-label', children: [props.label, props.inputProps.required && "*"] })), _jsx(Box, { children: _jsx(RenderComponent, __assign({}, props)) })] }));
};
var RenderComponent = function (props) {
    if (props.menu) {
        return _jsx(InputSelectWrapper, __assign({}, props));
    }
    else if (props.radio) {
        return _jsx(InputRadioWrapper, __assign({}, props));
    }
    else if (props.date) {
        return (_jsx(SelectDate, { label: props.label, errorField: props.isError, disableFutureDate: props.disableFutureDate, props: props, defaultValue: props.defaultValue, errorText: props.errorText }));
    }
    else if (props.controlledDate) {
        return _jsx(SelectControlledDate, __assign({}, props));
    }
    else if (props.controlledTime) {
        return _jsx(SelectControlledTime, __assign({}, props));
    }
    else if (props.slider) {
        return _jsx(ProgressBar, __assign({}, props));
    }
    else if (props.isAutoComplete) {
        return (_jsx(AutoCompleteField, __assign({ isNewDesign: true, defaultValue: props.eventProps.value, addressOnChangeHandler: props.addressOnChangeHandler, fieldName: props.fieldName }, props)));
    }
    return _jsx(InputTextWrapper, __assign({}, props));
};
export { InputFields };
