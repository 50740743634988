var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { convertSecondsToMilliSeconds } from 'globals/global.functions';
import { API } from 'globals/api';
import { CONFIG_END_POINTS } from 'globals/apiEndPoints';
import { APIMethod } from 'globals/enums';
export function getPortalConfigService(params) {
    return __awaiter(this, void 0, Promise, function () {
        var response, error_1, errorMessage;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
        return __generator(this, function (_q) {
            switch (_q.label) {
                case 0:
                    _q.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, API({
                            url: CONFIG_END_POINTS.GET_CONFIG_DATA,
                            method: APIMethod.Get,
                            params: params,
                        })];
                case 1:
                    response = _q.sent();
                    return [2 /*return*/, {
                            locationResponseTime: parseInt((_b = (_a = response.data) === null || _a === void 0 ? void 0 : _a.locate_me_loader) === null || _b === void 0 ? void 0 : _b.message),
                            uploadUrlInfo: (_d = (_c = response.data) === null || _c === void 0 ? void 0 : _c.portal_info_upload_url) === null || _d === void 0 ? void 0 : _d.message,
                            uploadFileInfo: (_f = (_e = response.data) === null || _e === void 0 ? void 0 : _e.portal_info_upload_file) === null || _f === void 0 ? void 0 : _f.message,
                            resourcesInfo: (_h = (_g = response.data) === null || _g === void 0 ? void 0 : _g.portal_info_resources) === null || _h === void 0 ? void 0 : _h.message,
                            autoSaveTimeOut: convertSecondsToMilliSeconds(Number((_k = (_j = response.data) === null || _j === void 0 ? void 0 : _j.portal_auto_save_timeout) === null || _k === void 0 ? void 0 : _k.message)),
                            passwordExpiryWarningDays: Number((_m = (_l = response.data) === null || _l === void 0 ? void 0 : _l.portal_password_expiry_warning) === null || _m === void 0 ? void 0 : _m.message),
                            passwordExpiryDays: Number((_p = (_o = response.data) === null || _o === void 0 ? void 0 : _o.portal_password_expiry_days) === null || _p === void 0 ? void 0 : _p.message)
                        }];
                case 2:
                    error_1 = _q.sent();
                    errorMessage = error_1.response.data.message;
                    throw new Error(errorMessage);
                case 3: return [2 /*return*/];
            }
        });
    });
}
/**
 * @function getSecretData
 * @description service to fetch the secrets data from aws
 * @param {IGetSecretDataParams} params keys to fetch the data
 */
export var getSecretsDataService = function (params) { return __awaiter(void 0, void 0, Promise, function () {
    var response, error_2, errorMessage;
    var _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _c.trys.push([0, 2, , 3]);
                return [4 /*yield*/, API({
                        url: CONFIG_END_POINTS.GET_SECRET_DATA,
                        method: APIMethod.Get,
                        params: params,
                    })];
            case 1:
                response = _c.sent();
                return [2 /*return*/, {
                        pusherCluster: (_a = response.data) === null || _a === void 0 ? void 0 : _a.PUSHER_CHANNEL_CLUSTER,
                        pusherKey: (_b = response.data) === null || _b === void 0 ? void 0 : _b.PUSHER_CHANNEL_KEY,
                    }];
            case 2:
                error_2 = _c.sent();
                errorMessage = error_2.response.data.message;
                throw new Error(errorMessage);
            case 3: return [2 /*return*/];
        }
    });
}); };
