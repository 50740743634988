import moment from 'moment';
import { DATE_FORMAT, timezoneAbbrsFullname, TIME_FORMAT, } from './global.constants';
import { getTimestamp } from './global.functions';
/**
 * @description get date in utc format
 * @param {date} date required date
 * @returns utc formated date
 */
export var convertDateInUTC = function (date) {
    var currentUTCOffset = moment(date).format('Z');
    var currentDate = moment(date).format('L');
    return moment("".concat(currentDate, " 00:00:00").concat(currentUTCOffset)).format();
};
/**
 * @description check if date is valid or not
 * @param {date} date required date
 * @returns NaN or time in milisenconds
 */
export var validateDate = function (date) {
    return Date.parse(date);
};
/**
 * @description fn to add the value in date
 * @param {date|undefined} date required date
 * @param {number} numberOfdays days to increase by
 * @param {string} type days || months || years
 * @returns updated increased date
 */
export var addDate = function (date, numberOfdays, type) {
    if (!date) {
        return null;
    }
    return moment(date).add(numberOfdays, type);
};
/**
 * @description fn to subtract the value from date
 * @param {date} date required date
 * @param {number} numberOfdays days to decrease by
 * @param {string} type days || months || years
 * @returns updated subtracted date
 */
export var subtractDate = function (date, numberOfdays, type) {
    if (!date) {
        return null;
    }
    return moment(date).subtract(numberOfdays, type);
};
/**
 * @description convert nanosecond to milisecond
 * @returns converted time
 */
export var convertNanoSecondsToMiliSeconds = function (nanoSecond) {
    return nanoSecond / 1000000;
};
/**
 * @description convert millisecond to nanosecond
 * @returns converted time
 */
export var convertMiliSecondsToNanoSeconds = function (nanoSecond) {
    return nanoSecond * 1000000;
};
/**
 * @description convert unix second to nanosecond
 * @returns converted time
 */
export var convertUTCSecondsToUnixNanoSeconds = function (seconds) {
    var unix = moment(seconds).unix();
    return unix * 1000000000;
};
/**
 * @description get client(BROWSER) timezone
 * @returns get client timezone
 */
export var getClientTimezone = function () { return moment.tz.guess(); };
/**
 * @description convert date to senior tz start day
 * @param {*} date
 * @param {string | undefined} timezone
 * @returns Date
 */
export var convertStartDateInUTCTz = function (date, timezone, time) {
    if (time === void 0) { time = '00:00:00'; }
    if (timezone) {
        var currentUTCOffset = moment(date).tz(timezone).format('Z');
        var currentDate = moment(date).format('L');
        return moment("".concat(currentDate, " ").concat(time).concat(currentUTCOffset))
            .tz(timezone)
            .format();
    }
    return '';
};
/**
 * @description convert date to senior tz end day
 * @param {*} date
 * @param {string | undefined } timezone
 * @returns Date
 */
export var convertEndDateInUTCTz = function (date, timezone, endTime) {
    if (endTime === void 0) { endTime = '23:59:59'; }
    if (timezone) {
        var currentUTCOffset = moment(date).tz(timezone).format('Z');
        var currentDate = moment(date).format('L');
        return moment("".concat(currentDate, " ").concat(endTime).concat(currentUTCOffset))
            .tz(timezone)
            .format();
    }
    return '';
};
/**
 * @description get date and time both
 * @param {*} date
 * @returns date and time
 */
export var getFormatedDateTime = function (date) {
    return moment(date).format("".concat(DATE_FORMAT, " ").concat(TIME_FORMAT));
};
/**
 * @description get date
 * @function getFormattedDate
 * @param {*} date
 * @returns date
 */
export var getFormattedDate = function (date, format) {
    if (format === void 0) { format = DATE_FORMAT; }
    if (!date)
        return '';
    var len = date.toString().length;
    var dt = parseInt(date);
    if (len === 19) {
        return moment(getTimestamp(dt)).format("".concat(format));
    }
    return moment(dt).format("".concat(format));
};
/**
 * @description function to get timezone long name
 * @function getTimezoneFullAbbr
 * @param {string} timezone
 * @returns {string} timezone full name
 */
export var getTimezoneFullAbbr = function (timezone) {
    var timezoneAbbrv = moment.tz([2012, 0], timezone).format('z');
    return "".concat(timezoneAbbrsFullname[timezoneAbbrv], "(").concat(timezoneAbbrv, ")");
};
/**
 * @description function to get timezone name
 * @function getTimezoneName
 * @param {string} timezone
 * @returns {string} timezone full name
 */
export var getTimezoneName = function (timezone) {
    var timezoneAbbrv = moment.tz([2012, 0], timezone).format('z');
    return "".concat(timezoneAbbrsFullname[timezoneAbbrv]);
};
/**
 * @description function to get summaryList array
 * @function summaryDetails
 * @param {array} summaryList
 * @returns {array} week summary details
 */
export var summaryDetails = function (summaryList) {
    var dateArr = [];
    summaryList.forEach(function (list, index) {
        list.forEach(function (item) {
            var sleepStart = item.time;
            var sleepEnd = item.end_timestamp;
            var min = parseFloat(moment(getTimestamp(sleepStart)).format('H.mm'));
            var max = parseFloat(moment(getTimestamp(sleepEnd)).format('H.mm'));
            if (min > max) {
                if (index != 0) {
                    dateArr.push({
                        x: moment(getTimestamp(item.senior_day))
                            .subtract(1, 'days')
                            .format('ddd'),
                        y0: min,
                        y: 24,
                    });
                }
                if (summaryList.length > 1 &&
                    summaryList.length - 1 == index &&
                    moment(getTimestamp(item.senior_day)).format('ddd') == 'Sun') {
                    //Do Nothing
                }
                else {
                    dateArr.push({
                        x: moment(getTimestamp(item.senior_day)).format('ddd'),
                        y0: 0,
                        y: max,
                    });
                }
            }
            else {
                var onlySunday = dateArr.every(function (value) { return value.x == 'Sun'; });
                if (moment(getTimestamp(item.senior_day)).format('ddd') != 'Sun' ||
                    onlySunday) {
                    dateArr.push({
                        x: moment(getTimestamp(item.senior_day)).format('ddd'),
                        y0: min,
                        y: max,
                    });
                }
            }
        });
    });
    return dateArr;
};
/**
 * @description fn to get the password reset warning
 * @function getCurrentWeek
 * @param  {number} endTimeT selected date
 * @returns {object} startTime, endTime
 */
export var getCurrentWeek = function (endTimeT) {
    var date = moment(convertNanoSecondsToMiliSeconds(endTimeT));
    var startTime = convertMiliSecondsToNanoSeconds(moment(date).clone().startOf('week').format('x'));
    var endTime = convertMiliSecondsToNanoSeconds(moment(date).clone().endOf('week').format('x'));
    return { startTime: startTime, endTime: endTime };
};
/** @description fn to get the password reset warning
 * @function getPasswordResetWarning
 * @param  {string} passwordCreatedDate
 * @returns {boolean}
 */
export var getPasswordResetWarning = function (passwordCreatedDate, passwordExpiryWarningDays) {
    var currentDate = moment();
    return (currentDate.diff(passwordCreatedDate, 'days') >= passwordExpiryWarningDays);
};
/**
 * @description fn to get the user password expiry date
 * @function getPasswordExpiryDate
 * @param {string} passwordCreatedDate
 * @param {number} passwordExpiryDays
 * @returns {string} date
 */
export var getPasswordExpiryDate = function (passwordCreatedDate, passwordExpiryDays) {
    return moment(passwordCreatedDate)
        .add(passwordExpiryDays, 'days')
        .format(DATE_FORMAT);
};
/**
 * @description method to get the difference between two dates
 * @param a {string date}
 * @param b {string date}
 * @returns string
 */
export var getDateDifference = function (a, b) {
    var aDate = moment(a);
    var bDate = moment(b);
    return aDate.diff(bDate, 'days');
};
