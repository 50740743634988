import { ACTIVITY_CARD_VARIANT } from 'globals/enums';
import { makeStyles } from 'tss-react/mui';
var getActivityTitleColor = function (variant, theme) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    switch (variant) {
        case ACTIVITY_CARD_VARIANT.ACTIVITY:
            return (_b = (_a = theme.palette) === null || _a === void 0 ? void 0 : _a.customColor) === null || _b === void 0 ? void 0 : _b.remGreen;
        case ACTIVITY_CARD_VARIANT.MODERATE:
            return (_d = (_c = theme.palette) === null || _c === void 0 ? void 0 : _c.customColor) === null || _d === void 0 ? void 0 : _d.moderateGreen;
        case ACTIVITY_CARD_VARIANT.INTENSE:
            return (_f = (_e = theme.palette) === null || _e === void 0 ? void 0 : _e.customColor) === null || _f === void 0 ? void 0 : _f.intenseGreen;
        default:
            return (_h = (_g = theme.palette) === null || _g === void 0 ? void 0 : _g.customColor) === null || _h === void 0 ? void 0 : _h.black;
    }
};
export var activityCardStyles = makeStyles()(function (theme, _a) {
    var _b, _c, _d, _e, _f, _g, _h, _j;
    var variant = _a.variant;
    return ({
        activityCard: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '16px',
            padding: '8px 16px',
            borderRadius: '8px',
            border: "solid 2px ".concat((_c = (_b = theme.palette) === null || _b === void 0 ? void 0 : _b.customColor) === null || _c === void 0 ? void 0 : _c.borderGrey),
        },
        percentageValue: {
            color: (_e = (_d = theme.palette) === null || _d === void 0 ? void 0 : _d.customColor) === null || _e === void 0 ? void 0 : _e.primaryGreen,
            fontSize: '40px',
            display: 'flex',
            alignItems: 'center',
            '& > span': {
                fontSize: '20px',
                marginLeft: '2px',
                color: (_g = (_f = theme.palette) === null || _f === void 0 ? void 0 : _f.customColor) === null || _g === void 0 ? void 0 : _g.lighterBlack,
            },
        },
        title: {
            color: getActivityTitleColor(variant, theme),
            fontWeight: '600',
            fontSize: '20px',
        },
        goal: {
            color: (_j = (_h = theme.palette) === null || _h === void 0 ? void 0 : _h.customColor) === null || _j === void 0 ? void 0 : _j.lighterBlack,
            fontSize: '19px',
        },
    });
});
