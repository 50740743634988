var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { autoCompleteSearchStyle } from './AutoComplete.style';
var AutoComplete = function (_a) {
    var options = _a.options, renderOption = _a.renderOption, open = _a.open, onOpen = _a.onOpen, onClose = _a.onClose, id = _a.id, filterOptions = _a.filterOptions, value = _a.value, onChange = _a.onChange, onInputChange = _a.onInputChange, loading = _a.loading, _b = _a.disabled, disabled = _b === void 0 ? false : _b, getOptionDisabled = _a.getOptionDisabled, placeholder = _a.placeholder, _c = _a.getOptionLabel, getOptionLabel = _c === void 0 ? function (option) { return option.fullName; } : _c, _d = _a.isOptionEqualToValue, isOptionEqualToValue = _d === void 0 ? function (option, value) { return option === value; } : _d, _e = _a.isOldStyle, isOldStyle = _e === void 0 ? false : _e, _f = _a.error, error = _f === void 0 ? false : _f, _g = _a.isFixedWidth, isFixedWidth = _g === void 0 ? true : _g;
    var className = autoCompleteSearchStyle({ isOldStyle: isOldStyle, error: error }).classes;
    return (_jsx(Autocomplete, { id: id, onFocus: function () {
            var b = document.body;
        }, onBlur: function () {
            var b = document.body;
            b.style.overflow = 'auto';
        }, classes: __assign({}, className), blurOnSelect: true, autoHighlight: true, options: options, sx: isFixedWidth ? { width: 300 } : {}, open: open, value: value, onChange: onChange, onOpen: onOpen, onClose: onClose, filterOptions: filterOptions, getOptionLabel: getOptionLabel, renderOption: renderOption, onInputChange: onInputChange, autoComplete: true, disablePortal: true, disableClearable: true, loading: loading, disabled: disabled, getOptionDisabled: getOptionDisabled, renderInput: function (params) { return (_jsx(TextField, __assign({ variant: 'standard' }, params, { InputProps: __assign(__assign({}, params.InputProps), { disableUnderline: true, endAdornment: (_jsxs(React.Fragment, { children: [loading ? (_jsx(CircularProgress, { color: 'inherit', size: 20 })) : null, params.InputProps.endAdornment] })) }), inputProps: __assign(__assign({}, params.inputProps), { classes: { icon: 'red' }, placeholder: placeholder }) }))); }, ListboxProps: {
            role: 'list-box', // prop to maintain the scroll position when loading more data
        }, isOptionEqualToValue: isOptionEqualToValue }));
};
export default AutoComplete;
