import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTheme } from '@mui/material';
import { roundOff } from 'globals/global.functions';
export var HeartRespirationTooltip = function (props) {
    var theme = useTheme();
    var x = props.x, datum = props.datum, height = props.height, data = props.data, data2 = props.data2;
    var width = 70;
    var date = datum.x;
    var actualRate = 0;
    var min = 0;
    var max = 0;
    if (data.length) {
        data === null || data === void 0 ? void 0 : data.forEach(function (val) {
            if (val.x == date) {
                actualRate = val.y;
            }
        });
    }
    if (data2.length) {
        data2 === null || data2 === void 0 ? void 0 : data2.forEach(function (val) {
            if (val.x == date) {
                min = val.y;
                max = val.y0;
            }
        });
    }
    return (_jsxs("g", { transform: "translate(".concat(x - 35, ", 0)"), "data-testid": 'heart-respiration-tooltip', children: [_jsxs("g", { transform: 'translate(0, 0)', children: [_jsx("rect", { height: 60, width: width, fill: theme.palette.background.green, ry: 3, rx: 3 }), _jsx("text", { dx: width / 2, dy: 15, alignmentBaseline: 'middle', textAnchor: 'middle', fontSize: 14, fill: theme.palette.common.black, children: roundOff(actualRate) }), _jsxs("text", { dx: width / 2, dy: 32, alignmentBaseline: 'middle', textAnchor: 'middle', fontSize: 12, fill: theme.palette.common.black, children: ["HIGH ", max] }), _jsxs("text", { dx: width / 2, dy: 47, alignmentBaseline: 'middle', textAnchor: 'middle', fontSize: 12, fill: theme.palette.common.black, children: ["LOW ", min] })] }), _jsxs("g", { transform: "translate(".concat(width / 2, ", 0)"), children: [_jsx("line", { y1: height - 50, y2: 60, stroke: theme.palette.customColor.strokegrey, strokeWidth: 1 }), _jsx("polygon", { points: '0,70 -6,60 8,60', fill: theme.palette.background.green, stroke: theme.palette.background.green, strokeWidth: '1' })] })] }, 'tooltip'));
};
