/**
 * @class FacilityModel
 * @description class to maintain the facility model
 */
var FacilityModel = /** @class */ (function () {
    function FacilityModel(id, name, facilityType, corporateId, corporateName, dateModified, addressStreet, addressCity, addressState, addressPostalCode, totalResidents, phone) {
        this.id = id;
        this.name = name;
        this.dateModified = dateModified;
        this.addressStreet = addressStreet;
        this.addressCity = addressCity;
        this.addressState = addressState;
        this.addressPostalCode = addressPostalCode;
        this.corporateName = corporateName;
        this.corporateId = corporateId;
        this.facilityType = facilityType;
        this.totalResidents = totalResidents;
        this.phone = phone;
    }
    return FacilityModel;
}());
export default FacilityModel;
