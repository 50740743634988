import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { VictoryLabel, VictoryGroup, VictoryArea, VictoryChart, VictoryAxis, VictoryVoronoiContainer, } from 'victory';
import moment from 'moment-timezone';
import { getHourMin } from 'globals/global.functions';
import { useTheme } from '@mui/material';
var getInHours = function (value) {
    var formatValue = moment.duration(value, 'seconds');
    return Math.floor(formatValue.asHours());
};
var SleepDurationGraph = function (_a) {
    var sleepMonthGraph = _a.sleepMonthGraph;
    var theme = useTheme();
    var maxTotalTimeInBed = Math.max.apply(Math, sleepMonthGraph.totalTimeInBed.map(function (i) { return i.y; }));
    return (_jsxs(VictoryChart, { width: 600, domainPadding: { x: 20 }, containerComponent: _jsx(VictoryVoronoiContainer, { activateLabels: true, labels: function (_a) {
                var datum = _a.datum;
                return "".concat(datum._x, " ").concat(datum._y);
            }, labelComponent: _jsx(Tooltip, { totalTimeInBed: sleepMonthGraph.totalTimeInBed, totalSleepTime: sleepMonthGraph.totalSleepTime }) }), children: [_jsxs("defs", { children: [_jsx("linearGradient", { id: 'grad', x1: '1', y1: '1', x2: '1', y2: '0', children: _jsx("stop", { offset: '1', stopColor: theme.palette.customColor.remGreen, stopOpacity: '1' }) }), _jsxs("linearGradient", { id: 'grad2', x1: '1', y1: '1', x2: '1', y2: '0', children: [_jsx("stop", { offset: '0', stopColor: 'white', stopOpacity: '1' }), _jsx("stop", { offset: '1', stopColor: theme.palette.customColor.intenseGreen, stopOpacity: '1' })] })] }), _jsxs(VictoryGroup, { colorScale: [
                    theme.palette.customColor.remGreen,
                    theme.palette.customColor.intenseGreen,
                ], domain: {
                    y: [1000, maxTotalTimeInBed + 10000],
                }, children: [_jsx(VictoryArea, { style: {
                            data: { fill: 'url(#grad)' },
                        }, data: sleepMonthGraph.totalTimeInBed }), _jsx(VictoryArea, { style: {
                            data: { fill: 'url(#grad2)' },
                        }, data: sleepMonthGraph.totalSleepTime })] }), _jsx(VictoryAxis, { dependentAxis: true, orientation: 'right', label: 'Hrs', axisLabelComponent: _jsx(VictoryLabel, { angle: 0, dy: -130, dx: -2, style: { fontSize: 18 } }), tickFormat: function (t) { return getInHours(t); }, style: {
                    axis: { stroke: 'transparent' },
                    tickLabels: {
                        fontSize: 18,
                        color: theme.palette.common.black,
                    },
                } }), _jsx(VictoryAxis, { orientation: 'bottom', width: 600, tickFormat: function (t, index) {
                    var _a;
                    if (index === 28 && ((_a = sleepMonthGraph === null || sleepMonthGraph === void 0 ? void 0 : sleepMonthGraph.tickValues) === null || _a === void 0 ? void 0 : _a.length) === 29) {
                        return '';
                    }
                    else {
                        return index % 7 == 0 ? t : '';
                    }
                }, style: {
                    axis: { stroke: 'transparent' },
                    tickLabels: {
                        fontSize: 18,
                        color: theme.palette.common.black,
                    },
                } })] }));
};
var Tooltip = function (props) {
    var theme = useTheme();
    var x = props.x, datum = props.datum, height = props.height;
    var width = 140;
    var getTimeInBed = props.totalTimeInBed.find(function (item) { return item.x == datum.x; });
    var getTotalSleepTime = props.totalSleepTime.find(function (item) { return item.x == datum.x; });
    return (_jsxs("g", { transform: "translate(".concat(x - 70, ", 0)"), children: [_jsxs("g", { transform: 'translate(0, 0)', children: [_jsx("rect", { height: 76, width: width, stroke: theme.palette.customColor.strokeBlack, fill: theme.palette.common.white, ry: 3, rx: 3 }), _jsx("text", { dx: width / 2, dy: 15, alignmentBaseline: 'middle', textAnchor: 'middle', fontSize: 14, fill: theme.palette.customColor.info, children: datum.z }), _jsx("circle", { cy: 35, cx: 10, r: 5, stroke: 'white', strokeWidth: 1, fill: theme.palette.customColor.remGreen }), _jsxs("text", { dx: width / 1.9, dy: 39, alignmentBaseline: 'middle', textAnchor: 'middle', fontSize: 12, fill: theme.palette.common.black, children: [_jsx("tspan", { fill: theme.palette.customColor.info, children: "In Bed" }), ' ', _jsx("tspan", { children: getHourMin(getTimeInBed.y) })] }), _jsx("circle", { cy: 57, cx: 10, r: 5, stroke: 'white', strokeWidth: 1, fill: theme.palette.customColor.intenseGreen }), _jsxs("text", { dx: width / 1.9, dy: 60, alignmentBaseline: 'middle', textAnchor: 'middle', fontSize: 12, fill: theme.palette.common.black, children: [_jsx("tspan", { fill: theme.palette.customColor.info, children: "Asleep" }), ' ', _jsx("tspan", { children: getHourMin(getTotalSleepTime.y) })] })] }), _jsxs("g", { transform: "translate(".concat(width / 2, ", 0)"), children: [_jsx("line", { y1: height - 50, y2: 76, stroke: 'grey', strokeWidth: 2 }), _jsx("polygon", { points: '0,85 -6,76 8,76', fill: theme.palette.common.white, stroke: theme.palette.customColor.strokeBlack, strokeWidth: '1' })] })] }, 'tooltip'));
};
export { SleepDurationGraph };
