var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { API } from 'globals/api';
import { UCLA_LONELINESS_ASSESSMENT_END_POINTS } from 'globals/apiEndPoints';
export function getUCLALonelinessAssessmentAdminService() {
    return __awaiter(this, void 0, Promise, function () {
        var response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, API({
                            url: "".concat(UCLA_LONELINESS_ASSESSMENT_END_POINTS.GET_UCLA_LONELINESS_ASSESSMENT_ADMIN),
                            method: 'get',
                        })];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, mapUCLALonelinessAssessmentPayload(response === null || response === void 0 ? void 0 : response.data)];
                case 2:
                    error_1 = _a.sent();
                    return [2 /*return*/, error_1];
                case 3: return [2 /*return*/];
            }
        });
    });
}
export function getUCLALonelinessAssessmentHistoryAdminService(params) {
    return __awaiter(this, void 0, Promise, function () {
        var historyResponse, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, API({
                            url: "".concat(UCLA_LONELINESS_ASSESSMENT_END_POINTS.GET_UCLA_LONELINESS_ASSESSMENT_HISTORY_ADMIN),
                            method: 'get',
                            params: params
                        })];
                case 1:
                    historyResponse = _a.sent();
                    return [2 /*return*/, mapHolisticAssessmentHistoryPayload(historyResponse.data)];
                case 2:
                    error_2 = _a.sent();
                    return [2 /*return*/, error_2];
                case 3: return [2 /*return*/];
            }
        });
    });
}
export function postUCLALonelinessAssessmentAdminService(param) {
    return __awaiter(this, void 0, Promise, function () {
        var error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, API({
                            url: "".concat(UCLA_LONELINESS_ASSESSMENT_END_POINTS.POST_UCLA_LONELINESS_ASSESSMENT_ADMIN),
                            method: 'post',
                            data: param,
                        })];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    error_3 = _a.sent();
                    console.error(error_3);
                    throw error_3;
                case 3: return [2 /*return*/];
            }
        });
    });
}
var mapUCLALonelinessAssessmentPayload = function (response) {
    if (!response.form) {
        // If the form data is empty, return a specific value to indicate empty data
        return { emptyData: true };
    }
    var formData = response === null || response === void 0 ? void 0 : response.form;
    return {
        survey: formData,
        versionNumber: response === null || response === void 0 ? void 0 : response.version_number,
        formId: response === null || response === void 0 ? void 0 : response.form_id,
        formStatus1: response === null || response === void 0 ? void 0 : response.form_status,
        publishDateTime: response === null || response === void 0 ? void 0 : response.publish_date_time,
    };
};
var mapHolisticAssessmentHistoryPayload = function (response) {
    var formData = response === null || response === void 0 ? void 0 : response.forms.map(function (data) {
        return createPreviousHistoryData(data);
    });
    return { data: formData, lastEvaluatedKey: response.last_evaluated_key };
};
var createPreviousHistoryData = function (data) { return ({
    versionNumber: data.version_number,
    formStatus1: data.form_status,
    createdDateAndTime: data.created_date,
    publishedDateAndTime: data.publish_date_time,
    adminName: data.updated_by_name,
    form: data.form,
}); };
