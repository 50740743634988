var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { API } from 'globals/api';
import PusherAPI from './pusherAPI';
import { PUSHER_END_POINTS } from 'globals/apiEndPoints';
var privateChannel = 'private';
export var channelData = Object.freeze({
    alert: {
        channelName: "".concat(privateChannel, "-alert"),
        events: {
            trigger: 'trigger',
            approveAlert: 'approve-alert',
            alertActionNotification: 'alert-action-notification',
        },
    },
    summary: {
        channelName: "".concat(privateChannel, "-summary"),
        events: {
            approveSummary: 'approve-summary',
            summaryActionNotification: 'summary-action-notification',
            milestoneNotification: 'milestone',
            currentLocationData: 'trace_completed',
            facilitySummaryActionNotification: 'facility-summary-action-notification',
        },
    },
    alarm: {
        channelName: "".concat(privateChannel, "-sos-fall-channel"),
        events: {
            sosFallEvent: 'sos-fall-event',
        },
    },
});
/**
 * @class PusherApp
 * @extends PusherAPI
 * @description class to access pusher API's and events subscribe and unsubscribe methods.
 */
var PusherApp = /** @class */ (function (_super) {
    __extends(PusherApp, _super);
    function PusherApp() {
        var _this = _super.call(this) || this;
        if (PusherApp.instance instanceof PusherApp) {
            return PusherApp.instance;
        }
        PusherApp.instance = _this;
        return _this;
    }
    /**
     * @function establishConnection
     * @description method to connect the application with pusher.
     */
    PusherApp.prototype.establishConnection = function (pusherKey, pusherCluster) {
        /*Pusher key and cluster from env variables*/
        var vimientPusherAppKey = pusherKey || '';
        var APP_CLUSTER = pusherCluster || '';
        /**
         * @function authorizer
         * @description authorizer method to authenticate the pusher private channel connection.
         * @param channel channel data get from pusher.
         * @returns authentication callback to pusher.
         */
        var authorizer = function (channel) {
            return {
                authorize: function (socketId, callback) {
                    API({
                        url: PUSHER_END_POINTS.AUTH,
                        method: 'GET',
                        headers: new Headers({ 'Content-Type': 'application/json' }),
                        params: {
                            socket_id: socketId,
                            channel_name: channel.name,
                        },
                    })
                        .then(function (data) {
                        callback(null, data.data);
                    })
                        .catch(function (err) {
                        callback(new Error("Error calling auth endpoint: ".concat(err)), {
                            auth: '',
                        });
                    });
                },
            };
        };
        /* Pusher API pusherConnect to connect with pusher */
        this.pusherConnect(vimientPusherAppKey, {
            cluster: APP_CLUSTER,
            authorizer: authorizer,
        });
    };
    /**
     * @function unsubscribeEventAlerts
     * @description method to unsubscribe Alert channel.
     */
    PusherApp.prototype.unsubscribeAppChannel = function () {
        /* Pusher API method to unsubscribe channel */
        this.unsubscribeChannel(channelData.alert.channelName);
    };
    /**
     * @function unsubscribeAllEvent
     * @description method to unsubscribe all the channels at once.
     */
    PusherApp.prototype.unsubscribeAllEvent = function () {
        this.unsubscribeAppChannel();
    };
    return PusherApp;
}(PusherAPI));
export default PusherApp;
