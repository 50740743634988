/**
 * @function ShowHyphen
 * @description component to show hyphen
 * @returns JSX
 */
var ShowHyphen = function (_a) {
    var children = _a.children, value = _a.value, withValue = _a.withValue;
    if (withValue) {
        if (!value) {
            return '-';
        }
    }
    if (children) {
        return children;
    }
    return '-';
};
export default ShowHyphen;
