var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import moment from 'moment-timezone';
import { Box } from '@mui/material';
import { DATE_FORMAT, INVALID_DATE } from 'globals/global.constants';
import { getCurrentSenior } from 'globals/global.functions';
import { InputFields } from 'common/InputFields';
import ControlledDatePicker from 'common/ControlledDatePicker/ControlledDatePicker';
import { getSeniorCalenderDates, getSeniorLocation, } from 'store/commonReducer/common.action';
import { useTheme } from '@emotion/react';
var LocationDateSelector = function (_a) {
    var coordinates = _a.coordinates;
    var theme = useTheme();
    var dispatch = useAppDispatch();
    var timezone = getCurrentSenior().timezone;
    var allEnableDatesData = useAppSelector(function (state) { return state.common.seniorLocation.calenderDates; });
    var seniorActualMonth = moment().tz(timezone).startOf('month');
    var _b = React.useState(seniorActualMonth), selectedMonth = _b[0], setSelectedMonth = _b[1];
    var _c = React.useState(moment().tz(timezone)), selectedDay = _c[0], handleDayChange = _c[1];
    var _d = React.useState(0), currentHours = _d[0], setCurrentHours = _d[1];
    var _e = React.useState(), enableDates = _e[0], setEnableDates = _e[1];
    var _f = React.useState(false), isCalenderOpen = _f[0], setIsCalenderOpen = _f[1];
    var _g = React.useState(true), isFirstTimeRender = _g[0], setIsFirstTimeRender = _g[1];
    var _h = React.useState(false), disablePrevArrow = _h[0], setDisablePrevArrow = _h[1];
    var _j = React.useState(false), disableNextArrow = _j[0], setDisableNextArrow = _j[1];
    React.useEffect(function () {
        dispatch(getSeniorCalenderDates(selectedDay));
    }, []);
    React.useEffect(function () {
        if (isFirstTimeRender) {
            var enableMonthData = allEnableDatesData[selectedMonth.format('MM-YYYY')];
            var calenderDate = enableMonthData === null || enableMonthData === void 0 ? void 0 : enableMonthData.calenderDates;
            setEnableDates(calenderDate);
        }
    }, [allEnableDatesData]);
    React.useEffect(function () {
        if (enableDates && !isCalenderOpen && isFirstTimeRender) {
            setIsFirstTimeRender(false);
            if (enableDates.length == 0) {
                var lastRecordedDate = getLastRecordedDate();
                if (lastRecordedDate) {
                    handleDayChange(moment(lastRecordedDate));
                    setSelectedMonth(moment(lastRecordedDate));
                }
            }
            else {
                handleDayChange(moment.utc(enableDates[enableDates.length - 1]));
            }
        }
    }, [enableDates]);
    React.useEffect(function () {
        fetchCalenderDates();
    }, [selectedMonth]);
    var fetchCalenderDates = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data, enableMonthData, calenderDate;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!!Object.keys(allEnableDatesData).includes(selectedMonth.format('MM-YYYY'))) return [3 /*break*/, 2];
                    return [4 /*yield*/, dispatch(getSeniorCalenderDates(selectedMonth))];
                case 1:
                    data = _a.sent();
                    setEnableDates(data);
                    return [3 /*break*/, 3];
                case 2:
                    enableMonthData = allEnableDatesData[selectedMonth.format('MM-YYYY')];
                    calenderDate = enableMonthData === null || enableMonthData === void 0 ? void 0 : enableMonthData.calenderDates;
                    setEnableDates(calenderDate);
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    React.useEffect(function () {
        if (coordinates.latitude) {
            var time = void 0, endTime = '';
            if (selectedDay.format(DATE_FORMAT) ==
                moment().tz(timezone).format(DATE_FORMAT)) {
                time = moment().tz(timezone).subtract({
                    hours: currentHours,
                    minutes: 59,
                    seconds: 59,
                });
                if (time.format(DATE_FORMAT) !== moment().tz(timezone).format(DATE_FORMAT)) {
                    time = moment(selectedDay)
                        .endOf('day')
                        .subtract({ hours: 23, minutes: 59, seconds: 59 });
                }
                endTime = moment().tz(timezone).format('HH:mm:ss');
            }
            else {
                time = moment(selectedDay)
                    .endOf('day')
                    .subtract({ hours: currentHours, minutes: 59, seconds: 59 });
                endTime = '23:59:59';
            }
            var startTime = time.format('HH:mm:ss');
            dispatch(getSeniorLocation({
                isHistory: true,
                date: selectedDay,
                startTime: startTime,
                endTime: endTime,
            }));
        }
    }, [coordinates, selectedDay, currentHours]);
    var navigatePrevDay = function () {
        setCurrentHours(0);
        setDisablePrevArrow(false);
        setDisableNextArrow(false);
        var prevEnableDateIndex = checkEnableDate(selectedDay);
        if (prevEnableDateIndex == 0) {
            var lastRecordedDate = getLastRecordedDate();
            if (lastRecordedDate) {
                handleDayChange(moment(lastRecordedDate));
                setSelectedMonth(moment(lastRecordedDate));
            }
            else {
                setDisablePrevArrow(true);
            }
        }
        else {
            var prevEnableDate = enableDates[prevEnableDateIndex - 1];
            handleDayChange(moment(prevEnableDate));
        }
        // }
    };
    var checkEnableDate = function (date) {
        var index = enableDates === null || enableDates === void 0 ? void 0 : enableDates.findIndex(function (x) { return x == date.format(DATE_FORMAT); });
        return index;
    };
    var navigateNextDay = function () {
        setCurrentHours(0);
        setDisablePrevArrow(false);
        setDisableNextArrow(false);
        var nextEnableDateIndex = checkEnableDate(selectedDay);
        if (nextEnableDateIndex == enableDates.length - 1) {
            var nextRecordedDate = getNextRecordedDate();
            if (nextRecordedDate) {
                handleDayChange(moment(nextRecordedDate));
                setSelectedMonth(moment(nextRecordedDate));
            }
            else {
                setDisableNextArrow(true);
            }
        }
        else {
            var nextEnableDate = enableDates[nextEnableDateIndex + 1];
            if (moment(selectedDay).format('L') == moment().format('L')) {
                return false;
            }
            handleDayChange(moment(nextEnableDate));
        }
    };
    var handleEnableDates = function (currentDate) {
        var _a;
        if (isCalenderOpen) {
            var enableMonthData = allEnableDatesData[selectedMonth.format('MM-YYYY')];
            return !((_a = enableMonthData === null || enableMonthData === void 0 ? void 0 : enableMonthData.calenderDates) === null || _a === void 0 ? void 0 : _a.find(function (disableDate) {
                return disableDate == moment(currentDate).format(DATE_FORMAT);
            }));
        }
    };
    var handleHour = function (evnt) {
        setCurrentHours(evnt.target.value);
    };
    var handleMonthChange = function (month) {
        setSelectedMonth(month);
    };
    var getLastRecordedDate = function () {
        var enableMonthData = allEnableDatesData[selectedMonth.format('MM-YYYY')];
        return enableMonthData === null || enableMonthData === void 0 ? void 0 : enableMonthData.lastRecordedDate;
    };
    var getNextRecordedDate = function () {
        var enableMonthData = allEnableDatesData[selectedMonth.format('MM-YYYY')];
        return enableMonthData === null || enableMonthData === void 0 ? void 0 : enableMonthData.nextRecordedDate;
    };
    return (_jsxs(_Fragment, { children: [_jsx(Box, { mt: 2, mr: 1, "data-testid": 'locationDateSelector', children: _jsx(InputFields, { menu: true, menuItems: [
                        { value: 0, label: '1:00 h' },
                        { value: 1, label: '2:00 h' },
                        { value: 3, label: '4:00 h' },
                        { value: 7, label: '8:00 h' },
                        { value: 15, label: '16:00 h' },
                        { value: 23, label: '24:00 h' },
                    ], inputProps: {
                        name: 'duration',
                        dataTestid: 'duration',
                    }, eventProps: {
                        value: currentHours,
                        onChange: handleHour,
                        sx: {
                            '&.MuiInputBase-root': {
                                backgroundColor: theme.palette.common.white,
                                border: "1px solid ".concat(theme.palette.customColor.borderGrey),
                                borderRadius: '8px',
                                boxShadow: 'none',
                                '& > div': {
                                    backgroundColor: "".concat(theme.palette.common.white, "!important"),
                                },
                                '& > svg > path': {
                                    fill: theme.palette.customColor.primaryGreen,
                                },
                            },
                        },
                    } }) }), _jsx(Box, { display: 'flex', alignItems: 'center', "data-testid": 'locationDateSelector-datePicker', children: _jsx(ControlledDatePicker, { value: selectedDay || null, id: 'dateFrom', variant: 'inline', autoOk: true, disableToolbar: true, disableFuture: true, format: DATE_FORMAT, placeholder: DATE_FORMAT, invalidDateMessage: INVALID_DATE, inputProps: { readOnly: true }, onPrev: navigatePrevDay, onNext: navigateNextDay, onOpen: function () {
                        setSelectedMonth(selectedDay);
                        setIsCalenderOpen(true);
                    }, onClose: function () {
                        setIsCalenderOpen(false);
                    }, onChange: function (day) {
                        handleDayChange(moment(day));
                    }, onAccept: function (day) {
                        setCurrentHours(0);
                        var enableMonthData = allEnableDatesData[moment(day).format('MM-YYYY')];
                        var calenderDate = enableMonthData === null || enableMonthData === void 0 ? void 0 : enableMonthData.calenderDates;
                        setSelectedMonth(moment(day));
                        setEnableDates(calenderDate);
                        setDisablePrevArrow(false);
                        setDisableNextArrow(false);
                    }, onMonthChange: function (month) {
                        handleMonthChange(moment(month));
                    }, shouldDisableDate: function (currentDate) {
                        return handleEnableDates(moment(currentDate));
                    }, style: {
                        backgroundColor: theme.palette.common.white,
                        height: 38.81,
                    }, disablePrevArrow: disablePrevArrow, disableNextArrow: disableNextArrow }) })] }));
};
export default LocationDateSelector;
