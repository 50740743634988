var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { RESET_CI_RANGE_MILESTONES, GET_COMPLETED_CI_RANGE_MILESTONES_SUCCESS, GET_OPEN_CI_RANGE_MILESTONES_SUCCESS, GET_OPEN_CI_RANGE_MILESTONES_FAIL, GET_COMPLETED_CI_RANGE_MILESTONES_FAIL, UPDATE_OPEN_CI_RANGE_MILESTONES_PAGE_NUMBER, UPDATE_COMPLETED_CI_RANGE_MILESTONES_PAGE_NUMBER, } from './CIRangeMilestones.action';
import { initialCIRangeMilestonesStates, } from './CIRangeMilestones.types';
/**
 * @description admin cIRangeMilestones reducer
 * @returns care insight range milestones states
 */
export var cIRangeMilestonesReducer = function (state, action) {
    if (state === void 0) { state = initialCIRangeMilestonesStates; }
    switch (action.type) {
        case UPDATE_OPEN_CI_RANGE_MILESTONES_PAGE_NUMBER: {
            return __assign(__assign({}, state), { open: __assign(__assign({}, state.open), { currentPage: action.payload }) });
        }
        case UPDATE_COMPLETED_CI_RANGE_MILESTONES_PAGE_NUMBER: {
            return __assign(__assign({}, state), { completed: __assign(__assign({}, state.completed), { currentPage: action.payload }) });
        }
        case GET_OPEN_CI_RANGE_MILESTONES_SUCCESS: {
            return __assign(__assign({}, state), { open: __assign(__assign({}, state.open), { data: __spreadArray([], action.payload.data, true), lastEvaluatedKey: action.payload.lastEvaluatedKey, loading: false }) });
        }
        case GET_COMPLETED_CI_RANGE_MILESTONES_SUCCESS: {
            return __assign(__assign({}, state), { completed: __assign(__assign({}, state.completed), { data: __spreadArray([], action.payload.data, true), lastEvaluatedKey: action.payload.lastEvaluatedKey, loading: false }) });
        }
        case GET_OPEN_CI_RANGE_MILESTONES_FAIL: {
            return __assign(__assign({}, state), { open: __assign(__assign({}, state.open), { loading: false }) });
        }
        case GET_COMPLETED_CI_RANGE_MILESTONES_FAIL: {
            return __assign(__assign({}, state), { completed: __assign(__assign({}, state.completed), { loading: false }) });
        }
        case RESET_CI_RANGE_MILESTONES: {
            return __assign({}, initialCIRangeMilestonesStates);
        }
        default: {
            return state;
        }
    }
};
