var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { GET_WELLNESS_PLAN, RESET_WELLNESS_PLAN, } from './SeniorWellnessPlan.action';
export var INITIAL_STATE = {
    wellnessPlanDetail: {
        situation: { error: false, errorText: '', value: '' },
        background: { error: false, errorText: '', value: '' },
        assessment: { error: false, errorText: '', value: '' },
        recommendation: { error: false, errorText: '', value: '' },
    },
    memberPriority: [
        {
            seq: 1,
            value: '',
            error: false,
            errorText: '',
        },
    ],
    challenges: [
        {
            seq: 1,
            value: '',
            error: false,
            errorText: '',
        },
    ],
    dateStarted: '',
    careagentId: '',
    createdDate: '',
    currentVersion: '',
    lastUpdatedBy: '',
    lastUpdatedDate: '',
    lastVersion: '',
    modificationDate: '',
    seniorId: '',
    seniorName: '',
    lastAvailableVersion: 0,
    isLatestVersion: true,
};
export var wellnessPlanReducer = function (state, action) {
    if (state === void 0) { state = INITIAL_STATE; }
    switch (action.type) {
        case GET_WELLNESS_PLAN:
            return __assign(__assign({}, state), action.payload);
        case RESET_WELLNESS_PLAN:
            return __assign({}, INITIAL_STATE);
        default:
            return state;
    }
};
