import { jsx as _jsx } from "react/jsx-runtime";
import { useAppSelector } from 'hooks/reduxHooks';
var RoleBaseRender = function (_a) {
    var forRole = _a.forRole, children = _a.children, hasAll = _a.hasAll, notForRole = _a.notForRole, ConditionalUI = _a.ConditionalUI;
    var userRole = useAppSelector(function (state) { return state.auth.userRole; });
    if (notForRole) {
        if (userRole.includes(notForRole)) {
            // if user role found than return null else component
            return null;
        }
        return children;
    }
    if (userRole.includes(forRole)) {
        return children;
    }
    else if (Array.isArray(forRole)) {
        var hasRole = hasAll
            ? forRole.every(function (role) { return userRole.includes(role); })
            : forRole.some(function (role) { return userRole.includes(role); });
        return hasRole ? children : null;
    }
    if (ConditionalUI) {
        return _jsx(ConditionalUI, {});
    }
    return null;
};
RoleBaseRender.defaultProps = {
    forRole: '',
    children: null,
    hasAll: false,
};
export default RoleBaseRender;
