import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Box } from '@mui/material';
// eslint-disable-next-line max-len
import MemberQuestionnaireSection from 'common/Print/components/MemberQuestionnaireSection/MemberQuestionnaireSection.component';
import { startCase } from 'lodash';
var MemberQuestionnaire2Template = function (_a) {
    var _b = _a.data, data = _b === void 0 ? [] : _b;
    return (_jsx(Box, { "data-testid": 'lawton-template', children: data === null || data === void 0 ? void 0 : data.map(function (section) { return (_jsx(MemberQuestionnaireSection, { heading: startCase(section.surveyName), data: section.surveyData }, section.surveyName)); }) }));
};
export default MemberQuestionnaire2Template;
