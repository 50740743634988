var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { showError } from 'store/commonReducer/common.action';
import { showApplicationLoader, hideApplicationLoader, } from './../../common/ApplicationLoader/ApplicationLoader.action';
import { getAlertCareInsightHistory } from './Events.action';
import moment from 'moment-timezone';
import { capitalizeFirstLetter, getCareAgentInfo, isAuthenticateUser, } from 'globals/global.functions';
import { DATE_FORMAT, TIME_FORMAT } from 'globals/global.constants';
import { EventsType, EventViewState, } from '../../globals/enums';
import { postAlertDialogService } from 'services/careInsightService/insightSummary.service';
import { getSeniorMappingService } from 'services/seniorService/senior.service';
export var ADD_SUMMARY = 'ADD_SUMMARY';
export var CREATE_SUMMARY = 'CREATE_SUMMARY';
export var MINIMIZE_EVENT = 'MINIMIZE_EVENT';
export var MAXIMIZE_EVENT = 'MAXIMIZE_EVENT';
export var CLOSE_EVENT = 'CLOSE_EVENT';
export var CREATE_ALERT = 'CREATE_ALERT';
export var REMOVE_ALL_EVENTS = 'REMOVE_ALL_EVENTS';
/**
 * @function createAlertDialog
 * @description action creator to create summary popup
 */
export var createAlertDialog = function (data) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var seniorQueryPayload, seniorDetail, seniorName, seniorTimezone, detailList, fullName, endDate, startDate, careInsightHistory, alertData, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                seniorQueryPayload = [
                    {
                        user_id: data.seniorId,
                        account_id: data.accountId,
                    },
                ];
                _a.label = 1;
            case 1:
                _a.trys.push([1, 4, , 5]);
                return [4 /*yield*/, getSeniorMappingService(seniorQueryPayload)];
            case 2:
                seniorDetail = _a.sent();
                seniorName = seniorDetail[data.accountId][data.seniorId]['minimal']['name'];
                seniorTimezone = seniorDetail[data.accountId][data.seniorId]['basic_info']['timezone'];
                detailList = [
                    {
                        label: 'Date/Time Generated',
                        value: "".concat(moment(data.dateGenerated).format(DATE_FORMAT), " @ ").concat(moment(data.dateGenerated).format(TIME_FORMAT)),
                    },
                    {
                        label: 'Vital Sign',
                        value: data.vitalLabel,
                    },
                    {
                        label: 'Variable',
                        value: capitalizeFirstLetter(data.variable),
                    },
                    {
                        label: 'Current',
                        value: "".concat(data.reading, " ").concat(data.meassurementUnit),
                    },
                    {
                        label: 'Range',
                        value: !data.range.goodLower || !data.range.goodUpper
                            ? '-'
                            : "".concat(data.range.goodLower, "-").concat(data.range.goodUpper, " ").concat(data.meassurementUnit),
                    },
                ];
                fullName = "".concat(seniorName.first_name, " ").concat(seniorName.last_name);
                endDate = moment(data.dateGenerated).format(DATE_FORMAT);
                startDate = moment(endDate).subtract(7, 'days').format(DATE_FORMAT);
                return [4 /*yield*/, dispatch(getAlertCareInsightHistory(startDate, endDate, data.accountId, data.seniorId))];
            case 3:
                careInsightHistory = _a.sent();
                if (!isAuthenticateUser()) {
                    return [2 /*return*/];
                }
                alertData = createAlertData(fullName, data.seniorId, startDate, endDate, careInsightHistory === null || careInsightHistory === void 0 ? void 0 : careInsightHistory.results, EventsType.Alert, EventViewState.Maximize, data.message, data.accountId, seniorTimezone, data.careInsightId, detailList, data.dateGenerated);
                dispatch({ type: CREATE_ALERT, payload: { alertData: alertData } });
                return [3 /*break*/, 5];
            case 4:
                error_1 = _a.sent();
                // eslint-disable-next-line no-console
                console.log('Data is missing in the API', { error: error_1 });
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}); }; };
/**
 * @function postAlertDialog
 * @description action creator to submit the care insight alert
 * @param {string} careInsightId
 * @param {string} status
 * @param {string} message
 * @returns void
 */
export var postAlertDialog = function (careInsightId, status, message) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var careAgentInfo, payload, response, error_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                careAgentInfo = getCareAgentInfo();
                payload = {
                    care_insight_id: careInsightId,
                    updated_by: careAgentInfo.userName.first_name,
                    status: status,
                    message: message.trim(),
                };
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                dispatch(showApplicationLoader());
                return [4 /*yield*/, postAlertDialogService(payload)];
            case 2:
                response = _a.sent();
                dispatch(hideApplicationLoader());
                return [2 /*return*/, response];
            case 3:
                error_2 = _a.sent();
                dispatch(hideApplicationLoader());
                dispatch(showError(error_2));
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
/**
 * @function createApproveAlert
 * @description action creator to create approve dialog
 * @param data
 */
export var createApproveAlert = function (data) { return function (dispatch, getState) {
    var alert = getState().events.alert;
    var isExist = Object.values(alert).some(function (value) {
        return value.viewState === EventViewState.Approve;
    });
    // if approve dialog for alert already existing return null
    if (isExist) {
        return;
    }
    var alertData = createAlertData('', data.seniorId, '', '', [], EventsType.Alert, EventViewState.Approve, data.message, data.accountId, '', data.careInsightId, [], data.dateGenerated);
    dispatch({ type: CREATE_ALERT, payload: { alertData: alertData } });
}; };
/**
 * @function createAlertActionNotification
 * @description action creator to create notification when action performed on careinsight by admin
 * @param data
 */
export var createAlertActionNotification = function (data) { return function (dispatch) {
    var alertData = createAlertData('', data.seniorId, '', '', [], EventsType.Alert, EventViewState.ActionNotification, data.message, data.accountId, '', data.careInsightId, [], data.dateGenerated);
    dispatch({ type: CREATE_ALERT, payload: { alertData: alertData } });
}; };
/**
 * @function createAlertData
 * @description method to create data for alert event
 * @param {string} fullName senior fullname
 * @param {string} seniorId senior ID
 * @param {string} startDate care insight history data start date
 * @param {string} endDate care insight history data end date
 * @param {any} careInsightHistory
 * @param {EventsType} type type of event alert
 * @param {EventViewState} viewState view state of event maximize or minimize
 * @returns {ISummaryEvent}
 */
var createAlertData = function (fullName, seniorId, startDate, endDate, careInsightHistory, eventType, viewState, message, accountId, seniorTimezone, alertId, detailList, dateGenerated) {
    var _a;
    var eventId = "".concat(seniorId, "-").concat(alertId);
    return _a = {},
        _a[eventId] = {
            eventType: eventType,
            viewState: viewState,
            fullName: fullName,
            seniorId: seniorId,
            startDate: startDate,
            endDate: endDate,
            careInsightHistory: careInsightHistory,
            message: message,
            accountId: accountId,
            seniorTimezone: seniorTimezone,
            eventId: eventId,
            alertId: alertId,
            detailList: detailList,
            dateGenerated: dateGenerated,
        },
        _a;
};
