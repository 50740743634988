var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { API } from 'globals/api';
import { CARE_INSIGHT_END_POINTS } from 'globals/apiEndPoints';
import { getTimestamp, getExistingVitalsData } from 'globals/global.functions';
export function getCareInsightHistoryService(params) {
    return __awaiter(this, void 0, Promise, function () {
        var response, results, verbiageMessage, error_1, errorMessage;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, API({
                            url: CARE_INSIGHT_END_POINTS.GET_CARE_INSIGHT_HISTORY,
                            method: 'get',
                            params: params,
                        })];
                case 1:
                    response = _a.sent();
                    results = response.data.results;
                    verbiageMessage = response.data.message;
                    return [2 /*return*/, {
                            results: mapCareInsightHistoryPayload(results) || [],
                            message: verbiageMessage || '',
                        }];
                case 2:
                    error_1 = _a.sent();
                    if (error_1.response) {
                        errorMessage = error_1.response.data.message;
                        throw new Error(errorMessage);
                    }
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    });
}
export function getCareInsightTransactionService(params) {
    return __awaiter(this, void 0, Promise, function () {
        var response, insights, error_2, errorMessage;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, API({
                            url: CARE_INSIGHT_END_POINTS.GET_CARE_INSIGHT_TRANSACTION,
                            method: 'get',
                            params: params,
                        })];
                case 1:
                    response = _a.sent();
                    insights = response.data.insights;
                    return [2 /*return*/, mapCareInsightHistoryPayload(insights)];
                case 2:
                    error_2 = _a.sent();
                    errorMessage = error_2.response.data.message;
                    throw new Error(errorMessage);
                case 3: return [2 /*return*/];
            }
        });
    });
}
/**
 * @description API service of submit summary message
 * @param params api payload for submit summary
 * @returns {Promise} promise
 */
export function postSummaryMessageService(params) {
    return __awaiter(this, void 0, Promise, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, API({
                        url: CARE_INSIGHT_END_POINTS.POST_CARE_INSIGHT_SUMMARY,
                        method: 'post',
                        data: params,
                    })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
var mapCareInsightHistoryPayload = function (payload) {
    return payload.map(function (data) { return createCareInsightData(data); });
};
export var createCareInsightData = function (data) {
    var _a, _b, _c, _d, _e;
    var existingVitalsData = getExistingVitalsData(null);
    return {
        seniorId: data.senior_id,
        accountId: data.account_id,
        careInsightId: data.care_insight_id,
        dateGenerated: getTimestamp(data.timestamp),
        dateUpdated: getTimestamp(data.updated_on),
        status: data.status,
        agent: data.updated_by || data.created_by,
        vitalSign: data.vital_type,
        vitalLabel: data.vital_type
            ? existingVitalsData[data.vital_type].label
            : '-',
        meassurementUnit: data.vital_type
            ? existingVitalsData[data.vital_type].measurementUnitAbbreviation
            : '-',
        type: data.insight_type,
        message: data.message || '',
        variable: data.range || '',
        range: {
            goodLower: (_a = data.range_map) === null || _a === void 0 ? void 0 : _a.good_lower,
            goodUpper: (_b = data.range_map) === null || _b === void 0 ? void 0 : _b.good_upper,
        },
        reading: data.value,
        seniorName: {
            firstName: (_c = data === null || data === void 0 ? void 0 : data.senior_name) === null || _c === void 0 ? void 0 : _c.first_name,
            middleName: (_d = data === null || data === void 0 ? void 0 : data.senior_name) === null || _d === void 0 ? void 0 : _d.middle_name,
            lastName: (_e = data === null || data === void 0 ? void 0 : data.senior_name) === null || _e === void 0 ? void 0 : _e.last_name,
        },
        facilityId: data.facility_id,
    };
};
export var createCIRangeMilestoneData = function (data, eventId) {
    var existingVitalsData = getExistingVitalsData(null);
    return {
        createdDate: data.created_date,
        lastSubmitted: data.last_submitted,
        vitalSign: data.measurement_type,
        vitalLabel: data.measurement_type
            ? existingVitalsData[data.measurement_type].label
            : '-',
        measurementUnit: data.measurement_type
            ? existingVitalsData[data.measurement_type].measurementUnitAbbreviation
            : '-',
        milestone: data.milestone,
        milestoneOrdinal: data.milestone_ordinal,
        modificationDate: data.modification_date,
        seniorId: data.senior_id,
        status: data.status,
        eventId: (eventId === null || eventId === void 0 ? void 0 : eventId.split('|')[1]) || '',
    };
};
/**
 * @description API service of update insight status
 * @param params api payload for set status
 * @returns {Promise} promise
 */
export function updateInsightStatusService(params) {
    return __awaiter(this, void 0, Promise, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, API({
                        url: CARE_INSIGHT_END_POINTS.UPDATE_CARE_INSIGHT_STATUS,
                        method: 'put',
                        data: params,
                    })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
/**
 * @description API service of send action message to CG/Admin
 * @param params api payload for submit alert
 * @returns {Promise} promise
 */
export function postAlertDialogService(params) {
    return __awaiter(this, void 0, Promise, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, API({
                        url: CARE_INSIGHT_END_POINTS.POST_CARE_INSIGHT_ALERT,
                        method: 'post',
                        data: params,
                    })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
/**
 * @function getAllCareInsightEventsService
 * @description API service to get existing events with status new
 * @param params api payload
 * @returns Promise
 */
export function getAllCareInsightEventsService(params) {
    return __awaiter(this, void 0, void 0, function () {
        var response, insights, error_3, errorMessage;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, API({
                            url: CARE_INSIGHT_END_POINTS.GET_ALL_CARE_INSIGHT,
                            method: 'get',
                            params: params,
                        })];
                case 1:
                    response = _a.sent();
                    insights = response.data.results;
                    return [2 /*return*/, mapCareInsightHistoryPayload(insights)];
                case 2:
                    error_3 = _a.sent();
                    errorMessage = error_3.response.data.message;
                    throw new Error(errorMessage);
                case 3: return [2 /*return*/];
            }
        });
    });
}
/**
 * @function getAllFacilityCareInsightEventsService
 * @description API service to get existing facility care insight events
 * @param params api payload
 * @returns Promise
 */
export function getAllFacilityCareInsightEventsService(params) {
    return __awaiter(this, void 0, void 0, function () {
        var response, insights, error_4, errorMessage;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, API({
                            url: CARE_INSIGHT_END_POINTS.GET_ALL_FACILITY_CARE_INSIGHT,
                            method: 'get',
                            params: params,
                        })];
                case 1:
                    response = _a.sent();
                    insights = response.data.results;
                    return [2 /*return*/, mapCareInsightHistoryPayload(insights)];
                case 2:
                    error_4 = _a.sent();
                    errorMessage = error_4.response.data.message;
                    throw new Error(errorMessage);
                case 3: return [2 /*return*/];
            }
        });
    });
}
/**
 * @function putAcknowledgeCareInsights
 * @description API service to acknowlege approved/rejected careInsight
 * @param {IAcknowledgeCareInsightParams} params api payload
 * @returns Promise
 */
export function putAcknowledgeCareInsights(params) {
    return __awaiter(this, void 0, void 0, function () {
        var error_5, errorMessage;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, API({
                            url: CARE_INSIGHT_END_POINTS.PUT_UPDATE_CARE_INSIGHT,
                            method: 'put',
                            data: params,
                        })];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    error_5 = _a.sent();
                    errorMessage = error_5.response.data.message;
                    throw new Error(errorMessage);
                case 3: return [2 /*return*/];
            }
        });
    });
}
