import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-len */
import { Box } from '@mui/material';
import { parseAssessmentMetaData } from 'common/Print/Print.utility';
import HolisticAssessmentTemplate from 'common/Print/templates/HolisticAssessmentTemplate/HolisticAssessmentTemplate.component';
import { PrintTemplates } from 'globals/enums';
import React from 'react';
import { useAppSelector } from 'hooks/reduxHooks';
import Header from './components/Header/Header.component';
import HeaderData from './components/HeaderData/HeaderData.component';
import MetaBox from './components/MetaBox/MetaBox.component';
import PrintLayout from './components/PrintLayout/PrintLayout.component';
import LawtonBrodyAssessmentTemplate from './templates/ADL/LawtonBrodyAssessmentTemplate/LawtonBrodyAssessmentTemplate.component';
import KatzADLAssessmentTemplate from './templates/ADL/KatzADLAssessmentTemplate/KatzADLAssessmentTemplate.component';
import './Print.style.css';
import CaregiverStrainAssessementTemplate from './templates/CGStrainAssessmentTemplate/CaregiverStrainTemplate.component';
import MedicalConditionTemplate from './templates/MedicalConditionTemplate/MedicalConditionTemplate.component';
import UCLALonelinessAssessmentTemplate from './templates/UCLALonelinessAssessmentTemplate/UCLALonelinessAssessmentTemplate.component';
import FallsAndHospitalizationAssessmentTemplate from 'common/Print/templates/FallsAndHospitalizationAssessmentTemplate/FallsAndHospitalizationAssessmentTemplate.component';
import HomeSafetyAssessmentTemplate from 'common/Print/templates/HomeSafetyAssessmentTemplate/HomeSafetyAssessmentTemplate.component';
import MemberQuestionnaire2Template from './templates/MemberQuestionnaire/MemberQuestionnaire2Template/MemberQuestionnaire2Template.component';
import MemberQuestionnaire1Template from './templates/MemberQuestionnaire/MemberQuestionnaire1Template/MemberQuestionnaire1Template.component';
import MemberQuestionnaire3Template from './templates/MemberQuestionnaire/MemberQuestionnaire3Template/MemberQuestionnaire3Template.component';
var Print = function () {
    var type = useAppSelector(function (state) { return state.print; }).type;
    var _a = useAppSelector(function (state) { return state.print.data; }), meta = _a.meta, data = _a.data;
    var _b = React.useMemo(function () {
        var HeaderContent = null, FrameContent = null;
        var subheading = "Senior Member:".concat((meta === null || meta === void 0 ? void 0 : meta.name) || ' - ');
        var headerStyle = {
            textWrap: 'nowrap',
        };
        switch (type) {
            case PrintTemplates.HOLISTIC:
                HeaderContent = (_jsxs(Box, { className: 'common-header-wrapper', children: [_jsx(Header, { children: _jsx(HeaderData, { heading: 'Holistic Assessment', subheading: subheading }) }), _jsx(MetaBox, { data: parseAssessmentMetaData(meta), columns: 22 })] }));
                FrameContent = _jsx(HolisticAssessmentTemplate, { data: data });
                break;
            case PrintTemplates.LAWTON:
                HeaderContent = (_jsxs(Box, { className: 'common-header-wrapper', children: [_jsx(Header, { children: _jsx(HeaderData, { heading: 'Lawton-Brody ADL', subheading: subheading }) }), _jsx(MetaBox, { data: parseAssessmentMetaData(meta), columns: 22 })] }));
                FrameContent = _jsx(LawtonBrodyAssessmentTemplate, { data: data });
                break;
            case PrintTemplates.KATZ:
                HeaderContent = (_jsxs(Box, { className: 'common-header-wrapper', children: [_jsx(Header, { children: _jsx(HeaderData, { heading: 'Katz Index of Independence in ADL', subheading: subheading }) }), _jsx(MetaBox, { data: parseAssessmentMetaData(meta), columns: 22 })] }));
                FrameContent = _jsx(KatzADLAssessmentTemplate, { data: data });
                break;
            case PrintTemplates.CAREGIVER_STRAIN:
                HeaderContent = (_jsxs(Box, { className: 'common-header-wrapper', children: [_jsx(Header, { children: _jsx(HeaderData, { heading: 'Caregiver Strain Assessment', subheading: subheading }) }), _jsx(MetaBox, { data: parseAssessmentMetaData(meta), columns: 22 })] }));
                FrameContent = (_jsx(CaregiverStrainAssessementTemplate, { data: data, caregiverName: meta === null || meta === void 0 ? void 0 : meta.caregiverName }));
                break;
            case PrintTemplates.MEDICAL_CONDITION:
                HeaderContent = (_jsxs(Box, { className: 'common-header-wrapper', children: [_jsx(Header, { children: _jsx(HeaderData, { heading: 'Medical Condition-Disease Assessment', subheading: subheading }) }), _jsx(MetaBox, { data: parseAssessmentMetaData(meta), columns: 22 })] }));
                FrameContent = _jsx(MedicalConditionTemplate, { data: data });
                break;
            case PrintTemplates.UCLA_LONELINESS:
                HeaderContent = (_jsxs(Box, { className: 'common-header-wrapper', children: [_jsx(Header, { children: _jsx(HeaderData, { heading: 'UCLALoneliness Assessment', subheading: subheading }) }), _jsx(MetaBox, { data: parseAssessmentMetaData(meta), columns: 22 })] }));
                FrameContent = _jsx(UCLALonelinessAssessmentTemplate, { data: data });
                break;
            case PrintTemplates.FALLSANDHOSPITALIZATION:
                HeaderContent = (_jsxs(Box, { className: 'common-header-wrapper', children: [_jsx(Header, { children: _jsx(HeaderData, { heading: 'Falls and Hospitalization Assessment', subheading: subheading }) }), _jsx(MetaBox, { data: parseAssessmentMetaData(meta), columns: 22 })] }));
                FrameContent = (_jsx(FallsAndHospitalizationAssessmentTemplate, { data: data }));
                break;
            case PrintTemplates.HOMESAFETY:
                HeaderContent = (_jsxs(Box, { className: 'common-header-wrapper', children: [_jsx(Header, { children: _jsx(HeaderData, { heading: 'Home Safety Assessment', subheading: subheading }) }), _jsx(MetaBox, { data: parseAssessmentMetaData(meta), columns: 22 })] }));
                FrameContent = _jsx(HomeSafetyAssessmentTemplate, { data: data });
                break;
            case PrintTemplates.MEMBER_QUESTIONNAIRE_1:
                HeaderContent = (_jsxs(Box, { className: 'common-header-wrapper', children: [_jsx(Header, { children: _jsx(HeaderData, { heading: 'Member Questionnaire 1', subheading: subheading }) }), _jsx(MetaBox, { data: parseAssessmentMetaData(meta), columns: 22 })] }));
                FrameContent = _jsx(MemberQuestionnaire1Template, { data: data });
                break;
            case PrintTemplates.MEMBER_QUESTIONNAIRE_2:
                HeaderContent = (_jsxs(Box, { className: 'common-header-wrapper', children: [_jsx(Header, { children: _jsx(HeaderData, { heading: 'Member Questionnaire 2 Member', subheading: subheading }) }), _jsx(MetaBox, { data: parseAssessmentMetaData(meta), columns: 22 })] }));
                FrameContent = _jsx(MemberQuestionnaire2Template, { data: data });
                break;
            case PrintTemplates.MEMBER_QUESTIONNAIRE_3:
                HeaderContent = (_jsxs(Box, { className: 'common-header-wrapper', children: [_jsx(Header, { children: _jsx(HeaderData, { heading: 'Member Questionnaire 3 Member Index of Independence in ADL', subheading: subheading }) }), _jsx(MetaBox, { data: parseAssessmentMetaData(meta), columns: 22 })] }));
                FrameContent = _jsx(MemberQuestionnaire3Template, { data: data });
                break;
            default:
                break;
        }
        return { HeaderContent: HeaderContent, FrameContent: FrameContent };
    }, [type, meta, data]), HeaderContent = _b.HeaderContent, FrameContent = _b.FrameContent;
    return _jsx(PrintLayout, { header: HeaderContent, children: FrameContent });
};
export default Print;
