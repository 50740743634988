var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable max-len */
import { API_LOAD_STATE } from 'globals/enums';
import { GET_CORPORATE_MANAGEMENT_LIST, GET_CORPORATE_MANAGEMENT_LIST_SUCCESS, GET_CORPORATE_MANAGEMENT_SEARCH_LIST_SUCCESS, GET_CORPORATE_MANAGEMENT_LIST_FAIL, UPDATE_CORPORATE_MANAGEMENT_LIST_PAGE_NUMBER, RESET_CORPORATE_MANAGEMENT_LIST, } from './CorporateManagement/CorporateManagement.action';
import { GET_FACILITY_MANAGEMENT_LIST, GET_FACILITY_MANAGEMENT_LIST_SUCCESS, GET_FACILITY_MANAGEMENT_SEARCH_LIST_SUCCESS, GET_FACILITY_MANAGEMENT_LIST_FAIL, UPDATE_FACILITY_MANAGEMENT_LIST_PAGE_NUMBER, RESET_FACILITY_MANAGEMENT_LIST, GET_FACILITY_DATA, SET_TOTAL_RESIDENTS, RESET_TOTAL_RESIDENTS, SET_FACILITY_TYPE_FILTER, } from './FacilityManagement/FacilityManagement.action';
import { GET_CORP_FACILITY_USERS, GET_CORP_FACILITY_USERS_FAIL, GET_CORP_FACILITY_USERS_SEARCH_SUCCESS, GET_CORP_FACILITY_USERS_SUCCESS, RESET_CORP_FACILITY_USERS_TABLE, SET_ACCOUNT_FILTER_CORPORATE_NAME, SET_CORP_FACILITY_USER_SEARCH, UPDATE_CORP_FACILITY_USERS_PAGE_NUMBER, } from '../Accounts/CorpFacilityUsers/CorpFacility.actions';
export var INITIAL_STATE = {
    corporateManagementList: {
        data: [],
        loading: API_LOAD_STATE.NOT_LOADED,
        totalRows: 0,
        currentPage: 1,
        offset: 0,
    },
    facilityManagementList: {
        data: [],
        lastEvaluatedKey: '',
        searchLastEvaluatedKey: '',
        loading: false,
        totalRows: 0,
        currentPage: 1,
    },
    facilityManagementData: {
        selectedFacility: {
            name: '',
            phone: '',
            totalResidents: 0,
            addressCity: '',
            addressPostalCode: '',
            addressState: '',
            addressStreet: '',
            corporateId: '',
            corporateName: '',
            dateModified: '',
            facilityType: [],
            id: '',
        },
        facilityTypeFilter: '',
    },
    totalResidents: 0,
    careAgents: {
        data: [],
        limit: 0,
        offset: 0,
        loading: API_LOAD_STATE.NOT_LOADED,
        currentPage: 1,
        totalRows: 0,
        corporateIdFilter: '',
        userSearchValue: '',
    },
};
export var corporateAndFacilitiesReducer = function (state, action) {
    var _a, _b;
    if (state === void 0) { state = INITIAL_STATE; }
    switch (action.type) {
        case GET_CORPORATE_MANAGEMENT_LIST:
            return __assign(__assign({}, state), { corporateManagementList: __assign(__assign({}, state.corporateManagementList), { loading: API_LOAD_STATE.PROGRESS }) });
        case GET_CORPORATE_MANAGEMENT_LIST_SUCCESS:
            return __assign(__assign({}, state), { corporateManagementList: __assign(__assign({}, state.corporateManagementList), { loading: API_LOAD_STATE.SUCCESSFUL, data: action.payload.data, offset: action.payload.offset }) });
        case GET_CORPORATE_MANAGEMENT_SEARCH_LIST_SUCCESS:
            return __assign(__assign({}, state), { corporateManagementList: __assign(__assign({}, state.corporateManagementList), { loading: API_LOAD_STATE.SUCCESSFUL, data: action.payload.data, offset: action.payload.offset }) });
        case GET_CORPORATE_MANAGEMENT_LIST_FAIL:
            return __assign(__assign({}, state), { corporateManagementList: __assign(__assign({}, state.corporateManagementList), { loading: API_LOAD_STATE.ERROR }) });
        case UPDATE_CORPORATE_MANAGEMENT_LIST_PAGE_NUMBER: {
            return __assign(__assign({}, state), { corporateManagementList: __assign(__assign({}, state.corporateManagementList), { currentPage: action.payload }) });
        }
        case RESET_CORPORATE_MANAGEMENT_LIST: {
            return __assign(__assign({}, state), { corporateManagementList: {
                    data: [],
                    loading: API_LOAD_STATE.NOT_LOADED,
                    totalRows: 0,
                    currentPage: 1,
                    offset: 0,
                } });
        }
        case GET_FACILITY_MANAGEMENT_LIST:
            return __assign(__assign({}, state), { facilityManagementList: __assign(__assign({}, state.facilityManagementList), { loading: API_LOAD_STATE.PROGRESS }) });
        case GET_FACILITY_MANAGEMENT_LIST_SUCCESS:
            return __assign(__assign({}, state), { facilityManagementList: __assign(__assign({}, state.facilityManagementList), { loading: API_LOAD_STATE.SUCCESSFUL, data: action.payload.data, lastEvaluatedKey: action.payload.lastEvaluatedKey, searchLastEvaluatedKey: '' }) });
        case GET_FACILITY_MANAGEMENT_SEARCH_LIST_SUCCESS:
            return __assign(__assign({}, state), { facilityManagementList: __assign(__assign({}, state.facilityManagementList), { loading: API_LOAD_STATE.SUCCESSFUL, data: action.payload.data, searchLastEvaluatedKey: ((_a = action.payload) === null || _a === void 0 ? void 0 : _a.searchLastEvaluatedKey) || '' }) });
        case GET_FACILITY_MANAGEMENT_LIST_FAIL:
            return __assign(__assign({}, state), { facilityManagementList: __assign(__assign({}, state.facilityManagementList), { loading: API_LOAD_STATE.ERROR }) });
        case UPDATE_FACILITY_MANAGEMENT_LIST_PAGE_NUMBER: {
            return __assign(__assign({}, state), { facilityManagementList: __assign(__assign({}, state.facilityManagementList), { currentPage: action.payload }) });
        }
        case RESET_FACILITY_MANAGEMENT_LIST: {
            return __assign(__assign({}, state), { facilityManagementList: {
                    data: [],
                    lastEvaluatedKey: '',
                    searchLastEvaluatedKey: '',
                    loading: false,
                    totalRows: 0,
                    currentPage: 1,
                } });
        }
        case GET_FACILITY_DATA: {
            return __assign(__assign({}, state), { facilityManagementData: __assign(__assign({}, state.facilityManagementData), { selectedFacility: __assign({}, action.payload) }) });
        }
        case SET_TOTAL_RESIDENTS: {
            return __assign(__assign({}, state), { totalResidents: action.payload });
        }
        case RESET_TOTAL_RESIDENTS: {
            return __assign(__assign({}, state), { totalResidents: 0 });
        }
        case GET_CORP_FACILITY_USERS:
            return __assign(__assign({}, state), { careAgents: __assign(__assign({}, state.careAgents), { loading: API_LOAD_STATE.PROGRESS }) });
        case GET_CORP_FACILITY_USERS_SUCCESS:
            return __assign(__assign({}, state), { careAgents: __assign(__assign({}, state.careAgents), { loading: API_LOAD_STATE.SUCCESSFUL, data: action.payload.data, offset: action.payload.offset }) });
        case GET_CORP_FACILITY_USERS_SEARCH_SUCCESS:
            return __assign(__assign({}, state), { careAgents: __assign(__assign({}, state.careAgents), { loading: API_LOAD_STATE.SUCCESSFUL, data: action.payload.data, offset: action.payload.offset }) });
        case GET_CORP_FACILITY_USERS_FAIL:
            return __assign(__assign({}, state), { careAgents: __assign(__assign({}, state.careAgents), { loading: API_LOAD_STATE.ERROR }) });
        case UPDATE_CORP_FACILITY_USERS_PAGE_NUMBER: {
            return __assign(__assign({}, state), { careAgents: __assign(__assign({}, state.careAgents), { currentPage: action.payload }) });
        }
        case RESET_CORP_FACILITY_USERS_TABLE: {
            return __assign(__assign({}, state), { careAgents: {
                    data: [],
                    loading: API_LOAD_STATE.NOT_LOADED,
                    totalRows: 0,
                    currentPage: 1,
                    offset: 0,
                    limit: 0,
                    corporateIdFilter: '',
                    userSearchValue: '',
                } });
        }
        case SET_CORP_FACILITY_USER_SEARCH: {
            return __assign(__assign({}, state), { careAgents: __assign(__assign({}, state.careAgents), { userSearchValue: action.payload }) });
        }
        case SET_ACCOUNT_FILTER_CORPORATE_NAME: {
            return __assign(__assign({}, state), { careAgents: __assign(__assign({}, state.careAgents), { corporateIdFilter: (_b = action.payload) === null || _b === void 0 ? void 0 : _b.id }) });
        }
        case SET_FACILITY_TYPE_FILTER: {
            return __assign(__assign({}, state), { facilityManagementData: __assign(__assign({}, state.facilityManagementData), { facilityTypeFilter: action.payload }) });
        }
        default:
            return state;
    }
};
