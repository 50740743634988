import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { AUTO_LOGOUT_MESSAGE, CACHE_EVENTS_AUTO_LOGOUT, IDLE_USER_TIMEOUT, TEN_SECONDS, } from 'globals/global.constants';
import { injectStorageEvent } from 'globals/global.functions';
import { closeAutoLogoutModel, logoutUser, openAutoLogoutModel, } from 'pages/WCPages/Login/Login.action';
import React, { useRef, useState } from 'react';
import IdleTimer from 'react-idle-timer';
import SessionTimeoutDialog from './SessionTimeoutDialog';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
var countdownInterval;
var timeout;
var SessionTimeout = function () {
    var _a = useState(0), timeoutCountdown = _a[0], setTimeoutCountdown = _a[1];
    var _b = useAppSelector(function (state) { return state.auth; }), isAuthenticated = _b.isAuthenticated, isTimeoutModel = _b.isTimeoutModel;
    var idleTimer = useRef(null);
    var dispatch = useAppDispatch();
    var clearIdleSession = function () {
        clearTimeout(timeout);
        clearInterval(countdownInterval);
    };
    var handleLogout = function () {
        dispatch(closeAutoLogoutModel());
        clearIdleSession();
        dispatch(logoutUser());
    };
    var handleAutologout = function () {
        dispatch(closeAutoLogoutModel());
        clearIdleSession();
        dispatch(logoutUser(AUTO_LOGOUT_MESSAGE));
    };
    var handleContinue = function () {
        injectStorageEvent(CACHE_EVENTS_AUTO_LOGOUT.RELOAD_SESSION, Date.now());
        window.location.reload();
    };
    var onActive = function () {
        if (!isTimeoutModel) {
            clearIdleSession();
        }
    };
    var handleOnIdle = function () {
        var delay = 1000 * 1;
        if (isAuthenticated && !isTimeoutModel) {
            timeout = window.setTimeout(function () {
                var countDown = 10;
                dispatch(openAutoLogoutModel());
                setTimeoutCountdown(countDown);
                countdownInterval = window.setInterval(function () {
                    if (countDown > 0) {
                        setTimeoutCountdown(--countDown);
                    }
                    else {
                        handleAutologout();
                    }
                }, 1000);
            }, delay);
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(IdleTimer, { ref: idleTimer, onActive: onActive, onIdle: handleOnIdle, debounce: 250, timeout: IDLE_USER_TIMEOUT - TEN_SECONDS, crossTab: {
                    type: 'localStorage',
                    channelName: 'idle-timer',
                    emitOnAllTabs: true,
                } }), _jsx(SessionTimeoutDialog, { countdown: timeoutCountdown, onContinue: handleContinue, onLogout: handleLogout })] }));
};
export default SessionTimeout;
