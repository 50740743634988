import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAppSelector } from 'hooks/reduxHooks';
import { Box, Backdrop, CircularProgress } from '@mui/material';
import { loaderStyles } from './ApplicationLoader.style';
import { PRINT_HIDE_CLASS } from 'common/Print/Print.types';
var ApplicationLoader = function () {
    var classes = loaderStyles().classes;
    var _a = useAppSelector(function (state) { return state.applicationLoader; }), show = _a.show, text = _a.text;
    var _b = useAppSelector(function (state) { return state.uploadFiles; }), filesUploadCount = _b.filesUploadCount, uploadedFilesCount = _b.uploadedFilesCount;
    return (_jsx(Backdrop, { className: "".concat(classes.backdrop, " ").concat(PRINT_HIDE_CLASS), open: show, "data-testid": 'application-loader', children: _jsxs(Box, { className: classes.loaderContainer, children: [_jsx(CircularProgress, { thickness: 2, size: 100, color: 'inherit' }), _jsx(Box, { children: text }), filesUploadCount > 0 && (_jsxs(Box, { children: ["Uploading: ", uploadedFilesCount, "/", filesUploadCount, " files"] }))] }) }));
};
export { ApplicationLoader };
