var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import _ from 'lodash';
import moment from 'moment';
import GoalDataModel from '../model/goalDataModel';
var GoalsDataParser = /** @class */ (function () {
    function GoalsDataParser() {
        this.goalsData = [];
        this.goalsActionMapData = {};
    }
    GoalsDataParser.prototype.parseGoalData = function (goalsResponseData) {
        this.goalsData = goalsResponseData.map(function (responseItem) {
            return new GoalDataModel(responseItem.resource, responseItem.goal_action_id, responseItem.goal, responseItem.action, responseItem.status, parseInt(responseItem.percentage || '0'), responseItem.start_date, responseItem.target_date, responseItem.notes, responseItem.created_date[0], responseItem.current_version);
        });
        this.goalsData = _.orderBy(this.goalsData, function (o) {
            return moment(o.createdDate).format('YYYYMMDD');
        }, ['asc']);
        return this.goalsData;
    };
    GoalsDataParser.prototype.parseGoalActionMapData = function (goalActionMapResponse) {
        var _this = this;
        goalActionMapResponse.forEach(function (goalItem) {
            goalItem.actions.forEach(function (actionItem) {
                var _a, _b;
                var _c;
                _this.goalsActionMapData = __assign(__assign({}, _this.goalsActionMapData), (_a = {}, _a[goalItem.goal_name] = __assign(__assign({}, _this.goalsActionMapData[goalItem.goal_name]), { goalName: goalItem.goal_name, occupied: false, rowIds: [], actions: __assign(__assign({}, (_c = _this.goalsActionMapData[goalItem.goal_name]) === null || _c === void 0 ? void 0 : _c.actions), (_b = {}, _b[actionItem] = {
                        actionName: actionItem,
                        selected: false,
                        rowId: [],
                    }, _b)) }), _a));
            });
        });
        return this.goalsActionMapData;
    };
    return GoalsDataParser;
}());
export default GoalsDataParser;
