var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { RESET_PREVIEW_DATA, SET_PREVIEW_DATA } from './preview.action';
import { initialState } from './preview.types';
var previewReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case SET_PREVIEW_DATA:
            return __assign(__assign({}, state), (action.payload || {}));
        case RESET_PREVIEW_DATA:
            return initialState;
        default:
            return state;
    }
};
export default previewReducer;
