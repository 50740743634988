import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { changeDocumentTitle, checkUserSession, isAuthenticateUser, getCareAgentInfo, } from 'globals/global.functions';
import SessionTimeout from '../SessionTimeout';
import { AppFallbackLoader } from 'common/AppFallbackLoader/ApplicationLoader.component';
import { SESSION_EXPIRED_MESSAGE } from 'globals/global.constants';
import { logoutUser } from 'pages/WCPages/Login/Login.action';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
var PrivateRouteView = React.memo(function (_a) {
    var component = _a.component, meta = _a.meta, requiredRoles = _a.requiredRoles;
    var location = useLocation();
    useEffect(function () {
        changeDocumentTitle(meta.title);
        window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);
    return (_jsxs(React.Suspense, { fallback: _jsx(AppFallbackLoader, {}), children: [_jsx(Renderer, { component: component, requiredRoles: requiredRoles }), _jsx(SessionTimeout, {})] }));
});
/**
 * @function Renderer
 * @description Renderer component to load the auth routes
 * @returns {JSX}
 */
var Renderer = function (_a) {
    var component = _a.component, requiredRoles = _a.requiredRoles;
    var userRoleArr = getCareAgentInfo().userRole;
    var dispatch = useAppDispatch();
    var roleConfig = useAppSelector(function (state) { return state.auth.roleConfig; });
    var hasRequiredRole = userRoleArr.some(function (requireRole) {
        return requiredRoles.includes(requireRole);
    });
    var isAuthenticated = isAuthenticateUser();
    if (isAuthenticated && !checkUserSession()) {
        dispatch(logoutUser(SESSION_EXPIRED_MESSAGE));
        return _jsx(AppFallbackLoader, {});
    }
    else if (isAuthenticated && hasRequiredRole)
        return component;
    else if (isAuthenticated && !hasRequiredRole)
        return _jsx(Navigate, { to: roleConfig === null || roleConfig === void 0 ? void 0 : roleConfig.defaultPage, replace: true });
    else {
        return _jsx(Navigate, { to: '/login', replace: true });
    }
};
export { PrivateRouteView };
