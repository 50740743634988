/**
 * @class CorpFacilityUserModel
 * @description class to maintain the CorpFacilityUserModel model
 */
var CorpFacilityUserModel = /** @class */ (function () {
    function CorpFacilityUserModel(id, name, address, facilityName, facilityId, corporateName, corporateId, role, email, phone, facilityType) {
        this.id = id;
        this.name = name;
        this.address = address;
        this.facilityName = facilityName;
        this.facilityId = facilityId;
        this.corporateName = corporateName;
        this.corporateId = corporateId;
        this.role = role;
        this.email = email;
        this.phone = phone;
        this.facilityType = facilityType;
    }
    return CorpFacilityUserModel;
}());
export default CorpFacilityUserModel;
