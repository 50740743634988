var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { GoalStatus } from 'globals/enums';
export var GET_GOALS_CONTEXT = 'GET_GOALS_CONTEXT';
export var ADD_GOAL_CONTEXT = 'ADD_GOAL_CONTEXT';
export var UPDATE_GOAL_CONTEXT = 'UPDATE_GOAL_CONTEXT';
export var UPDATE_GOAL_ERROR = 'UPDATE_GOAL_ERROR';
export var DELETE_GOAL_CONTEXT = 'DELETE_GOAL_CONTEXT';
export var reducer = function (state, action) {
    var _a;
    switch (action.type) {
        case GET_GOALS_CONTEXT:
            return __assign(__assign({}, state), { deleteRowsData: [], goalsRowsData: action.payload.length > 0
                    ? JSON.parse(JSON.stringify(__spreadArray([], action.payload, true)))
                    : [
                        {
                            id: '',
                            goal: '',
                            action: '',
                            status: 'not_started',
                            startDate: '',
                            targetDate: '',
                            notes: '',
                            isNewGoal: true,
                            newGoalId: 1,
                        },
                    ] });
        case ADD_GOAL_CONTEXT:
            return __assign(__assign({}, state), { goalsRowsData: __spreadArray([
                    {
                        id: '',
                        goal: '',
                        action: '',
                        status: 'not_started',
                        progress: 0,
                        startDate: '',
                        targetDate: '',
                        notes: '',
                        isNewGoal: true,
                        newGoalId: (((_a = state.goalsRowsData[0]) === null || _a === void 0 ? void 0 : _a.newGoalId) || 1) + 1,
                    }
                ], state.goalsRowsData, true) });
        case UPDATE_GOAL_CONTEXT:
            return __assign(__assign({}, state), { goalsRowsData: __spreadArray([], state.goalsRowsData, true).map(function (data) {
                    if ((data.id || data.newGoalId) === action.payload.id) {
                        data[action.payload.name] = action.payload.value;
                        data.isEdited = true;
                        if (action.payload.name === 'goal') {
                            data.goalErrorText = '';
                            data.goalError = false;
                            data.action = '';
                        }
                        if (action.payload.name === 'action') {
                            data.actionErrorText = '';
                            data.actionError = false;
                        }
                        return data;
                    }
                    return data;
                }) });
        case UPDATE_GOAL_ERROR: {
            return __assign(__assign({}, state), { goalsRowsData: __spreadArray([], action.payload, true) });
        }
        case DELETE_GOAL_CONTEXT: {
            var deleteGoal = state.goalsRowsData.find(function (data) {
                if (data.id === action.payload.id && !data.isNewGoal) {
                    return action.payload.id;
                }
            });
            if (deleteGoal === null || deleteGoal === void 0 ? void 0 : deleteGoal.id) {
                deleteGoal.status = GoalStatus.Deleted;
            }
            var updatedArray = state.goalsRowsData.filter(function (data) {
                return (data.isNewGoal ? data.newGoalId : data.id) !==
                    (action.payload.id || action.payload.newGoalId);
            });
            return __assign(__assign({}, state), { goalsRowsData: __spreadArray([], updatedArray, true), deleteRowsData: (deleteGoal === null || deleteGoal === void 0 ? void 0 : deleteGoal.id)
                    ? __spreadArray([deleteGoal], state.deleteRowsData, true) : __spreadArray([], state.deleteRowsData, true) });
        }
        default:
            return state;
    }
};
