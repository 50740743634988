var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { makeStyles } from 'tss-react/mui';
import { TemplateTypography } from 'common/Print/Print.utility';
export var footerStyles = makeStyles()(function () { return ({
    footer: __assign(__assign({}, TemplateTypography.regular), { textAlign: 'left', padding: '56px 144px', backgroundColor: '#ffffff', position: 'relative', borderTop: '1px solid #707070' }),
}); });
