var GoalDataModel = /** @class */ (function () {
    function GoalDataModel(resource, id, goal, action, status, progress, startDate, targetDate, notes, createdDate, current_version) {
        if (id === void 0) { id = ''; }
        if (goal === void 0) { goal = ''; }
        if (action === void 0) { action = ''; }
        if (status === void 0) { status = ''; }
        if (progress === void 0) { progress = 0; }
        if (startDate === void 0) { startDate = ''; }
        if (targetDate === void 0) { targetDate = ''; }
        if (notes === void 0) { notes = ''; }
        if (createdDate === void 0) { createdDate = ''; }
        if (current_version === void 0) { current_version = 0; }
        this.id = '';
        this.goal = '';
        this.action = '';
        this.status = '';
        this.progress = 0;
        this.startDate = '';
        this.targetDate = '';
        this.notes = '';
        this.createdDate = '';
        this.current_version = 0;
        if (resource) {
            this.resource = resource.map(function (r) { return ({
                resourceId: r.resource_id,
                name: r.name,
                description: r.description,
                date: r.date,
                format: r.format,
                resourceVersion: r.resource_version,
                createdBy: r.created_by,
                lastUpdatedBy: r.last_updated_by,
                lastViewed: r.last_viewed
            }); });
        }
        else {
            this.resource = [];
        }
        this.id = id;
        this.goal = goal;
        this.action = action;
        this.status = status;
        this.progress = progress;
        this.startDate = startDate;
        this.targetDate = targetDate;
        this.notes = notes;
        this.createdDate = createdDate;
        this.current_version = current_version;
    }
    return GoalDataModel;
}());
export default GoalDataModel;
