var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/* eslint-disable no-unused-vars */
import { createAlarmData } from 'services/alarmService/alarm.service';
import { channelData } from '../pusherApp';
import ChannelUtils from './channelUtils';
var AlarmChannelEvents = /** @class */ (function (_super) {
    __extends(AlarmChannelEvents, _super);
    function AlarmChannelEvents() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.alarmChannel = null;
        return _this;
    }
    /**
     * @function subscribeEventAlarm
     * @description subscribe to Alarm channel.
     */
    AlarmChannelEvents.prototype.subscribeAlarmChannel = function () {
        if (!this.alarmChannel) {
            this.alarmChannel = this.subscribeChannel(channelData.alarm.channelName);
        }
    };
    /**
     * @function listenSosFallEvent
     * @description method to listen the pusher alarm event
     * @param fn param to be called when any new alarm occured
     */
    AlarmChannelEvents.prototype.listenSosFallEvent = function (fn) {
        /* Channel to bind Alarm channel event "listenSosFallEvent" */
        this.channelEventListener(this.alarmChannel, channelData.alarm.events.sosFallEvent, function (data) { return createAlarmData(data.data); }, fn);
    };
    return AlarmChannelEvents;
}(ChannelUtils));
export default AlarmChannelEvents;
