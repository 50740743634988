import { makeStyles } from 'tss-react/mui';
export var updatePasswordStyle = makeStyles()(function (theme) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    return ({
        inputField: {
            borderRadius: 7,
            '& .MuiOutlinedInput-root': {
                height: 40,
            },
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: "".concat((_b = (_a = theme === null || theme === void 0 ? void 0 : theme.palette) === null || _a === void 0 ? void 0 : _a.customColor) === null || _b === void 0 ? void 0 : _b.borderBlue, "!important"),
            },
        },
        visibility: {
            height: 18,
            width: 18,
        },
        errorText: {
            color: theme.palette.error.light,
        },
        successText: {
            color: (_d = (_c = theme === null || theme === void 0 ? void 0 : theme.palette) === null || _c === void 0 ? void 0 : _c.customColor) === null || _d === void 0 ? void 0 : _d.success,
        },
        infoText: {
            color: (_f = (_e = theme === null || theme === void 0 ? void 0 : theme.palette) === null || _e === void 0 ? void 0 : _e.customColor) === null || _f === void 0 ? void 0 : _f.info,
        },
        errorTextWrapper: {
            display: 'flex',
            alignItems: 'center',
            '& img': {
                marginRight: 8,
            },
        },
        mainContainer: {
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
        },
        choosePassword: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            minHeight: 395,
            borderRadius: 16,
            backgroundColor: (_h = (_g = theme === null || theme === void 0 ? void 0 : theme.palette) === null || _g === void 0 ? void 0 : _g.customColor) === null || _h === void 0 ? void 0 : _h.white,
            width: '928px',
        },
        headingText: {
            fontWeight: 'bold',
            paddingTop: 30,
        },
        welcomeContainer: {
            height: 180,
            width: '100%',
            borderRadius: 10,
            display: 'flex',
            position: 'relative',
            alignItems: 'center',
            backgroundColor: (_k = (_j = theme === null || theme === void 0 ? void 0 : theme.palette) === null || _j === void 0 ? void 0 : _j.customColor) === null || _k === void 0 ? void 0 : _k.welcome,
            marginBottom: 50,
            marginTop: 50,
        },
        welcomeImage: {
            position: 'absolute',
            bottom: 0,
            height: 220,
        },
        welcomeImageContainer: {
            width: 350,
            marginLeft: 30,
        },
        welcomeText: {
            color: theme.palette.customColor.white,
        },
        isError: { color: theme.palette.error.light },
        errorField: {
            borderRadius: 7,
            '& .MuiOutlinedInput-root': {
                height: 40,
            },
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: "".concat(theme.palette.error.light, "!important"),
            },
        },
        errorIcon: {
            margin: '2px 5px 0 0',
            width: '24px',
            height: '24px',
        },
    });
});
