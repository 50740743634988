var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import clsx from 'clsx';
import { Box, Typography } from '@mui/material';
import { InputText, InputMasked, Label } from './Input.component';
import { Masked } from '../Masked.component';
import { fieldsStyle } from '../InputFields.style';
var InputTextWrapper = React.forwardRef(function (item, ref) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    var inputProps = item.inputProps, eventProps = item.eventProps, withExtensionProps = item.withExtensionProps, props = __rest(item, ["inputProps", "eventProps", "withExtensionProps"]);
    var classes = fieldsStyle().classes;
    return (_jsxs(_Fragment, { children: [_jsxs(Box, { className: clsx((_a = {}, _a[classes.inlineFormFieldDesc] = props.inline, _a), (_b = {}, _b[classes.alignCenter] = props.center, _b)), marginRight: props.spacing ? 2 : 0, children: [props.masked ? (_jsx(InputMasked, __assign({ fullWidth: true, autoComplete: 'off', className: clsx((_c = {}, _c[classes.errorMasked] = props.isError, _c), (_d = {}, _d[classes.validMasked] = props.validated === false, _d)), 
                        //using data-error prop for autoscroll to the first error on the screen
                        inputComponent: Masked, inputProps: inputProps, 'data-error': props.isError ? 'error' : '' }, eventProps))) : (_jsx(InputText, __assign({ fullWidth: true, variant: 'outlined', autoComplete: 'off', InputProps: {
                            readOnly: props.readOnly,
                            inputRef: ref,
                        }, multiline: props.multiline, rows: props.rows, className: clsx((_e = {}, _e[classes.multiline] = props.multiline === true && !props.isError, _e), (_f = {}, _f[classes.errorField] = props.isError, _f), (_g = {}, _g[classes.validInput] = props.validated === false, _g), (_h = {}, _h[classes.fontLarge] = props.fontSize === 'large', _h), (_j = {}, _j[classes.removeSpinArrow] = props.type === 'number', _j), (_k = {}, _k[classes.secondary] = props.secondary === true, _k), (_l = {}, _l[classes.withBorder] = props.withBorder && !props.isError, _l)), inputProps: __assign(__assign({}, inputProps), { 'data-error': props.isError ? 'error' : '' }) }, eventProps))), props.extraComponent && (_jsx(Box, { paddingLeft: 1, children: props.extraComponent })), props.bottomText && (_jsx(Label, { className: classes.multilineHelperText, children: props.bottomText })), props.unitValue && (_jsx(Typography, { variant: 'subtitle1', children: props.unitValue }))] }), props.errorText && (_jsx(Typography, { variant: 'subtitle1', className: clsx((_m = {}, _m[classes.errorText] = true, _m), (_o = {}, _o[classes.errorCustomStyle] = props.isErrorCustomeStyle, _o)), children: props.errorText }))] }));
});
export default InputTextWrapper;
