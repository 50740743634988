var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { makeStyles } from 'tss-react/mui';
import { TemplateTypography } from 'common/Print/Print.utility';
export var fallsAndHospitalizationStatsStyles = makeStyles()(function () { return ({
    heading: __assign(__assign({}, TemplateTypography.regular), { fontWeight: 500, lineHeight: 1, padding: '36px', maxWidth: '332px', minWidth: '332px', width: '330px', verticalAlign: 'top', textAlign: 'center' }),
    container: {
        '@media print': {
            pageBreakInside: 'avoid',
            breakInside: 'avoid',
        },
    },
    tableContainer: {
        borderRadius: '10px',
        border: '1px solid #c9c9c9',
        backgroundColor: '#fff',
        marginBottom: '30px',
        '&:last-of-type': {
            marginBottom: 'none',
        },
    },
    content: __assign(__assign({}, TemplateTypography.regular), { textAlign: 'center' }),
    totalScore: __assign(__assign({}, TemplateTypography.regular), { padding: '30px', borderRadius: '10px', border: 'solid 1px #c9c9c9', marginTop: '30px', '& > span': {
            fontWeight: 500,
            lineHeight: 0.72,
            whiteSpace: 'nowrap',
        } }),
}); });
