import { makeStyles } from 'tss-react/mui';
export var bodyHealthStyle = makeStyles()(function (theme) { return ({
    headerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    activityContainer: {
        display: 'flex',
        height: '100%',
        alignItems: 'center',
    },
    summaryBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        borderRadius: 10,
        border: "solid 1px ".concat(theme.palette.customColor.borderGrey),
        padding: 5,
        height: '100%',
        minHeight: 'fit-content',
    },
    summaryCircle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 160,
        borderRadius: '50%',
        padding: 5,
        backgroundColor: theme.palette.background.green,
    },
    summaryBoxLabel: {
        fontSize: 14,
        margin: '5px 0',
        color: theme.palette.customColor.lighterBlack,
    },
    summaryBoxValue: {
        marginBottom: 'auto',
        fontSize: 17,
        fontWeight: 'bold',
        color: theme.palette.customColor.primaryGreen,
    },
    summaryBoxValueData: {
        fontWeight: 'Bold',
        fontSize: 24,
        color: theme.palette.customColor.primaryGreen,
    },
    lastRecordedColor: {
        marginBottom: 'auto',
        fontSize: 17,
        color: theme.palette.customColor.lightBlack,
        fontWeight: 'bold',
        '&.hydration': {
            fontSize: 24,
        },
    },
}); });
