import { makeStyles } from 'tss-react/mui';
export var AllResidentsStyle = makeStyles()(function (theme) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    return ({
        paginationTableContainer: {
            backgroundColor: (_b = (_a = theme.palette) === null || _a === void 0 ? void 0 : _a.customColor) === null || _b === void 0 ? void 0 : _b.white,
            borderRadius: '16px',
            boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.16)',
            alignItems: 'center',
            margin: '30px 0',
            position: 'relative',
        },
        HeaderText: {
            fontSize: 20,
            fontWeight: 'normal',
            color: (_c = theme.palette) === null || _c === void 0 ? void 0 : _c.primary.main,
        },
        pagination: {
            '& ul': {
                padding: '2% 0',
                justifyContent: 'center',
                '& .Mui-selected': {
                    color: ((_e = (_d = theme.palette) === null || _d === void 0 ? void 0 : _d.customColor) === null || _e === void 0 ? void 0 : _e.white) || 'white',
                    background: ((_g = (_f = theme.palette) === null || _f === void 0 ? void 0 : _f.customColor) === null || _g === void 0 ? void 0 : _g.primary) || '#16a9d0',
                    height: 24,
                    minWidth: 24,
                },
            },
        },
        searchContainer: {
            paddingTop: '20px',
            paddingRight: '40px',
        },
        title: {
            position: 'absolute',
            top: '20px',
            left: '35px',
            '& h2': {
                color: (_h = theme.palette) === null || _h === void 0 ? void 0 : _h.primary.main
            }
        },
    });
});
