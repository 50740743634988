var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { createCareInsightData, createCIRangeMilestoneData, } from 'services/careInsightService/insightSummary.service';
import { channelData } from '../pusherApp';
import { createLocationData } from 'services/locationService/seniorLocationService';
import ChannelUtils from './channelUtils';
var SummaryChannelEvents = /** @class */ (function (_super) {
    __extends(SummaryChannelEvents, _super);
    function SummaryChannelEvents() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.summaryChannel = null;
        return _this;
    }
    /**
     * @function subscribeEventAlerts
     * @description subscribe to Summary channel.
     */
    SummaryChannelEvents.prototype.subscribeSummaryChannel = function () {
        if (!this.summaryChannel) {
            this.summaryChannel = this.subscribeChannel(channelData.summary.channelName);
        }
    };
    /**
     * @function listenTrigger
     * @description method to listen the pusher summary event for admin approval
     * @param fn param to be called when any new summary comes for approval
     */
    SummaryChannelEvents.prototype.listenApproveAlertEventSummary = function (fn) {
        /* Channel to bind Summary channel event "approve-summary" */
        this.channelEventListener(this.summaryChannel, channelData.summary.events.approveSummary, function (data) { return createCareInsightData(data.data); }, fn);
    };
    /**
     * @function listenSummaryActionNotification
     * @description method to listen the pusher summary event for CI comes to CA after approval
     * @param fn param to be called when any new summary comes to CA after approval
     */
    SummaryChannelEvents.prototype.listenSummaryActionNotification = function (fn) {
        /* Channel to bind Summary channel event "summary-action-notification" */
        this.channelEventListener(this.summaryChannel, channelData.summary.events.summaryActionNotification, function (data) { return createCareInsightData(data.data); }, fn);
    };
    /**
     * @function listenFacilitySummaryActionNotification
     * @description method to listen the pusher summary event for CI comes to FA after approval
     * @param fn param to be called when any new summary comes to FA after approval
     */
    SummaryChannelEvents.prototype.listenFacilitySummaryActionNotification = function (fn) {
        /* Channel to bind Summary channel event "facility-summary-action-notification" */
        this.channelEventListener(this.summaryChannel, channelData.summary.events.facilitySummaryActionNotification, function (data) { return createCareInsightData(data.data); }, fn);
    };
    /**
     * @function listenCIRangeMilestoneNotification
     * @description method to listen the pusher summary event for CI Range Milestone
     * @param fn param to be called when any senior reached 1000/3000 milestone
     */
    SummaryChannelEvents.prototype.listenCIRangeMilestoneNotification = function (fn) {
        /* Channel to bind Summary channel event "milestone" */
        this.channelEventListener(this.summaryChannel, channelData.summary.events.milestoneNotification, function (data) { return createCIRangeMilestoneData(data.data, data.event_id); }, fn);
    };
    /**
     * @function listenLocationData
     * @description method to listen the pusher summary event for location data
     * @param fn param to be called when we click on "locate Me" button
     */
    SummaryChannelEvents.prototype.listenLocationData = function (fn) {
        /* Channel to bind Summary channel event "Location Data" */
        this.channelEventListener(this.summaryChannel, channelData.summary.events.currentLocationData, function (data) { return createLocationData(data.data); }, fn);
    };
    return SummaryChannelEvents;
}(ChannelUtils));
export default SummaryChannelEvents;
