import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Box, Grid } from '@mui/material';
import get from 'lodash.get';
import clsx from 'clsx';
import { roundOff } from 'globals/global.functions';
import { heartRateStyle } from './HearRate.style';
var HeartRateSummary = function (_a) {
    var summary = _a.summary, currentState = _a.currentState;
    var classes = heartRateStyle().classes;
    return (_jsxs(Box, { display: 'flex', flexDirection: 'column', width: '100%', "data-testid": 'heart-rate-summary', justifyContent: 'space-between', children: [_jsx(Box, { display: 'flex', justifyContent: 'center', alignItems: 'center', children: _jsxs(Box, { mt: 5, mb: 5, className: classes.summaryCircle, children: [_jsx("span", { className: clsx(classes.summaryBoxValueData, classes.currentRate), children: currentState == 'day'
                                ? get(summary, 'last')
                                : roundOff(get(summary, 'mean'), 0) }), "\u00A0", _jsx("span", { className: classes.currentUnit, children: "bpm" })] }) }), _jsxs(Grid, { container: true, direction: 'row', justifyContent: 'space-around', alignItems: 'center', children: [_jsx(Grid, { item: true, sm: 3, children: _jsxs(Box, { className: classes.summaryBox, children: [_jsx(Box, { className: classes.summaryBoxLabel, children: "Resting Avg" }), _jsxs(Box, { className: classes.summaryBoxValue, children: [_jsx("span", { className: classes.summaryBoxValueData, children: "-" }), ' ', _jsx("span", { className: classes.currentUnit, children: "bpm" })] })] }) }), _jsx(Grid, { item: true, sm: 3, children: _jsxs(Box, { className: classes.summaryBox, children: [_jsx(Box, { className: classes.summaryBoxLabel, children: "Overall" }), _jsxs(Box, { className: classes.summaryBoxValue, children: [_jsxs("span", { className: classes.summaryBoxValueData, children: [get(summary, 'min'), "-", get(summary, 'max')] }), ' ', _jsx("span", { className: classes.currentUnit, children: "bpm" })] })] }) }), _jsx(Grid, { item: true, sm: 3, children: _jsxs(Box, { className: classes.summaryBox, children: [_jsx(Box, { className: classes.summaryBoxLabel, children: "Walking Avg" }), _jsxs(Box, { className: classes.summaryBoxValue, children: [_jsx("span", { className: classes.summaryBoxValueData, children: "-" }), ' ', _jsx("span", { className: classes.currentUnit, children: "bpm" })] })] }) })] })] }));
};
export { HeartRateSummary };
