export var BUSINESS_VOILATION_CODE = 2032;
export var ASSESSMENT_TIME_DIFF = 24;
/**
 * @description map application codes
 * @param {number} a business code
 * @param {number} b feature code
 * @returns number
 */
var mapApplicationNumber = function (a, b) { return Number("".concat(a, ".").concat(b)); };
export var APPLICATION_CODE = {
    multipleSurveyCode: mapApplicationNumber(BUSINESS_VOILATION_CODE, 3001),
    callScheduleCAConflictCode: mapApplicationNumber(BUSINESS_VOILATION_CODE, 3002),
    callScheduleUserConflictCode: mapApplicationNumber(BUSINESS_VOILATION_CODE, 3003),
    deviceNotReachableCode: 2046,
};
