import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import clsx from 'clsx';
import moment from 'moment-timezone';
import { Box, Tooltip, Typography } from '@mui/material';
import { emotionalSurveyStyle } from '../WellnessSurvey.style';
import { ReactComponent as CommentIcon } from 'assets/icons/Comment.svg';
import { DATE_FORMAT } from 'globals/global.constants';
import { capitalizeFirstLetter } from 'globals/global.functions';
import { useTheme } from '@emotion/react';
var Heading = function (_a) {
    var head1 = _a.head1, head2 = _a.head2;
    return (_jsx("th", { children: _jsxs(Box, { p: 1, children: [head1, _jsx("span", { children: _jsx(Typography, { variant: 'body1', children: "- or -" }) }), head2] }) }));
};
var ResidentHeading = function (_a) {
    var head1 = _a.head1, head2 = _a.head2;
    var theme = useTheme();
    return (_jsx("th", { children: _jsxs(Box, { style: {
                backgroundColor: theme.palette.background.green,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }, children: [_jsx("div", { style: { color: theme.palette.common.black }, children: head1 }), _jsx("div", { style: { color: theme.palette.customColor.emotionRed }, children: head2 })] }) }));
};
var Row = React.memo(function (_a) {
    var _b;
    var value = _a.value, comment = _a.comment, positiveTitle = _a.positiveTitle, negativeTitle = _a.negativeTitle, _c = _a.isResident, isResident = _c === void 0 ? false : _c;
    var classes = emotionalSurveyStyle({ isResident: isResident }).classes;
    return (_jsxs("td", { className: clsx((_b = {},
            _b[classes.positive] = !value,
            _b[classes.negative] = value,
            _b[classes.cell] = isResident,
            _b)), children: [isResident && (_jsxs(Box, { children: [_jsx(Typography, { variant: 'subtitle1', children: value ? negativeTitle : positiveTitle }), value && (_jsxs(Typography, { variant: 'subtitle1', children: ["(", capitalizeFirstLetter(value), ")"] }))] })), !isResident && (_jsxs(_Fragment, { children: [_jsx(Typography, { variant: 'subtitle1', style: isResident ? { lineHeight: '12px' } : {}, children: value ? negativeTitle : positiveTitle }), value && (_jsxs(Typography, { variant: 'subtitle1', children: ["(", capitalizeFirstLetter(value), ")"] })), comment && _jsx(Comments, { value: comment })] }))] }));
});
var Comments = React.memo(function (_a) {
    var value = _a.value;
    var classes = emotionalSurveyStyle({}).classes;
    return (_jsx(Tooltip, { classes: {
            tooltip: classes.tooltip,
            arrow: classes.customArrow,
        }, arrow: true, PopperProps: {
            disablePortal: true,
        }, placement: 'right', title: _jsxs(Box, { p: 0.5, minWidth: 200, display: 'flex', flexDirection: 'column', textAlign: 'left', children: [_jsx(Box, { mb: 1, children: _jsx(Typography, { variant: 'h5', children: "Comments" }) }), _jsx(Typography, { variant: 'body1', children: value })] }), children: _jsx(CommentIcon, { className: classes.commentIcon }) }));
});
var SurveyTable = function (_a) {
    var data = _a.data, _b = _a.isResident, isResident = _b === void 0 ? false : _b;
    var theme = useTheme();
    var classes = emotionalSurveyStyle({ isResident: isResident, isEmpty: !data.length }).classes;
    return (_jsxs(Box, { children: [_jsxs("table", { className: classes.table, "data-testid": 'survey-table', children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { children: isResident ? 'Date' : 'Time Stamp' }), [
                                    { head1: 'Relaxed', head2: 'Stressed' },
                                    { head1: 'Happy', head2: 'Sad' },
                                    { head1: 'Rested', head2: 'Tired' },
                                    { head1: 'Purpose', head2: 'No Purpose' },
                                    { head1: 'Busy', head2: 'Bored' },
                                    { head1: 'Social', head2: 'Lonely' },
                                    { head1: 'Pain Free', head2: 'Pain' },
                                ].map(function (item) {
                                    return isResident ? (_jsx(ResidentHeading, { head1: item.head1, head2: item.head2 }, item.head1)) : (_jsx(Heading, { head1: item.head1, head2: item.head2 }, item.head1));
                                }), _jsxs("th", { children: ["Wellness", _jsx("br", {}), "Score"] })] }) }), _jsx("tbody", { children: data === null || data === void 0 ? void 0 : data.map(function (surveyData, i) {
                            var _a;
                            if (surveyData.isEmpty) {
                                return (_jsx("tr", { children: Array(9).fill(_jsx("td", { children: _jsx("div", {}) })) }, i));
                            }
                            return (_jsxs("tr", { children: [_jsx("td", { children: moment(surveyData === null || surveyData === void 0 ? void 0 : surveyData.modification_date).format(isResident ? DATE_FORMAT : "".concat(DATE_FORMAT, " hh:mm A")) }), _jsx(Row, { value: surveyData.answer.relax.value, comment: surveyData.answer.relax.comment, positiveTitle: 'Relaxed', negativeTitle: 'Stressed', isResident: isResident }), _jsx(Row, { value: surveyData.answer.happiness.value, comment: surveyData.answer.happiness.comment, positiveTitle: 'Happy', negativeTitle: 'Sad', isResident: isResident }), _jsx(Row, { value: surveyData.answer.energy.value, comment: surveyData.answer.energy.comment, positiveTitle: 'Rested', negativeTitle: 'Tired', isResident: isResident }), _jsx(Row, { value: surveyData.answer.purpose.value, comment: surveyData.answer.purpose.comment, positiveTitle: 'Purpose', negativeTitle: 'No Purpose', isResident: isResident }), _jsx(Row, { value: surveyData.answer.engagement.value, comment: surveyData.answer.engagement.comment, positiveTitle: 'Busy', negativeTitle: 'Bored', isResident: isResident }), _jsx(Row, { value: surveyData.answer.social.value, comment: surveyData.answer.social.comment, positiveTitle: 'Social', negativeTitle: 'Lonely', isResident: isResident }), _jsx(Row, { value: surveyData.answer.comfort.value, comment: surveyData.answer.comfort.comment, positiveTitle: 'Pain Free', negativeTitle: 'Pain', isResident: isResident }), _jsx("td", { style: {
                                            padding: 0,
                                            color: isResident
                                                ? theme.palette.common.black
                                                : theme.palette.common.white,
                                            fontWeight: isResident ? 600 : 400,
                                        }, children: _jsx(Box, { display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center', className: clsx((_a = {},
                                                _a[classes.red] = surveyData.score >= 0 && surveyData.score <= 2,
                                                _a[classes.yellow] = surveyData.score >= 3 && surveyData.score <= 4,
                                                _a[classes.green] = surveyData.score >= 5 && surveyData.score <= 7,
                                                _a)), children: isResident
                                                ? surveyData.score
                                                : "".concat(surveyData.score, "/").concat(surveyData.scoreLimit) }) })] }, surveyData === null || surveyData === void 0 ? void 0 : surveyData.created_date));
                        }) })] }), data.length === 0 && (_jsx(Box, { display: 'flex', height: '40px', justifyContent: 'center', alignItems: 'center', children: "No record found" }))] }));
};
export default React.memo(SurveyTable);
