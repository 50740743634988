var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Checkbox, FormControlLabel, Typography, useTheme, Tooltip } from '@mui/material';
import InputSelectWrapper from 'common/InputFields/component/InputSelectWrapper';
import React from 'react';
import { initialZoneState, } from './Filters.types';
import { filtersStyle } from './Filters.style';
import { CHART_ZONES } from 'globals/enums';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { capitalizeFirstLetter } from 'globals/global.functions';
import FacilityTypeSelect from 'common/FacilityTypeSelect/FacilityTypeSelect.component';
import InfoIcon from '@mui/icons-material/Info';
var Filters = function (_a) {
    var state = _a.state, setState = _a.setState, residentCount = _a.residentCount, disabled = _a.disabled, _b = _a.facilityTypes, facilityTypes = _b === void 0 ? [] : _b, _c = _a.currentFacilityType, currentFacilityType = _c === void 0 ? '' : _c;
    var theme = useTheme();
    var classes = filtersStyle().classes;
    var monthOptions = React.useMemo(function () { return [
        {
            label: '3 Months',
            value: 3,
        },
        {
            label: '12 Months',
            value: 12,
        },
    ]; }, []);
    //zonefilter
    var zoneOptions = Object.values(CHART_ZONES);
    var handleSingleSelect = React.useCallback(function (key) {
        setState(function (state) {
            var _a;
            if (key === CHART_ZONES.OVERALL) {
                // One can only Check "Overall" option, cannot Uncheck it
                if (!state.zones[key]) {
                    return __assign(__assign({}, state), { zones: __assign({}, initialZoneState) });
                }
            }
            else {
                if (state.zones[key]) {
                    // Checked option gets unchecked & "Overall" option gets checked
                    return __assign(__assign({}, state), { zones: __assign({}, initialZoneState) });
                }
                else {
                    // Unchecked option gets Checked & "Overall" option gets Unchecked
                    return __assign(__assign({}, state), { zones: __assign(__assign({}, initialZoneState), (_a = {}, _a[key] = true, _a.overall = false, _a)) });
                }
            }
            return state;
        });
    }, [state.zones]);
    var handleMultipleSelect = React.useCallback(function (key) {
        setState(function (state) {
            var _a, _b;
            if (key === CHART_ZONES.OVERALL) {
                // One can only Check "Overall" option, cannot Uncheck it
                if (!state.zones[key]) {
                    return __assign(__assign({}, state), { zones: __assign({}, initialZoneState) });
                }
            }
            else {
                if (state.zones[key]) {
                    var zones = __assign(__assign({}, state.zones), (_a = {}, _a[key] = false, _a));
                    if (zones.gold || zones.blue || zones.green) {
                        // if any of the option is checked after unchecking, then checked options are returned
                        return __assign(__assign({}, state), { zones: zones });
                    }
                    else {
                        // if no option is checked after unchecking, then "Overall" option is checked
                        return __assign(__assign({}, state), { zones: __assign({}, initialZoneState) });
                    }
                }
                else {
                    // Unchecked option gets Checked & "Overall" option gets Unchecked
                    return __assign(__assign({}, state), { zones: __assign(__assign({}, state.zones), (_b = {}, _b[key] = true, _b.overall = false, _b)) });
                }
            }
            return state;
        });
    }, [state.zones]);
    React.useEffect(function () { return setState(function (state) { return (__assign(__assign({}, state), { zones: __assign({}, initialZoneState) })); }); }, [state.months]);
    var tooltipText = (_jsxs(React.Fragment, { children: [_jsx(Typography, { variant: 'h3', component: 'div', sx: { fontWeight: 'bold' }, children: "Understanding Resident Zones" }), _jsx(Typography, { variant: 'h3', component: 'div', sx: { fontWeight: 'bold' }, children: "White Zone:" }), _jsx(Typography, { component: 'div', children: "Welcome to new residents embarking on their wellness path. (not shown here)" }), _jsx(Typography, { variant: 'h3', component: 'div', sx: { fontWeight: 'bold' }, children: "Blue Zone:" }), _jsx(Typography, { component: 'div', children: "Focused on active goal- setting to outline personal objectives." }), _jsx(Typography, { variant: 'h3', component: 'div', sx: { fontWeight: 'bold' }, children: "Green Zone:" }), _jsx(Typography, { component: 'div', children: "Demonstrates active engagement and noticeable progress towards goals." }), _jsx(Typography, { variant: 'h3', component: 'div', sx: { fontWeight: 'bold' }, children: "Gold Zone:" }), _jsx(Typography, { component: 'div', children: "Celebrates the maintenance of achieved goals and overall wellness sustainability." }), _jsx(Typography, { component: 'div', children: "Each zone is designed to provide tailored support and recognition, guiding residents through their health and wellness journey within the ClearWellness program" })] }));
    return (_jsxs(Box, { style: {
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '20px',
            alignItems: 'center',
            flexWrap: 'wrap',
        }, children: [_jsx(Box, { ml: '6px', children: _jsxs(Typography, { variant: 'subtitle2', children: ["Residents: ", _jsx(Typography, { variant: 'h3v1', children: residentCount })] }) }), _jsxs(Box, { style: { display: 'flex' }, children: [_jsx(Box, { className: classes.zoneContainer, children: _jsx(FacilityTypeSelect, { selectedType: state.facilityType, facilityTypeMenu: currentFacilityType ? [currentFacilityType] : facilityTypes, onChange: function (e) {
                                var value = e.target.value;
                                setState(function (state) { return (__assign(__assign({}, state), { facilityType: value })); });
                            }, rootClass: classes.selectRoot, disabled: disabled || !!currentFacilityType }) }), _jsx(Box, { width: '140px', children: _jsx(InputSelectWrapper, { menu: true, inputProps: {
                                name: 'months',
                            }, menuItems: monthOptions, white: true, eventProps: {
                                onChange: function (e) {
                                    return setState(function (state) { return (__assign(__assign({}, state), { months: e.target.value })); });
                                },
                                value: state.months,
                                'data-testid': 'months-input',
                                style: {
                                    backgroundColor: theme.palette.common.white,
                                    borderRadius: '8px',
                                    border: "1px solid ".concat(theme.palette.customColor.borderGrey),
                                    borderTop: "1px solid ".concat(theme.palette.customColor.borderGrey),
                                },
                                disabled: disabled,
                            }, hideSelect: true }) }), _jsxs(Box, { className: classes.zoneContainer, children: [_jsxs(Typography, { variant: 'subtitle2', mr: '16px', style: { display: "inline-flex", marginTop: '5px' }, children: [_jsx("div", { style: { marginTop: '1px' }, children: "Select Zone:" }), _jsx(Tooltip, { title: tooltipText, children: _jsx(Box, { children: _jsx(InfoIcon, { style: { top: '2px', position: 'relative' }, fontSize: 'medium' }) }) })] }), zoneOptions.map(function (option) { return (_jsx(FormControlLabel, { disabled: disabled, control: _jsx(Checkbox, { icon: _jsx(Box, { className: classes.blankCheckbox }), checkedIcon: _jsx(CheckBoxIcon, { style: { color: theme.palette.customColor.highlight } }), checked: state.zones[option], onClick: function () {
                                        return state.months === 12
                                            ? handleSingleSelect(option)
                                            : handleMultipleSelect(option);
                                    }, sx: {
                                        padding: '2px 4px',
                                        transform: 'scale(1.15)',
                                    } }), label: capitalizeFirstLetter(option) }, option)); })] })] })] }));
};
export default Filters;
