var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { makeStyles } from 'tss-react/mui';
import { TemplateTypography } from 'common/Print/Print.utility';
export var fieldsStyle = makeStyles()(function () { return ({
    radioComponent: {
        '& svg': {
            width: '40px',
            height: '40px',
        },
    },
    labelStyle: __assign({}, TemplateTypography.regular),
}); });
