/**
 * @class MedicationListModel
 * @description class to maintain the medication list assessment  model
 */
var MedicationListModel = /** @class */ (function () {
    function MedicationListModel(medicationName, doseForm, doseFrequency, whenDoTheyTakeIt, datePrescribed, dateDiscontinued, pharmacyName, pharmacyPhone, notes, lastSaved, medicationID, status) {
        this.medicationName = medicationName;
        this.doseForm = doseForm;
        this.doseFrequency = doseFrequency;
        this.whenDoTheyTakeIt = whenDoTheyTakeIt;
        this.datePrescribed = datePrescribed;
        this.dateDiscontinued = dateDiscontinued;
        this.pharmacyName = pharmacyName;
        this.pharmacyPhone = pharmacyPhone;
        this.notes = notes;
        this.lastSaved = lastSaved;
        this.medicationID = medicationID;
        this.status = status;
    }
    return MedicationListModel;
}());
export default MedicationListModel;
