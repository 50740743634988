import { jsx as _jsx } from "react/jsx-runtime";
import { TableContainer } from '@mui/material';
import Table from 'common/Table/Table.component';
import { PRIMARY_COLOR } from 'globals/enums';
import { DATE_FORMAT } from 'globals/global.constants';
import moment from 'moment';
var HEADER_DATA = [
    {
        columnId: 'date',
        label: 'Date',
        style: {
            paddingLeft: '40px',
        },
    },
    {
        columnId: 'time',
        label: 'Time',
    },
    {
        columnId: 'message',
        label: 'Message',
        style: {
            paddingRight: '40px',
        },
    },
];
var ROW_DATA = {
    values: [
        {
            dataId: 'dateUpdated',
            dataKey: 'date',
            format: function (value) { return moment(value).format(DATE_FORMAT); },
            style: {
                paddingLeft: '40px',
                width: '160px',
                maxWidth: '160px',
            },
        },
        {
            dataId: 'dateUpdated',
            dataKey: 'time',
            format: function (value) { return moment(value).format('hh:mm'); },
            style: {
                width: '80px',
                maxWidth: '80px',
            },
        },
        {
            dataId: 'message',
            style: {
                paddingRight: '40px',
            },
        },
    ],
};
var FacilitySummaryTable = function (_a) {
    var _b = _a.data, data = _b === void 0 ? [] : _b, _c = _a.loading, loading = _c === void 0 ? false : _c, _d = _a.height, height = _d === void 0 ? '475px' : _d;
    return (_jsx(TableContainer, { sx: { maxHeight: height, height: height }, "data-testid": 'facilitySummaryTableContainer', children: _jsx(Table, { rowId: 'careInsightId', rowData: ROW_DATA, headerData: HEADER_DATA, primaryColor: PRIMARY_COLOR.GREEN, data: data, isFilterLoading: loading }) }));
};
export default FacilitySummaryTable;
