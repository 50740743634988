var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { fieldsStyle } from '../InputFields.style';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import clsx from 'clsx';
import { InputControlledDatePicker } from '../../Input';
import { DATE_FORMAT } from '../../../globals/global.constants';
import { Typography } from '@mui/material';
var SelectControlledDate = function (_a) {
    var _b, _c, _d;
    var inputProps = _a.inputProps, eventProps = _a.eventProps, props = __rest(_a, ["inputProps", "eventProps"]);
    var classes = fieldsStyle().classes;
    return (_jsxs(LocalizationProvider, { dateAdapter: AdapterDateFns, children: [_jsx(InputControlledDatePicker, __assign({ id: props.label, clearable: true, style: { marginBottom: 4 }, className: clsx((_b = {}, _b[classes.inputDateError] = props.isError, _b)), variant: 'inline', autoOk: true, InputProps: {
                    disableUnderline: true,
                    readOnly: props.readOnly || false,
                }, 
                //using data-error prop for autoscroll to the first error on the screen
                inputProps: __assign(__assign({ placeholder: DATE_FORMAT }, inputProps), { 'data-error': props.isError ? 'error' : '' }) }, eventProps, { invalidDateMessage: '', 
                // maxDateMessage={DATE_ERROR_MESSAGE.futureDateDisable}
                format: 'MM/dd/yyyy' })), props.isError && props.errorText && (_jsx(Typography, { variant: 'subtitle1', className: clsx((_c = {}, _c[classes.errorTextSelect] = true, _c), (_d = {}, _d[classes.errorCustomStyle] = props.isErrorCustomeStyle, _d)), children: props.errorText }))] }));
};
export default SelectControlledDate;
