var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { assessmentStates } from './Assessments.type';
import { GET_ASSESSMENT, RESET_ASSESSMENT, GET_ASSESSMENT_HISTORY_SUCCESS, UPDATE_ASSESSMENT_HISTORY_PAGE_NUMBER, TOGGLE_VIEW_STATE, TOGGLE_IS_COMPLETED, GET_LOADING_STATUS, SEARCH_RESULT_SUCCESS, RESET_SEARCH, SEARCH_RESULT_FAIL, GET_SEARCH_RESULT, UPDATE_ASSESSMENT_PAGE_NUMBER, } from './Assessments.action';
import { RESET_ASSESSMENT_STATUS, UPDATE_ASSESSMENT_STATUS, } from './AssessmentStatus/AssessmentStatus.action';
import { initialAssessmentStatus } from './AssessmentStatus/AssessmentStatus.types';
export var assessmentsReducer = function (state, action) {
    if (state === void 0) { state = assessmentStates; }
    switch (action.type) {
        case GET_LOADING_STATUS: {
            return __assign(__assign({}, state), { loading: !!action.payload });
        }
        case GET_ASSESSMENT:
            return __assign(__assign({}, state), { surveys: action.payload.surveys, assessmentStatus: action.payload.assessmentStatus, assessmentId: action.payload.assessmentId || '', versionNumber: action.payload.versionNumber, dateTime: action.payload.dateTime, careAgentId: action.payload.careAgentId, offset: action.payload.offset });
        case UPDATE_ASSESSMENT_PAGE_NUMBER:
            return __assign(__assign({}, state), { currentPage: action.payload || 1 });
        case RESET_ASSESSMENT:
            return __assign(__assign({}, assessmentStates), { assessmentStatuses: __assign({}, state.assessmentStatuses) });
        case GET_ASSESSMENT_HISTORY_SUCCESS: {
            return __assign(__assign({}, state), { history: __assign(__assign({}, state.history), { data: __spreadArray([], action.payload.data, true), lastEvaluatedKey: action.payload.lastEvaluatedKey, loading: false }) });
        }
        case UPDATE_ASSESSMENT_HISTORY_PAGE_NUMBER: {
            return __assign(__assign({}, state), { history: __assign(__assign({}, state.history), { currentPage: action.payload }) });
        }
        case TOGGLE_VIEW_STATE: {
            return __assign(__assign({}, state), { isHistory: action.payload.isHistory, dateTime: action.payload.dateTime, caregiverId: action.payload.caregiverId || '' });
        }
        case TOGGLE_IS_COMPLETED: {
            return __assign(__assign({}, state), { isCompleted: action.payload.isCompleted });
        }
        case GET_SEARCH_RESULT: {
            return __assign(__assign({}, state), { searchLoading: true });
        }
        case SEARCH_RESULT_SUCCESS: {
            return __assign(__assign({}, state), { searchResult: action.payload, searchLoading: false });
        }
        case SEARCH_RESULT_FAIL: {
            return __assign(__assign({}, state), { searchLoading: false });
        }
        case RESET_SEARCH:
            return __assign(__assign({}, state), { searchResult: assessmentStates.searchResult });
        case UPDATE_ASSESSMENT_STATUS: {
            return __assign(__assign({}, state), { assessmentStatuses: __assign(__assign({}, state.assessmentStatuses), action.payload) });
        }
        case RESET_ASSESSMENT_STATUS: {
            return __assign(__assign({}, state), { assessmentStatuses: initialAssessmentStatus });
        }
        default:
            return state;
    }
};
