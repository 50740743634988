var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { API } from 'globals/api';
import { showApplicationLoader, hideApplicationLoader, } from 'common/ApplicationLoader';
import { showToast } from 'common/Toast';
import { APPLICATION_CODE } from 'globals/applicationCodes';
import { formatDateTime, toIsoString } from 'globals/global.functions';
export var CHECK_CALL_SCHEDULED = 'CHECK_CALL_SCHEDULED';
export var CHECK_CALL_SCHEDULED_FAILED = 'CHECK_CALL_SCHEDULED_FAILED';
export var CLEAR_MESSAGE = 'CLEAR_MESSAGE';
export var fetchCareGiverInfo = function (seniorInfo) {
    return function () {
        return API({
            // eslint-disable-next-line max-len
            url: "users/senior/care-circle?senior_id=".concat(seniorInfo.seniorID, "&account_id=").concat(seniorInfo.accountID),
            method: 'get',
        }).then(function (res) {
            return res.data;
        });
    };
};
export var initiateCallSchedule = function (data, checkCallScheduledFields, closeDialog) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var scheduleCall, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    dispatch(showApplicationLoader());
                    return [4 /*yield*/, API({
                            // eslint-disable-next-line max-len
                            url: "supervision/call-scheduler",
                            method: 'post',
                            data: data,
                        })];
                case 1:
                    scheduleCall = _a.sent();
                    dispatch(hideApplicationLoader());
                    dispatch(showToast('Call Scheduled Successfully!', 'success'));
                    if (closeDialog) {
                        closeDialog();
                    }
                    return [2 /*return*/, scheduleCall.data];
                case 2:
                    error_1 = _a.sent();
                    dispatch(hideApplicationLoader());
                    dispatch(showToast('Call Scheduled Unsuccessful!', 'error'));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var updateCallSchedule = function (data) {
    return function (dispatch) {
        dispatch(showApplicationLoader());
        return API({
            // eslint-disable-next-line max-len
            url: "supervision/update-call-scheduler",
            method: 'put',
            data: data,
        }).then(function (res) {
            dispatch(hideApplicationLoader());
            dispatch(showToast('Call Updated Successfully!', 'success'));
            return res.data;
        });
    };
};
export var getCompletedCallDetail = function (user_id) {
    return function () {
        return API({
            url: "supervision/callee-call-scheduler",
            method: 'get',
            params: {
                callee_id: user_id,
                call_status: 'completed',
                limit: 1,
            },
        }).then(function (res) {
            return res.data;
        });
    };
};
export var getPendingCallDetail = function (user_id) {
    return function () {
        var currentTime = toIsoString(new Date());
        return API({
            url: "supervision/callee-call-scheduler",
            method: 'get',
            params: {
                callee_id: user_id,
                call_status: 'pending',
                gte_time: currentTime,
            },
        }).then(function (res) {
            return res.data;
        });
    };
};
export var getCareAgentList = function () {
    return function () {
        return API({
            url: "users/query/careagent-list",
            method: 'get',
        }).then(function (res) {
            return res.data;
        });
    };
};
/**
 * @description action to fetch the available call slot through API.
 * @param {callerList, callDuration, callTime, callDate, assignedCareAgent} data
 * @returns void
 */
export var checkCallScheduled = function (data, callInfo) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var params, slotRes, error_2, _a, _b, application_code, _c, message;
    var _d;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                _e.trys.push([0, 2, , 3]);
                dispatch(showApplicationLoader());
                params = {
                    callee_id: data.callerList,
                    duration: data.callDuration,
                    start_time: formatDateTime(data.callDate, data.callTime),
                    careagent_id: data.assignedCareAgent,
                };
                //while edit call add exclude
                if (callInfo) {
                    if (callInfo.callee_id === params.callee_id ||
                        callInfo.careagent_id === params.careagent_id) {
                        params.exclude = callInfo.call_id;
                    }
                }
                return [4 /*yield*/, API({
                        url: 'supervision/check-scheduler-slot',
                        method: 'post',
                        params: params,
                    })];
            case 1:
                slotRes = _e.sent();
                dispatch(hideApplicationLoader());
                return [2 /*return*/, slotRes];
            case 2:
                error_2 = _e.sent();
                dispatch(hideApplicationLoader());
                _a = ((_d = error_2 === null || error_2 === void 0 ? void 0 : error_2.response) === null || _d === void 0 ? void 0 : _d.data) || {}, _b = _a.application_code, application_code = _b === void 0 ? '400' : _b, _c = _a.message, message = _c === void 0 ? 'Something went wrong.' : _c;
                //display alert message when call slot conflicts occurs
                if (application_code === APPLICATION_CODE.callScheduleCAConflictCode ||
                    application_code === APPLICATION_CODE.callScheduleUserConflictCode) {
                    dispatch({
                        type: CHECK_CALL_SCHEDULED_FAILED,
                        payload: message,
                    });
                }
                return [2 /*return*/, error_2];
            case 3: return [2 /*return*/];
        }
    });
}); }; };
/**
 * @description action creator to clear out the slot alert message
 * @returns void
 */
export var clearCheckCallSchedluedMessage = function () { return function (dispatch) {
    dispatch({ type: CLEAR_MESSAGE });
}; };
