var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { END_PAGINATION, GET_CARE_AGENTS, RESET_CARE_AGENTS, RESET_PAGINATION, } from './CareAgentAccount.actions';
var careAgentAccountInitialStates = {
    allCareAgentAccounts: null,
    isPaginate: true,
};
/**
 * @description admin careAgentAccount reducer
 * @returns care agent states
 */
export var careAgentAccountReducer = function (state, action) {
    if (state === void 0) { state = careAgentAccountInitialStates; }
    switch (action.type) {
        case GET_CARE_AGENTS: {
            return __assign(__assign({}, state), { allCareAgentAccounts: __spreadArray(__spreadArray([], ((state === null || state === void 0 ? void 0 : state.allCareAgentAccounts) || []), true), action.payload, true) });
        }
        case RESET_CARE_AGENTS: {
            return __assign(__assign({}, state), { allCareAgentAccounts: null });
        }
        case RESET_PAGINATION: {
            return __assign(__assign({}, state), { isPaginate: true });
        }
        case END_PAGINATION: {
            return __assign(__assign({}, state), { isPaginate: false });
        }
        default: {
            return state;
        }
    }
};
