import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable max-len */
import { Avatar } from '@mui/material';
import React from 'react';
/**
 * @description common component which create an avatar
 * @param {string} altText alternate text will display when image is not able to load because of any issue
 * @param {string} imageUrl url of image
 * @param {React.ReactNode} child any component which we want to show as a child
 * @param {any} className styling
 * @param {any} style styling props
 * @param {any} component type of component
 * @param {AvatarVariants} variant varient of avatar
 * @returns an avatar
 */
var AvatarComponent = function (_a) {
    var altText = _a.altText, imageUrl = _a.imageUrl, child = _a.child, className = _a.className, style = _a.style, component = _a.component, variant = _a.variant;
    return (_jsx(_Fragment, { children: _jsx(Avatar, { alt: altText, src: imageUrl, className: className, style: style, component: component, variant: variant, children: child }) }));
};
export default AvatarComponent;
