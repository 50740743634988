import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@mui/system';
import SleepGoal from '../sleepGoal';
import SleepPercentage from '../sleepPercentage';
import { CircularProgress } from 'common/CircularProgress';
var PercentageComponent = function (_a) {
    var value = _a.value, sleepGoal = _a.sleepGoal, percentage = _a.percentage;
    return (_jsxs(Box, { display: 'flex', justifyContent: 'center', alignItems: 'center', children: [_jsx(CircularProgress, { value: value, goal: sleepGoal, fontSize: 80 }), _jsx(SleepPercentage, { percentage: percentage !== null && percentage !== void 0 ? percentage : value })] }));
};
var SleepQualityGoals = function (_a) {
    var value = _a.value, percentage = _a.percentage, noRecordFound = _a.noRecordFound, onGoalGetSuccess = _a.onGoalGetSuccess, _b = _a.sleepGoal, sleepGoal = _b === void 0 ? 100 : _b;
    return (_jsxs(Box, { style: { alignItems: 'center' }, "data-testid": 'Sleep-quality-goals', children: [_jsx(SleepGoal, { width: 370, onGoalGetSuccess: onGoalGetSuccess }), !noRecordFound && (_jsx(PercentageComponent, { value: value, sleepGoal: sleepGoal, percentage: percentage }))] }));
};
export default SleepQualityGoals;
