import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, CircularProgress, useTheme } from '@mui/material';
import { useMemo } from 'react';
var SemiCircularProgress = function (_a) {
    var _b = _a.value, value = _b === void 0 ? 0 : _b, _c = _a.display, display = _c === void 0 ? '' : _c, _d = _a.size, size = _d === void 0 ? 300 : _d, color = _a.color, bgColor = _a.bgColor, height = _a.height, thickness = _a.thickness;
    var theme = useTheme();
    var percentValue = useMemo(function () {
        if (value > 100) {
            return 100;
        }
        else if (value < 0) {
            return 0;
        }
        return value;
    }, [value]);
    return (_jsxs(Box, { position: 'relative', "data-testid": 'semi-circular-progress', children: [_jsx(Box, { height: height !== null && height !== void 0 ? height : "".concat(size / 2, "px"), overflow: 'hidden', mb: 0.75, children: _jsxs(Box, { width: "".concat(size, "px"), height: "".concat(size, "px"), position: 'relative', style: { transform: 'rotate(-90deg)' }, children: [_jsx(CircularProgress, { value: 100, variant: 'determinate', size: '100%', style: {
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                color: bgColor || theme.palette.customColor.bgGrey,
                            }, thickness: thickness }), _jsx(CircularProgress, { value: percentValue / 2, variant: 'determinate', size: '100%', thickness: thickness, style: {
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                color: color || theme.palette.primary.main,
                            } })] }) }), _jsx(Box, { position: 'absolute', width: '100%', left: 0, top: "".concat(size / 4, "px"), display: 'flex', justifyContent: 'center', children: display })] }));
};
export default SemiCircularProgress;
