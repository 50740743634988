var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { API } from 'globals/api';
import { getClientTimezone } from 'globals/date.functions';
import { getCurrentSenior } from 'globals/global.functions';
import get from 'lodash.get';
var logError = function (error) {
    var message = get(error, 'response.data.message');
    // eslint-disable-next-line no-console
    console.error(message);
};
var clientTimezone = getClientTimezone();
var GraphAPI = {
    fetchHeartExtreme: function (startTime, endTime, info) {
        if (info === void 0) { info = __assign({}, getCurrentSenior()); }
        return API({
            url: "measurement/vitals/heart-extreme",
            method: 'get',
            params: {
                start_timestamp: startTime,
                end_timestamp: endTime,
                senior_id: info.seniorID,
                account_id: info.accountID,
                timezone: clientTimezone,
            },
        })
            .then(function (data) {
            return get(data, 'data.data[0]');
        })
            .catch(logError);
    },
    fetchHeartRate: function (startTime, endTime, info) {
        if (info === void 0) { info = __assign({}, getCurrentSenior()); }
        return API({
            url: "measurement/vitals/heart",
            method: 'get',
            params: {
                start_timestamp: startTime,
                end_timestamp: endTime,
                senior_id: info.seniorID,
                account_id: info.accountID,
                timezone: clientTimezone,
            },
        })
            .then(function (data) {
            return get(data, 'data.data');
        })
            .catch(logError);
    },
    fetchActivitySeries: function (startTime, endTime, info) {
        if (info === void 0) { info = __assign({}, getCurrentSenior()); }
        return API({
            url: "measurement/vitals/activity-series",
            method: 'get',
            params: {
                start_timestamp: startTime,
                end_timestamp: endTime,
                senior_id: info.seniorID,
                account_id: info.accountID,
                timezone: clientTimezone,
            },
        })
            .then(function (data) {
            return get(data, 'data.data');
        })
            .catch(logError);
    },
    fetchRespirationExtreme: function (startTime, endTime, info) {
        if (info === void 0) { info = __assign({}, getCurrentSenior()); }
        return API({
            url: "measurement/vitals/respiration-extreme",
            method: 'get',
            params: {
                start_timestamp: startTime,
                end_timestamp: endTime,
                senior_id: info.seniorID,
                account_id: info.accountID,
                timezone: clientTimezone,
            },
        })
            .then(function (data) {
            return get(data, 'data.data[0]');
        })
            .catch(logError);
    },
    fetchRespirationRate: function (startTime, endTime, info) {
        if (info === void 0) { info = __assign({}, getCurrentSenior()); }
        return API({
            url: "measurement/vitals/respiration",
            method: 'get',
            params: {
                start_timestamp: startTime,
                end_timestamp: endTime,
                senior_id: info.seniorID,
                account_id: info.accountID,
                timezone: clientTimezone,
            },
        })
            .then(function (data) {
            return get(data, 'data.data');
        })
            .catch(logError);
    },
    fetchBodyHealthMeasurement: function (startTime, endTime, info) {
        if (info === void 0) { info = __assign({}, getCurrentSenior()); }
        return API({
            url: "measurement/body-health/raw-measurement",
            method: 'get',
            params: {
                start_timestamp: startTime,
                end_timestamp: endTime,
                senior_id: info.seniorID,
                account_id: info.accountID,
                timezone: clientTimezone,
            },
        })
            .then(function (data) {
            return __assign(__assign({}, get(data, 'data.data', {})), { goal_percentile: get(data, 'data.goal_percentile', {}), last: get(data, 'data.last', null) });
        })
            .catch(logError);
    },
    fetchBodyHealthDifference: function (startTime, endTime, info) {
        if (info === void 0) { info = __assign({}, getCurrentSenior()); }
        return API({
            url: "measurement/body-health/raw-difference",
            method: 'get',
            params: {
                start_timestamp: startTime,
                end_timestamp: endTime,
                senior_id: info.seniorID,
                account_id: info.accountID,
                timezone: clientTimezone,
            },
        })
            .then(function (data) {
            return get(data, 'data.data');
        })
            .catch(logError);
    },
    fetchBodyHealthGraph: function (type, startTime, endTime, info) {
        if (info === void 0) { info = __assign({}, getCurrentSenior()); }
        return API({
            // eslint-disable-next-line max-len
            url: "measurement/body-health/body-graph",
            method: 'get',
            params: {
                measurement_type: type,
                start_timestamp: startTime,
                end_timestamp: endTime,
                senior_id: info.seniorID,
                account_id: info.accountID,
                timezone: clientTimezone,
            },
        })
            .then(function (data) {
            return get(data, 'data.data');
        })
            .catch(logError);
    },
    fetchActivityScore: function (startTime, endTime, info) {
        if (info === void 0) { info = __assign({}, getCurrentSenior()); }
        return API({
            url: "measurement/vitals/activity-score",
            method: 'get',
            params: {
                senior_id: info.seniorID,
                account_id: info.accountID,
                start_timestamp: startTime,
                end_timestamp: endTime,
                timezone: clientTimezone,
            },
        })
            .then(function (data) {
            return get(data, 'data');
        })
            .catch(logError);
    },
};
export var GraphAPIRegexUrls = {
    heartExtreme: /measurement\/vitals\/heart-extreme\/?.*/,
    heart: /measurement\/vitals\/heart\/?.*/,
    activityGoal: /measurement\/vitals\/activity-goal\/?.*/,
    activitySeries: /measurement\/vitals\/activity-series\/?.*/,
    activityScore: /measurement\/vitals\/activity-score\/?.*/,
    respirationExtreme: /measurement\/vitals\/respiration-extreme\/?.*/,
    respiration: /measurement\/vitals\/respiration\/?.*/,
    bodyHealthMeasurement: /measurement\/body-health\/raw-measurement\/?.*/,
    bodyHealthDifference: /measurement\/body-health\/raw-difference\/?.*/,
    bodyHealthGraph: /measurement\/body-health\/body-graph\/?.*/,
    sleepDaily: /measurement\/sleep\/sleep-daily\/?.*/,
    sleepDepth: /measurement\/sleep\/sleep-depth\/?.*/,
    sleepPhase: /measurement\/sleep\/sleep-phase\/?.*/,
    sleepHR: /measurement\/sleep\/sleep-hr\/?.*/,
    location: /measurement\/alarm\/location\/?.*/,
};
export default GraphAPI;
