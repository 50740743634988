import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
/**
 * @description React component to display City and State name.
 * @param {StateCityFormatterProps} props
 * @returns JSX
 */
var StateCityFormatter = function (props) {
    var city = props.city, state = props.state;
    if (!city) {
        return _jsx(React.Fragment, { children: "-" });
    }
    return (_jsxs(React.Fragment, { children: [city, ",\u00A0", state] }));
};
export default StateCityFormatter;
