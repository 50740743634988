import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { useTheme } from '@mui/material';
var ErrorIcon = function (props) {
    var theme = useTheme();
    var className = props.className, _a = props.color, color = _a === void 0 ? theme.palette.error.light : _a;
    return (_jsx(ErrorOutlineOutlinedIcon, { className: className, style: {
            color: color,
        } }));
};
export default ErrorIcon;
