var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { convertDateInUTC } from 'globals/date.functions';
import { API_LOAD_STATE } from 'globals/enums';
import { GET_DASHBOARD_CARE_INSIGHT, GET_DASHBOARD_CARE_INSIGHT_FAIL, GET_DASHBOARD_CARE_INSIGHT_SUCCESS, } from './components/CareInsights/CareInsights.action';
import { GET_WELLNESS_SURVEY, GET_WELLNESS_SURVEY_FAIL, GET_WELLNESS_SURVEY_SUCCESS, IS_DATE_ERROR, RESET_DATE_FILTER, RESET_WELLNESS_SURVEY, CLEAR_SURVEYS, UPDATE_TO_DATE, UPDATE_FROM_DATE, UPDATE_CURRENT_PAGE, } from '../Assessments/Wellbieng/WellnessSurvey/WellnessSurvey.action';
var INITIAL_STATE = {
    wellnessSurvey: {
        allSurveys: [],
        surveyHeaders: [],
        loading: API_LOAD_STATE.NOT_LOADED,
        fromDate: '',
        toDate: new Date(),
        isDateError: false,
        lastEvaluatedKey: '',
        currentPage: 1,
    },
    careInsightHistory: {
        data: [],
        loading: false,
    },
};
/**
 * @description senior dashboard reducer
 * @returns wellness states
 */
export var seniorDashboardReducer = function (state, action) {
    if (state === void 0) { state = INITIAL_STATE; }
    switch (action.type) {
        case GET_WELLNESS_SURVEY:
            return __assign(__assign({}, state), { wellnessSurvey: __assign(__assign({}, state.wellnessSurvey), { loading: API_LOAD_STATE.PROGRESS }) });
        case GET_WELLNESS_SURVEY_SUCCESS:
            return __assign(__assign({}, state), { wellnessSurvey: __assign(__assign({}, state.wellnessSurvey), { loading: API_LOAD_STATE.SUCCESSFUL, allSurveys: action.payload.allSurveys, lastEvaluatedKey: action.payload.lastEvaluatedKey }) });
        case GET_WELLNESS_SURVEY_FAIL:
            return __assign(__assign({}, state), { wellnessSurvey: __assign(__assign({}, state.wellnessSurvey), { loading: API_LOAD_STATE.ERROR }) });
        case UPDATE_TO_DATE: {
            return __assign(__assign({}, state), { wellnessSurvey: __assign(__assign({}, state.wellnessSurvey), { toDate: action.payload.date, isDateError: false }) });
        }
        case UPDATE_FROM_DATE: {
            return __assign(__assign({}, state), { wellnessSurvey: __assign(__assign({}, state.wellnessSurvey), { fromDate: convertDateInUTC(action.payload.date), isDateError: false }) });
        }
        case IS_DATE_ERROR: {
            return __assign(__assign({}, state), { wellnessSurvey: __assign(__assign({}, state.wellnessSurvey), { isDateError: action.payload }) });
        }
        case RESET_DATE_FILTER: {
            return __assign(__assign({}, state), { wellnessSurvey: __assign(__assign({}, state.wellnessSurvey), { fromDate: convertDateInUTC(action.payload.priorThirtyDayDate), toDate: new Date(), isDateError: false, allSurveys: [], lastEvaluatedKey: null }) });
        }
        case CLEAR_SURVEYS: {
            return __assign(__assign({}, state), { wellnessSurvey: __assign(__assign({}, state.wellnessSurvey), { allSurveys: [], lastEvaluatedKey: null }) });
        }
        case RESET_WELLNESS_SURVEY: {
            return __assign({}, INITIAL_STATE);
        }
        case UPDATE_CURRENT_PAGE: {
            return __assign(__assign({}, state), { wellnessSurvey: __assign(__assign({}, state.wellnessSurvey), { currentPage: action.payload }) });
        }
        case GET_DASHBOARD_CARE_INSIGHT: {
            return __assign(__assign({}, state), { careInsightHistory: __assign(__assign({}, state.careInsightHistory), { loading: true }) });
        }
        case GET_DASHBOARD_CARE_INSIGHT_SUCCESS: {
            return __assign(__assign({}, state), { careInsightHistory: __assign(__assign({}, state.careInsightHistory), { data: action.payload.data, loading: false }) });
        }
        case GET_DASHBOARD_CARE_INSIGHT_FAIL: {
            return __assign(__assign({}, state), { careInsightHistory: __assign(__assign({}, state.careInsightHistory), { loading: false }) });
        }
        default:
            return state;
    }
};
