import { makeStyles } from 'tss-react/mui';
var facilityDashboard = makeStyles()(function (theme) { return ({
    container: {
        backgroundColor: theme.palette.common.white,
        borderRadius: '16px',
        padding: '16px 10px',
        boxShadow: "0 2px 8px 0 ".concat(theme.palette.customColor.boxShadow),
        alignItems: 'center',
    },
    zoneGraphContainer: {
        display: 'flex',
        gap: '1rem',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
    },
}); });
export default facilityDashboard;
