import { initialAssessmentStatus, } from './AssessmentStatus/AssessmentStatus.types';
export var assessmentStates = {
    surveys: null,
    loading: false,
    searchLoading: false,
    assessmentId: '',
    assessmentStatus: '',
    searchResult: [],
    caregiverId: '',
    history: {
        data: [],
        lastEvaluatedKey: '',
        loading: false,
        totalRows: 0,
        currentPage: 1,
    },
    isHistory: false,
    dateTime: '',
    isCompleted: true,
    assessmentStatuses: initialAssessmentStatus,
    offset: 0,
    currentPage: 1,
};
