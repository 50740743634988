var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Slider, Box } from '@mui/material';
export var ProgressBar = function (_a) {
    var inputProps = _a.inputProps, eventProps = _a.eventProps, props = __rest(_a, ["inputProps", "eventProps"]);
    function valuetext(value) {
        return "".concat(value);
    }
    return (_jsx(Box, { width: '100%', className: inputProps === null || inputProps === void 0 ? void 0 : inputProps.classes, style: { padding: 20 }, "data-testid": inputProps.dataTestid, children: _jsx(Slider, { value: eventProps.value, onChange: eventProps.onChange, defaultValue: props.initialValue, getAriaValueText: valuetext, step: props.step, marks: props.marks, valueLabelDisplay: props.valueLabelDisplay }) }));
};
