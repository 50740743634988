import CorporateListModel from '../model/corporateListModel';
var CorporateListParser = /** @class */ (function () {
    function CorporateListParser() {
        this.corporateList = [];
    }
    /**
     * @description function to parse the response data
     * @function parse
     * @param {ICorporateManagementAPIData} data api response array data
     * @returns {ICorporateManagementData[]} parsed data
     */
    CorporateListParser.prototype.parse = function (data) {
        this.corporateList = data.map(function (corporate) {
            return new CorporateListModel(corporate.id, corporate.name, corporate.phone_office, corporate.facility_count, corporate.total_residents);
        });
        return this.corporateList;
    };
    return CorporateListParser;
}());
export default CorporateListParser;
