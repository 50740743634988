import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Box, TableContainer } from '@mui/material';
import Table from 'common/Table/Table.component';
import { PRIMARY_COLOR } from 'globals/enums';
import { goalPercentageStyle } from './GoalPercentage.style';
var HEADER_DATA = [
    {
        columnId: 'goals',
        label: 'Goals',
        style: { padding: '5px 10px 5px 16px' },
    },
    {
        columnId: 'percentage',
        label: 'Progress',
        style: { padding: '5px 16px 5px 16px', width: '10px' },
    },
];
var GoalPercentageTable = function (_a) {
    var _b = _a.data, data = _b === void 0 ? [] : _b, _c = _a.loading, loading = _c === void 0 ? false : _c;
    var classes = goalPercentageStyle().classes;
    var ROW_DATA = {
        values: [
            {
                dataId: 'action',
                render: function (value) {
                    return _jsx(Box, { className: classes.row, children: value });
                },
            },
            {
                dataId: 'progress',
                render: function (value) { return _jsxs(Box, { className: classes.row, children: [value, "%"] }); },
            },
        ],
    };
    return (_jsx(Box, { mt: '26px', height: '252px', overflow: 'auto', children: _jsx(TableContainer, { "data-testid": 'goalPercentageTable', children: _jsx(Table, { rowId: 'careInsightId', rowData: ROW_DATA, headerData: HEADER_DATA, primaryColor: PRIMARY_COLOR.GREEN, data: data, isFilterLoading: loading }) }) }));
};
export default GoalPercentageTable;
