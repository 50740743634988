var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useQueries } from '@tanstack/react-query';
import { useParams, useLocation } from 'react-router-dom';
var createBreadcrumbOptions = function (disable, isLoading, path, value, roles) {
    return {
        disable: disable,
        isLoading: isLoading,
        path: path,
        value: value,
        roles: roles,
    };
};
/**
 *
 * @param {IQueryOptions} optionsData queryOptions data
 * @param {FACILITY_BREADCRUMB_OPTIONS} breadcrumbOptionsArray number of breadcrumbs to display
 * @returns
 */
var useGenerateBreadcrumbData = function (optionsData, breadcrumbOptionsArray, currentUserRoles) {
    var urlParams = useParams();
    var location = useLocation();
    var hasRoleAccess = function (options) {
        return currentUserRoles.some(function (requireRole) {
            return options.roles.includes(requireRole);
        });
    };
    //Filter options to display
    var filteredOptionsArray = breadcrumbOptionsArray.filter(function (option) {
        return (urlParams[option] ||
            urlParams[optionsData[option].slug] ||
            location.pathname.includes("/".concat(option))) &&
            hasRoleAccess(optionsData[option]);
    });
    var fetchedData = useQueries({
        queries: filteredOptionsArray.map(function (option) { return ({
            queryKey: ['optionData', option],
            queryFn: optionsData[option].fetchAsync,
            onSuccess: function (data) { return optionsData[option].onSuccess(data); },
            refetchOnWindowFocus: false,
            retry: false,
        }); }),
    });
    var breadcrumbData = {};
    filteredOptionsArray.forEach(function (optionName, index) {
        var queryData = fetchedData[index];
        var _a = optionsData[optionName].dataModifier(queryData.data), path = _a.path, data = _a.data, dependentPath = _a.dependentPath;
        //in case path value is dependent on some different option & dependent path has role access
        if (dependentPath && breadcrumbData[dependentPath.key]) {
            breadcrumbData[dependentPath.key] = __assign(__assign({}, breadcrumbData[dependentPath.key]), { path: dependentPath.path });
        }
        breadcrumbData[optionName] = createBreadcrumbOptions(optionsData[optionName].disable, queryData.isLoading, path || '', data, optionsData[optionName].roles);
    });
    return breadcrumbData;
};
export default useGenerateBreadcrumbData;
