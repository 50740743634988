import { makeStyles } from 'tss-react/mui';
export var controlledDatePickerStyle = makeStyles()(function (theme) { return ({
    rootContainer: {
        backgroundColor: 'white',
    },
    error: {
        backgroundColor: 'white',
        border: '1px solid red',
        borderWidth: '1px!important',
        '& .Mui-error': {
            width: 160,
        },
    },
    formError: {
        backgroundColor: 'white',
        border: '1px solid #CC0000!important ',
        borderWidth: '1px!important',
        '& .MuiFormHelperText-sizeMedium': {
            fontSize: '14px!important'
        }
    },
    disabledArrow: {
        pointerEvents: 'none',
        color: "".concat(theme.palette.customColor.disabled, "!important"),
        cursor: 'pointer',
    },
    activeArrow: {
        pointerEvents: 'all',
        color: theme.palette.customColor.primaryGreen,
        cursor: 'pointer',
    },
}); });
