import { jsx as _jsx } from "react/jsx-runtime";
import { Link, Typography, Skeleton } from '@mui/material';
import { capitalizeAndJoinStrings } from 'globals/global.functions';
import { useNavigate } from 'react-router-dom';
import { facilityBreadcrumbs } from './facilityBreadcrumbs.style';
import clsx from 'clsx';
import useFacitlityBreadcrumb from 'hooks/useFacilityBreadcrumbs';
import { facilitySlugs } from 'globals/global.constants';
import React from 'react';
import Breadcrumbs from './Breadcrumbs.component';
import { useAppSelector } from 'hooks/reduxHooks';
import { Roles } from 'globals/enums';
/**
 * @description method to modify the breadcrum title
 * @param {string[]}arrayTitles
 * @returns {string}
 */
var breadCrumbTitle = function (arrayTitles) {
    var key = arrayTitles[0], title = arrayTitles[1];
    if (key === facilitySlugs.residentDashboard) {
        return title + ' Dashboard';
    }
    return title;
};
/**
 * @description Breadcrumb items
 * @param {IBreadcrumbItemsProps} props
 * @returns {React.ReactElemen}
 */
var BreadcrumbItems = function (_a) {
    var data = _a.data, pathnames = _a.pathnames, index = _a.index, navigate = _a.navigate, isFacilityAgent = _a.isFacilityAgent;
    var key = data[0], value = data[1];
    var breadCrumbOption = value;
    //isLast checking if the breadcrumb item is the selected one.
    var isLast = index === pathnames.length - 1;
    var name = breadCrumbOption.value;
    name = breadCrumbTitle([key, name]);
    //routeTo is the path, where user should navigate on clicking on breadcrumb
    var routeTo = function (index) {
        if (isFacilityAgent) {
            return "/".concat(pathnames
                .slice(0, index + 1)
                .join('/'));
        }
        return "/".concat(pathnames
            .slice(1, index + 1)
            .join('/'));
    };
    var isLoading = breadCrumbOption.isLoading;
    if (isLoading) {
        return _jsx(Skeleton, { animation: 'wave', width: 200 });
    }
    if (isLast) {
        return (_jsx(Typography, { variant: 'h6', color: '#0186a5', children: capitalizeAndJoinStrings((name === null || name === void 0 ? void 0 : name.split(' ')) || [], ' ') }, name));
    }
    if (breadCrumbOption.disable) {
        return (_jsx(Typography, { variant: 'h6', color: '#8b8989', children: capitalizeAndJoinStrings((name === null || name === void 0 ? void 0 : name.split(' ')) || [], ' ') }, name));
    }
    return (_jsx(Link, { style: { color: 'black', textDecoration: 'none', cursor: 'pointer' }, className: clsx({ disableLink: true }), onClick: function () { return navigate(index === 0 ? pathnames[0] : routeTo(index)); }, children: capitalizeAndJoinStrings((name === null || name === void 0 ? void 0 : name.split(' ')) || [], ' ') }, name));
};
var FacilityBreadcrumbs = function () {
    var navigate = useNavigate();
    var classes = facilityBreadcrumbs().classes;
    var currentUserRoles = useAppSelector(function (state) { return state.auth.userRole; });
    var isFacilityAgent = currentUserRoles.includes(Roles.FACILITY_AGENT);
    var options = useFacitlityBreadcrumb().options;
    var optionsArr = Object.entries(options);
    var pathnames = optionsArr.map(function (_a) {
        var paramKey = _a[0], paramObject = _a[1];
        return paramObject.path;
    });
    return (_jsx(Breadcrumbs, { className: classes.breadcrumb, "aria-label": 'breadcrumb', "data-testid": 'breadcrumb', separator: '>', children: optionsArr.map(function (data, index) { return (_jsx(BreadcrumbItems, { data: data, pathnames: pathnames, index: index, navigate: navigate, isFacilityAgent: isFacilityAgent }, "breadcrumbitem-".concat(index))); }) }));
};
export default FacilityBreadcrumbs;
