var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import clsx from 'clsx';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { InputControlledDatePicker, Label } from 'common/Input/Input.component';
import { InputAdornment, Box, Grid } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { controlledDatePickerStyle } from './ControlledDatePicker.style';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { fieldsStyle } from 'common/Fields/Fields.style';
var ControlledDatePicker = function (props) {
    var _a, _b;
    var onPrev = props.onPrev, onNext = props.onNext, label = props.label, error = props.error, onNextMouseDown = props.onNextMouseDown, onNextMouseUp = props.onNextMouseUp, onPrevMouseDown = props.onPrevMouseDown, onPrevMouseUp = props.onPrevMouseUp, value = props.value, disablePrevArrow = props.disablePrevArrow, disableNextArrow = props.disableNextArrow, formUi = props.formUi, _c = props.required, required = _c === void 0 ? false : _c;
    var classes = controlledDatePickerStyle(formUi).classes;
    var fieldClass = fieldsStyle().classes;
    return (_jsx(React.Fragment, { children: _jsx(LocalizationProvider, { dateAdapter: AdapterDateFns, children: formUi ? (_jsxs(Grid, { item: true, xs: 4, "data-testid": 'controlledDatePicker', children: [label && (_jsxs(Label, { htmlFor: 'date', className: error && fieldClass.errorTextStyle, "data-testid": 'field-label', children: [label, required && "*"] })), _jsx(Box, { display: 'flex', flexDirection: 'column', children: _jsx(Box, { display: 'flex', mb: 2, justifyContent: 'center', alignItems: 'center', children: _jsx(InputControlledDatePicker, __assign({}, props, { className: error ? classes.formError : classes.rootContainer, label: '', InputProps: {
                                    disableUnderline: true,
                                    style: { fontSize: 15 },
                                    endAdornment: (_jsx(InputAdornment, { position: 'end', children: _jsx(CalendarTodayIcon, { style: { paddingRight: 8 } }) })),
                                } })) }) })] })) : (_jsxs(Box, { display: 'flex', alignItems: 'center', justifyContent: 'center', "data-testid": 'controlledDatePicker', children: [label && (_jsx(Box, { htmlFor: 'date', component: 'label', children: label })), _jsxs(Box, { display: 'flex', flexDirection: 'column', children: [_jsx(Box, { style: {
                                    visibility: error ? 'visible' : 'hidden',
                                }, color: 'red', fontSize: 12, ml: 3, children: "*Required field" }), _jsxs(Box, { display: 'flex', mb: 2, justifyContent: 'center', alignItems: 'center', children: [_jsx(NavigateBeforeIcon, { "data-testid": "".concat(label, "-navigate-before"), className: clsx((_a = {},
                                            _a[classes.activeArrow] = value,
                                            _a[classes.disabledArrow] = !value || disablePrevArrow,
                                            _a)), onClick: onPrev, onMouseDown: onPrevMouseDown, onMouseUp: onPrevMouseUp }), _jsx(InputControlledDatePicker, __assign({}, props, { className: error ? classes.error : classes.rootContainer, label: '', InputProps: {
                                            disableUnderline: true,
                                            style: { width: 160, fontSize: 15 },
                                            endAdornment: (_jsx(InputAdornment, { position: 'end', children: _jsx(CalendarTodayIcon, { style: { paddingRight: 8 } }) })),
                                        } })), _jsx(NavigateNextIcon, { "data-testid": "".concat(label, "-navigate-next"), className: clsx((_b = {},
                                            _b[classes.activeArrow] = value,
                                            _b[classes.disabledArrow] = !value || disableNextArrow,
                                            _b)), onClick: onNext, onMouseDown: onNextMouseDown, onMouseUp: onNextMouseUp })] })] })] })) }) }));
};
export default ControlledDatePicker;
