import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-len */
import { Box } from '@mui/material';
import CaregiverStrainSection from '../../components/CaregiverStrainSection/CaregiverStrainSection.component';
import CaregiverStrainStats from '../../components/CaregiverStrainSection/CaregiverStrainStats.component';
var CaregiverStrainAssessementTemplate = function (props) {
    var _a = props.data, data = _a === void 0 ? [] : _a, caregiverName = props.caregiverName;
    return (_jsxs(Box, { "data-testid": 'caregiver-strain-template', children: [_jsx(CaregiverStrainSection, { data: data, caregiverName: caregiverName }), _jsx(CaregiverStrainStats, { data: data })] }));
};
export default CaregiverStrainAssessementTemplate;
