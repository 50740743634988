var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { GET_INSIGHT_HISTORY, END_PAGINATION, RESET_INSIGHT_HISTORY, RESET_PAGINATION, UPDATE_SUBHISTORY, EXPAND_COLLAPSE_CARE_INSIGHT_HISTORY, } from './MessageManager.action';
var INITIAL_STATE = {
    careInsightHistory: [],
    careInsightSubHistory: {},
    isPaginate: true,
};
var messageManagerReducer = function (state, action) {
    if (state === void 0) { state = INITIAL_STATE; }
    switch (action.type) {
        case GET_INSIGHT_HISTORY: {
            return __assign(__assign({}, state), { careInsightHistory: __spreadArray(__spreadArray([], state.careInsightHistory, true), action.payload.careInsightHistory, true) });
        }
        case UPDATE_SUBHISTORY: {
            return __assign(__assign({}, state), { careInsightSubHistory: __assign(__assign({}, state.careInsightSubHistory), action.payload.subHistoryData) });
        }
        case RESET_INSIGHT_HISTORY: {
            return __assign(__assign({}, state), { careInsightHistory: INITIAL_STATE.careInsightHistory });
        }
        case RESET_PAGINATION: {
            return __assign(__assign({}, state), { isPaginate: true });
        }
        case END_PAGINATION: {
            return __assign(__assign({}, state), { isPaginate: false });
        }
        case EXPAND_COLLAPSE_CARE_INSIGHT_HISTORY: {
            return __assign(__assign({}, state), { careInsightHistory: __spreadArray([], action.payload.careInsightHistory, true) });
        }
        default: {
            return state;
        }
    }
};
export default messageManagerReducer;
