var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { EventViewState } from 'globals/enums';
import { CLOSE_EVENT, CREATE_SUMMARY, MAXIMIZE_EVENT, MINIMIZE_EVENT, CREATE_ALERT, CREATE_MILESTONE, REMOVE_ALL_EVENTS, UPDATE_FACILITY_SUMMARY_LIST, } from './Events.action';
import { intialStateEvents, } from './Events.state';
import { UPDATE_IS_RENDER_LOCATION } from './locationData.action';
var eventsReducer = function (state, action) {
    var _a, _b, _c, _d, _e;
    if (state === void 0) { state = intialStateEvents; }
    switch (action.type) {
        case CREATE_SUMMARY:
            return __assign(__assign({}, state), { summary: __assign(__assign({}, state.summary), action.payload.summaryData) });
        case CREATE_ALERT:
            return __assign(__assign({}, state), { alert: __assign(__assign({}, state.alert), action.payload.alertData) });
        case CREATE_MILESTONE:
            return __assign(__assign({}, state), { milestone: __assign(__assign({}, state.milestone), action.payload.milestoneData) });
        case MINIMIZE_EVENT:
            return __assign(__assign({}, state), (_a = {}, _a[action.payload.eventType] = __assign(__assign({}, state[action.payload.eventType]), (_b = {}, _b[action.payload.eventId] = __assign(__assign(__assign({}, state[action.payload.eventType][action.payload.eventId]), action.payload.data), { viewState: EventViewState.Minimize }), _b)), _a));
        case MAXIMIZE_EVENT:
            return __assign(__assign({}, state), (_c = {}, _c[action.payload.eventType] = __assign(__assign({}, state[action.payload.eventType]), (_d = {}, _d[action.payload.eventId] = __assign(__assign({}, state[action.payload.eventType][action.payload.eventId]), { viewState: EventViewState.Maximize }), _d)), _c));
        case CLOSE_EVENT:
            return __assign(__assign({}, state), (_e = {}, _e[action.payload.eventType] = action.payload.updatedEvent, _e));
        case REMOVE_ALL_EVENTS:
            return __assign({}, intialStateEvents);
        case UPDATE_IS_RENDER_LOCATION:
            return __assign(__assign({}, state), { isRenderLocation: action.payload });
        case UPDATE_FACILITY_SUMMARY_LIST:
            return __assign(__assign({}, state), { facilitySummary: __spreadArray([], (action.payload || []), true) });
        default:
            return state;
    }
};
export default eventsReducer;
