import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Suspense } from 'react';
import { Routes } from 'react-router';
import { Route } from 'react-router-dom';
import { publicRoutesList, privateRoutesList, PublicRouteView, PrivateRouteView, facilityRoutesList, } from 'routes';
import { Toast } from './common/Toast';
import { AppFallbackLoader } from 'common/AppFallbackLoader/ApplicationLoader.component';
import './App.css';
import { WCThemeProvider } from 'themes';
import { FacilityLayout, PublicLayout, WCLayout } from 'layouts';
var App = function () {
    var AuthInit = React.lazy(function () { return import('common/AuthInit/AuthInit'); });
    var Dialogs = React.lazy(function () { return import('./common/Dialogs'); });
    var OverlayDialogs = React.lazy(function () { return import('./common/Dialogs/OverlayDialogs.component'); });
    var PusherChannelSubscriber = React.lazy(function () { return import('pusher/PusherChannelSubscriber'); });
    var Events = React.lazy(function () { return import('common/Events/Events'); });
    return (_jsxs(Suspense, { fallback: _jsx(AppFallbackLoader, {}), children: [_jsx(AuthInit, {}), _jsx(PusherChannelSubscriber, {}), _jsxs(WCThemeProvider, { children: [_jsx(Toast, {}), _jsx(Dialogs, {}), _jsx(OverlayDialogs, {}), _jsx(Events, {})] }), _jsxs(Routes, { children: [_jsx(Route, { element: _jsx(PublicLayout, {}), children: publicRoutesList.map(function (route) {
                            return (_jsx(Route, { path: route.path, element: _jsx(PublicRouteView, { component: route.component, meta: route.meta }) }, route.meta.title));
                        }) }), _jsx(Route, { element: _jsx(WCLayout, {}), children: privateRoutesList.map(function (route) {
                            return (_jsx(Route, { path: route.path, element: _jsx(PrivateRouteView, { component: route.component, meta: route.meta, requiredRoles: route.requiredRoles }) }, route.meta.title));
                        }) }), _jsx(Route, { element: _jsx(FacilityLayout, {}), children: facilityRoutesList.map(function (route) {
                            return (_jsx(Route, { path: route.path, element: _jsx(PrivateRouteView, { component: route.component, meta: route.meta, requiredRoles: route.requiredRoles }) }, route.meta.title));
                        }) })] })] }));
};
var AppPage = App;
export default AppPage;
