import { jsx as _jsx } from "react/jsx-runtime";
import { Navigate, useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
import { changeDocumentTitle, isAuthenticateUser, } from 'globals/global.functions';
var PublicRouteView = React.memo(function (_a) {
    var component = _a.component, meta = _a.meta;
    var defaultPath = '/';
    var isAuthenticated = isAuthenticateUser();
    var location = useLocation();
    useEffect(function () {
        changeDocumentTitle(meta.title);
        window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);
    return !isAuthenticated ? component : _jsx(Navigate, { to: defaultPath });
});
export { PublicRouteView };
