var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { makeStyles } from 'tss-react/mui';
import { TemplateTypography } from 'common/Print/Print.utility';
export var memberQuestionnaireSectionStyles = makeStyles()(function () { return ({
    section: {
        borderRadius: '10px',
        border: '1px solid #707070',
        backgroundColor: '#fff',
        marginBottom: '30px',
    },
    header: {
        padding: '30px',
        display: 'flex',
        justifyContent: 'space-between',
        gap: '30px',
        borderBottom: '1px solid #707070',
    },
    heading: __assign(__assign({}, TemplateTypography.regular), { fontSize: '40px', fontWeight: 500, lineHeight: 0.66, color: '#0186a5' }),
    tableHeading: __assign(__assign({}, TemplateTypography.regular), { fontWeight: 500, lineHeight: 0.73, color: '#000' }),
    tableContent: __assign(__assign({}, TemplateTypography.regular), { display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', gap: '30px' }),
    container: {
        padding: '30px',
    },
    radioGroupHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '520px',
        paddingBottom: '10px',
    },
    radioGroup: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        '& label': {
            margin: 0,
        },
        fontSize: '40px',
        fontWeight: 500,
    },
    tableHeading2: __assign(__assign({}, TemplateTypography.regular), { lineHeight: 1.0 }),
    '@media print': {
        section: {
            pageBreakInside: 'avoid',
            breakInside: 'avoid',
        },
    },
}); });
