/**
 * @class UCLALonelinessGraphModel
 * @description class to maintain the UCLALoneliness graph data
 */
var UCLALonelinessGraphModel = /** @class */ (function () {
    function UCLALonelinessGraphModel(overall, blue, green, gold) {
        this.overall = overall;
        this.blue = blue;
        this.green = green;
        this.gold = gold;
    }
    return UCLALonelinessGraphModel;
}());
export default UCLALonelinessGraphModel;
