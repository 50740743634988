var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { UPLOAD_FILES, UPLOAD_FILES_RESET, UPLOAD_FILES_STATUS_UPDATE } from "./uploadFiles.action";
import { initialState } from "./uploadFiles.types";
var uploadFilesReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case UPLOAD_FILES:
            return __assign(__assign({}, state), { filesUploadCount: action.payload.filesUploadCount, uploadedFilesCount: 0 });
        case UPLOAD_FILES_STATUS_UPDATE:
            return __assign(__assign({}, state), { uploadedFilesCount: action.payload.uploadedFilesCount });
        case UPLOAD_FILES_RESET:
            return initialState;
        default:
            return state;
    }
};
export default uploadFilesReducer;
