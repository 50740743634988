import { makeStyles } from 'tss-react/mui';
export var welcomeWrapperStyle = makeStyles()(function (theme) {
    var _a, _b;
    return ({
        welcomeContainer: {
            height: 180,
            width: '100%',
            borderRadius: 10,
            display: 'flex',
            position: 'relative',
            alignItems: 'center',
            backgroundColor: (_b = (_a = theme === null || theme === void 0 ? void 0 : theme.palette) === null || _a === void 0 ? void 0 : _a.customColor) === null || _b === void 0 ? void 0 : _b.welcome,
            marginBottom: 50,
            marginTop: 50,
        },
        welcomeImage: {
            position: 'absolute',
            bottom: 0,
            height: 220,
        },
        welcomeImageContainer: {
            width: 350,
            marginLeft: 30,
        },
        welcomeText: {
            color: theme.palette.customColor.white,
        },
    });
});
