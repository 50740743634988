var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
//@TODO: NEED TO REMOVE THE COMMENTED CODE LATER ON
import moment from 'moment';
import { hideApplicationLoader, showApplicationLoader, } from 'common/ApplicationLoader';
import { hideToast, showToast } from 'common/Toast';
import { ThresholdOperations, ToastMessageType, VitalState } from 'globals/enums';
import { DATE_FORMAT, DIALOG_TYPES } from 'globals/global.constants';
import { sortBy, convertArrayToObject, getCurrentSenior, } from 'globals/global.functions';
import { getVitalsService, updateVitalStateService, submitThresholdConfigService, getRefreshedBaselineService, downloadDetailedDataService, } from 'services/careInsightService/threshold.service';
import { openDialog } from 'store/commonReducer/common.action';
export var GET_VITALS = 'GET_VITALS';
export var GET_VITALS_SUCCESS = 'GET_VITALS_SUCCESS';
export var GET_VITALS_FAILS = 'GET_VITALS_FAILS';
export var GET_ACTIVE_VITALS = 'GET_ACTIVE_VITALS';
export var GET_INACTIVE_VITALS = 'GET_INACTIVE_VITALS';
export var UPDATE_VITALS = 'UPDATE_VITALS';
export var SET_SELECTED_VITALS = 'SET_SELECTED_VITALS';
export var REMOVE_SELECTED_VITAL = 'REMOVE_SELECTED_VITAL';
export var UPDATE_VITAL_STATE = 'UPDATE_VITAL_STATE';
/**
 * @description action creator to fetch the vitals data of the senior
 * @returns void
 */
export var getVitals = function () { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, user_id, account_id, created_date, body, endTime, startTime, vitalInsightBody, response, vitalsData, error_1;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 2, , 3]);
                dispatch({ type: GET_VITALS });
                dispatch(showApplicationLoader());
                _a = getState().common.seniorDetail.minimalInfo, user_id = _a.user_id, account_id = _a.account_id, created_date = _a.created_date;
                body = {
                    senior_id: user_id,
                };
                endTime = moment().format('x');
                startTime = moment(created_date).format('x');
                vitalInsightBody = {
                    start_timestamp: startTime * 1000000,
                    end_timestamp: endTime * 1000000,
                    senior_id: user_id,
                    account_id: account_id,
                };
                return [4 /*yield*/, getVitalsService(body, vitalInsightBody)];
            case 1:
                response = _b.sent();
                vitalsData = {
                    active: sortBy(response.activeVitalsData, 'measurementTitle'),
                    inactive: sortBy(response.inActiveVitalsData, 'measurementTitle'),
                };
                dispatch({
                    type: GET_VITALS_SUCCESS,
                    payload: __assign({}, vitalsData),
                });
                dispatch(hideApplicationLoader());
                return [2 /*return*/, vitalsData];
            case 2:
                error_1 = _b.sent();
                dispatch(showToast("".concat(GET_VITALS_FAILS, ":- ").concat(error_1), ToastMessageType.Error));
                dispatch(hideApplicationLoader());
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); }; };
export var getHeartRateData = function (start, end) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, user_id, account_id, _b, measurementName_1, _c, active, inactive, vitalInsightBody, response_1, error_2;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 2, , 3]);
                dispatch(showApplicationLoader());
                _a = getState().common.seniorDetail.minimalInfo, user_id = _a.user_id, account_id = _a.account_id;
                _b = getState().seniorCareInsights.thresholds, measurementName_1 = _b.selectedVital.measurementName, _c = _b.vitals, active = _c.active, inactive = _c.inactive;
                vitalInsightBody = {
                    start_timestamp: start,
                    end_timestamp: end,
                    // start_timestamp: 1643763008000000000,
                    // end_timestamp: 1655005382000000000,
                    senior_id: user_id,
                    account_id: account_id,
                };
                return [4 /*yield*/, getRefreshedBaselineService(measurementName_1, vitalInsightBody)];
            case 1:
                response_1 = _d.sent();
                active.forEach(function (vitalsData) {
                    if (vitalsData.measurementName === measurementName_1) {
                        vitalsData.array = __spreadArray([], response_1, true);
                        vitalsData.tableFilterValue = null;
                    }
                });
                dispatch(updateVitals(active, inactive));
                dispatch(hideApplicationLoader());
                return [3 /*break*/, 3];
            case 2:
                error_2 = _d.sent();
                dispatch(hideApplicationLoader());
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); }; };
/**
 * @description add active vitals
 * @param selectedItem the selected item that needs to be added
 */
export var addActiveVitals = function (selectedItem) { return function (dispatch, getState) {
    var _a = getState().seniorCareInsights.thresholds.vitals, active = _a.active, inactive = _a.inactive;
    var inActiveList = inactive.filter(function (data) { return data.measurementName !== selectedItem.measurementName; });
    selectedItem.selected = false;
    active.push(selectedItem);
    dispatch(updateVitals(active, inActiveList));
}; };
/**
 * @description remove active vitals
 * @param selectedItem the selected item that needs to be removed
 */
export var removeActiveVitals = function (selectedItem) { return function (dispatch, getState) {
    var _a = getState().seniorCareInsights.thresholds.vitals, active = _a.active, inactive = _a.inactive;
    var selectedVital = getState().seniorCareInsights.thresholds.selectedVital;
    for (var index = 0; index < active.length; index++) {
        var element = active[index];
        if (element.measurementName === selectedItem.measurementName) {
            active.splice(index, 1);
            if (element.measurementName === (selectedVital === null || selectedVital === void 0 ? void 0 : selectedVital.measurementName)) {
                dispatch(removeSelectedVital());
            }
        }
    }
    selectedItem.selected = false;
    inactive.push(selectedItem);
    dispatch(updateVitals(active, inactive));
}; };
export var updateHistoryDateFilter = function (value) { return function (dispatch, getState) {
    var _a = getState().seniorCareInsights.thresholds, selectedVital = _a.selectedVital, _b = _a.vitals, active = _b.active, inactive = _b.inactive;
    active.forEach(function (vitalsData) {
        if (vitalsData.measurementName === selectedVital.measurementName) {
            vitalsData.tableFilterValue = value;
        }
    });
    dispatch(updateVitals(active, inactive));
}; };
/**
 * @description update vitals list
 * @param active active vital list
 * @param inactive inactive vital list
 */
var updateVitals = function (active, inactive) { return function (dispatch) {
    dispatch({
        type: UPDATE_VITALS,
        payload: {
            active: sortBy(active, 'measurementTitle'),
            inactive: sortBy(inactive, 'measurementTitle'),
        },
    });
}; };
/**
 * @description update selected vitals
 * @param selectedItem the selected item that needs to be selected
 */
export var setSelectedVital = function (selectedItem) { return function (dispatch, getState) {
    var active = getState().seniorCareInsights.thresholds.vitals.active;
    active.forEach(function (data) {
        if (data.measurementName === selectedItem.measurementName) {
            data.selected = true;
        }
        else {
            data.selected = false;
        }
    });
    dispatch({
        type: SET_SELECTED_VITALS,
        payload: {
            active: sortBy(active, 'measurementTitle'),
            selectedItem: selectedItem,
        },
    });
}; };
export var removeSelectedVital = function () { return function (dispatch) {
    dispatch({ type: REMOVE_SELECTED_VITAL });
}; };
/**
 * @description update vital state to assign
 * @param {IThresholdVitalsData} selectedItem selected vital data
 * @returns void
 */
export var assignVitalState = function (selectedItem) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var seniorId, body, error_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(addActiveVitals(selectedItem));
                dispatch(hideToast());
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                seniorId = getState().common.seniorDetail.minimalInfo.user_id;
                body = {
                    senior_id: seniorId,
                    measurement_name: selectedItem.measurementName,
                    state: VitalState.ASSIGNED,
                };
                return [4 /*yield*/, updateVitalStateService(body)];
            case 2:
                _a.sent();
                dispatch(showToast('Vital assigned successfully.', ToastMessageType.Success));
                return [3 /*break*/, 4];
            case 3:
                error_3 = _a.sent();
                dispatch(removeActiveVitals(selectedItem));
                dispatch(showToast('Vital state assign failed.', ToastMessageType.Error));
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
/**
 * @description update vital state to unassign
 * @param {IThresholdVitalsData} selectedItem selected vital data
 * @returns void
 */
export var unAssignVitalState = function (selectedItem) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var seniorId, body, error_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(removeActiveVitals(selectedItem));
                dispatch(hideToast());
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                seniorId = getState().common.seniorDetail.minimalInfo.user_id;
                body = {
                    senior_id: seniorId,
                    measurement_name: selectedItem.measurementName,
                    state: VitalState.UNASSIGNED,
                };
                return [4 /*yield*/, updateVitalStateService(body)];
            case 2:
                _a.sent();
                dispatch(showToast('Vital unassigned successfully.', ToastMessageType.Success));
                return [3 /*break*/, 4];
            case 3:
                error_4 = _a.sent();
                dispatch(addActiveVitals(selectedItem));
                dispatch(showToast('Vital state unassign failed.', ToastMessageType.Error));
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
/**
 * @description update vital state to active_insight
 * @param {VitalState} vitalState vital state
 * @returns void
 */
export var updateVitalStateActiveInsight = function (vitalState) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var seniorId, _a, selectedVital_1, _b, active, inactive, body, error_5;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                dispatch(hideToast());
                _c.label = 1;
            case 1:
                _c.trys.push([1, 3, , 4]);
                seniorId = getState().common.seniorDetail.minimalInfo.user_id;
                _a = getState().seniorCareInsights.thresholds, selectedVital_1 = _a.selectedVital, _b = _a.vitals, active = _b.active, inactive = _b.inactive;
                body = {
                    senior_id: seniorId,
                    measurement_name: selectedVital_1.measurementName,
                    state: vitalState,
                };
                return [4 /*yield*/, updateVitalStateService(body)];
            case 2:
                _c.sent();
                active.forEach(function (vitalsData) {
                    if (vitalsData.measurementName === selectedVital_1.measurementName) {
                        vitalsData.currentState = vitalState;
                    }
                });
                dispatch(updateVitals(active, inactive));
                dispatch(showToast("Care insight turned ".concat(vitalState === VitalState.ACTIVE_INSIGHT ? 'ON' : 'OFF', " successfully."), ToastMessageType.Success));
                return [3 /*break*/, 4];
            case 3:
                error_5 = _c.sent();
                dispatch(showToast('Vital state active insight failed.', ToastMessageType.Error));
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
/**
 * @description update threshold config
 * @param {IThresholdVitalsData} selectedItem selected vital data
 * @returns void
 */
export var submitThresholdConfig = function (inputFields, thresholdRange, actual, baseline) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var thresholdRangeObject, seniorInfo, seniorId, selectedVital, timestamp_1, body_1, openDialogProp;
    return __generator(this, function (_a) {
        try {
            thresholdRangeObject = convertArrayToObject(thresholdRange, 'name');
            seniorInfo = getCurrentSenior();
            seniorId = getState().common.seniorDetail.minimalInfo.user_id;
            selectedVital = getState().seniorCareInsights.thresholds
                .selectedVital;
            timestamp_1 = {
                end: selectedVital.array[0].time,
                start: selectedVital.array[Object.entries(selectedVital.array).length - 1]
                    .time,
            };
            body_1 = {
                timezone: seniorInfo.timezone,
                senior_id: seniorId,
                measurement_name: selectedVital.measurementName,
                baseline_info: {
                    high: baseline.high,
                    low: baseline.low,
                    upper_action_pct: Number(inputFields.upHigh) || null,
                    upper_attention_pct: Number(inputFields.upLow) || null,
                    lower_attention_pct: Number(inputFields.lowHigh) || null,
                    lower_action_pct: Number(inputFields.lowLow) || null,
                },
                actual_info: {
                    high: actual.high,
                    low: actual.low,
                },
                timestamp: timestamp_1,
                threshold: {
                    definition: {
                        action: [
                            {
                                operation: ThresholdOperations.GT,
                                value: thresholdRangeObject.upperHigh.value,
                                message: '',
                                event: 'action',
                                range: 'upper',
                            },
                            {
                                operation: ThresholdOperations.LT,
                                value: thresholdRangeObject.lowerLow.value,
                                message: '',
                                event: 'action',
                                range: 'lower',
                            },
                        ],
                    },
                },
            };
            if (selectedVital.currentState !== VitalState.ACTIVE_INSIGHT) {
                openDialogProp = {
                    boldMessage: 'Are you sure you want to Submit?',
                    secondMessage: 'Submitting will Switch "ON" the Care Insight Messages',
                    successButtonText: 'Submit',
                    type: DIALOG_TYPES.ADMIN_ACTION_DIALOG,
                    isFailButton: true,
                    onSuccessButton: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    dispatch(showApplicationLoader());
                                    return [4 /*yield*/, submitThresholdConfigService(body_1)];
                                case 1:
                                    _a.sent();
                                    dispatch(updateVitalData(inputFields, timestamp_1));
                                    dispatch(updateVitalStateActiveInsight(VitalState.ACTIVE_INSIGHT));
                                    dispatch(hideApplicationLoader());
                                    dispatch(showToast('Updated successfully.', ToastMessageType.Success));
                                    window.scrollTo({ top: 0, behavior: 'smooth' });
                                    return [2 /*return*/];
                            }
                        });
                    }); },
                };
                dispatch(openDialog(__assign({}, openDialogProp)));
            }
        }
        catch (error) {
            dispatch(hideApplicationLoader());
            dispatch(showToast('Update failed', ToastMessageType.Error));
        }
        return [2 /*return*/];
    });
}); }; };
/**
 * @description update threshold range values in the active vital list
 * @param inputFields
 * @returns
 */
export var updateVitalData = function (inputFields, timestamp) { return function (dispatch, getState) {
    var _a = getState().seniorCareInsights.thresholds, selectedVital = _a.selectedVital, _b = _a.vitals, active = _b.active, inactive = _b.inactive;
    active.forEach(function (vitalsData) {
        if (vitalsData.measurementName === selectedVital.measurementName) {
            vitalsData.threshold = {
                upHigh: Number(inputFields.upHigh) || 0,
                upLow: Number(inputFields.upLow),
                lowHigh: Number(inputFields.lowHigh),
                lowLow: Number(inputFields.lowLow) || 0,
            };
            vitalsData.timestamp = timestamp;
        }
    });
    dispatch(updateVitals(active, inactive));
}; };
/**
 * @description Update the threshold value when on change of active fields.
 * @param fieldEvent Event
 * @returns void
 */
export var updateOnChangeActiveRange = function (fieldEvent) { return function (dispatch, getState) {
    var _a = getState().seniorCareInsights.thresholds, selectedVital = _a.selectedVital, _b = _a.vitals, active = _b.active, inactive = _b.inactive;
    var value = fieldEvent.target.value;
    active.forEach(function (vitalsData) {
        var _a;
        if (vitalsData.measurementName === selectedVital.measurementName) {
            vitalsData.threshold = __assign(__assign({}, vitalsData.threshold), (_a = {}, _a[fieldEvent.target.name] = value, _a));
        }
    });
    dispatch(updateVitals(active, inactive));
}; };
/**
 * @description reset threshold range values in the selected active vital
 * @returns void
 */
export var resetVitalThresholdData = function () { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, selectedVital_2, _b, active, inactive, seniorInfo, seniorId, body, error_6;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _c.trys.push([0, 2, , 3]);
                dispatch(showApplicationLoader());
                _a = getState().seniorCareInsights.thresholds, selectedVital_2 = _a.selectedVital, _b = _a.vitals, active = _b.active, inactive = _b.inactive;
                seniorInfo = getCurrentSenior();
                seniorId = getState().common.seniorDetail.minimalInfo.user_id;
                body = {
                    timezone: seniorInfo.timezone,
                    senior_id: seniorId,
                    measurement_name: selectedVital_2.measurementName,
                    actual_info: {
                        high: selectedVital_2.actual.high,
                        low: selectedVital_2.actual.low,
                    },
                    baseline_info: {
                        high: selectedVital_2.baseline.high,
                        low: selectedVital_2.baseline.low,
                        upper_action_pct: null,
                        upper_attention_pct: null,
                        lower_attention_pct: null,
                        lower_action_pct: null,
                    },
                    timestamp: {
                        end: selectedVital_2.array[0].time,
                        start: selectedVital_2.array[Object.entries(selectedVital_2.array).length - 1]
                            .time,
                    },
                    threshold: {
                        definition: {
                            action: [
                                {
                                    operation: ThresholdOperations.GT,
                                    value: null,
                                    message: '',
                                    event: 'action',
                                    range: 'upper',
                                },
                                {
                                    operation: ThresholdOperations.LT,
                                    value: null,
                                    message: '',
                                    event: 'action',
                                    range: 'lower',
                                },
                            ],
                        },
                    },
                };
                return [4 /*yield*/, submitThresholdConfigService(body)];
            case 1:
                _c.sent();
                active.forEach(function (vitalsData) {
                    if (vitalsData.measurementName === selectedVital_2.measurementName) {
                        vitalsData.threshold = {
                            upHigh: '',
                            upLow: '',
                            lowHigh: '',
                            lowLow: '',
                        };
                        vitalsData.tableFilterValue = null;
                    }
                });
                dispatch(updateVitals(active, inactive));
                dispatch(hideApplicationLoader());
                dispatch(showToast('Range score cleared successfully.', ToastMessageType.Success));
                return [3 /*break*/, 3];
            case 2:
                error_6 = _c.sent();
                dispatch(hideApplicationLoader());
                dispatch(showToast('Update failed', ToastMessageType.Error));
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); }; };
/**
 * @description action creator to refresh the baseline value on clicking of refresh button.
 * @returns void
 */
export var refreshBaseline = function () { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, measurementName_2, _b, active, inactive, _c, user_id, account_id, vitalInsightBody, response_2, error_7;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 2, , 3]);
                _a = getState().seniorCareInsights.thresholds, measurementName_2 = _a.selectedVital.measurementName, _b = _a.vitals, active = _b.active, inactive = _b.inactive;
                _c = getState().common.seniorDetail.minimalInfo, user_id = _c.user_id, account_id = _c.account_id;
                vitalInsightBody = {
                    limit: 1000,
                    senior_id: user_id,
                    account_id: account_id,
                };
                dispatch(showApplicationLoader());
                return [4 /*yield*/, getRefreshedBaselineService(measurementName_2, vitalInsightBody)];
            case 1:
                response_2 = _d.sent();
                active.forEach(function (vitalsData) {
                    if (vitalsData.measurementName === measurementName_2) {
                        vitalsData.array = __spreadArray([], response_2, true);
                        vitalsData.tableFilterValue = null;
                    }
                });
                dispatch(updateVitals(active, inactive));
                dispatch(hideApplicationLoader());
                dispatch(showToast('Baseline refreshed successfully.', ToastMessageType.Success));
                return [3 /*break*/, 3];
            case 2:
                error_7 = _d.sent();
                dispatch(hideApplicationLoader());
                dispatch(showToast('Refresh failed', ToastMessageType.Error));
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); }; };
/**
 * @description action creator to download the file
 * @param params
 * @returns void
 */
export var downloadThresholdData = function (params) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var selectedVital, _a, first_name, last_name, response, date, time, url, link, fileName, err_1;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 2, , 3]);
                dispatch(showApplicationLoader());
                selectedVital = getState().seniorCareInsights.thresholds.selectedVital;
                _a = getState().common.seniorDetail.minimalInfo.name, first_name = _a.first_name, last_name = _a.last_name;
                return [4 /*yield*/, downloadDetailedDataService(params, selectedVital.measurementName)];
            case 1:
                response = _b.sent();
                date = moment().format(DATE_FORMAT);
                time = moment().format('h:mm A');
                url = window.URL.createObjectURL(new Blob([response.file]));
                link = document.createElement('a');
                link.href = url;
                fileName = "Sr_".concat(first_name, "_").concat(last_name, "-").concat(selectedVital.measurementTitle, "-").concat(date, "-").concat(time, ".xlsx");
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.remove(); // removing anchor element after dfile download
                dispatch(hideApplicationLoader());
                return [3 /*break*/, 3];
            case 2:
                err_1 = _b.sent();
                dispatch(hideApplicationLoader());
                dispatch(showToast('Download Summary Failed!', ToastMessageType.Error));
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); }; };
export var createDashedLine = function (data, baseline) {
    if (!data)
        return;
    var array = [];
    var array2 = [];
    data.forEach(function (interup, index) {
        var lastIndex = data[index - 1];
        var currentIndex = data[index];
        var nextIndex = data[index + 1];
        if (interup.y >= baseline.high || interup.y <= baseline.low) {
            if (index > 0 && index < data.length - 1) {
                array.push({
                    x: lastIndex === null || lastIndex === void 0 ? void 0 : lastIndex.x,
                    y: lastIndex === null || lastIndex === void 0 ? void 0 : lastIndex.y,
                });
                array2.push({
                    x: lastIndex === null || lastIndex === void 0 ? void 0 : lastIndex.x,
                    y: null,
                });
                array.push({
                    x: currentIndex === null || currentIndex === void 0 ? void 0 : currentIndex.x,
                    y: currentIndex === null || currentIndex === void 0 ? void 0 : currentIndex.y,
                });
                array2.push({
                    x: currentIndex === null || currentIndex === void 0 ? void 0 : currentIndex.x,
                    y: null,
                });
                array.push({
                    x: nextIndex === null || nextIndex === void 0 ? void 0 : nextIndex.x,
                    y: nextIndex === null || nextIndex === void 0 ? void 0 : nextIndex.y,
                });
                array2.push({
                    x: nextIndex === null || nextIndex === void 0 ? void 0 : nextIndex.x,
                    y: null,
                });
            }
        }
        if (interup.y < baseline.high && interup.y > baseline.low) {
            if ((nextIndex === null || nextIndex === void 0 ? void 0 : nextIndex.y) < baseline.high && (nextIndex === null || nextIndex === void 0 ? void 0 : nextIndex.y) > baseline.low) {
                array2.push({
                    x: currentIndex === null || currentIndex === void 0 ? void 0 : currentIndex.x,
                    y: currentIndex === null || currentIndex === void 0 ? void 0 : currentIndex.y,
                });
                array.push({
                    x: nextIndex === null || nextIndex === void 0 ? void 0 : nextIndex.x,
                    y: null,
                });
            }
            else {
                array2.push({
                    x: currentIndex === null || currentIndex === void 0 ? void 0 : currentIndex.x,
                    y: currentIndex === null || currentIndex === void 0 ? void 0 : currentIndex.y,
                });
                array.push({
                    x: currentIndex === null || currentIndex === void 0 ? void 0 : currentIndex.x,
                    y: null,
                });
            }
        }
    });
    return [array, array2];
};
