import { CHART_ZONES } from 'globals/enums';
/**
 * @description function to get average score & target from data & zonestate
 * @function getScoreAndTarget
 * @param zones selected zone state
 * @param data all zones data
 * @returns {{score: number; target: number;}} {score, target}
 */
export var getScoreAndTarget = function (zones, data) {
    if (data) {
        var losScoreCount_1 = 0;
        var losTotalScore_1 = 0;
        var emotionalScoreCount_1 = 0;
        var emotionalTotalScore_1 = 0;
        var uCLALonelinessScoreCount_1 = 0;
        var uCLALonelinessTotalScore_1 = 0;
        var targetCount_1 = 0;
        var totalTarget_1 = 0;
        Object.values(CHART_ZONES).forEach(function (zone) {
            var _a, _b, _c, _d;
            if (zones[zone]) {
                if ((_a = data === null || data === void 0 ? void 0 : data[zone]) === null || _a === void 0 ? void 0 : _a.target) {
                    targetCount_1 += 1;
                    totalTarget_1 += data[zone].target;
                }
                if ((_b = data === null || data === void 0 ? void 0 : data[zone]) === null || _b === void 0 ? void 0 : _b.data) {
                    var zoneData = data[zone].data;
                    var totalLOS = zoneData.reduce(function (acc, _a) {
                        var los = _a.los;
                        return acc + (los || 0);
                    }, 0);
                    var averageLOS = totalLOS / (zoneData.length || 1);
                    losTotalScore_1 += averageLOS;
                    losScoreCount_1 += 1;
                }
                // Process Emotional Score data
                if ((_c = data === null || data === void 0 ? void 0 : data[zone]) === null || _c === void 0 ? void 0 : _c.data) {
                    var emotionalZoneData = data[zone].data;
                    // Calculate total and average Emotional Score
                    var totalEmotionalScore = emotionalZoneData.reduce(function (acc, _a) {
                        var score = _a.score;
                        return acc + (score || 0);
                    }, // Replace 'score' if field is different
                    0);
                    var averageEmotionalScore = totalEmotionalScore / (emotionalZoneData.length || 1);
                    emotionalTotalScore_1 += averageEmotionalScore;
                    emotionalScoreCount_1 += 1;
                }
                // Process Holistic Score data
                // if (data?.[zone]?.data) {
                //   const holisticZoneData = data[zone].data;
                //   // Calculate total and average Holistic Score
                //   const totalHolisticScore = holisticZoneData.reduce(
                //     (acc, { score }) => acc + (score || 0), // Replace 'score' if field is different
                //     0
                //   );
                //   const averageHolisticScore = totalHolisticScore / (holisticZoneData.length || 1);
                //   holisticTotalScore += averageHolisticScore;
                //   holisticScoreCount += 1;
                // }
                // Process UclaLoneliness Score data
                if ((_d = data === null || data === void 0 ? void 0 : data[zone]) === null || _d === void 0 ? void 0 : _d.data) {
                    var uCLALonelinessZoneData = data[zone].data;
                    // Calculate total and average UCLALoneliness Score
                    var totalUCLALonelinessScore = uCLALonelinessZoneData.reduce(function (acc, _a) {
                        var score = _a.score;
                        return acc + (score || 0);
                    }, // Replace 'score' if field is different
                    0);
                    var averageUCLALonelinessScore = totalUCLALonelinessScore / (uCLALonelinessZoneData.length || 1);
                    uCLALonelinessTotalScore_1 += averageUCLALonelinessScore;
                    uCLALonelinessScoreCount_1 += 1;
                }
            }
        });
        // Calculate average target and average scores across all zones
        var avgTarget = Math.ceil(targetCount_1 ? totalTarget_1 / targetCount_1 : totalTarget_1);
        var avgLOSScore = Math.ceil(losScoreCount_1 ? losTotalScore_1 / losScoreCount_1 : losTotalScore_1);
        var avgEmotionalScore = Math.ceil(emotionalScoreCount_1
            ? emotionalTotalScore_1 / emotionalScoreCount_1
            : emotionalTotalScore_1);
        var avgUCLALonelinessScore = Math.ceil(uCLALonelinessScoreCount_1
            ? uCLALonelinessTotalScore_1 / uCLALonelinessScoreCount_1
            : uCLALonelinessTotalScore_1);
        return {
            score: avgLOSScore,
            emotionalScore: avgEmotionalScore,
            uCLALonelinessScore: avgUCLALonelinessScore,
            target: avgTarget
        };
    }
    // Default return if no data is present
    return { score: 0, emotionalScore: 0, uCLALonelinessScore: 0, target: 0 };
};
/**
 * @description get ActivitiChart Tick Value from Activity Target & Data
 * @function getActivityChartTickValues
 * @param {IZoneGraphData} activityData
 * @param {number} activityTarget
 * @returns {number[]} tickValues array
 */
export var getActivityChartTickValues = function (activityData, activityTarget, diff) {
    if (diff === void 0) { diff = 5000; }
    var max = activityTarget > 50000 ? activityTarget : 50000;
    Object.values(activityData).forEach(function (data) {
        data === null || data === void 0 ? void 0 : data.forEach(function (record) {
            if (record.y && record.y > max)
                max = record.y;
        });
    });
    var tickCount = Math.ceil(max / diff) + 1;
    var tickValues = Array.from(Array(tickCount).keys()).map(function (t) { return t * diff; });
    return tickValues;
};
/**
 * @description get FallChart Tick Value Data
 * @function getFallChartTickValues
 * @param {IZoneGraphData} fallData
 * @returns {number[]} tickValues array
 */
export var getFallChartTickValues = function (fallData) {
    var max = 20;
    Object.values(fallData).forEach(function (data) {
        data === null || data === void 0 ? void 0 : data.forEach(function (record) {
            if (record.y && record.y > max)
                max = record.y;
        });
    });
    var tickCount = Math.ceil(max / 2) + 1;
    var tickValues = Array.from(Array(tickCount).keys()).map(function (t) { return t * 2; });
    return tickValues;
};
/**
 * @description get LOSChart Tick Value Data
 * @function getLOSChartTickValues
 * @returns {number[]} tickValues array
 */
export var getLOSChartTickValues = function () {
    var tickCount = 11;
    var tickValues = Array.from(Array(tickCount).keys()).map(function (t) { return t * 3; });
    return tickValues;
};
