import { makeStyles } from 'tss-react/mui';
export var activityStyle = makeStyles()(function (theme) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    return ({
        activitySummaryConatiner: {
            border: "1px solid ".concat((_b = (_a = theme.palette) === null || _a === void 0 ? void 0 : _a.grey) === null || _b === void 0 ? void 0 : _b.A200),
            height: 130,
            padding: 10,
            borderRadius: 8,
            textAlign: 'center',
        },
        activitySummaryTitle: {
            color: (_d = (_c = theme.palette) === null || _c === void 0 ? void 0 : _c.customColor) === null || _d === void 0 ? void 0 : _d.lighterBlack,
            textTransform: 'uppercase',
            fontSize: 11,
        },
        duration: {
            marginTop: 14,
            fontSize: 18,
        },
        goal: {
            color: (_f = (_e = theme.palette) === null || _e === void 0 ? void 0 : _e.customColor) === null || _f === void 0 ? void 0 : _f.lighterBlack,
            fontSize: 14,
        },
        activityGoalLabel: {
            color: (_h = (_g = theme.palette) === null || _g === void 0 ? void 0 : _g.customColor) === null || _h === void 0 ? void 0 : _h.lightBlack,
            marginBottom: '4px',
        },
    });
});
