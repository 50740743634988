import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Box } from '@mui/material';
import RoleBaseRender from 'common/RoleBaseRender/RoleBaseRender';
import { Roles } from 'globals/enums';
import { residentSubPages } from 'globals/global.constants';
import { changeDocumentTitle } from 'globals/global.functions';
import SeniorLocation from 'pages/WCPages/SeniorLocation/SeniorLocation.component';
import WellnessDashboard from 'pages/WCPages/WellnessDashboard/WellnessDashboard.component';
import React from 'react';
import { useParams } from 'react-router-dom';
var ResidentSubPages = function () {
    var subRoute = useParams().subRoute;
    var changeTitle = React.useCallback(function (title) {
        setTimeout(function () {
            changeDocumentTitle(title);
        }, 360);
    }, []);
    switch (subRoute) {
        case residentSubPages.wellnessData.path:
            changeTitle(residentSubPages.wellnessData.title);
            return (_jsx(RoleBaseRender, { forRole: [Roles.Admin, Roles.FACILITY_AGENT], children: _jsx(Box, { marginTop: '30px', children: _jsx(WellnessDashboard, {}) }) }));
        case residentSubPages.location.path:
            changeTitle(residentSubPages.location.title);
            return (_jsx(RoleBaseRender, { forRole: [Roles.Admin, Roles.FACILITY_AGENT], children: _jsx(Box, { marginTop: '30px', children: _jsx(SeniorLocation, {}) }) }));
        default:
            return _jsx(_Fragment, {});
    }
};
export default ResidentSubPages;
