import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import reducers from './rootReducer';
import { getCareAgentInfo, saveUserInfo } from 'globals/global.functions';
import { userLoggedIn } from 'pages/WCPages/Login/Login.action';
import interceptor from '../globals/axiosInterceptor';
import { createReduxHistory, routerMiddleware } from './reduxFirstHistory';
import { Roles } from 'globals/enums';
var middleware = [routerMiddleware, thunk];
//Enable devetool extension only in development environment
var composeEnhancers = process.env.NODE_ENV === 'development' && typeof window !== 'undefined'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose;
var store = createStore(reducers, composeEnhancers(applyMiddleware.apply(void 0, middleware)));
var userData = getCareAgentInfo();
if (userData.accessToken) {
    //Roles functionality check for already loged in user. Will remove in future.
    if (!userData.userRole || userData.userRole.length === 0) {
        userData.userRole = [Roles.CareAgent];
        saveUserInfo(userData);
    }
    store.dispatch(userLoggedIn());
}
interceptor(store);
var history = createReduxHistory(store);
export { store, history };
