var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { GET_ACTIVE_VITALS, GET_INACTIVE_VITALS, UPDATE_VITALS, SET_SELECTED_VITALS, GET_VITALS_SUCCESS, REMOVE_SELECTED_VITAL, } from './ThresholdSettings/Threshold.action';
import { INITIAL_STATE } from './SeniorCareInsight.state';
var seniorCareInsightsReducer = function (state, action) {
    if (state === void 0) { state = INITIAL_STATE; }
    switch (action.type) {
        case GET_ACTIVE_VITALS: {
            return state.thresholds.vitals.active;
        }
        case GET_INACTIVE_VITALS: {
            return state.thresholds.vitals.inactive;
        }
        case GET_VITALS_SUCCESS: {
            return __assign(__assign({}, state), { thresholds: __assign(__assign({}, state.thresholds), { vitals: __assign(__assign({}, state.thresholds.vitals), { active: action.payload.active, inactive: action.payload.inactive }) }) });
        }
        case UPDATE_VITALS: {
            return __assign(__assign({}, state), { thresholds: __assign(__assign({}, state.thresholds), { vitals: __assign(__assign({}, state.thresholds.vitals), { active: __spreadArray([], action.payload.active, true), inactive: __spreadArray([], action.payload.inactive, true) }) }) });
        }
        case SET_SELECTED_VITALS: {
            return __assign(__assign({}, state), { thresholds: __assign(__assign({}, state.thresholds), { vitals: __assign(__assign({}, state.thresholds.vitals), { active: __spreadArray([], action.payload.active, true) }), selectedVital: action.payload.selectedItem }) });
        }
        case REMOVE_SELECTED_VITAL: {
            return __assign(__assign({}, state), { thresholds: __assign(__assign({}, state.thresholds), { selectedVital: INITIAL_STATE.thresholds.selectedVital }) });
        }
        default: {
            return state;
        }
    }
};
export default seniorCareInsightsReducer;
