var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/* eslint-disable no-unused-vars */
import { createCareInsightData } from 'services/careInsightService/insightSummary.service';
import { channelData } from '../pusherApp';
import ChannelUtils from './channelUtils';
var AlertChannelEvents = /** @class */ (function (_super) {
    __extends(AlertChannelEvents, _super);
    function AlertChannelEvents() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.alertChannel = null;
        return _this;
    }
    /**
     * @function subscribeEventAlerts
     * @description subscribe to Alert channel.
     */
    AlertChannelEvents.prototype.subscribeAlertsChannel = function () {
        if (!this.alertChannel) {
            this.alertChannel = this.subscribeChannel(channelData.alert.channelName);
        }
    };
    /**
     * @function listenAlerts
     * @description method to listen the pusher alert event for alerts
     * @param fn param to be called when any new alert happen
     */
    AlertChannelEvents.prototype.listenAlerts = function (fn) {
        /* Channel to bind Alert channel event "trigger" */
        this.channelEventListener(this.alertChannel, channelData.alert.events.trigger, function (data) { return createCareInsightData(data.data); }, fn);
    };
    /**
     * @function listenApproveAlert
     * @description method to listen the pusher event for alert sent to admin approval
     * @param fn param to be called when any new alert come for approval
     */
    AlertChannelEvents.prototype.listenApproveAlert = function (fn) {
        /* Channel to bind Alert channel event "approveAlert" */
        this.channelEventListener(this.alertChannel, channelData.alert.events.approveAlert, function (data) { return createCareInsightData(data.data); }, fn);
    };
    /**
     * @function listenAlertActionNotification
     * @description method to listen the pusher event for alerts comes to CG after Approval
     * @param fn param to be called when any new alert comes to CG after Admin approval
     */
    AlertChannelEvents.prototype.listenAlertActionNotification = function (fn) {
        /* Channel to bind Alert channel event "alert-action-notification" */
        this.channelEventListener(this.alertChannel, channelData.alert.events.alertActionNotification, function (data) { return createCareInsightData(data.data); }, fn);
    };
    return AlertChannelEvents;
}(ChannelUtils));
export default AlertChannelEvents;
