import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@mui/material';
import { appStyle } from 'App.style';
import FacilityBreadcrumbs from 'common/Breadcrumbs/FacilityBreadcrumbs';
import Footer from 'common/Footer/Footer.component';
import Header from 'common/Header';
import { Outlet } from 'react-router-dom';
import { FacilityThemeProvider } from 'themes';
var FacilityLayout = function () {
    var classes = appStyle().classes;
    return (_jsx(FacilityThemeProvider, { children: _jsxs(Box, { children: [_jsx(Header, { showMenu: true }), _jsxs(Box, { className: classes.layout, children: [_jsx(FacilityBreadcrumbs, {}), _jsx(Outlet, {})] }), _jsx(Footer, {})] }) }));
};
export { FacilityLayout };
