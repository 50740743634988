var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import useAutoComplete from 'hooks/useAutoComplete';
import SelectInputText from 'common/Fields/Components/SelectInputText';
import InputTextWrapper from 'common/InputFields/component/InputTextWrapper';
var AutoCompleteField = function (_a) {
    var _b = _a.label, label = _b === void 0 ? '' : _b, _c = _a.required, required = _c === void 0 ? false : _c, _d = _a.register, register = _d === void 0 ? function () { } : _d, _e = _a.helperText, helperText = _e === void 0 ? '' : _e, _f = _a.errorField, errorField = _f === void 0 ? '' : _f, _g = _a.isNewDesign, isNewDesign = _g === void 0 ? false : _g, _h = _a.defaultValue, defaultValue = _h === void 0 ? '' : _h, _j = _a.fieldName, fieldName = _j === void 0 ? '' : _j, _k = _a.addressOnChangeHandler, addressOnChangeHandler = _k === void 0 ? function () { } : _k, _l = _a.handleChange, handleChange = _l === void 0 ? function () { } : _l, props = __rest(_a, ["label", "required", "register", "helperText", "errorField", "isNewDesign", "defaultValue", "fieldName", "addressOnChangeHandler", "handleChange"]);
    var addressRef = React.useRef(null);
    var autocompleteConfig = React.useMemo(function () { return ({
        autocompleteConfig: {
            inputRef: addressRef,
            autocompleteOptions: {
                componentRestrictions: { country: 'us' },
                fields: ['address_components', 'formatted_address', 'geometry'],
                types: fieldName.includes('zip') ? ['postal_code'] : ['address'],
            },
            initialInputValue: defaultValue,
            onChange: handleChange,
        },
    }); }, [addressRef]);
    var selectedLocation = useAutoComplete(autocompleteConfig)[0];
    React.useEffect(function () {
        if (selectedLocation.fullAddress) {
            addressOnChangeHandler(selectedLocation);
        }
    }, [selectedLocation]);
    if (isNewDesign) {
        return (_jsx(InputTextWrapper, __assign({ ref: function (e) {
                addressRef.current = e;
            } }, props)));
    }
    else {
        return (_jsx(_Fragment, { children: _jsx(SelectInputText, __assign({ ref: function (e) {
                    register.ref(e);
                    addressRef.current = e;
                }, label: label, helperText: helperText, errorField: errorField, defaultValue: defaultValue }, props)) }));
    }
};
export default AutoCompleteField;
