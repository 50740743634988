import { makeStyles } from 'tss-react/mui';
var getBorderStyle = function (error, theme, isOldStyle) {
    if (error) {
        return "1px solid ".concat(theme.palette.customColor.error);
    }
    else if (isOldStyle) {
        return 'none';
    }
    else {
        return "1px solid ".concat(theme.palette.customColor.borderGrey);
    }
};
export var autoCompleteSearchStyle = makeStyles()(function (theme, _a) {
    var isOldStyle = _a.isOldStyle, error = _a.error;
    return ({
        root: {
            border: getBorderStyle(error, theme, isOldStyle),
            overflow: 'hidden',
            borderRadius: isOldStyle ? 4 : 8,
            backgroundColor: isOldStyle
                ? theme.palette.customColor.activeCellBackground
                : theme.palette.common.white,
            minWidth: '270px',
            height: isOldStyle ? '42px' : '35px',
            padding: isOldStyle ? '2.5px 0' : 'initial',
        },
        inputRoot: {
            paddingTop: '2px',
            paddingLeft: '15px',
        },
        popupIndicator: {
            color: theme.palette.customColor.primaryLight,
            marginRight: 2,
        },
        popper: {
            marginTop: '1px !important',
            minHeight: '80px !important',
        },
        popperDisablePortal: {
            position: 'relative',
        },
    });
});
