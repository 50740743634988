var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Slide, } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useAppSelector } from 'hooks/reduxHooks';
import { red } from '@mui/material/colors';
import clsx from 'clsx';
var useStyles = makeStyles()(function () { return ({
    dialog: {
        borderRadius: 0,
    },
    button: {
        borderRadius: 0,
        textTransform: 'none',
        padding: 5,
    },
    logout: {
        color: '#fff',
        backgroundColor: red[500],
        '&:hover': {
            backgroundColor: red[700],
        },
    },
    countdown: {
        color: red[700],
    },
}); });
var Transition = React.forwardRef(function Transition(props, ref) {
    return _jsx(Slide, __assign({ direction: 'up', ref: ref }, props));
});
var SessionTimeoutDialog = function (_a) {
    var countdown = _a.countdown, onLogout = _a.onLogout, onContinue = _a.onContinue;
    var classes = useStyles().classes;
    var isTimeoutModel = useAppSelector(function (state) { return state.auth; }).isTimeoutModel;
    return (_jsxs(Dialog, { open: isTimeoutModel, classes: { paper: classes.dialog }, TransitionComponent: Transition, children: [_jsx(DialogTitle, { children: "Session Timeout" }), _jsxs(DialogContent, { children: [_jsxs(Typography, { variant: 'body2', children: ["The current session is about to expire in", _jsxs("span", { className: classes.countdown, children: ["\u00A0", countdown] }), " seconds."] }), _jsx(Typography, { variant: 'body2', children: "Would you like to continue the session?" })] }), _jsxs(DialogActions, { children: [_jsx(Button, { onClick: onLogout, variant: 'contained', className: clsx(classes.logout, classes.button), children: "Logout" }), _jsx(Button, { onClick: onContinue, color: 'primary', variant: 'contained', className: classes.button, children: "Continue Session" })] })] }));
};
export default SessionTimeoutDialog;
