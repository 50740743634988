var ReportsParser = /** @class */ (function () {
    function ReportsParser() {
        this.reportsParsedData = {
            data: [],
            lastEvaluatedKey: '',
        };
    }
    /**
     * @description function to parse the response data
     * @function parse
     * @param {IReportsResponse[]} data api response data
     * @returns {IRepo} parsed data
     */
    ReportsParser.prototype.parse = function (data) {
        var responseData = (data === null || data === void 0 ? void 0 : data.data) || [];
        var parsedData = responseData.map(function (data) { return ({
            reportType: data === null || data === void 0 ? void 0 : data.report_type,
            fromDate: (data === null || data === void 0 ? void 0 : data.from_date) || '',
            toDate: (data === null || data === void 0 ? void 0 : data.to_date) || '',
            corporationId: (data === null || data === void 0 ? void 0 : data.corporation_id) || '',
            corporationName: (data === null || data === void 0 ? void 0 : data.corporation_name) || '',
            creatorName: (data === null || data === void 0 ? void 0 : data.creator_name) || '',
            creatorUserId: (data === null || data === void 0 ? void 0 : data.creator_user_id) || '',
            referenceId: data === null || data === void 0 ? void 0 : data.reference_id,
            status: data === null || data === void 0 ? void 0 : data.status,
            createdDate: (data === null || data === void 0 ? void 0 : data.created_date) || '',
            facilityName: (data === null || data === void 0 ? void 0 : data.facility_name) || '',
            facilityId: (data === null || data === void 0 ? void 0 : data.facility_id) || '',
            reportUrl: (data === null || data === void 0 ? void 0 : data.report_url) || '',
            presignedUrl: (data === null || data === void 0 ? void 0 : data.presigned_url) || '',
            zone: (data === null || data === void 0 ? void 0 : data.zone) || '',
            modificationDate: (data === null || data === void 0 ? void 0 : data.modification_date) || '',
        }); });
        return {
            data: parsedData,
            lastEvaluatedKey: data.last_evaluated_key || '',
        };
    };
    return ReportsParser;
}());
export default ReportsParser;
