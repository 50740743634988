var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { makeStyles } from 'tss-react/mui';
import { TemplateTypography } from 'common/Print/Print.utility';
export var caregiverStrainSectionStyles = makeStyles()(function () { return ({
    tableContainer: {
        borderRadius: '10px',
        border: 'solid 1px rgba(0, 0, 0, 0.25)',
        boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
        backgroundColor: '#fff',
        marginBottom: '30px',
        paddingBottom: '30px',
    },
    table: {
        borderCollapse: 'collapse',
        position: 'relative',
        '&  th': { borderBottom: '1px solid #c9c9c9' },
        '& tbody': {
            '&  th': { border: 0 },
        },
    },
    tableHeading: __assign(__assign({}, TemplateTypography.regular), { fontWeight: 500, color: '#000', textAlign: 'center', padding: 5 }),
    CGNameSection: __assign(__assign({}, TemplateTypography.regular), { width: 845, display: 'flex', alignItems: 'center', margin: '20px 0 20px 30px', padding: 30, borderRadius: 10, boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)', border: 'solid 1px rgba(0, 0, 0, 0.25)', backgroundColor: '#fff' }),
    tableContent: __assign(__assign({ paddingLeft: 30 }, TemplateTypography.regular), { width: '1427px', marginRight: 145, justifyContent: 'flex-start', alignItems: 'flex-start', paddingTop: 60 }),
    container: {
        padding: '30px',
    },
    radioGroupHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '650px',
        paddingBottom: '10px',
    },
    radioGroup: {
        width: '600px',
        paddingLeft: 40,
        marginLeft: 30,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        '& label': {
            margin: 0,
        },
    },
    '@media print': {
        section: {
            pageBreakInside: 'avoid',
            breakInside: 'avoid',
        },
    },
    statsValueSection: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: 650,
        padding: '0 36px',
    },
    statsTableContainer: {
        borderRadius: '10px',
        border: '1px solid #c9c9c9',
        backgroundColor: '#fff',
    },
    statsHeader: __assign(__assign({}, TemplateTypography.regular), { fontWeight: 500, color: '#000', padding: 18 }),
    statsTable: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    statsText: __assign(__assign({}, TemplateTypography.regular), { padding: '18px 36px' }),
}); });
