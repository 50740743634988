var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useQuery as useQueryRq, useMutation as useMutationRq, QueryClient, QueryClientProvider, useInfiniteQuery as useInfiniteQueryRq, } from '@tanstack/react-query';
/**
 * @function useQuery
 * @description custom hook built on top of userQuery, used to provide default values.
 */
function useQuery(_a) {
    var _b = _a.refetchOnWindowFocus, refetchOnWindowFocus = _b === void 0 ? false : _b, _c = _a.retry, retry = _c === void 0 ? false : _c, props = __rest(_a, ["refetchOnWindowFocus", "retry"]);
    return useQueryRq(__assign(__assign({}, props), { refetchOnWindowFocus: refetchOnWindowFocus, retry: retry }));
}
/**
 * @function useMutation
 * @description custom hook built on top of useMutation, used to provide default values.
 */
function useMutation(_a) {
    var props = __rest(_a, []);
    return useMutationRq(__assign({}, props));
}
/**
 * @function useInfiniteQuery
 * @description custom hook built on top of useInfiniteQuery, used to provide default values.
 */
function useInfiniteQuery(_a) {
    var _b = _a.refetchOnWindowFocus, refetchOnWindowFocus = _b === void 0 ? false : _b, _c = _a.retry, retry = _c === void 0 ? false : _c, props = __rest(_a, ["refetchOnWindowFocus", "retry"]);
    return useInfiniteQueryRq(__assign(__assign({}, props), { refetchOnWindowFocus: refetchOnWindowFocus, retry: retry }));
}
export { useQuery, useMutation, QueryClient, QueryClientProvider, useInfiniteQuery, };
