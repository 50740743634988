var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import get from 'lodash.get';
import { API } from 'globals/api';
import { isAuthenticateUser, removeObjectProperty, toIsoString, } from 'globals/global.functions';
import { showApplicationLoader, hideApplicationLoader, } from 'common/ApplicationLoader';
import { showToast } from 'common/Toast';
import { TABLE_CACHE_KEY } from 'globals/global.constants';
import { emptyPaginationData, showError, } from 'store/commonReducer/common.action';
import { EventsType, EventViewState } from 'globals/enums';
import { getSeniorMappingService } from 'services/seniorService/senior.service';
export var CREATE_CALL_ENTRY = 'CREATE_CALL_ENTRY';
export var CLOSE_CALL_ENTRY = 'CLOSE_CALL_ENTRY';
export var createCallEntryDialog = function (data) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var seniorQueryPayload, seniorDetail, seniorName, seniorMobile, seniorTimezone, fullName, callEntryData, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                seniorQueryPayload = [
                    {
                        user_id: data.seniorId,
                        account_id: data.accountId,
                    },
                ];
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, getSeniorMappingService(seniorQueryPayload)];
            case 2:
                seniorDetail = _a.sent();
                seniorName = seniorDetail[data.accountId][data.seniorId]['minimal']['name'];
                seniorMobile = seniorDetail[data.accountId][data.seniorId]['minimal']['mobile_number'];
                seniorTimezone = get(seniorDetail[data.accountId][data.seniorId], 'minimal.timezone');
                fullName = "".concat(seniorName.first_name, " ").concat(seniorName.last_name);
                if (!isAuthenticateUser()) {
                    return [2 /*return*/];
                }
                callEntryData = createCallEntryData(fullName, seniorMobile, data.seniorId, EventsType.CALL_ENTRY, EventViewState.Maximize, data.accountId, seniorTimezone, data.alarmId, data.timestamp, data.status, data.lastAlertTime, data.lastCallTime);
                dispatch({
                    type: CREATE_CALL_ENTRY,
                    payload: { callEntryAlarm: callEntryData },
                });
                return [3 /*break*/, 4];
            case 3:
                error_1 = _a.sent();
                // eslint-disable-next-line no-console
                console.log('Data is missing in the API', { error: error_1 });
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
/**
 * @function closeAlarms
 * @description action creator to close the alarms
 */
export var closeCallEntry = function (eventId) { return function (dispatch, getState) {
    var events = getState().callEntry.events;
    var updatedCallEntry = removeObjectProperty(events, eventId);
    dispatch({
        type: CLOSE_CALL_ENTRY,
        payload: { updatedCallEntry: updatedCallEntry },
    });
}; };
var clearCallLogs = function (dispatch) {
    //Clear data of call logs to get updated data.
    dispatch(emptyPaginationData(TABLE_CACHE_KEY.CALL_LOGS));
    dispatch(hideApplicationLoader());
    dispatch(showToast('Call Updated Successfully!', 'success'));
};
var handleCallError = function (dispatch) {
    dispatch(hideApplicationLoader());
    dispatch(showToast('Something Went Wrong!', 'error'));
};
export var updateCallEntryInbound = function (callInfo, data, type) {
    if (type === void 0) { type = 'inbound'; }
    var currentTime = toIsoString(new Date());
    return function (dispatch) {
        dispatch(showApplicationLoader());
        return API({
            url: "supervision/call-logs/instant",
            method: 'post',
            data: {
                account_id: callInfo.account_id,
                senior_id: callInfo.senior_id,
                careagent_id: callInfo.careagent_id,
                start_time: callInfo.start_time,
                call_time: currentTime,
                duration: callInfo.duration,
                call_direction: type,
                call_reason: data.call_reason,
                call_type: data.call_type === 'Fall Detection' ? 'Fall Detected' : data.call_type,
                call_status: 'completed',
                callee_type: callInfo.callee_type,
                call_priority: callInfo.call_priority,
                care_call_notes: data.callNotes,
                action_item: data.actionItems,
                disposition: data.disposition || null,
            },
        })
            .then(function () {
            clearCallLogs(dispatch);
            return { success: true };
        })
            .catch(function (err) {
            handleCallError(dispatch);
            dispatch(showError(err));
            return { success: false };
        });
    };
};
export var updateCallEntryOutbound = function (callInfo, data) {
    var currentTime = toIsoString(new Date());
    return function (dispatch) {
        dispatch(showApplicationLoader());
        return API({
            url: "supervision/call-logs/outbound",
            method: 'post',
            data: {
                account_id: callInfo.account_id,
                senior_id: callInfo.senior_id,
                call_id: callInfo.call_id,
                call_time: currentTime,
                care_call_notes: data.callNotes,
                action_item: data.actionItems,
            },
        })
            .then(function () {
            clearCallLogs(dispatch);
        })
            .catch(function () {
            handleCallError(dispatch);
        });
    };
};
export var resetCallEntryData = function () {
    return function (dispatch) {
        dispatch({
            type: 'SET_CALL_ENTRY',
            payload: {
                callEntryData: [],
            },
        });
    };
};
/**
 * @function createCallEntryData
 * @description method to create data for alert event
 * @param {string} fullName senior fullname
 * @param {string} seniorId senior ID
 
 * @param {EventsType} type type of event alert
 * @param {EventViewState} viewState view state of event maximize or minimize
 
 */
var createCallEntryData = function (fullName, seniorMobile, seniorId, eventType, viewState, accountId, seniorTimezone, alarmId, startTime, callType, lastAlertTime, lastCallTime) {
    var _a;
    var eventId = "".concat(seniorId, "-").concat(alarmId);
    return _a = {},
        _a[eventId] = {
            eventType: eventType,
            viewState: viewState,
            fullName: fullName,
            seniorMobile: seniorMobile,
            seniorId: seniorId,
            accountId: accountId,
            seniorTimezone: seniorTimezone,
            eventId: eventId,
            alarmId: alarmId,
            startTime: startTime,
            callType: callType,
            lastAlertTime: lastAlertTime,
            lastCallTime: lastCallTime,
        },
        _a;
};
