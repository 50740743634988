var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { useLocation } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import { isEmpty } from 'lodash';
import moment from 'moment-timezone';
import { get } from 'react-hook-form';
import { Card } from 'common/components/Card';
import { getHourMin, printStartEndTime, getTimestamp, } from 'globals/global.functions';
import { OPEN_DIALOG, } from 'store/commonReducer/common.action';
import { DIALOG_TYPES } from 'globals/global.constants';
import { SleepQuality } from './components/SleepQuality.component';
import { AverageSleep } from './components/AverageSleep.component';
import { SleepCycle } from './components/SleepCycle.component';
import { getSleepDaily, getSleepDepth, getSleepHR, getSleepPhase, } from './sleep.action';
import { SleepDuration } from './components/sleepDuration';
import { sleepStyle } from './Sleep.style';
var SleepComponent = function (_a) {
    var dailyRecapDateHandler = _a.dailyRecapDateHandler;
    var ref = useRef(true);
    var classes = sleepStyle().classes;
    var dispatch = useAppDispatch();
    var location = useLocation();
    var _b = useState(80), goal = _b[0], setGoal = _b[1];
    var _c = useState(null), goalPercentage = _c[0], setGoalPercentage = _c[1];
    var _d = useAppSelector(function (state) { return state.wellnessDashboard; }), startTime = _d.startTime, endTime = _d.endTime, reRender = _d.reRender;
    var _e = useState({
        data: [],
        loading: true,
        notFound: false,
        subTitle: '',
    }), summary = _e[0], setSummary = _e[1];
    var _f = useState({
        data: [],
        loading: true,
        notFound: false,
        subTitle: '',
    }), sleepHeartRate = _f[0], setSleepHeartRate = _f[1];
    var _g = useState({
        data: [],
        loading: true,
        notFound: false,
        subTitle: '',
    }), sleepCycle = _g[0], setSleepCycle = _g[1];
    var _h = useState({
        data: [],
        loading: true,
        notFound: false,
        subTitle: '',
    }), sleepDepth = _h[0], setSleepDepth = _h[1];
    useEffect(function () {
        if (location.state && ref.current) {
            if (moment(getTimestamp(location.state.vitalData.date)).format('L') ==
                moment(getTimestamp(startTime)).format('L')) {
                ref.current = false;
            }
            dispatch({
                type: OPEN_DIALOG,
                payload: {
                    type: DIALOG_TYPES.DAILY_RECAP,
                    data: {
                        summaryMessage: location.state.vitalData.summaryMessage,
                        date: location.state.vitalData.date,
                    },
                },
            });
            dailyRecapDateHandler(location.state.vitalData.date);
        }
    }, [location.state]);
    useEffect(function () {
        if (startTime && endTime && reRender) {
            printStartEndTime(startTime, endTime);
            setSummary(__assign(__assign({}, summary), { loading: true, notFound: false, subTitle: '' }));
            setSleepHeartRate(__assign(__assign({}, sleepHeartRate), { loading: true, notFound: false, subTitle: '' }));
            setSleepCycle(__assign(__assign({}, sleepCycle), { loading: true, notFound: false, subTitle: '' }));
            setSleepDepth(__assign(__assign({}, sleepDepth), { loading: true, notFound: false, subTitle: '' }));
            getSleepSummary();
            getSleepDepthGraph();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startTime, endTime, reRender]);
    var getSleepDepthGraph = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, dispatch(getSleepDepth(startTime, endTime))];
                case 1:
                    res = _a.sent();
                    if (isEmpty(res)) {
                        setSleepDepth({
                            loading: false,
                            notFound: true,
                        });
                        return [2 /*return*/];
                    }
                    setSleepDepth({
                        data: res,
                        loading: false,
                        notFound: false,
                    });
                    return [2 /*return*/];
            }
        });
    }); };
    var getSleepSummary = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, dispatch(getSleepDaily(startTime, endTime))];
                case 1:
                    response = _a.sent();
                    setGoal(get(response, 'targetScore', 80));
                    setGoalPercentage(get(response, 'goalPercentage', 0));
                    if (isEmpty(response)) {
                        setSummary({
                            data: [],
                            loading: false,
                            notFound: true,
                        });
                        setSleepCycle({
                            loading: false,
                            notFound: true,
                        });
                        setSleepHeartRate({
                            loading: false,
                            notFound: true,
                        });
                        return [2 /*return*/];
                    }
                    setSummary(__assign(__assign({}, summary), { data: response.daySummary, loading: false, notFound: false, subTitle: 'Restful' }));
                    return [4 /*yield*/, getSleepHeartRate(response.daySummary.summaryCycle)];
                case 2:
                    _a.sent();
                    return [4 /*yield*/, getSleepCycleGraph(response.daySummary.summaryCycle)];
                case 3:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var getSleepCycleGraph = function (summaryCycleTimestamps) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, dispatch(getSleepPhase(summaryCycleTimestamps))];
                case 1:
                    res = _a.sent();
                    if (isEmpty(res)) {
                        setSleepCycle({
                            loading: false,
                            notFound: true,
                        });
                        return [2 /*return*/];
                    }
                    setSleepCycle({
                        data: res,
                        loading: false,
                        notFound: false,
                    });
                    return [2 /*return*/];
            }
        });
    }); };
    var getSleepHeartRate = function (summaryCycleTimestamps) { return __awaiter(void 0, void 0, void 0, function () {
        var res, graph, interupptionGraph;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, dispatch(getSleepHR(summaryCycleTimestamps))];
                case 1:
                    res = _a.sent();
                    if (isEmpty(res)) {
                        setSleepHeartRate({
                            loading: false,
                            notFound: true,
                        });
                        return [2 /*return*/];
                    }
                    graph = [];
                    interupptionGraph = [];
                    Object.entries(res).forEach(function (_a) {
                        var key = _a[0], value = _a[1];
                        var sleepCycleData = value.measurement;
                        sleepCycleData === null || sleepCycleData === void 0 ? void 0 : sleepCycleData.map(function (chunk, resCount) {
                            var lastIndex = chunk.measurements.length - 1;
                            var lastIndexTime = chunk.end_time;
                            var lastIndexHR = chunk.measurements[lastIndex].heart_rate;
                            var firstIndexTime = chunk.start_time;
                            var firstIndexHR = chunk.measurements[0].heart_rate;
                            if (resCount == 0 && sleepCycleData.length < 0) {
                                interupptionGraph.push({
                                    x: lastIndexTime,
                                    y: lastIndexHR,
                                });
                            }
                            else if (resCount + 1 == sleepCycleData.length) {
                                interupptionGraph.push({
                                    x: firstIndexTime,
                                    y: firstIndexHR,
                                });
                                interupptionGraph.push({
                                    x: lastIndexTime,
                                    y: null,
                                });
                            }
                            else {
                                interupptionGraph.push({
                                    x: firstIndexTime,
                                    y: firstIndexHR,
                                });
                                interupptionGraph.push({
                                    x: firstIndexTime,
                                    y: null,
                                });
                                interupptionGraph.push({
                                    x: lastIndexTime,
                                    y: lastIndexHR,
                                });
                            }
                            chunk === null || chunk === void 0 ? void 0 : chunk.measurements.map(function (data, i) {
                                graph.push({
                                    x: data.time,
                                    y: data.heart_rate,
                                });
                                if (chunk.measurements.length == i + 1) {
                                    graph.push({
                                        x: data.time,
                                        y: null,
                                    });
                                }
                            });
                        });
                    });
                    setSleepHeartRate({
                        data: graph,
                        interupptionGraph: interupptionGraph,
                        loading: false,
                        notFound: false,
                    });
                    return [2 /*return*/];
            }
        });
    }); };
    var onGoalGetSuccess = function () {
        getSleepSummary();
        getSleepDepthGraph();
    };
    return (_jsxs(_Fragment, { children: [_jsx(Grid, { container: true, spacing: 3, children: _jsx(Grid, { item: true, sm: 12, "data-testid": 'sleep-quality-container', children: _jsxs(Card, { noRecordFound: false, childrenClass: classes.childrenClass, cardContentStyle: {
                            padding: 0,
                        }, children: [_jsx(Grid, { item: true, lg: 6, xs: 6, children: _jsx(Card, { noRecordFound: false, isLoading: summary.loading, title: 'Sleep Quality', subTitle: summary.subTitle, stretch: true, style: { borderRadius: 0, boxShadow: 'none' }, showBorder: true, cardContentStyle: {
                                        margin: '16px 0px',
                                        padding: '0px 16px',
                                        borderRight: '1px solid rgba(0, 0, 0, 0.16)',
                                    }, childrenClass: summary.notFound && classes.alignStart, children: _jsx(SleepQuality, { noRecordFound: summary.notFound, sleepSummary: summary.data, goal: goal, goalPercentage: goalPercentage, onGoalGetSuccess: onGoalGetSuccess }) }) }), _jsx(Grid, { lg: 6, xs: 6, children: _jsx(Card, { noRecordFound: summary.notFound, isLoading: summary.loading, title: 'Weekly SLEEP SCORE', stretch: true, style: { borderRadius: 0, boxShadow: 'none', height: '100%' }, cardContentStyle: {
                                        paddingTop: 0,
                                        paddingBottom: 0,
                                        marginBottom: 24,
                                    }, children: _jsx(SleepDuration, {}) }) })] }) }) }), _jsx(Box, { mt: 2, children: _jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, lg: 6, xs: 6, children: _jsx(Card, { noRecordFound: sleepCycle.notFound, isLoading: sleepCycle.loading, title: 'Sleep Cycles', subTitle: getHourMin(get(summary, 'data.totalSleepTime', '')), stretch: true, children: _jsx(SleepCycle, { graphData: sleepCycle.data }) }) }), _jsx(Grid, { item: true, lg: 6, xs: 6, children: _jsx(Card, { noRecordFound: sleepHeartRate.notFound, isLoading: sleepHeartRate.loading, title: 'Average Sleep Heart Rate', subTitle: "".concat(get(summary, 'data.heartRateAvg', ''), "bpm"), stretch: true, children: _jsx(AverageSleep, { graphData: sleepHeartRate.data, interupptionGraph: sleepHeartRate.interupptionGraph }) }) })] }) })] }));
};
export { SleepComponent };
