import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@mui/material';
import { adlScoreStyles } from './ADLScore.style';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
var ADLScore = function (props) {
    var info = props.info, _a = props.score, score = _a === void 0 ? 0 : _a;
    var classes = adlScoreStyles().classes;
    return (_jsxs(Box, { className: classes.aldScore, "data-testid": 'adl-score', children: [_jsxs(Box, { className: classes.scoreBox, children: [_jsx("span", { className: classes.scoreLabel, children: "Score:" }), score] }), _jsxs(Box, { className: classes.scoreInfo, children: [_jsx(InfoOutlinedIcon, { style: { width: '40px', height: '40px' } }), _jsx(Box, { children: info })] })] }));
};
export default ADLScore;
