var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable max-len */
import { Box, Checkbox, CircularProgress, Table as MuiTable, TableBody, TableCell, TableHead, TableRow, } from '@mui/material';
import { PRIMARY_COLOR, SelectAllStatus, TableSelectionType, API_LOAD_STATE } from 'globals/enums';
import React from 'react';
import { blueTableStyles, greenTableStyles } from './Table.style';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import clsx from 'clsx';
/**
 * @description Table is a customizable table component with Single & MultipleSelect Feature
 * @param {ITableColumn[]} headerData data for rendering header cells of table
 * @param {string} rowId key for uniqueId in record
 * @param {TRecord[]} data actual data array
 * @param {ITableRowData} rowData data for rendering Row & its cells of table body
 * @param {TableSelectionType} selectionType type of selection single/multiple/none
 * @param {TRecord[]} selected selected items array
 * @param onChangeSelected setter function for updated list of selected items
 * @param isSelectable function to determine whether a record is selectable or not
 * @returns render Table
 */
function Table(_a) {
    var headerData = _a.headerData, rowId = _a.rowId, data = _a.data, rowData = _a.rowData, _b = _a.selectionType, selectionType = _b === void 0 ? TableSelectionType.NONE : _b, _c = _a.selected, selected = _c === void 0 ? [] : _c, _d = _a.onChangeSelected, onChangeSelected = _d === void 0 ? function (selected) { } : _d, _e = _a.isSelectable, isSelectable = _e === void 0 ? function (record) { return true; } : _e, _f = _a.noDataMessage, noDataMessage = _f === void 0 ? 'No Records' : _f, _g = _a.isDataLoading, isDataLoading = _g === void 0 ? API_LOAD_STATE.SUCCESSFUL : _g, _h = _a.isFilterLoading, isFilterLoading = _h === void 0 ? false : _h, _j = _a.primaryColor, primaryColor = _j === void 0 ? PRIMARY_COLOR.BLUE : _j, _k = _a.isHighlighted, isHighlighted = _k === void 0 ? function (rowData) { return false; } : _k, _l = _a.highlightClass, highlightClass = _l === void 0 ? '' : _l;
    var blueTableClasses = blueTableStyles().classes;
    var greenTableClasses = greenTableStyles().classes;
    var classes = React.useMemo(function () {
        return primaryColor === PRIMARY_COLOR.GREEN
            ? greenTableClasses
            : blueTableClasses;
    }, [primaryColor]);
    /**
     * @description function to handle single select
     * @param record record to be selected or unselected
     */
    var handleSingleSelect = React.useCallback(function (record, fn) {
        var currentSelection = selected[0];
        if ((currentSelection === null || currentSelection === void 0 ? void 0 : currentSelection[rowId]) === record[rowId])
            onChangeSelected([]);
        else
            onChangeSelected([record]);
    }, [selected, onChangeSelected, rowId]);
    /**
     * @description function to handle multiple select
     * @param record record to be selected or unselected
     */
    var handleMultipleSelect = React.useCallback(function (record, fn) {
        var index = selected.findIndex(function (currentRecord) { return record[rowId] === currentRecord[rowId]; });
        if (index > -1) {
            onChangeSelected(__spreadArray(__spreadArray([], selected.slice(0, index), true), selected.slice(index + 1), true));
        }
        else
            onChangeSelected(__spreadArray(__spreadArray([], selected, true), [record], false));
    }, [selected, onChangeSelected, rowId]);
    /**
     * @description get the handler to be used depending on TableSelection Type
     */
    var handleSelect = React.useMemo(function () {
        switch (selectionType) {
            case TableSelectionType.SINGLE:
                return handleSingleSelect;
            case TableSelectionType.MULTIPLE:
                return handleMultipleSelect;
            case TableSelectionType.ROW:
                return function (record, fn) {
                    fn(record);
                };
            default:
                return function (record) { };
        }
    }, [selectionType, handleSingleSelect, handleMultipleSelect]);
    /**
     * @description filter out the selectable records
     */
    var selectableData = React.useMemo(function () {
        return data.filter(function (record) {
            return isSelectable ? isSelectable(record) : true;
        });
    }, [data, isSelectable]);
    /**
     * @description Determine the status of SelectAll whether All, Some or None are selected
     */
    var selectAllStatus = React.useMemo(function () {
        if (selectableData.length) {
            var currentSelected = selectableData.filter(function (record) {
                return selected.filter(function (rec) { return rec[rowId] === record[rowId]; })
                    .length;
            });
            if (currentSelected.length === 0)
                return SelectAllStatus.NONE;
            else if (currentSelected.length === selectableData.length)
                return SelectAllStatus.ALL;
            else
                return SelectAllStatus.SOME;
        }
        else
            return SelectAllStatus.NONE;
    }, [selected, rowId, selectableData]);
    /**
     * @description handler for selectAll button
     */
    var handleSelectAll = React.useCallback(function () {
        var newSelected = selected;
        if (selectAllStatus === SelectAllStatus.NONE) {
            newSelected = __spreadArray(__spreadArray([], selected, true), selectableData, true);
        }
        else {
            newSelected = selected.filter(function (record) {
                return !data.filter(function (rec) { return rec[rowId] === record[rowId]; }).length;
            });
        }
        onChangeSelected(newSelected);
    }, [
        selectAllStatus,
        selectableData,
        onChangeSelected,
        selected,
        data,
        rowId,
    ]);
    /**
     * @description function to determine whether a record is selected or not
     * @param record
     */
    var isSelected = React.useCallback(function (record) {
        var index = selected.findIndex(function (currentRecord) { return record[rowId] === currentRecord[rowId]; });
        return index > -1;
    }, [selected, rowId]);
    return (_jsxs(_Fragment, { children: [_jsxs(MuiTable, { classes: { root: classes.table }, stickyHeader: true, "data-testid": 'table-component', children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [selectionType === TableSelectionType.SINGLE && (_jsx(TableCell, { width: '48px', style: { padding: '0' } })), selectionType === TableSelectionType.MULTIPLE && (_jsx(TableCell, { width: '48px', style: { padding: '0' }, children: _jsx(Box, { display: 'flex', alignItems: 'center', children: _jsx(Checkbox, { icon: _jsx(CheckBoxOutlineBlankIcon, {}), checkedIcon: selectAllStatus === SelectAllStatus.ALL ? (_jsx(CheckBoxIcon, {})) : (_jsx(IndeterminateCheckBoxIcon, {})), checked: selectAllStatus !== SelectAllStatus.NONE, onClick: handleSelectAll, disabled: selectableData.length === 0, sx: {
                                                padding: '2px 4px',
                                                transform: 'scale(1.15)',
                                                color: '#000',
                                            }, "data-testid": 'select-all' }) }) })), headerData.map(function (columnData) {
                                    var columnId = columnData.columnId, label = columnData.label, render = columnData.render, columnProps = __rest(columnData, ["columnId", "label", "render"]);
                                    return (_jsx(TableCell, __assign({}, columnProps, { children: render ? (render()) : (_jsx(Box, { padding: '4px 4px 2px 4px', children: label })) }), columnId));
                                })] }) }), !isFilterLoading &&
                        isDataLoading === API_LOAD_STATE.SUCCESSFUL &&
                        (data === null || data === void 0 ? void 0 : data.length) > 0 && (_jsx(TableBody, { children: data === null || data === void 0 ? void 0 : data.map(function (record) {
                            var _a;
                            var values = rowData.values, className = rowData.className, _b = rowData.onRowClick, onRowClick = _b === void 0 ? function (event, rowData) { } : _b, rowProps = __rest(rowData, ["values", "className", "onRowClick"]);
                            var active = isSelected(record);
                            var selectable = isSelectable(record);
                            return (_jsxs(TableRow, __assign({ className: clsx(className, (_a = {},
                                    _a[classes.row] = selectionType !== TableSelectionType.NONE && selectable,
                                    _a.active = selectable && active,
                                    _a[highlightClass || classes.highlight] = isHighlighted(record),
                                    _a)), onClick: selectable
                                    ? function () { return handleSelect(record, onRowClick); }
                                    : function () { } }, rowProps, { "data-testid": 'table-row', children: [selectionType === TableSelectionType.SINGLE && (_jsx(TableCell, { width: '48px', style: {
                                            padding: '0',
                                            verticalAlign: 'middle',
                                        }, children: _jsx(Box, { display: 'flex', alignItems: 'center', children: _jsx(Checkbox, { icon: _jsx(CheckCircleOutlineIcon, {}), checkedIcon: _jsx(CheckCircleIcon, {}), checked: active, disabled: !selectable, classes: {
                                                    checked: classes.singleSelectColorPrimary,
                                                }, sx: {
                                                    padding: '2px 4px',
                                                    transform: 'scale(1.15)',
                                                } }) }) })), selectionType === TableSelectionType.MULTIPLE && (_jsx(TableCell, { width: '48px', style: {
                                            padding: '0',
                                            verticalAlign: 'middle',
                                        }, children: _jsx(Box, { display: 'flex', alignItems: 'center', children: _jsx(Checkbox, { icon: _jsx(CheckBoxOutlineBlankIcon, {}), checkedIcon: _jsx(CheckBoxIcon, {}), checked: active, disabled: !selectable, sx: {
                                                    padding: '2px 4px',
                                                    transform: 'scale(1.15)',
                                                    color: '#000',
                                                } }) }) })), values.map(function (columnData) {
                                        var dataId = columnData.dataId, dataKey = columnData.dataKey, format = columnData.format, render = columnData.render, columnProps = __rest(columnData, ["dataId", "dataKey", "format", "render"]);
                                        var value = record[dataId];
                                        return (_jsx(TableCell, __assign({}, columnProps, { children: render ? (render(value, record)) : (_jsx(Box, { padding: '12.5px 4px', children: format ? format(value) : value })) }), dataKey || dataId));
                                    })] }), record[rowId]));
                        }) }))] }), !isFilterLoading &&
                isDataLoading !== API_LOAD_STATE.PROGRESS &&
                !(data === null || data === void 0 ? void 0 : data.length) && (_jsx(Box, { typography: 'body1', textAlign: 'center', padding: '12.5px', width: '100%', style: { marginTop: 108 }, children: noDataMessage })), (isDataLoading === API_LOAD_STATE.PROGRESS || isFilterLoading) && (_jsx(Box, { textAlign: 'center', padding: '12.5px', width: '100%', "data-testid": 'loader', children: _jsx(CircularProgress, {}) }))] }));
}
export default Table;
