import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Typography } from '@mui/material';
import Welcome from 'assets/Welcome.png';
import { getCareAgentInfo } from 'globals/global.functions';
import { welcomeWrapperStyle } from './WelcomeWrapper.style';
var WelcomeWrapper = function (_a) {
    var _b;
    var children = _a.children;
    var careAgentInfo = getCareAgentInfo();
    var classes = welcomeWrapperStyle().classes;
    return (_jsxs(_Fragment, { children: [_jsxs(Box, { className: classes.welcomeContainer, "data-testid": 'home-component', children: [_jsx(Box, { className: classes.welcomeImageContainer, children: _jsx("img", { alt: 'welcome username image', className: classes.welcomeImage, src: Welcome }) }), _jsxs(Box, { className: classes.welcomeText, children: [_jsxs(Box, { display: 'flex', children: [_jsx(Typography, { variant: 'h2v1', style: { fontWeight: 300 }, children: "Welcome,\u00A0" }), _jsx(Typography, { variant: 'h2v1', style: { fontWeight: 800 }, children: (_b = careAgentInfo === null || careAgentInfo === void 0 ? void 0 : careAgentInfo.userName) === null || _b === void 0 ? void 0 : _b.first_name })] }), _jsx(Typography, { variant: 'body1', children: "Have a nice day at work" })] })] }), children] }));
};
export default WelcomeWrapper;
