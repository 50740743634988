import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-len */
import { Box, Table, TableBody, TableCell as MuiTableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React from 'react';
import { homeSafetySectionStyles } from './HomeSafetySection.style';
import { withStyles } from 'tss-react/mui';
var TableCell = withStyles(MuiTableCell, {
    root: {
        borderBottom: 'none',
        padding: '5px 0',
    },
});
var HomeSafetySection = function (props) {
    var _a = props.heading, heading = _a === void 0 ? '' : _a, _b = props.data, data = _b === void 0 ? [] : _b;
    // Ensure data is always an array
    var dataArray = Array.isArray(data) ? data : [];
    var classes = homeSafetySectionStyles().classes;
    return (_jsxs(Box, { component: 'section', className: classes.section, "data-testid": 'print-homeSafety-section', children: [_jsxs(Box, { className: classes.header, children: [_jsx("div", { className: classes.heading, children: heading }), _jsx("div", { className: classes.tableHeading, style: { paddingRight: '30px' } })] }), _jsx(Box, { className: classes.container, children: _jsx(TableContainer, { children: _jsxs(Table, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, { children: _jsx("div", { style: { width: '1580px' } }) }), _jsx(TableCell, { align: 'right', children: _jsx(Box, { className: classes.radioGroupHeader, children: _jsx(Box, { className: classes.tableHeading, children: "Answer" }) }) })] }) }), _jsx(TableBody, { children: dataArray.map(function (row, index) { return (_jsxs(TableRow, { children: [_jsx(TableCell, { component: 'th', scope: 'row', children: _jsxs(Box, { className: classes.tableContent, children: [_jsxs(Box, { children: [index + 1, ".)"] }), _jsxs(Box, { children: [row.question, _jsx("span", { style: { color: '#cc0000' }, children: "*" })] })] }) }), _jsx(TableCell, { children: _jsx(Box, { className: classes.tableHeading2, children: row.answers }) })] }, row.question)); }) })] }) }) })] }));
};
export default HomeSafetySection;
