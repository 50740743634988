var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Grid, Box, Typography, CircularProgress } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { maskPhoneNumber } from 'globals/global.functions';
import ShowHyphen from 'common/ShowHyphen/ShowHyphen';
import { ResidentSummaryStyle } from './ResidentDetailSummary.style';
import { useAppSelector } from 'hooks/reduxHooks';
import { DEVICE_TYPE } from 'globals/global.constants';
import { FACILITY_TYPES } from 'globals/enums';
var ResidentDetailSummary = function () {
    var classes = ResidentSummaryStyle().classes;
    var _a = useAppSelector(function (state) { return state.common; }), _b = _a.seniorDetail, minimalInfo = _b.minimalInfo, isLoading = _b.isLoading, caregiverData = _a.careGiverInfo, providerData = _a.providerInfo;
    var basicInfo = useAppSelector(function (state) { return state.common.seniorDetail.basicInfo; });
    var deviceDetail = useAppSelector(function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.devices) === null || _a === void 0 ? void 0 : _a.data; });
    var summaryList = React.useMemo(function () {
        var _a, _b, _c, _d, _e, _f, _g;
        var _h = __assign(__assign({}, basicInfo), minimalInfo), _j = _h.emergency_phone, emergency_phone = _j === void 0 ? '' : _j, _k = _h.mobile_number, mobile_number = _k === void 0 ? null : _k, _l = _h.facility_type, facilityType = _l === void 0 ? '' : _l;
        var emergencyCareGiver = caregiverData === null || caregiverData === void 0 ? void 0 : caregiverData.find(function (data) { return data.emergencyContact === true; });
        var doctorDetail = (_a = providerData === null || providerData === void 0 ? void 0 : providerData.doctor) === null || _a === void 0 ? void 0 : _a.find(function (item) { return item.isPrimary; });
        var watchDetail = deviceDetail === null || deviceDetail === void 0 ? void 0 : deviceDetail.find(function (device) { return device.device_type == DEVICE_TYPE.WATCH; });
        if (facilityType === FACILITY_TYPES.INDEPENDENT_LIVING) {
            return [
                {
                    label: 'Senior Mobile #',
                    value: maskPhoneNumber(mobile_number),
                },
                {
                    label: 'Watch phone #',
                    value: maskPhoneNumber(watchDetail === null || watchDetail === void 0 ? void 0 : watchDetail.watch_phone_number) || '',
                },
                {
                    label: 'Emergency Contact',
                    value: "".concat(((_b = emergencyCareGiver === null || emergencyCareGiver === void 0 ? void 0 : emergencyCareGiver.name) === null || _b === void 0 ? void 0 : _b.firstName) || '', " ").concat(((_c = emergencyCareGiver === null || emergencyCareGiver === void 0 ? void 0 : emergencyCareGiver.name) === null || _c === void 0 ? void 0 : _c.lastName) || ''),
                },
                {
                    label: 'Emergency Mobile #',
                    value: maskPhoneNumber(emergencyCareGiver === null || emergencyCareGiver === void 0 ? void 0 : emergencyCareGiver.mobileNumber) || '',
                },
            ];
        }
        return [
            {
                label: 'Senior Mobile #',
                value: maskPhoneNumber(mobile_number),
            },
            {
                label: 'Watch phone #',
                value: maskPhoneNumber(watchDetail === null || watchDetail === void 0 ? void 0 : watchDetail.watch_phone_number) || '',
            },
            {
                label: 'Local 911 #',
                value: maskPhoneNumber(emergency_phone),
            },
            {
                label: 'Emergency Contact',
                value: "".concat(((_d = emergencyCareGiver === null || emergencyCareGiver === void 0 ? void 0 : emergencyCareGiver.name) === null || _d === void 0 ? void 0 : _d.firstName) || '', " ").concat(((_e = emergencyCareGiver === null || emergencyCareGiver === void 0 ? void 0 : emergencyCareGiver.name) === null || _e === void 0 ? void 0 : _e.lastName) || ''),
            },
            {
                label: 'Emergency Mobile #',
                value: maskPhoneNumber(emergencyCareGiver === null || emergencyCareGiver === void 0 ? void 0 : emergencyCareGiver.mobileNumber) || '',
            },
            {
                label: 'Primary Doctor',
                value: "".concat(((_f = doctorDetail === null || doctorDetail === void 0 ? void 0 : doctorDetail.name) === null || _f === void 0 ? void 0 : _f.firstName) || '', " ").concat(((_g = doctorDetail === null || doctorDetail === void 0 ? void 0 : doctorDetail.name) === null || _g === void 0 ? void 0 : _g.lastName) || ''),
            },
            {
                label: 'Doctor Office #',
                value: maskPhoneNumber(doctorDetail === null || doctorDetail === void 0 ? void 0 : doctorDetail.contactPhone) || '',
            },
        ];
    }, [
        basicInfo,
        caregiverData,
        deviceDetail,
        minimalInfo,
        providerData === null || providerData === void 0 ? void 0 : providerData.doctor,
    ]);
    if (isLoading) {
        return (_jsx(Box, { className: classes.ResidentSummaryContainer, display: 'flex', justifyContent: 'center', alignItems: 'center', "data-testid": 'loader', children: _jsx(CircularProgress, { color: 'primary' }) }));
    }
    return (_jsx(Grid, { className: classes.ResidentSummaryContainer, "data-testid": 'Resident-detail-component', children: summaryList.map(function (summary) {
            return (_jsxs(Box, { className: classes.ResidentDetail, children: [_jsxs(Typography, { variant: 'h5', className: classes.ResidentDetailHeading, children: [summary.label, ":"] }), _jsx(Typography, { variant: 'body1', className: classes.ResidentDetailValue, children: _jsx(ShowHyphen, { withValue: true, value: summary.value.trim(), children: _jsx(NavLink, { to: '#', className: classes.NavLink, children: summary.value }) }) })] }, summary.label));
        }) }));
};
export default ResidentDetailSummary;
