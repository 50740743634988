var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import { isEmpty } from 'lodash';
import get from 'lodash.get';
import moment from 'moment';
import calendar from 'calendar-month-array';
import sprintf from 'sprintf';
import { getHourMin, printStartEndTime, getTimestamp, } from 'globals/global.functions';
import { Card } from 'common/components/Card';
import { SleepDurationGraph } from 'common/Graphs';
import { CircularProgress } from 'common/CircularProgress';
import { getSleepDaily } from './sleep.action';
import { DATE_FORMAT_SHORT_YEAR } from 'globals/global.constants';
import SleepQualityGoals from './components/SleepQualityGoal';
var SleepMonth = function () {
    var theme = useTheme();
    var dispatch = useAppDispatch();
    var _a = useAppSelector(function (state) { return state.wellnessDashboard; }), startTime = _a.startTime, endTime = _a.endTime, reRender = _a.reRender;
    var _b = useState(100), goal = _b[0], setGoal = _b[1];
    var _c = useState({
        data: [],
        loading: true,
        notFound: false,
        subTitle: '',
    }), score = _c[0], setScore = _c[1];
    var _d = useState({
        data: [],
        loading: true,
        notFound: false,
        subTitle: '',
    }), summary = _d[0], setSummary = _d[1];
    var _e = useState({
        data: [],
        loading: true,
        notFound: false,
        subTitle: '',
    }), sleepMonthGraph = _e[0], setSleepMonthGraph = _e[1];
    var _f = useState(null), currentMonth = _f[0], setCurrentMonth = _f[1];
    var _g = useState(null), goalPercentage = _g[0], setGoalPercentage = _g[1];
    useEffect(function () {
        if (startTime && endTime && reRender) {
            printStartEndTime(startTime, endTime);
            setSummary(__assign(__assign({}, summary), { loading: true, subTitle: '' }));
            setScore(__assign(__assign({}, score), { loading: true }));
            setSleepMonthGraph(__assign(__assign({}, sleepMonthGraph), { loading: true }));
            getMonthSummary();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startTime, endTime, reRender]);
    var getMonthSummary = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res, data, timestamp, weeks, avgSleepTime, avgTimeInBed, totalSleepTime, totalTimeInBed, graphData, monthArr, startDate, endDate, startDateNew, i, sleepTime, timeInBed;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, dispatch(getSleepDaily(startTime, endTime))];
                case 1:
                    res = _a.sent();
                    data = get(res, 'summary');
                    timestamp = getTimestamp(startTime);
                    setGoal(get(res, 'targetScore'));
                    setGoalPercentage(get(res, 'goalPercentage'));
                    weeks = calendar(new Date(timestamp), {
                        weekStartDay: 1,
                        formatHeader: function (date) { return date.toString().slice(0, 2); },
                        formatDate: function (date) { return sprintf('%d', date.getDate()); },
                        formatSiblingMonthDate: function () { return ''; },
                    });
                    setCurrentMonth(weeks);
                    if (isEmpty(data)) {
                        setSummary(__assign(__assign({}, summary), { loading: false, notFound: true }));
                        setScore(__assign(__assign({}, score), { loading: false, notFound: true }));
                        setSleepMonthGraph(__assign(__assign({}, sleepMonthGraph), { loading: false, notFound: true }));
                        return [2 /*return*/];
                    }
                    setSummary(__assign(__assign({}, summary), { data: res, loading: false, notFound: false }));
                    avgSleepTime = get(res, 'averageSleepTime');
                    avgTimeInBed = get(res, 'averageSleepTimeInBed');
                    totalSleepTime = [];
                    totalTimeInBed = [];
                    graphData = [];
                    monthArr = [];
                    startDate = moment(startTime * 0.000001);
                    endDate = moment(endTime * 0.000001).subtract(1, 'days');
                    while (endDate.diff(startDate, 'days') >= 0) {
                        startDateNew = moment(startDate).valueOf() * 1000000;
                        monthArr.push(startDateNew);
                        startDate.add(1, 'days');
                    }
                    for (i in monthArr) {
                        sleepTime = {
                            x: moment(getTimestamp(monthArr[i])).format('MM/DD'),
                            y: null,
                            z: moment(getTimestamp(monthArr[i])).format(DATE_FORMAT_SHORT_YEAR),
                        };
                        timeInBed = {
                            x: moment(getTimestamp(monthArr[i])).format('MM/DD'),
                            y: null,
                            z: moment(getTimestamp(monthArr[i])).format(DATE_FORMAT_SHORT_YEAR),
                        };
                        totalSleepTime.push(sleepTime);
                        totalTimeInBed.push(timeInBed);
                    }
                    monthArr.forEach(function (eachDayTime, index) {
                        data === null || data === void 0 ? void 0 : data.forEach(function (sleepData) {
                            if (moment(getTimestamp(sleepData.time)).format('MM/DD') ===
                                moment(getTimestamp(eachDayTime)).format('MM/DD')) {
                                totalSleepTime[index].y = sleepData.total_sleep_time;
                                totalTimeInBed[index].y = sleepData.total_timeinbed;
                            }
                        });
                    });
                    graphData = {
                        totalSleepTime: totalSleepTime,
                        totalTimeInBed: totalTimeInBed,
                        avgSleepTime: avgSleepTime,
                        avgTimeInBed: avgTimeInBed,
                    };
                    setSleepMonthGraph(__assign(__assign({}, sleepMonthGraph), { data: graphData, loading: false, notFound: false }));
                    updateScore(get(res, 'summary'));
                    return [2 /*return*/];
            }
        });
    }); };
    var updateScore = function (monthScore) {
        monthScore.sort(function (x, y) {
            return x.time - y.time;
        });
        var allScore = [];
        monthScore.forEach(function (data) {
            var monthScoreTimestamp = getTimestamp(data.time);
            allScore.push({
                week: moment(monthScoreTimestamp).format('D'),
                score: data.sleep_score,
            });
        });
        setScore(__assign(__assign({}, score), { data: allScore, loading: false, notFound: false }));
    };
    var renderScoreDayView = function (day, index) {
        var existDay = score.data.find(function (scoreDay) { return scoreDay.week == day; });
        if (existDay) {
            return (_jsx(CircularProgress, { innerText: existDay.week, value: existDay.score, tooltip: true }, index));
        }
        if (!existDay && day) {
            return (_jsx(CircularProgress, { innerText: day, value: 0, tooltip: true }, index));
        }
        return _jsx(Box, {}, index);
    };
    var onGoalGetSuccess = function () {
        getMonthSummary();
    };
    return (_jsx(_Fragment, { children: _jsxs(Grid, { spacing: 2, container: true, "data-testid": 'sleep-month-component', children: [_jsxs(Grid, { item: true, sm: 3, display: 'flex', flexDirection: 'column', children: [_jsx(Card, { title: 'Sleep Cycle', noRecordFound: false, isLoading: summary.loading, children: _jsx(SleepQualityGoals, { noRecordFound: summary.notFound, sleepGoal: goal, percentage: goalPercentage, value: get(summary, 'data.averageSleepScore', 0), onGoalGetSuccess: onGoalGetSuccess }) }), _jsx(Card, { mt: 2, title: 'Monthly Average', noRecordFound: summary.notFound, isLoading: summary.loading, stretch: true, children: _jsx(Box, { fontSize: 24, color: theme.palette.customColor.primaryGreen, children: getHourMin(get(summary, 'data.averageSleepTime')) }) })] }), _jsx(Grid, { item: true, sm: 4, display: 'flex', flexDirection: 'column', children: _jsx(Card, { title: 'Sleep Score', noRecordFound: score.notFound, isLoading: score.loading, stretch: true, children: _jsx(Box, { width: '100%', style: { overflow: 'scroll', height: '100%' }, children: _jsx(Box, { display: 'grid', gridTemplateColumns: 'repeat(7, 1fr)', children: currentMonth &&
                                    currentMonth.map(function (week, index) {
                                        return week.map(function (day, i) {
                                            if (index == 0) {
                                                return (_jsx(Box, { style: { padding: '5px 20px' }, alignItems: 'center', children: _jsx("strong", { children: day }, day) }, day));
                                            }
                                            else {
                                                return renderScoreDayView(day, i);
                                            }
                                        });
                                    }) }) }) }) }), _jsx(Grid, { item: true, sm: 5, children: _jsx(Card, { title: 'Sleep Duration', isLoading: sleepMonthGraph.loading, noRecordFound: sleepMonthGraph.notFound, stretch: true, children: _jsxs(Box, { children: [_jsx(SleepDurationGraph, { sleepMonthGraph: get(sleepMonthGraph, 'data') }), _jsxs(Box, { width: '100%', display: 'grid', rowGap: '28px', justifyContent: 'space-between', children: [_jsxs(Box, { display: 'flex', children: [_jsx(Typography, { style: { paddingRight: 5 }, children: "Average Sleep Duration" }), _jsx(Typography, { style: { fontWeight: 'bold' }, children: getHourMin(get(sleepMonthGraph, 'data.avgSleepTime')) })] }), _jsxs(Box, { display: 'flex', children: [_jsx(Typography, { style: { paddingRight: 5 }, children: "Average Time In Bed" }), _jsx(Typography, { style: { fontWeight: 'bold' }, children: getHourMin(get(sleepMonthGraph, 'data.avgTimeInBed')) })] })] })] }) }) })] }) }));
};
export { SleepMonth };
