import { makeStyles } from 'tss-react/mui';
export var useRowStyles = makeStyles()(function (theme) {
    var _a, _b, _c, _d;
    return ({
        root: {
            '& ul': {
                // paddingBottom: '2%',
                justifyContent: 'center',
                '& .Mui-selected': {
                    color: ((_b = (_a = theme.palette) === null || _a === void 0 ? void 0 : _a.customColor) === null || _b === void 0 ? void 0 : _b.white) || 'white',
                    background: ((_d = (_c = theme.palette) === null || _c === void 0 ? void 0 : _c.customColor) === null || _d === void 0 ? void 0 : _d.primary) || '#16a9d0',
                    height: 24,
                    minWidth: 24,
                },
            },
        },
        secondary: {
            backgroundColor: 'white',
            height: 32,
        },
        secondaryWithBorder: {
            backgroundColor: 'white',
            height: 32,
            borderRadius: '10px',
        },
        border: {
            border: '1px solid #c9c9c9',
            borderRadius: 10,
            marginBottom: 30,
            paddingBottom: 20,
        },
    });
});
