import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Box, useTheme } from '@mui/material';
import { VictoryChart, VictoryAxis, VictoryLine, VictoryLabel, VictoryVoronoiContainer, } from 'victory';
import moment from 'moment-timezone';
import { getTimestamp } from 'globals/global.functions';
import { sleepStyle } from '../Sleep.style';
var AverageSleep = function (_a) {
    var graphData = _a.graphData, interupptionGraph = _a.interupptionGraph;
    var theme = useTheme();
    var classes = sleepStyle().classes;
    return (_jsx(_Fragment, { children: _jsx(Box, { className: classes.heartRateContainer, "data-testid": 'average-sleep-graph', children: _jsxs(VictoryChart, { width: 900, height: 312, minDomain: { y: 30 }, containerComponent: _jsx(VictoryVoronoiContainer, { activateLabels: true, labels: function (_a) {
                        var datum = _a.datum;
                        return "".concat(datum._x, " ").concat(datum._y);
                    }, labelComponent: _jsx(Tooltip, {}) }), children: [_jsx(VictoryAxis, { dependentAxis: true, style: {
                            axis: { stroke: 'none' },
                            grid: { stroke: theme.palette.customColor.strokeGrey },
                        }, label: 'bpm', axisLabelComponent: _jsx(VictoryLabel, { angle: 0, dy: -120, style: { fontWeight: 600, fontSize: 15 } }) }), _jsx(VictoryLine, { style: {
                            data: {
                                stroke: theme.palette.customColor.moderateGreen,
                                strokeWidth: 2,
                            },
                        }, data: graphData }), _jsx(VictoryLine, { style: {
                            data: {
                                stroke: theme.palette.customColor.strokeBlue,
                                strokeWidth: 3,
                            },
                        }, data: interupptionGraph })] }) }) }));
};
var Tooltip = function (props) {
    var theme = useTheme();
    var x = props.x, datum = props.datum, height = props.height;
    return (_jsxs("g", { transform: "translate(".concat(x - 40, ", 0)"), children: [_jsxs("g", { transform: 'translate(0, 0)', children: [_jsx("rect", { height: 76, width: 80, stroke: theme.palette.background.green, fill: theme.palette.background.green, ry: 3, rx: 3 }), _jsx("text", { dx: 80 / 2, dy: 30, alignmentBaseline: 'middle', textAnchor: 'middle', fontSize: 36, fill: theme.palette.common.black, children: datum.y }), _jsx("text", { dx: 80 / 2, dy: 58, alignmentBaseline: 'middle', textAnchor: 'middle', fontSize: 14, fontWeight: 'bold', fill: theme.palette.customColor.info, children: moment(getTimestamp(datum.x)).format('h:mm a') })] }), _jsxs("g", { transform: "translate(".concat(80 / 2, ", 0)"), children: [_jsx("line", { y1: height - 30, y2: 76, stroke: 'grey', strokeWidth: 2 }), _jsx("polygon", { points: '0,85 -6,76 8,76', fill: theme.palette.background.green, stroke: theme.palette.background.green, strokeWidth: '1' })] })] }, 'tooltip'));
};
export { AverageSleep };
