import { makeStyles } from 'tss-react/mui';
export var facilityHomeScreenTheme = makeStyles()(function (theme) { return ({
    cardAction: {
        padding: '64px 0',
    },
    cardMedia: {
        width: '148px',
        margin: '0 auto 0px auto',
    },
    cardContent: {
        textAlign: 'center',
        paddingTop: '32px',
    },
    cardButton: {
        color: theme.palette.common.white,
        padding: '8px 22px',
        fontWeight: '600',
        fontSize: '18px',
        borderRadius: '24px',
        marginTop: '24px',
    },
    imageWrapper: {
        height: '148px',
        display: 'flex',
        flexDirection: 'column-reverse',
    }
}); });
