import UCLALonelinessGraphModel from '../model/uCLALonelinessGraphModel';
import moment from 'moment';
import 'moment-timezone';
import { DATE_FORMAT_YEAR } from 'globals/global.constants';
var UCLALonelinessGraphParser = /** @class */ (function () {
    function UCLALonelinessGraphParser() {
        this.uCLALonelinessGraphData = {
            overall: {
                data: [],
                score: 0,
            },
            blue: {
                data: [],
                score: 0,
            },
            green: {
                data: [],
                score: 0,
            },
            gold: {
                data: [],
                score: 0,
            },
        };
        this.getDataArray = function (data) {
            var dataArray = Object.entries(data).map(function (entry) {
                var timestamp = entry[0], score = entry[1];
                return {
                    timestamp: moment.tz(timestamp, 'UTC').format(DATE_FORMAT_YEAR),
                    score: score,
                };
            });
            return dataArray;
        };
    }
    /**
     * @description function to parse the response data
     * @function parse
     * @param {IUCLALonelinessGraphDataResponse[]} data api response data
     * @returns {IUCLALonelinessGraphParse} parsed data
     */
    UCLALonelinessGraphParser.prototype.parse = function (data, currentMonthString) {
        var _a, _b, _c, _d;
        this.uCLALonelinessGraphData = new UCLALonelinessGraphModel({
            data: this.getDataArray((data === null || data === void 0 ? void 0 : data.overall) || {}),
            score: ((_a = data === null || data === void 0 ? void 0 : data.overall) === null || _a === void 0 ? void 0 : _a[currentMonthString]) || 0,
        }, {
            data: this.getDataArray((data === null || data === void 0 ? void 0 : data.blue) || {}),
            score: ((_b = data === null || data === void 0 ? void 0 : data.blue) === null || _b === void 0 ? void 0 : _b[currentMonthString]) || 0,
        }, {
            data: this.getDataArray((data === null || data === void 0 ? void 0 : data.green) || {}),
            score: ((_c = data === null || data === void 0 ? void 0 : data.green) === null || _c === void 0 ? void 0 : _c[currentMonthString]) || 0,
        }, {
            data: this.getDataArray((data === null || data === void 0 ? void 0 : data.gold) || {}),
            score: ((_d = data === null || data === void 0 ? void 0 : data.gold) === null || _d === void 0 ? void 0 : _d[currentMonthString]) || 0,
        });
        return this.uCLALonelinessGraphData;
    };
    return UCLALonelinessGraphParser;
}());
export default UCLALonelinessGraphParser;
