var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { RESET_PRINT_DATA, SET_PRINT_DATA } from "./print.action";
import { initialState } from "./print.types";
var printReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case SET_PRINT_DATA:
            return __assign(__assign({}, state), (action.payload || {}));
        case RESET_PRINT_DATA:
            return initialState;
        default:
            return state;
    }
};
export default printReducer;
