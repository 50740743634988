import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@mui/material';
import React from 'react';
import ADLScore from 'common/Print/components/ADLScore/ADLScore.component';
import ADLSection from 'common/Print/components/ADLSection/ADLSection.component';
import { getADLScore, infoText } from 'common/Print/Print.utility';
var KatzADLAssessmentTemplate = function (_a) {
    var _b = _a.data, data = _b === void 0 ? [] : _b;
    var score = React.useMemo(function () {
        return getADLScore(data);
    }, [data]);
    return (_jsxs(Box, { "data-testid": 'katz-template', children: [data === null || data === void 0 ? void 0 : data.map(function (section) {
                var _a, _b;
                return (_jsx(ADLSection, { title: (_a = section.surveyData) === null || _a === void 0 ? void 0 : _a.title, options: (_b = section.surveyData) === null || _b === void 0 ? void 0 : _b.options }, section.surveyName));
            }), _jsx(ADLScore, { score: score, info: infoText.katz })] }));
};
export default KatzADLAssessmentTemplate;
