import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-len */
import { Box, Table, TableCell, TableContainer, TableHead, TableRow, TableBody, } from '@mui/material';
import { parseUCLALonelinessStats, splitIntoSmallerArrays, } from 'common/Print/Print.utility';
import { uCLALonelinessStatsStyles } from './UCLALonelinessStats.style';
import { startCase } from 'lodash';
var UCLALonelinessStats = function (_a) {
    var _b = _a.data, data = _b === void 0 ? [] : _b;
    var _c = parseUCLALonelinessStats(data), _d = _c.scores, allScores = _d === void 0 ? [] : _d, _e = _c.totalScore, totalScore = _e === void 0 ? 0 : _e;
    var classes = uCLALonelinessStatsStyles().classes;
    return (_jsxs(Box, { className: classes.container, "data-testid": 'print-uCLALoneliness-stats', children: [splitIntoSmallerArrays(allScores, 6).map(function (scores) { return (_jsx(TableContainer, { className: classes.tableContainer, "data-testid": 'uCLALoneliness-stats-table', children: _jsxs(Table, { children: [_jsx(TableHead, { children: _jsx(TableRow, { children: scores.map(function (section) { return (_jsx(TableCell, { className: classes.heading, scope: 'header', children: startCase(section.surveyName) }, section.surveyName)); }) }) }), _jsx(TableBody, { children: _jsx(TableRow, { children: scores.map(function (section) { return (_jsx(TableCell, { scope: 'row', style: { width: 'max-content' }, children: _jsx(Box, { className: classes.content, children: section.score || 0 }) }, section.surveyName)); }) }) })] }) }, scores[0].surveyName)); }), _jsx(Box, { display: 'flex', justifyContent: 'flex-end', children: _jsxs(Box, { className: classes.totalScore, children: [_jsx("span", { children: "Total Score: " }), " ", totalScore || 0] }) })] }));
};
export default UCLALonelinessStats;
