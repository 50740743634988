var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, CircularProgress, useTheme } from '@mui/material';
import { capitalizeFirstLetter, getMonthRange, getMonthsFromRange, } from 'globals/global.functions';
import React from 'react';
import { VictoryAxis, VictoryBar, VictoryChart, VictoryGroup, VictoryLegend, VictoryLine, } from 'victory';
import { zoneChartStyle } from './ZoneChart.style';
var LegendLine = function (props) {
    var theme = useTheme();
    var x = props.x, y = props.y, color = props.color;
    return (_jsx("line", { x1: x - 8, y1: y, x2: x + 8, y2: y, style: {
            stroke: color || theme.palette.customColor.strokeRed,
            strokeWidth: 2,
            opacity: 1,
            fill: 'none',
        } }));
};
var ZoneChart = function (_a) {
    var state = _a.state, data = _a.data, _b = _a.title, title = _b === void 0 ? '' : _b, _c = _a.current, current = _c === void 0 ? false : _c, _d = _a.currentLabel, currentLabel = _d === void 0 ? '' : _d, _e = _a.currentValue, currentValue = _e === void 0 ? '' : _e, _f = _a.showTarget, showTarget = _f === void 0 ? false : _f, _g = _a.target, target = _g === void 0 ? 0 : _g, _h = _a.isLoading, isLoading = _h === void 0 ? false : _h, _j = _a.yAxisProps, yAxisProps = _j === void 0 ? {} : _j, _k = _a.showLegend, showLegend = _k === void 0 ? true : _k, _l = _a.isSmall, isSmall = _l === void 0 ? false : _l;
    var theme = useTheme();
    var classes = zoneChartStyle().classes;
    var _m = getMonthRange(state.months), startMonth = _m[0], endMonth = _m[1];
    var legendData = React.useMemo(function () {
        if (!showLegend) {
            return [];
        }
        var data = [];
        if (state.zones.overall) {
            data.push({
                name: 'All Zones',
                symbol: { fill: theme.palette.customColor.intenseGreen, type: 'square' },
            });
        }
        if (state.zones.blue) {
            data.push({
                name: 'Blue',
                symbol: { fill: theme.palette.zoneBlue.main, type: 'square' },
            });
        }
        if (state.zones.green) {
            data.push({
                name: 'Green',
                symbol: { fill: theme.palette.zoneGreen.main, type: 'square' },
            });
        }
        if (state.zones.gold) {
            data.push({
                name: 'Gold',
                symbol: { fill: theme.palette.zoneGold.main, type: 'square' },
            });
        }
        return data;
    }, [state.zones, showLegend]);
    var zoneCount = React.useMemo(function () {
        var zones = state.zones;
        var count = 0;
        if (zones.overall) {
            return 1;
        }
        if (zones.blue)
            count += 1;
        if (zones.green)
            count += 1;
        if (zones.gold)
            count += 1;
        return count;
    }, [state.zones]);
    var barWidth = React.useMemo(function () {
        if (state.months === 12) {
            return 20;
        }
        else if (zoneCount === 1) {
            return 60;
        }
        else if (zoneCount === 2) {
            return 30;
        }
        return 20;
    }, [state.months, zoneCount]);
    return (_jsxs(Box, { className: classes.container, style: isSmall
            ? {
                width: '397px',
            }
            : {}, children: [_jsxs(Box, { display: 'flex', flexDirection: 'column', children: [_jsx(Box, { className: classes.header, children: _jsx(Box, { className: classes.title, children: title }) }), current && (_jsxs(Box, { className: classes.currentContainer, children: [_jsx("span", { className: classes.currentLabel, children: currentLabel }), _jsx("span", { className: classes.currentValue, children: currentValue })] }))] }), isLoading ? (_jsx(Box, { className: classes.loader, style: isSmall ? { height: '360px' } : { height: '340px' }, children: _jsx(CircularProgress, {}) })) : (_jsx(Box, { style: isSmall ? { margin: '-30px 0 0 10px' } : { margin: '0 -30px 0 0' }, children: _jsxs(VictoryChart, { width: 500, height: isSmall ? 540 : 300, domainPadding: { x: state.months === 12 ? 20 : 50, y: 0 }, style: {
                        parent: {
                            height: isSmall ? '470px' : '350px',
                            marginTop: '-20px',
                            marginBottom: '5px',
                        },
                    }, children: [_jsx(VictoryAxis, { crossAxis: true, tickValues: getMonthsFromRange(startMonth, endMonth), tickFormat: function (month) { return capitalizeFirstLetter(month).slice(0, 3); }, style: { tickLabels: { fontSize: isSmall ? 20 : 14 } } }), _jsx(VictoryAxis, __assign({ dependentAxis: true, style: { tickLabels: { fontSize: isSmall ? 20 : 14 } } }, yAxisProps)), _jsx(VictoryLegend, { x: 20, y: 284, orientation: 'horizontal', gutter: 10, symbolSpacer: 8, data: legendData, style: {
                                labels: {
                                    fontSize: isSmall ? 20 : 14,
                                },
                            } }), showTarget && (_jsx(VictoryLegend, { x: 396, y: isSmall ? 536 : 284, orientation: 'horizontal', gutter: 10, symbolSpacer: 8, data: [
                                {
                                    name: 'Target',
                                },
                            ], dataComponent: _jsx(LegendLine, {}), style: {
                                labels: {
                                    fontSize: isSmall ? 20 : 14,
                                },
                            } })), _jsxs(VictoryGroup, { offset: zoneCount === 2 ? 35 : 25, children: [state.zones.overall && (_jsx(VictoryBar, { data: data.overall || [], colorScale: [theme.palette.customColor.intenseGreen], barWidth: barWidth })), state.zones.blue && (_jsx(VictoryBar, { data: data.blue || [], colorScale: [theme.palette.zoneBlue.main], barWidth: barWidth })), state.zones.green && (_jsx(VictoryBar, { data: data.green || [], colorScale: [theme.palette.zoneGreen.main], barWidth: barWidth })), state.zones.gold && (_jsx(VictoryBar, { data: data.gold || [], colorScale: [theme.palette.zoneGold.main], barWidth: barWidth }))] }), showTarget && !!target && (_jsx(VictoryLine, { y: function () { return target; }, style: {
                                data: {
                                    stroke: theme.palette.customColor.strokeRed,
                                    strokeWidth: '1px',
                                },
                            } }))] }) }))] }));
};
export default ZoneChart;
