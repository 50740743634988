import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import Chip from '@mui/material/Chip';
import { zoneChipStyle } from './ZoneChip.style';
import { ZoneColor } from 'globals/enums';
/**
 * @description component to display senior/resident zone
 * @function ZoneChip
 */
var ZoneChip = function (_a) {
    var zoneType = _a.zoneType;
    var classes = zoneChipStyle().classes;
    var zoneColor = ZoneColor["zone".concat(zoneType)];
    return (_jsx(Chip, { "data-testid": 'zone-chip', label: "".concat(zoneType, " Zone"), variant: 'filled', size: 'small', color: zoneColor, classes: { root: classes.welcomeContainer } }));
};
export default ZoneChip;
