import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@mui/material';
import ShowHyphen from 'common/ShowHyphen/ShowHyphen';
import { DATE_FORMAT } from 'globals/global.constants';
import moment from 'moment';
import { medicalConditionSectionStyles } from './MedicalConditionSection.style';
var MedicalConditionSection = function (_a) {
    var data = _a.data;
    var classes = medicalConditionSectionStyles().classes;
    var date = data.date_of_onset || '';
    return (_jsxs("section", { className: classes.section, "data-testid": 'print-medical-condition-section', children: [_jsxs(Box, { className: classes.header, children: [_jsx(Box, { className: classes.heading, children: data.condition }), _jsxs(Box, { className: classes.severity, children: [_jsx(Box, { className: classes.label, children: "Level of Severity:" }), _jsx(Box, { children: _jsx(ShowHyphen, { children: data.severity_level }) })] }), _jsxs(Box, { className: classes.date, children: [_jsx(Box, { className: classes.label, children: "Date of Onset:" }), _jsx(Box, { children: _jsx(ShowHyphen, { children: date && moment(date).format(DATE_FORMAT) }) })] })] }), _jsxs(Box, { className: classes.notesContainer, children: [_jsx(Box, { className: classes.notesLabel, children: "Notes:" }), _jsx(Box, { children: _jsx(ShowHyphen, { children: data.notes }) })] })] }));
};
export default MedicalConditionSection;
