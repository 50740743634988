import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CssBaseline } from '@mui/material';
import { ThemeProvider, responsiveFontSizes } from '@mui/material/styles';
import { theme } from 'config/theme.config';
var InitTheme = responsiveFontSizes(theme);
var WCThemeProvider = function (_a) {
    var children = _a.children;
    return (_jsxs(ThemeProvider, { theme: InitTheme, children: [_jsx(CssBaseline, {}), children] }));
};
export default WCThemeProvider;
