import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { ZoneColor, ZoneType } from 'globals/enums';
import { MenuItem, Select } from '@mui/material';
import { zoneSelectStyle } from './ZoneSelect.style';
var MENU_ITEMS = Object.values(ZoneType);
/**
 * @description component to display senior/resident zone
 * @function ZoneSelect
 */
var ZoneSelect = function (_a) {
    var _b = _a.zoneType, zoneType = _b === void 0 ? '' : _b, _c = _a.onChange, onChange = _c === void 0 ? function (e) { } : _c, _d = _a.hasAllOption, hasAllOption = _d === void 0 ? false : _d, disabled = _a.disabled;
    var zoneColor = (zoneType ? ZoneColor["zone".concat(zoneType)] : '');
    var classes = zoneSelectStyle({ zoneColor: zoneColor, hasAllOption: hasAllOption }).classes;
    return (_jsxs(Select, { disabled: disabled, displayEmpty: true, value: zoneType, onChange: onChange, className: classes.root, inputProps: {
            'data-testid': 'zone-select',
        }, onClick: function (e) {
            e.stopPropagation();
        }, children: [hasAllOption ? (_jsx(MenuItem, { "data-testid": 'allZones', value: '', children: "All Zones" })) : (_jsx(MenuItem, { "data-testid": 'selectZone', disabled: true, value: '', style: { display: 'none' }, children: "Select zone" })), MENU_ITEMS.map(function (zone) { return (_jsx(MenuItem, { "data-testid": "".concat(zone, "Zone"), value: zone, children: "".concat(zone, " Zone") }, zone)); })] }));
};
export default ZoneSelect;
