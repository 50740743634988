var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a, _b;
/* eslint-disable max-len */
import axios from 'axios';
import { APIMethod } from './enums';
// URL for Online Deployments: Port Number not Required
var CARE_PORTAL_DEFAULT_URL = "".concat((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.origin, "/api/");
// URL for Local Deployment includes Port Number
var CARE_PORTAL_LOCAL_URL = "".concat((_b = window === null || window === void 0 ? void 0 : window.location) === null || _b === void 0 ? void 0 : _b.origin, "/api/");
var CARE_PORTAL_URL = window.location.hostname === 'localhost'
    ? CARE_PORTAL_LOCAL_URL
    : CARE_PORTAL_DEFAULT_URL;
var CARE_PORTAL_URL_OLD = process.env.REACT_APP_CARE_PORTAL_URL_OLD;
// This contains all the request cancel object.
var cancelRequestList = {};
export var cancelRequests = function () {
    var keys = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        keys[_i] = arguments[_i];
    }
    keys.forEach(function (key) {
        if (cancelRequestList[key]) {
            cancelRequestList[key]();
            delete cancelRequestList[key];
        }
    });
};
export var cancelAllRequests = function () {
    Object.keys(cancelRequestList).forEach(function (key) {
        if (cancelRequestList[key]) {
            cancelRequestList[key](key);
            delete cancelRequestList[key];
        }
    });
};
function API(params) {
    var url = params.mode
        ? "".concat(CARE_PORTAL_URL_OLD).concat(params.mode, "/").concat(params.url)
        : "".concat(CARE_PORTAL_URL).concat(params.url);
    //need to stringify params in latest axios
    var stringifyParams = {};
    if (params.params) {
        Object.keys(params === null || params === void 0 ? void 0 : params.params).forEach(function (item) {
            if ((params === null || params === void 0 ? void 0 : params.params[item]) !== null && (params === null || params === void 0 ? void 0 : params.params[item]) !== undefined) {
                stringifyParams[item] =
                    typeof (params === null || params === void 0 ? void 0 : params.params[item]) === 'string'
                        ? params.params[item]
                        : JSON.stringify(params.params[item]);
            }
        });
    }
    return axios({
        url: url,
        params: stringifyParams,
        method: params.method,
        cancelToken: new axios.CancelToken(function (c) {
            cancelRequestList[params.cancelKey] = c;
        }),
        responseType: params.responseType,
        headers: __assign({}, params.headers),
        data: params.data,
    })
        .then(function (res) {
        var resData = res.data;
        // check api res for file
        if (resData instanceof ArrayBuffer) {
            return {
                file: resData,
            };
        }
        return {
            applicationCode: resData.application_code,
            message: resData.message,
            data: resData.data,
            error: resData.error,
        };
    })
        .catch(function (error) {
        return Promise.reject(error);
    });
}
function QuestionnaireAPI(params) {
    var url = params.mode
        ? "".concat(CARE_PORTAL_URL_OLD).concat(params.mode, "/").concat(params.url)
        : "".concat(CARE_PORTAL_URL).concat(params.url);
    //need to stringify params in latest axios
    var stringifyParams = {};
    if (params.params) {
        Object.keys(params === null || params === void 0 ? void 0 : params.params).forEach(function (item) {
            if ((params === null || params === void 0 ? void 0 : params.params[item]) !== null && (params === null || params === void 0 ? void 0 : params.params[item]) !== undefined) {
                stringifyParams[item] =
                    typeof (params === null || params === void 0 ? void 0 : params.params[item]) === 'string'
                        ? params.params[item]
                        : JSON.stringify(params.params[item]);
            }
        });
    }
    return axios({
        url: url,
        params: stringifyParams,
        method: params.method,
        cancelToken: new axios.CancelToken(function (c) {
            cancelRequestList[params.cancelKey] = c;
        }),
        responseType: params.responseType,
        headers: __assign({}, params.headers),
        data: params.data,
    })
        .then(function (res) {
        var resData = res === null || res === void 0 ? void 0 : res.data.content;
        // check api res for file
        if (resData instanceof ArrayBuffer) {
            return {
                file: resData,
            };
        }
        return {
            applicationCode: resData === null || resData === void 0 ? void 0 : resData.application_code,
            message: resData === null || resData === void 0 ? void 0 : resData.message,
            data: resData === null || resData === void 0 ? void 0 : resData.data,
            error: resData === null || resData === void 0 ? void 0 : resData.error,
        };
    })
        .catch(function (error) {
        console.log('error2', error);
        return Promise.reject(error);
    });
}
function UPLOAD_API(params) {
    var uninterceptedAxiosInstance = axios.create();
    return uninterceptedAxiosInstance({
        url: params.url,
        method: APIMethod.Post,
        data: params.uploadApiData,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
        .then(function (res) {
        var resData = res.data;
        // check api res for file
        if (resData instanceof ArrayBuffer) {
            return {
                file: resData,
            };
        }
        return {
            applicationCode: resData.application_code,
            message: resData.message,
            data: resData.data,
            error: resData.error,
        };
    })
        .catch(function (error) {
        return Promise.reject(error);
    });
}
export { API, UPLOAD_API, QuestionnaireAPI };
