import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable max-len */
import { Box, CircularProgress } from '@mui/material';
import React from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { emptySeniorDetail, emptySeniorImage, resetLocationDates, setSeniorImage, } from 'store/commonReducer/common.action';
import { getDashboardCareInsight } from 'pages/WCPages/SeniorDashboard/components/CareInsights/CareInsights.action';
import MemoryCareResidentDashboard from './MemoryCareResidentDashboard.component';
import { FACILITY_TYPES } from 'globals/enums';
import IndependentLivingResidentDashboard from './IndependentLivingResidentDashboard.component';
var ResidentDashboard = function () {
    var seniorDetail = useAppSelector(function (state) { return state.common; }).seniorDetail;
    var minimalInfo = seniorDetail.minimalInfo, isLoading = seniorDetail.isLoading;
    var dispatch = useAppDispatch();
    React.useEffect(function () {
        dispatch(setSeniorImage());
        dispatch(getDashboardCareInsight());
        return function () {
            dispatch(emptySeniorDetail());
            dispatch(emptySeniorImage());
            dispatch(resetLocationDates());
        };
    }, []);
    if (isLoading) {
        return (_jsx(Box, { display: 'flex', justifyContent: 'center', alignItems: 'center', "data-testid": 'loader', height: 'calc(100vh - 180px)', children: _jsx(CircularProgress, {}) }));
    }
    if (minimalInfo.facility_type === FACILITY_TYPES.INDEPENDENT_LIVING) {
        return _jsx(IndependentLivingResidentDashboard, {});
    }
    return _jsx(MemoryCareResidentDashboard, {});
};
export default ResidentDashboard;
