var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import moment from 'moment-timezone';
import { TextField, InputLabel, Checkbox, Select, FormControl, Radio, Input, useTheme, } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import CheckBoxOutlineBlankSharpIcon from '@mui/icons-material/CheckBoxOutlineBlankSharp';
import CheckBoxSharpIcon from '@mui/icons-material/CheckBoxSharp';
import { ReactComponent as Calender } from 'assets/icons/Calender.svg';
import { ReactComponent as Time } from 'assets/icons/Time.svg';
import { ZINDEX } from 'globals/global.constants';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';
var inputDatePickerStyles = makeStyles()(function (theme) { return ({
    root: {
        '&.MuiTextField-root': {
            backgroundColor: theme.palette.common.white,
            width: '100%',
            borderRadius: 5,
            paddingTop: 5,
            paddingLeft: 10,
            paddingRight: 10,
            margin: 0,
            height: 39,
            borderWidth: 0,
            '& .MuiInput-underline:before': {
                borderBottom: "0px solid ".concat(theme.palette.common.white),
            },
            '& .MuiInput-underline:after': {
                borderBottom: "0px solid ".concat(theme.palette.common.white),
            },
        },
    },
    error: {
        '& .MuiFormHelperText-root.Mui-error': {
            color: theme.palette.customColor.error,
            fontSize: 13,
            position: 'relative',
            right: 9,
        },
    },
    timePickerIconColor: {
        '& svg': {
            '& path': {
                fill: theme.palette.customColor.primaryGreen,
            },
        },
    },
}); });
export var OpenPickerIcon = function () {
    return _jsx(Calender, { "aria-label": 'right-align', height: 20 });
};
var OpenTimePickerIcon = function () {
    return _jsx(Time, { "aria-label": 'right-align', height: 20 });
};
var InputText = withStyles(function (props) {
    return _jsx(TextField, __assign({}, props));
}, function (theme) { return ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        borderRadius: 5,
        '& .MuiOutlinedInput-root': {
            height: 36,
            borderRadius: 5,
        },
        '& .MuiInputBase-multiline': {
            backgroundColor: '#FAFAFA',
            padding: 11,
            height: 'auto',
            '& .MuiInputBase-multiline:disabled': {
                color: '#a7a7a7',
            },
        },
    },
}); });
var InputMasked = withStyles(function (props) {
    return _jsx(Input, __assign({}, props));
}, function () { return ({
    root: {
        backgroundColor: '#EAF8F7',
        padding: '17.5px 14px',
        borderRadius: 5,
        height: 36,
        '& .MuiOutlinedInput-root': {
            height: 36,
        },
        '& .MuiOutlinedInput-multiline': {
            height: 'auto',
        },
        '&.MuiInput-underline::before': {
            borderBottom: '0px solid #fff',
        },
        '&.MuiInput-underline::after': {
            borderBottom: '0px solid #fff',
        },
        '&:hover': {
            '&.MuiInput-underline::before': {
                borderBottom: '0px solid #fff',
            },
            '&.MuiInput-underline::after': {
                borderBottom: '0px solid #fff',
            },
        },
    },
}); });
var InputControlledDatePicker = function (props) {
    var classes = inputDatePickerStyles().classes;
    return (_jsx(DatePicker, __assign({}, props, { components: {
            OpenPickerIcon: OpenPickerIcon,
        }, renderInput: function (params) { return (_jsx(TextField, __assign({ variant: 'standard' }, params, { sx: {
                svg: {
                    '& .cls-1': {
                        fill: props.disabled ? '#a7a7a7' : '#6ba539',
                    },
                },
            } }))); }, className: clsx(classes.root, classes.error, props.className), value: props.value })));
};
var InputTimePicker = function (props) {
    var _a;
    var classes = inputDatePickerStyles().classes;
    var _b = React.useState(null), customTime = _b[0], setCustomTime = _b[1];
    React.useEffect(function () {
        setCustomTime(props.value);
    }, [props.value]);
    return (_jsx(TimePicker, __assign({}, props, { components: {
            OpenPickerIcon: OpenTimePickerIcon,
        }, onOpen: function () {
            if (!props.value) {
                setCustomTime(moment().format());
            }
            if (props.onOpen) {
                props.onOpen();
            }
        }, onClose: function () {
            if (!props.value) {
                setCustomTime(null);
            }
            if (props.onClose) {
                props.onClose();
            }
        }, "data-error": props.isError ? 'error' : '', renderInput: function (params) { return _jsx(TextField, __assign({ variant: 'standard' }, params)); }, className: clsx(classes.root, classes.error, (_a = {}, _a[classes.timePickerIconColor] = !props.disabled, _a), props.className), showToolbar: true, value: customTime })));
};
var InputRadio = withStyles(function (props) { return _jsx(Radio, __assign({ color: 'default' }, props)); }, {
    root: {
        color: 'inherit',
        '& .MuiSvgIcon-root-199': {
            height: '1.2em',
            width: '1.2em',
        },
    },
    checked: {},
});
var InputCheckBox = withStyles(function (props) { return (_jsx(Checkbox, __assign({ icon: _jsx(CheckBoxOutlineBlankSharpIcon, {}), checkedIcon: _jsx(CheckBoxSharpIcon, {}), color: 'default' }, props))); }, function (_theme, _params, classes) {
    var _a;
    return ({
        root: (_a = {
                color: '#000',
                height: 25,
                width: 35,
                '&:hover': {
                    background: 'transparent',
                }
            },
            _a["&.".concat(classes.checked)] = {
                color: '#16A9D0',
            },
            _a['& svg'] = {
                width: '1.2em',
                height: '1.2em',
            },
            _a),
        checked: {},
    });
});
var Label = withStyles(function (props) { return _jsx(InputLabel, __assign({}, props)); }, function (theme) { return ({
    root: {
        color: '#000',
        marginBottom: theme.spacing(1),
    },
}); });
var InputSelect = withStyles(function (props) {
    var theme = useTheme();
    return (_jsx(FormControl, { style: { marginTop: 0, borderRadius: 5 }, margin: 'dense', size: 'small', fullWidth: true, children: _jsx(Select, __assign({ id: props.id, style: { borderRadius: 5 }, SelectDisplayProps: {
                style: {
                    backgroundColor: theme.palette.secondary.main,
                    paddingTop: 0,
                    paddingBottom: 0,
                },
            }, displayEmpty: true, MenuProps: {
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                },
                transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                },
                // getContentAnchorEl: null,
                style: { zIndex: ZINDEX.ACTION_ERROR },
            } }, props)) }));
}, function (theme) { return ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        borderRadius: 5,
    },
    outlined: {
        borderColor: theme.palette.secondary.main,
    },
}); });
export { InputText, Label, InputSelect, InputTimePicker, InputCheckBox, InputRadio, InputControlledDatePicker, InputMasked, };
