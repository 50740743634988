import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, CircularProgress } from '@mui/material';
import { theme } from 'config/theme.config';
var Circle = function (_a) {
    var _b, _c, _d, _e;
    var _f = _a.value, value = _f === void 0 ? 0 : _f, _g = _a.color, color = _g === void 0 ? (_c = (_b = theme === null || theme === void 0 ? void 0 : theme.palette) === null || _b === void 0 ? void 0 : _b.customColor) === null || _c === void 0 ? void 0 : _c.black : _g, _h = _a.bgColor, bgColor = _h === void 0 ? (_e = (_d = theme === null || theme === void 0 ? void 0 : theme.palette) === null || _d === void 0 ? void 0 : _d.customColor) === null || _e === void 0 ? void 0 : _e.bgGrey : _h, _j = _a.size, size = _j === void 0 ? 100 : _j, _k = _a.thickness, thickness = _k === void 0 ? 0.1 : _k, _l = _a.top, top = _l === void 0 ? 0 : _l, _m = _a.left, left = _m === void 0 ? 0 : _m;
    return (_jsxs(Box, { style: {
            position: 'absolute',
            left: 0,
            top: 0,
        }, "data-testid": 'circular-graph-circle', children: [_jsx(CircularProgress, { variant: 'determinate', value: 100, style: {
                    color: bgColor,
                    position: 'absolute',
                    top: top,
                    left: left,
                }, size: size, thickness: thickness }), _jsx(CircularProgress, { variant: 'determinate', value: value, style: {
                    color: color,
                    position: 'absolute',
                    top: top,
                    left: left,
                }, size: size, thickness: thickness })] }));
};
var CircularGraph = function (_a) {
    var _b, _c;
    var _d = _a.data, data = _d === void 0 ? [] : _d, _e = _a.bgColor, bgColor = _e === void 0 ? (_c = (_b = theme === null || theme === void 0 ? void 0 : theme.palette) === null || _b === void 0 ? void 0 : _b.customColor) === null || _c === void 0 ? void 0 : _c.bgGrey : _e, _f = _a.thickness, thickness = _f === void 0 ? 7.5 : _f, _g = _a.gap, gap = _g === void 0 ? 10 : _g, _h = _a.centerSize, centerSize = _h === void 0 ? 50 : _h;
    return (_jsx(Box, { position: 'relative', "data-testid": 'circular-graph', children: data.map(function (circle, i) {
            var size = centerSize + thickness * 2;
            if (i > 0) {
                size = size + i * (thickness + gap * 2) * 2;
            }
            var thicknessRatio = (thickness / size) * 100;
            var position = (data.length - i - 1) * (thickness + gap * 2);
            return (_jsx(Circle, { color: circle.color, bgColor: circle.bgColor || bgColor, value: circle.value > 100 ? 100 : circle.value, size: size, thickness: thicknessRatio, left: position, top: position }, circle.key));
        }) }));
};
export default CircularGraph;
