import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@mui/material';
import UCLALonelinessSection from 'common/Print/components/UCLALonelinessSection/UCLALonelinessSection.component';
import UCLALonelinessStats from 'common/Print/components/UCLALonelinessStats/UCLALonelinessStats.component';
import { startCase } from 'lodash';
var UCLALonelinessAssessmentTemplate = function (props) {
    var _a = props.data, data = _a === void 0 ? [] : _a;
    return (_jsxs(Box, { "data-testid": 'holistic-template', children: [data === null || data === void 0 ? void 0 : data.map(function (section) { return (_jsx(UCLALonelinessSection, { heading: startCase(section.surveyName), data: section.surveyData }, section.surveyName)); }), _jsx(UCLALonelinessStats, { data: data })] }));
};
export default UCLALonelinessAssessmentTemplate;
