var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { API } from 'globals/api';
import { VitalState } from 'globals/enums';
import { getAvgOfTwoValue, roundOff, getExistingVitalsData, } from 'globals/global.functions';
import { getClientTimezone } from 'globals/date.functions';
import { THRESHOLD_END_POINTS } from 'globals/apiEndPoints';
/**
 * @function getThresholdService
 * @description method to get all thresholds via api response.
 * @param {object} params API PAYLOAD
 * @returns promise response
 */
export function getThresholdService(params) {
    return __awaiter(this, void 0, Promise, function () {
        var response, error_1, errorMessage;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, API({
                            url: THRESHOLD_END_POINTS.GET_THRESHOLD,
                            method: 'get',
                            params: params,
                        })];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
                case 2:
                    error_1 = _a.sent();
                    console.log(error_1);
                    errorMessage = error_1.response.data.message;
                    throw new Error(errorMessage);
                case 3: return [2 /*return*/];
            }
        });
    });
}
/**
 * @description Service to get all the threshold vitals list
 * @param {object} params API PAYLOAD
 * @returns promise response
 */
export function getVitalsService(params, vitalInsightBody) {
    return __awaiter(this, void 0, Promise, function () {
        var clientTimezone, vitalInsightEndPointMap, response, resVitalsData, activeVitalsData_1, inActiveVitalsData_1, error_2, errorMessage;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    clientTimezone = getClientTimezone();
                    vitalInsightEndPointMap = getExistingVitalsData(clientTimezone);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, , 5]);
                    return [4 /*yield*/, API({
                            url: THRESHOLD_END_POINTS.GET_VITALS,
                            method: 'post',
                            data: params,
                        })];
                case 2:
                    response = _a.sent();
                    resVitalsData = response.data || [];
                    activeVitalsData_1 = [];
                    inActiveVitalsData_1 = [];
                    return [4 /*yield*/, Promise.all(resVitalsData.map(function (vital) {
                            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
                            var measurementName = vital.measurement_name;
                            if (vitalInsightEndPointMap[measurementName]) {
                                var data = {
                                    seniorId: vital.senior_id,
                                    measurementUnit: vitalInsightEndPointMap[vital.measurement_name].measurementUnit,
                                    measurementName: vital.measurement_name,
                                    currentState: vital.current_state,
                                    measurementTitle: vitalInsightEndPointMap[vital.measurement_name].label ||
                                        vital.measurement_name,
                                    vendorName: vital.vendor_name,
                                    modelNumber: vital.model_number,
                                    createdDate: vital.created_date,
                                    selected: false,
                                    baseline: {
                                        high: Number(roundOff((_a = vital.baseline_info) === null || _a === void 0 ? void 0 : _a.high)),
                                        low: Number(roundOff((_b = vital.baseline_info) === null || _b === void 0 ? void 0 : _b.low)),
                                        avg: Number(roundOff(getAvgOfTwoValue(parseFloat("".concat((_c = vital.baseline_info) === null || _c === void 0 ? void 0 : _c.high)), parseFloat("".concat((_d = vital.baseline_info) === null || _d === void 0 ? void 0 : _d.low))))),
                                    },
                                    actual: {
                                        high: Number(roundOff((_e = vital.actual_info) === null || _e === void 0 ? void 0 : _e.high)),
                                        low: Number((_f = vital.actual_info) === null || _f === void 0 ? void 0 : _f.low),
                                        avg: Number(roundOff(getAvgOfTwoValue(parseFloat("".concat((_g = vital.actual_info) === null || _g === void 0 ? void 0 : _g.high)), parseFloat("".concat((_h = vital.actual_info) === null || _h === void 0 ? void 0 : _h.low))))),
                                    },
                                    timestamp: {
                                        start: (_j = vital.timestamp) === null || _j === void 0 ? void 0 : _j.start,
                                        end: (_k = vital.timestamp) === null || _k === void 0 ? void 0 : _k.end,
                                    },
                                    threshold: {
                                        upHigh: (_l = vital.baseline_info) === null || _l === void 0 ? void 0 : _l.upper_action_pct,
                                        upLow: (_m = vital.baseline_info) === null || _m === void 0 ? void 0 : _m.upper_attention_pct,
                                        lowHigh: (_o = vital.baseline_info) === null || _o === void 0 ? void 0 : _o.lower_attention_pct,
                                        lowLow: (_p = vital.baseline_info) === null || _p === void 0 ? void 0 : _p.lower_action_pct,
                                    },
                                    isDisable: vitalInsightEndPointMap[measurementName].isDisable,
                                };
                                if ((vital.current_state === VitalState.ASSIGNED ||
                                    vital.current_state === VitalState.ACTIVE_INSIGHT) &&
                                    !vitalInsightEndPointMap[measurementName].isDisable) {
                                    activeVitalsData_1.push(data);
                                }
                                else {
                                    inActiveVitalsData_1.push(data);
                                }
                            }
                        }))];
                case 3:
                    _a.sent();
                    return [2 /*return*/, { activeVitalsData: activeVitalsData_1, inActiveVitalsData: inActiveVitalsData_1 }];
                case 4:
                    error_2 = _a.sent();
                    errorMessage = error_2.response.data.message;
                    throw new Error("".concat(errorMessage));
                case 5: return [2 /*return*/];
            }
        });
    });
}
/**
 * @description API service to update the vital state
 * @param {IVitalUpdatePayload} params API PAYLOAD
 * @returns promise response
 */
export function updateVitalStateService(params) {
    return __awaiter(this, void 0, Promise, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, API({
                        url: THRESHOLD_END_POINTS.UPDATE_VITAL_STATE,
                        method: 'post',
                        data: params,
                    })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
/**
 * @description API service to update the threshold config
 * @param {IVitalUpdateThresholdPayload} params API PAYLOAD
 * @returns promise response
 */
export function submitThresholdConfigService(params) {
    return __awaiter(this, void 0, Promise, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, API({
                        url: THRESHOLD_END_POINTS.UPDATE_THRESHOLD_CONFIG,
                        method: 'post',
                        data: params,
                    })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
/**
 * @description Service to fetch the vital data
 * @param measurementName
 * @param payload
 * @returns
 */
export var getVitalDataService = function (measurementName, payload) {
    var clientTimezone = getClientTimezone();
    var vitalInsightEndPointMap = getExistingVitalsData(clientTimezone);
    var selectedVitalConfig = vitalInsightEndPointMap[measurementName];
    return API({
        url: "".concat(THRESHOLD_END_POINTS.MEASUREMENT).concat(selectedVitalConfig.apiEndPoint),
        method: 'get',
        params: selectedVitalConfig.payload
            ? __assign(__assign({}, payload), selectedVitalConfig.payload) : payload,
    });
};
/**
 * @description service to fetch the vital baseline
 * @param measurementName vital name
 * @param payload
 * @returns {IBaselineValues} Baseline vlaues
 */
export var getRefreshedBaselineService = function (measurementName, payload) { return __awaiter(void 0, void 0, Promise, function () {
    var baselineValues, res, resData, error_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                baselineValues = {
                    high: 0,
                    low: 0,
                };
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, getVitalDataService(measurementName, payload)];
            case 2:
                res = _a.sent();
                resData = res.data.data;
                if (measurementName === 'activity_measurement') {
                    baselineValues.high = Number(roundOff(resData.max_high_activity));
                    baselineValues.low = Number(roundOff(resData.min_high_activity));
                }
                else {
                    if (Array.isArray(resData) && resData.length > 0) {
                        baselineValues.high = Math.max.apply(Math, resData.map(function (item) { return Number(item.value) || 0; }));
                        baselineValues.low = Math.min.apply(Math, resData.map(function (item) { return Number(item.value) || 0; }));
                    }
                }
                return [2 /*return*/, baselineValues];
            case 3:
                error_3 = _a.sent();
                return [2 /*return*/, new Error("".concat(error_3))];
            case 4: return [2 /*return*/];
        }
    });
}); };
/**
 * @description API service to download the detailed data
 * @param {IDownloadDetailDataPayload} params API PAYLOAD
 * @returns promise response
 */
export function downloadDetailedDataService(params, vitalName) {
    return __awaiter(this, void 0, Promise, function () {
        var clientTimezone, summaryEndpoint;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    clientTimezone = getClientTimezone();
                    summaryEndpoint = getExistingVitalsData(clientTimezone);
                    return [4 /*yield*/, API({
                            url: "".concat(THRESHOLD_END_POINTS.MEASUREMENT).concat(summaryEndpoint[vitalName].downloadEndPoint),
                            method: 'get',
                            params: summaryEndpoint[vitalName].reportPayload
                                ? __assign(__assign({}, params), summaryEndpoint[vitalName].reportPayload) : params,
                            headers: {
                                'Content-Type': 'blob',
                            },
                            responseType: 'arraybuffer',
                        })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
