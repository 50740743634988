import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-len */
import React from 'react';
import { Box, Table, TableBody, TableCell as MuiTableCell, TableContainer, TableRow, } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { assessmentScore, CGAssessmentResponseValues, } from 'globals/global.constants';
import { calculatePercentage } from 'globals/global.functions';
import { caregiverStrainSectionStyles } from './CaregiverStrainSection.style';
var TableCell = withStyles(MuiTableCell, {
    root: {
        padding: '5px 0',
    },
});
var CaregiverStrainStats = function (props) {
    var _a = props.data, data = _a === void 0 ? [] : _a;
    var _b = React.useState({
        no: 0,
        sometimes: 0,
        regular: 0,
    }), responses = _b[0], setResponses = _b[1];
    /**
     * @description to calculate response
     */
    React.useEffect(function () {
        var no = assessmentScore.NotSelected;
        var sometimes = assessmentScore.NotSelected;
        var regular = assessmentScore.NotSelected;
        data.forEach(function (item) {
            if (item.regular) {
                regular += 1;
            }
            else if (item.sometimes) {
                sometimes += 1;
            }
            else if (item.no) {
                no += 1;
            }
        });
        setResponses({ no: no, sometimes: sometimes, regular: regular });
    }, [data]);
    var maxScore = data.length * 2;
    var scoreOfNo = responses.no * CGAssessmentResponseValues.No;
    var scoreOfSometimes = responses.sometimes * CGAssessmentResponseValues.Sometimes;
    var scoreOfRegular = responses.regular * CGAssessmentResponseValues.Regular;
    var totalScore = React.useMemo(function () {
        return responses.no * 0 + responses.sometimes * 1 + responses.regular * 2;
    }, [responses]);
    var scorePercent = calculatePercentage(totalScore, maxScore);
    var responseTableRows = [
        {
            rowHeader: 'Response Count:',
            column1: responses.regular,
            column2: responses.sometimes,
            column3: responses.no,
        },
        {
            rowHeader: 'Points:',
            column1: scoreOfRegular,
            column2: scoreOfSometimes,
            column3: scoreOfNo,
        },
    ];
    var classes = caregiverStrainSectionStyles().classes;
    return (_jsx(TableContainer, { className: classes.statsTableContainer, children: _jsx(Table, { children: _jsxs(TableBody, { children: [responseTableRows.map(function (row) {
                        return (_jsx(TableRow, { children: _jsxs(TableCell, { className: classes.statsTable, children: [_jsx(Box, { className: classes.statsHeader, children: row.rowHeader }), _jsxs(Box, { className: classes.statsValueSection, children: [_jsx(Box, { className: classes.statsText, children: row.column1 }), _jsx(Box, { className: classes.statsText, children: row.column2 }), _jsx(Box, { className: classes.statsText, children: row.column3 })] })] }) }, row.rowHeader));
                    }), _jsx(TableRow, { children: _jsxs(TableCell, { className: classes.statsTable, children: [_jsx(Box, { className: classes.statsHeader, children: "Score:" }), _jsxs(Box, { className: classes.statsHeader, style: { marginRight: '36px' }, children: [totalScore, "/", maxScore, "=", scorePercent, "%"] })] }) })] }) }) }));
};
export default CaregiverStrainStats;
