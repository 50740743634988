import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable max-len */
import { Box } from '@mui/material';
import MedicalConditionSection from 'common/Print/components/MedicalConditionSection/MedicalConditionSection.component';
import React from 'react';
import { medicalConditionTemplateStyles } from './MedicalConditionTemplate.style';
var MedicalConditionTemplate = function (_a) {
    var data = _a.data;
    var indexWidth = React.useMemo(function () {
        var _a;
        var len = (_a = data === null || data === void 0 ? void 0 : data.length) === null || _a === void 0 ? void 0 : _a.toString().length;
        return len || 1;
    }, [data === null || data === void 0 ? void 0 : data.length]);
    var classes = medicalConditionTemplateStyles({ indexWidth: indexWidth }).classes;
    return (_jsx(Box, { className: classes.container, "data-testid": 'print-medical-condition', children: data === null || data === void 0 ? void 0 : data.map(function (sectionData, index) { return (_jsxs(Box, { className: classes.listItem, children: [_jsxs(Box, { className: classes.listIndex, children: [index + 1, "."] }), _jsx(MedicalConditionSection, { data: sectionData })] }, sectionData.condition)); }) }));
};
export default MedicalConditionTemplate;
