import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { getTimestamp, kgToLbs, roundOff } from 'globals/global.functions';
import { wellnessDashboardStyle } from '../../../WellnessDashboard.style';
import { bodyHealthStyle } from '../BodyHealth.styles';
import { VictoryAxis, VictoryBar, VictoryChart, VictoryStack, VictoryVoronoiContainer, } from 'victory';
import get from 'lodash.get';
import { DATE_FORMAT_SHORT } from 'globals/global.constants';
import clsx from 'clsx';
import { GraphView } from 'globals/enums';
var BodyComposition = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    var type = _a.type, summary = _a.summary, difference = _a.difference, lastRecordedDate = _a.lastRecordedDate, lastAvailableKey = _a.lastAvailableKey, graphTickValues = _a.graphTickValues;
    var theme = useTheme();
    var globalClasses = wellnessDashboardStyle().classes;
    var classes = bodyHealthStyle().classes;
    var compositionClasses = compositionStyle().classes;
    var muscleDiff = get(difference, 'current_mass.muscle_mass') -
        get(difference, 'previous_mass.muscle_mass');
    var fatDiff = get(difference, 'current_mass.fat_mass') -
        get(difference, 'previous_mass.fat_mass');
    var boneDiff = get(difference, 'current_mass.bone_mass') -
        get(difference, 'previous_mass.bone_mass');
    var graphData = get(summary, 'all_data');
    var isNoData = function (chart2) {
        var isData = false;
        Object.entries(chart2).forEach(function (_a) {
            var key = _a[0], value = _a[1];
            isData = value.find(function (ele) {
                return ele.y != null;
            });
        });
        return isData;
    };
    var createCompositionGraph = function (arr) {
        var chart1 = { bone: [], muscle: [], fat: [] };
        var chart2 = { bone: [], muscle: [], fat: [] };
        if (isEmpty(arr))
            return;
        Object.entries(arr).forEach(function (data) {
            chart1.bone.push({
                x: moment(getTimestamp(data[0])).format(type === GraphView.DAY ? 'LT' : DATE_FORMAT_SHORT),
                y: get(data[1], 'percentage.bone_mass'),
            });
            chart1.muscle.push({
                x: moment(getTimestamp(data[0])).format(type === GraphView.DAY ? 'LT' : DATE_FORMAT_SHORT),
                y: get(data[1], 'percentage.muscle_mass'),
            });
            chart1.fat.push({
                x: moment(getTimestamp(data[0])).format(type === GraphView.DAY ? 'LT' : DATE_FORMAT_SHORT),
                y: get(data[1], 'percentage.fat_mass'),
            });
        });
        graphTickValues.forEach(function (tick) {
            Object.entries(chart1).forEach(function (_a) {
                var key = _a[0], value = _a[1];
                var compositionData = value.find(function (data) {
                    return data.x === tick;
                });
                compositionData != undefined
                    ? chart2["".concat(key)].push({ x: tick, y: compositionData.y })
                    : chart2["".concat(key)].push({ x: tick, y: null });
            });
        });
        var data = isNoData(chart2);
        if (data) {
            return chart2;
        }
    };
    var bodyCompositionData = createCompositionGraph(graphData);
    var dailyAvgChartConfig = {
        chart: { width: 1000 },
        xAxis: { orientation: 'bottom' },
    };
    var loadDayPercentageChart = function () {
        return type === GraphView.DAY
            ? compositionDayChart()
            : compositionWeekChart();
    };
    var compositionDayChart = function () {
        var _a, _b, _c;
        var avgBoneMass = roundOff(get(summary, "all_data[".concat(lastAvailableKey, "].percentage.bone_mass")) || 0);
        var avgFatRatio = roundOff(get(summary, "all_data[".concat(lastAvailableKey, "].percentage.fat_mass")) || 0);
        var avgMuscleMass = roundOff(get(summary, "all_data[".concat(lastAvailableKey, "].percentage.muscle_mass")) || 0);
        return (_jsx(_Fragment, { children: _jsxs(Box, { height: 160, mb: 4, "data-testid": 'day-body-composition-bar', children: [_jsxs(Box, { className: compositionClasses.dayBarChart, height: "".concat(avgMuscleMass, "%"), children: [_jsx(Box, { className: compositionClasses.dayBarChartArea, style: { backgroundColor: theme.palette.background.green } }), _jsxs(Box, { ml: 1, display: 'flex', alignItems: 'center', children: [_jsx(Typography, { className: compositionClasses.dayBarChartText, children: "MUSCLE" }), _jsxs(Typography, { className: clsx(compositionClasses.dayBarChartValue, (_a = {},
                                            _a[compositionClasses.disableTextColor] = lastRecordedDate,
                                            _a)), children: [avgMuscleMass, "%"] })] })] }), _jsxs(Box, { className: compositionClasses.dayBarChart, height: "".concat(avgFatRatio, "%"), children: [_jsx(Box, { style: { backgroundColor: theme.palette.customColor.remGreen }, className: compositionClasses.dayBarChartArea }), _jsxs(Box, { ml: 1, display: 'flex', alignItems: 'center', children: [_jsx(Typography, { className: compositionClasses.dayBarChartText, children: "FAT" }), _jsxs(Typography, { className: clsx(compositionClasses.dayBarChartValue, (_b = {},
                                            _b[compositionClasses.disableTextColor] = lastRecordedDate,
                                            _b)), children: [avgFatRatio, "%"] })] })] }), _jsxs(Box, { className: compositionClasses.dayBarChart, height: "".concat(avgBoneMass, "%"), children: [_jsx(Box, { style: { backgroundColor: theme.palette.customColor.intenseGreen }, className: compositionClasses.dayBarChartArea }), _jsxs(Box, { ml: 1, display: 'flex', alignItems: 'center', children: [_jsx(Typography, { className: compositionClasses.dayBarChartText, children: "BONE" }), _jsxs(Typography, { className: clsx(compositionClasses.dayBarChartValue, (_c = {},
                                            _c[compositionClasses.disableTextColor] = lastRecordedDate,
                                            _c)), children: [avgBoneMass, "%"] })] })] })] }) }));
    };
    var compositionWeekChart = function () {
        if (isEmpty(bodyCompositionData)) {
            return (_jsxs(Box, { display: 'flex', flexDirection: 'column', children: [_jsx(Box, { style: {
                            textAlign: 'center',
                            width: '100%',
                            color: theme.palette.customColor.lighterBlack,
                            fontSize: 18,
                        }, mt: 6, children: "No Data" }), _jsx(VictoryChart, { width: dailyAvgChartConfig.chart.width, height: 150, children: _jsx(VictoryAxis, { orientation: dailyAvgChartConfig.xAxis.orientation, fixLabelOverlap: true, tickValues: graphTickValues, style: {
                                axis: { stroke: theme.palette.customColor.borderGrey },
                                tickLabels: {
                                    fill: theme.palette.customColor.lighterBlack,
                                    fontSize: 15,
                                },
                            } }) })] }));
        }
        else {
            return (_jsxs(VictoryChart, { domainPadding: { x: 20, y: 20 }, width: 790, height: 200, containerComponent: _jsx(VictoryVoronoiContainer, { activateLabels: true, labels: function (data) {
                        return data;
                    }, labelComponent: _jsx(Tooltip, { data: [
                            bodyCompositionData.bone,
                            bodyCompositionData.fat,
                            bodyCompositionData.muscle,
                        ] }) }), children: [_jsx(VictoryAxis, { orientation: 'bottom', fixLabelOverlap: true }), _jsxs(VictoryStack, { colorScale: [
                            theme.palette.customColor.intenseGreen,
                            theme.palette.customColor.remGreen,
                            theme.palette.background.green,
                        ], padding: { right: 89 }, children: [_jsx(VictoryBar, { barRatio: bodyCompositionData.bone.length === 1 ? 8 : undefined, data: bodyCompositionData.bone, barWidth: type === GraphView.WEEK ? 70 : 15 }), _jsx(VictoryBar, { barRatio: bodyCompositionData.fat.length === 1 ? 8 : undefined, data: bodyCompositionData.fat, barWidth: type === GraphView.WEEK ? 70 : 15 }), _jsx(VictoryBar, { barRatio: bodyCompositionData.muscle.length === 1 ? 8 : undefined, data: bodyCompositionData.muscle, barWidth: type === GraphView.WEEK ? 70 : 15 })] })] }));
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(Box, { className: classes.headerContainer, children: _jsx(Box, { className: globalClasses.cardTitle, children: "Body Composition" }) }), _jsxs(Grid, { container: true, direction: 'row', justifyContent: 'space-between', alignItems: 'center', "data-testid": 'body-composition-graph', children: [_jsxs(Grid, { item: true, sm: 5, className: compositionClasses.bodyContainerLeft, children: [_jsx(Box, { className: clsx(compositionClasses.square, (_b = {},
                                    _b[compositionClasses.noData] = type !== GraphView.DAY && !get(summary, "extremities", 0),
                                    _b), (_c = {},
                                    _c[compositionClasses.noData] = (type === GraphView.DAY && !muscleDiff) || lastRecordedDate,
                                    _c)), children: _jsxs(Grid, { container: true, direction: 'row', justifyContent: 'space-between', alignItems: 'center', children: [_jsx(Grid, { item: true, sm: 4, className: compositionClasses.squareLabel, children: "MUSCLE" }), _jsxs(Grid, { item: true, sm: 4, className: clsx(compositionClasses.squareValue, (_d = {},
                                                _d[compositionClasses.disableTextColor] = lastRecordedDate,
                                                _d)), children: [roundOff(type === GraphView.DAY
                                                    ? kgToLbs(muscleDiff)
                                                    : get(summary, "extremities.percentage.average_muscle_mass") || 0), type !== GraphView.DAY && "%"] }), type !== GraphView.DAY && (_jsxs(Grid, { item: true, sm: 4, className: clsx(compositionClasses.squareAvg, (_e = {},
                                                _e[compositionClasses.disableTextColor] = isEmpty(bodyCompositionData),
                                                _e)), children: [_jsxs("div", { children: ["High:", ' ', roundOff(get(summary, 'extremities.percentage.max_muscle_mass')) || 0, "%"] }), _jsxs("div", { children: ["Low:", ' ', roundOff(get(summary, 'extremities.percentage.min_muscle_mass')) || 0, "%"] })] }))] }) }), _jsx(Box, { className: clsx(compositionClasses.square, (_f = {},
                                    _f[compositionClasses.noData] = type !== GraphView.DAY && !get(summary, "extremities", 0),
                                    _f), (_g = {},
                                    _g[compositionClasses.noData] = (type === GraphView.DAY && !fatDiff) || lastRecordedDate,
                                    _g)), children: _jsxs(Grid, { container: true, direction: 'row', justifyContent: 'space-between', alignItems: 'center', children: [_jsx(Grid, { item: true, sm: 4, className: compositionClasses.squareLabel, children: "FAT" }), _jsxs(Grid, { item: true, sm: 4, className: clsx(compositionClasses.squareValue, (_h = {},
                                                _h[compositionClasses.disableTextColor] = lastRecordedDate,
                                                _h)), children: [roundOff(type === GraphView.DAY
                                                    ? kgToLbs(fatDiff)
                                                    : get(summary, "extremities.percentage.average_fat_mass") ||
                                                        0), type !== GraphView.DAY && "%"] }), type !== GraphView.DAY && (_jsxs(Grid, { item: true, sm: 4, className: clsx(compositionClasses.squareAvg, (_j = {},
                                                _j[compositionClasses.disableTextColor] = isEmpty(bodyCompositionData),
                                                _j)), children: [_jsxs("div", { children: ["High:", ' ', roundOff(get(summary, 'extremities.percentage.max_fat_mass')) || 0, "%"] }), _jsxs("div", { children: ["Low:", ' ', roundOff(get(summary, 'extremities.percentage.min_fat_mass')) || 0, "%"] })] }))] }) }), _jsx(Box, { className: clsx(compositionClasses.square, (_k = {},
                                    _k[compositionClasses.noData] = type !== GraphView.DAY && !get(summary, "extremities", 0),
                                    _k), (_l = {},
                                    _l[compositionClasses.noData] = (type === GraphView.DAY && !boneDiff) || lastRecordedDate,
                                    _l)), children: _jsxs(Grid, { container: true, direction: 'row', justifyContent: 'space-between', alignItems: 'center', children: [_jsx(Grid, { item: true, sm: 4, className: compositionClasses.squareLabel, children: "BONE" }), _jsxs(Grid, { item: true, sm: 4, className: clsx(compositionClasses.squareValue, (_m = {},
                                                _m[compositionClasses.disableTextColor] = lastRecordedDate,
                                                _m)), children: [roundOff(type === GraphView.DAY
                                                    ? kgToLbs(boneDiff)
                                                    : get(summary, "extremities.percentage.average_bone_mass") || 0), type !== GraphView.DAY && "%"] }), type !== GraphView.DAY && (_jsxs(Grid, { item: true, sm: 4, className: clsx(compositionClasses.squareAvg, (_o = {},
                                                _o[compositionClasses.disableTextColor] = isEmpty(bodyCompositionData),
                                                _o)), children: [_jsxs("div", { children: ["High:", ' ', roundOff(get(summary, 'extremities.percentage.max_bone_mass')) || 0, "%"] }), _jsxs("div", { children: ["Low:", ' ', roundOff(get(summary, 'extremities.percentage.min_bone_mass')) || 0, "%"] })] }))] }) })] }), _jsxs(Grid, { item: true, sm: 7, className: compositionClasses.graphContainer, children: [_jsxs(Box, { display: 'flex', justifyContent: 'space-between', pl: 2, children: [_jsx(Box, { className: compositionClasses.subHeading, children: "Day Percentage" }), lastRecordedDate && (_jsxs(Box, { className: clsx(globalClasses.lastRecordedTitle, globalClasses.lastRecordedRelativeStyle), children: ["Last recorded \u00A0", lastRecordedDate] }))] }), _jsx(Box, { display: 'flex', alignItems: 'center', justifyContent: 'center', children: loadDayPercentageChart() })] })] })] }));
};
var Tooltip = function (props) {
    var theme = useTheme();
    var x = props.x, data = props.data, datum = props.datum;
    var width = 130;
    var date = datum.x;
    var actual = [0, 0, 0];
    if (data.length) {
        actual = data === null || data === void 0 ? void 0 : data.map(function (val) {
            var exact = val.filter(function (fil) { return fil.x === date; });
            return exact[0].y;
        });
    }
    return (_jsxs("g", { transform: "translate(".concat(x - 65, ", 0)"), children: [_jsxs("g", { transform: 'translate(0, 0)', children: [_jsx("rect", { height: 60, width: width, stroke: theme.palette.background.green, fill: theme.palette.background.green, ry: 3, rx: 3 }), _jsx("circle", { cy: 15, cx: 10, r: 5, strokeWidth: 1, stroke: theme.palette.customColor.strokeGrey, fill: theme.palette.background.green }), _jsxs("text", { dx: 20, dy: 18, alignmentBaseline: 'inherit', textAnchor: 'start', fontSize: 12, fill: theme.palette.common.black, children: [_jsx("tspan", { children: "MUSCLE" }), _jsxs("tspan", { dx: 14, children: [roundOff(actual[2]), "%"] })] }), _jsx("circle", { cy: 30, cx: 10, r: 5, strokeWidth: 1, stroke: theme.palette.customColor.strokeGrey, fill: theme.palette.customColor.remGreen }), _jsxs("text", { dx: 20, dy: 34, 
                        //alignmentBaseline='middle'
                        textAnchor: 'start', fontSize: 12, fill: theme.palette.common.black, children: [_jsx("tspan", { children: "FAT" }), _jsxs("tspan", { dx: 38, children: [roundOff(actual[1]), "%"] })] }), _jsx("circle", { cy: 46, cx: 10, r: 5, strokeWidth: 1, stroke: theme.palette.customColor.strokeGrey, fill: theme.palette.customColor.intenseGreen }), _jsxs("text", { dx: 20, dy: 50, alignmentBaseline: 'middle', textAnchor: 'start', fontSize: 12, fill: theme.palette.common.black, children: [_jsx("tspan", { children: "BONE" }), _jsxs("tspan", { dx: 28, children: [roundOff(actual[0]), "%"] })] })] }), _jsx("g", { transform: "translate(".concat(width / 2, ", -16)"), children: _jsx("polygon", { points: '0,85 -6,76 8,76', stroke: theme.palette.background.green, fill: theme.palette.background.green, strokeWidth: '1' }) })] }, 'tooltip'));
};
export { BodyComposition };
var compositionStyle = makeStyles()(function (theme) { return ({
    bodyContainerLeft: {
        borderRight: "1px solid ".concat(theme.palette.customColor.lightSeparator),
    },
    graphContainer: {
        alignSelf: 'flex-start',
    },
    square: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        //minHeight: 70,
        width: '55%',
        borderRadius: 10,
        border: "solid 2px ".concat(theme.palette.customColor.squareGreen),
        padding: 5,
        margin: 'auto',
        marginTop: 20,
    },
    noData: {
        border: "solid 2px ".concat(theme.palette.customColor.noDataGrey),
    },
    squareLabel: {
        color: theme.palette.customColor.lighterBlack,
        // display: 'flex',
        // alignItems: 'center',
        justifyContent: 'center',
    },
    squareValue: {
        fontSize: 20,
        color: theme.palette.customColor.primaryGreen,
        //marginLeft: 3,
    },
    squareAvg: {
        color: theme.palette.common.black,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-between',
    },
    subHeading: {
        color: theme.palette.customColor.lighterBlack,
        fontSize: 18,
        textTransform: 'uppercase',
        position: 'relative',
        bottom: 25,
    },
    dayBarChart: {
        display: 'flex',
        minHeight: 15,
        alignItems: 'center',
    },
    dayBarChartArea: {
        height: '100%',
        width: 100,
    },
    dayBarChartText: {
        display: 'flex',
        fontSize: 14,
        color: theme.palette.customColor.lighterBlack,
        marginRight: 5,
    },
    dayBarChartValue: {
        fontSize: 17,
        fontWeight: 'bold',
        color: theme.palette.common.black,
    },
    disableTextColor: {
        color: theme.palette.customColor.noDataGrey,
    },
}); });
