var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import clsx from 'clsx';
import { Label } from 'common/Input';
import { commonStyle } from '../FormField.style';
var LabelText = function (_a) {
    var _b, _c;
    var _d = _a.label, label = _d === void 0 ? '' : _d, _e = _a.required, required = _e === void 0 ? false : _e, _f = _a.errorField, errorField = _f === void 0 ? undefined : _f, _g = _a.bold, bold = _g === void 0 ? false : _g, props = __rest(_a, ["label", "required", "errorField", "bold"]);
    var classes = commonStyle().classes;
    return (_jsx(_Fragment, { children: _jsxs(Label, { htmlFor: props.id, className: clsx((_b = {}, _b[classes.labelBold] = bold, _b), (_c = {},
                _c[classes.errorTextStyle] = errorField,
                _c)), "data-testid": 'labelText-component', children: [label, _jsx("span", { className: classes.errorTextSelect, children: required && "*" })] }) }));
};
export { LabelText };
