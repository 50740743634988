var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { InputSelect } from 'common/Input';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import { Controller } from 'react-hook-form';
import clsx from 'clsx';
import { commonStyle } from '../FormField.style';
var Menu = function (_a) {
    var _b = _a.input, input = _b === void 0 ? 'menu' : _b, _c = _a.menuItems, menuItems = _c === void 0 ? [] : _c, _d = _a.control, control = _d === void 0 ? null : _d, _e = _a.errorField, errorField = _e === void 0 ? undefined : _e, _f = _a.errorText, errorText = _f === void 0 ? '' : _f, _g = _a.defaultValue, defaultValue = _g === void 0 ? '' : _g, _h = _a.handleChange, handleChange = _h === void 0 ? {} : _h, props = __rest(_a, ["input", "menuItems", "control", "errorField", "errorText", "defaultValue", "handleChange"]);
    var classes = commonStyle().classes;
    return (_jsxs(_Fragment, { children: [_jsx(Controller, { render: function (_a) {
                    var _b, _c;
                    var field = _a.field;
                    return (_jsxs(InputSelect, __assign({}, field, { className: clsx((_b = {}, _b[classes.inputSelectError] = errorField, _b), (_c = {}, _c[classes.whiteBackground] = props.white, _c)), variant: 'outlined', displayEmpty: true, onChange: function (e) {
                            field.onChange(e);
                            handleChange(e.target.value);
                        } }, props, { inputProps: {
                            'data-testid': 'menu-component',
                            classes: {
                                icon: classes.icon,
                            },
                        }, children: [_jsx(MenuItem, { value: '', disabled: true, children: "Select" }), menuItems.map(function (data) { return (_jsx(MenuItem, { value: data.key, children: data.value }, data.key)); })] })));
                }, defaultValue: defaultValue, name: props.name, control: control, 
                // value={props.value}
                rules: props.rules }), errorText && _jsx(Box, { className: classes.errorTextSelect, children: errorText })] }));
};
export { Menu };
