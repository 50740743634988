import { makeStyles } from 'tss-react/mui';
export var loaderStyles = makeStyles()(function (theme) { return ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 999,
        color: '#fff',
    },
    loaderContainer: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        lineHeight: 4,
        '& div': {
            fontWeight: 600,
            fontSize: 16,
        },
    },
}); });
