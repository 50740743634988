import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { fieldsStyle } from '../InputFields.style';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import clsx from 'clsx';
import { InputDatePicker } from '../../Input';
import { DATE_FORMAT } from '../../../globals/global.constants';
import { Typography } from '@mui/material';
var SelectDate = function (_a) {
    var _b;
    var label = _a.label, errorField = _a.errorField, disableFutureDate = _a.disableFutureDate, props = _a.props, errorText = _a.errorText;
    var classes = fieldsStyle().classes;
    return (_jsxs(LocalizationProvider, { dateAdapter: AdapterDateFns, children: [_jsx(InputDatePicker, { id: label, clearable: true, 
                //disableToolbar
                style: { marginBottom: 4 }, className: clsx((_b = {}, _b[classes.inputDateError] = errorField, _b)), variant: 'inline', "data-error": errorField ? 'error' : '', disableFuture: disableFutureDate, autoOk: true, inputProps: {
                    placeholder: DATE_FORMAT,
                }, InputProps: {
                    disableUnderline: true,
                }, invalidDateMessage: '', format: 'MM/dd/yyyy', onAccept: props.onChange
                    ? props.onChange
                    : function () {
                        return;
                    }, onChange: props.onChange
                    ? props.onChange
                    : function () {
                        return;
                    }, value: function () {
                    return null;
                }, disabled: props.disabled ? true : false }), errorText && (_jsx(Typography, { variant: 'subtitle1', className: classes.errorTextSelect, children: errorText }))] }));
};
export default SelectDate;
