import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@mui/material';
import { appStyle } from 'App.style';
import Footer from 'common/Footer/Footer.component';
import Header from 'common/Header';
import { PRINT_HIDE_CLASS } from 'common/Print/Print.types';
import { Outlet } from 'react-router-dom';
import { WCThemeProvider } from 'themes';
var WCLayout = function () {
    var classes = appStyle().classes;
    return (_jsx(WCThemeProvider, { children: _jsxs(Box, { className: PRINT_HIDE_CLASS, children: [_jsx(Header, { showMenu: true }), _jsx(Box, { className: classes.layout, children: _jsx(Outlet, {}) }), _jsx(Footer, {})] }) }));
};
export { WCLayout };
