var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { getCurrentSenior } from 'globals/global.functions';
import { useAppDispatch } from './reduxHooks';
import React from 'react';
import { useQuery } from 'utilities/react-query';
import { getFallDetectionListService, getSOSListService, } from 'services/alarmService/alarm.service';
import { showError } from 'store/commonReducer/common.action';
export var useSOSData = function () {
    var dispatch = useAppDispatch();
    var params = React.useMemo(function () {
        var seniorInfo = getCurrentSenior();
        var date = new Date();
        var days = 30;
        var startDate = new Date(date.getTime() - days * 24 * 60 * 60 * 1000);
        var endDateTime = new Date();
        var endDateTimeStamp = Math.round(endDateTime / 1000) * Math.pow(10, 9);
        var startDateTimestamp = Math.round(startDate / 1000) * Math.pow(10, 9);
        return {
            senior_id: seniorInfo.seniorID,
            account_id: seniorInfo.accountID,
            start_timestamp: startDateTimestamp,
            end_timestamp: endDateTimeStamp,
        };
    }, []);
    //query to fetch sosData
    var _a = useQuery({
        queryKey: ['sosData'],
        queryFn: function () {
            return getSOSListService(params);
        },
        onError: function (error) {
            dispatch(showError(error));
        },
        cacheTime: 0,
    }), _b = _a.data, sosData = _b === void 0 ? [] : _b, sosLoading = _a.isLoading;
    //query to fetch fallData
    var _c = useQuery({
        queryKey: ['fallData'],
        queryFn: function () {
            return getFallDetectionListService(params);
        },
        onError: function (error) {
            dispatch(showError(error));
        },
        cacheTime: 0,
    }), _d = _c.data, fallData = _d === void 0 ? [] : _d, fallLoading = _c.isLoading;
    var sosFallData = React.useMemo(function () {
        var filteredSOSData = sosData
            .filter(function (sos) { return sos.time; })
            .map(function (sos) {
            return __assign(__assign({}, sos), { type: 'SOS' });
        });
        var filteredFallData = fallData
            .filter(function (fall) { return fall.time; })
            .map(function (fall) {
            return __assign(__assign({}, fall), { type: 'Fall' });
        });
        var finalData = __spreadArray(__spreadArray([], filteredSOSData, true), filteredFallData, true);
        finalData.sort(function (a, b) { return b.time - a.time; });
        return finalData.slice(0, 20);
    }, [sosData, fallData]);
    return { sosFallData: sosFallData, sosLoading: sosLoading, fallLoading: fallLoading };
};
