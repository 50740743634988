import AutoCompleteAPI from '../classes/GoogleMapAddressAPI';
import { autoCompleteEvents } from 'globals/enums';
import React from 'react';
import { initialStateSelectedAddress, } from './useAutoComplete.types';
var autoCompleteAPI = new AutoCompleteAPI();
/**
 * @description Custom hook to use google map autocomplete inside input field.
 * @component useAutoComplete
 * @param {IUseAutoCompleteProps} autocompleteConfig
 * @returns
 */
var useAutoComplete = function (_a) {
    var autocompleteConfig = _a.autocompleteConfig;
    var _b = React.useState(initialStateSelectedAddress), selectedLocation = _b[0], setSelectedLocation = _b[1];
    // useEffect to add addeventlistener on input
    React.useEffect(function () {
        var _a;
        var onChange = autocompleteConfig.onChange, inputRef = autocompleteConfig.inputRef;
        (_a = inputRef === null || inputRef === void 0 ? void 0 : inputRef.current) === null || _a === void 0 ? void 0 : _a.addEventListener('keyup', onChange);
        return function () {
            var _a;
            (_a = inputRef === null || inputRef === void 0 ? void 0 : inputRef.current) === null || _a === void 0 ? void 0 : _a.removeEventListener('keyup', onChange);
        };
    }, [autocompleteConfig]);
    // use effect to call google map API
    React.useEffect(function () {
        var initialInputValue = autocompleteConfig.initialInputValue, inputRef = autocompleteConfig.inputRef, autocompleteOptions = autocompleteConfig.autocompleteOptions;
        //set default value to input field
        if (initialInputValue) {
            inputRef.current.value = initialInputValue;
        }
        // Google map places api
        var autoComplete = new google.maps.places.Autocomplete(inputRef === null || inputRef === void 0 ? void 0 : inputRef.current, autocompleteOptions);
        /**
         * @function handlePlaceSelect
         * @description Function to format the selected address into our use case format
         */
        var handlePlaceSelect = function () {
            var addressDetails = autoCompleteAPI.getAddress(autoComplete);
            inputRef.current.value = addressDetails.streetAddress;
            setSelectedLocation(addressDetails);
        };
        autoComplete.addListener(autoCompleteEvents.placeChanged, function () {
            handlePlaceSelect();
        });
    }, [autocompleteConfig]);
    return [selectedLocation];
};
export default useAutoComplete;
