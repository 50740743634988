import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Table, TableBody, TableCell as MuiTableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { getScoreFalls_ass } from 'common/Print/Print.utility';
import { InputFields } from '../InputFields';
import React from 'react';
import { fallsAndHospitalizationSectionStyles } from './FallsAndHospitalizationSection.style';
var getValue = function (radioData) {
    if (radioData.yes == 1) {
        return 'yes';
    }
    else if (radioData.no == 1) {
        return 'no';
    }
    else if (radioData.comment == 1) {
        return radioData.commentText || 'comment';
    }
    else {
        return '';
    }
};
var TableCell = withStyles(MuiTableCell, {
    root: {
        borderBottom: 'none',
        padding: '5px 0',
    },
});
var FallsAndHospitalizationSection = function (props) {
    var _a = props.heading, heading = _a === void 0 ? '' : _a, _b = props.data, data = _b === void 0 ? [] : _b;
    var dataArray = Array.isArray(data) ? data : [];
    var maxScore = React.useMemo(function () { return data.length * 3; }, [dataArray]);
    var totalScore = React.useMemo(function () {
        return dataArray.reduce(function (acc, b) { return acc + getScoreFalls_ass(b); }, 0);
    }, [dataArray]);
    var classes = fallsAndHospitalizationSectionStyles().classes;
    return (_jsxs(Box, { component: 'section', className: classes.section, "data-testid": 'print-fallsAndHospitalization-section', children: [_jsxs(Box, { className: classes.header, children: [_jsx("div", { className: classes.heading, children: heading }), _jsx("div", { className: classes.tableHeading, style: { paddingRight: '30px' }, children: "Total(Max Score is ".concat(maxScore, ")= ").concat(totalScore) })] }), _jsx(Box, { className: classes.container, children: _jsx(TableContainer, { children: _jsxs(Table, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, { children: _jsx("div", { style: { width: '1580px' } }) }), _jsx(TableCell, { align: 'right', children: _jsxs(Box, { className: classes.radioGroupHeader, children: [_jsx(Box, { className: classes.tableHeading, children: "Yes" }), _jsx(Box, { className: classes.tableHeading, children: "No" })] }) })] }) }), _jsx(TableBody, { children: dataArray.map(function (row, index) { return (_jsxs(TableRow, { children: [_jsx(TableCell, { component: 'th', scope: 'row', children: _jsxs(Box, { className: classes.tableContent, children: [_jsxs(Box, { children: [index + 1, ".)"] }), _jsxs(Box, { children: [row.question, _jsx("span", { style: { color: '#cc0000' }, children: "*" })] })] }) }), _jsx(TableCell, { children: row.comment ? (_jsx(TextField, { InputProps: {
                                                    classes: {
                                                        input: classes.tableHeading2,
                                                    },
                                                }, value: row.comment || '', placeholder: 'Comment' })) : (_jsx(InputFields, { className: classes.radioGroup, radio: true, inputProps: {
                                                    name: 'row.question',
                                                }, eventProps: {
                                                    value: getValue(row),
                                                }, radioItems: [
                                                    { value: 'yes', label: 'Yes' },
                                                    { value: 'no', label: 'No' },
                                                ] })) })] }, row.question)); }) })] }) }) })] }));
};
export default FallsAndHospitalizationSection;
