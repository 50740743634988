export var SET_PREVIEW_DATA = 'SET_PREVIEW_DATA';
export var RESET_PREVIEW_DATA = 'RESET_PREVIEW_DATA';
export var setPreviewData = function (payload) {
    return {
        type: SET_PREVIEW_DATA,
        payload: payload,
    };
};
export var resetPreviewData = function () {
    return {
        type: RESET_PREVIEW_DATA,
    };
};
